import { Component, OnInit, Input } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { isEmpty, sortBy } from 'lodash';

import * as SharedFunctions from 'apps/contract-estimator/src/app/shared/utils/functions.shared';

@Component({
  selector: 'closing-ratio-table',
  templateUrl: './closing-ratio-table.component.html',
  styleUrls: ['./closing-ratio-table.component.scss']
})
export class ClosingRatioTableComponent implements OnInit {
  _data: Array<any>;
  @Input() set data(data: Array<any>) {
    this._data = data;
    if (!isEmpty(data)) {
      let keys = SharedFunctions.getAllKeysInArray(data);
      keys = sortBy(keys, [
        function(key) {
          return key;
        }
      ]);
      const columns = SharedFunctions.composeKeyValueTableColumns(keys);
      this.cols = columns;
    }
  }
  @Input() cols: Array<any>;
  @Input() maxValues: any;
  @Input() highlightMaxCells: boolean;
  @Input() tableType: string;
  @Input() title: string;
  @Input() formatHeaderAsDate: boolean = false;

  constructor() {}

  ngOnInit() {}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  // Gets max value css class
  getMaxValueCSSClass(rowDataValue, fieldName, salesRepName) {
    try {
      if (salesRepName === 'Company') return null;
      if (!this.highlightMaxCells) return null;
      if (rowDataValue === this.maxValues[fieldName] && rowDataValue != 0) {
        return 'max-value';
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
