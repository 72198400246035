import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'contractor-payroll-list-options-menu',
  templateUrl: './payroll-list-options-menu.component.html',
  styleUrls: ['./payroll-list-options-menu.component.scss']
})
export class PayrollListOptionsMenuComponent implements OnInit {

  @Input() _id;
  @Input() status;

  @Output() deletedPayroll = new Subject();
  @Output() navigatedToPayroll = new Subject();

  constructor() { }

  ngOnInit(): void {
  }


  delete() {
    this.deletedPayroll.next();
  }

  navigateToPayroll() {
    this.navigatedToPayroll.next();
  }

}
