import {MenuItem} from "primeng/api";
import {TradeComponentMenuItem} from "../shared/trade-component-menu-item.class";
import {TradeActionMenuItem} from "../shared/trade-action-menu-item.class";
import {NavigateToInvoicePayload} from "@contract-estimator/shared/classes";
import {QueryParamsMenu} from "../shared/query-params-menu.class";

export class BillingMenu implements  MenuItem{
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded: boolean
  ) {}

  static from (callback: any, expanded: boolean) {
    const navigateToInvoicePayload = new NavigateToInvoicePayload();
    const invoiceMenuItem =  new TradeActionMenuItem(
      'INVOICE',
      null,
      callback(navigateToInvoicePayload)
    )

    const jobCostsMenuItem =  new TradeComponentMenuItem(
      'JOB COSTS',
      ['job-costs'],
      new QueryParamsMenu(null, true)
    )

    const items = [
      invoiceMenuItem,
      jobCostsMenuItem
    ]

    return new BillingMenu(
      "BILLING",
      items,
      expanded
    )

  }

}
