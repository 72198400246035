import { AgreementRecipient } from '../agreement-recipient/agreement-recipient.class';

export class JobCostsMetadata {
  markup: number;
  changeOrder: number;
  sales: number;
  referredBySalesRep: boolean;
  commissionPaymentStatus: string;
  agreementPartyRecipient: AgreementRecipient;
  agreementPartyManager: AgreementRecipient;

  constructor(builder: JobCostsMetadataBuilderState) {
    this.markup = builder.markup;
    this.changeOrder = builder.changeOrder;
    this.sales = builder.sales;
    this.referredBySalesRep = builder.referredBySalesRep;
    this.commissionPaymentStatus = builder.commissionPaymentStatus;
    this.agreementPartyRecipient = builder.agreementPartyRecipient;
    this.agreementPartyManager = builder.agreementPartyManager;
  }
}

interface JobCostsMetadataBuilderState {
  markup: number;
  changeOrder: number;
  sales: number;
  referredBySalesRep: boolean;
  commissionPaymentStatus: string;
  agreementPartyRecipient: AgreementRecipient;
  agreementPartyManager: AgreementRecipient;
}

interface JobCostsMetadataBuilderBehavior {
  setMarkup: Function;
  setChangeOrder: Function;
  setSales: Function;
  setReferredBySalesRep: Function;
  setCommissionPaymentStatus: Function;
  setAgreementPartyRecipient: Function;
  setAgreementPartyManager: Function;
  build: Function;
}

export class JobCostsMetadataStateBuilder
  implements JobCostsMetadataBuilderBehavior, JobCostsMetadataBuilderState {
  markup: number;
  changeOrder: number;
  sales: number;
  referredBySalesRep: boolean;
  commissionPaymentStatus: string;
  agreementPartyRecipient: AgreementRecipient;
  agreementPartyManager: AgreementRecipient;

  setMarkup(value: number) {
    this.markup = value;
    return this;
  }
  setChangeOrder(value: number) {
    this.changeOrder = value;
    return this;
  }
  setSales(value: number) {
    this.sales = value;
    return this;
  }
  setReferredBySalesRep(value: boolean) {
    this.referredBySalesRep = value;
    return this;
  }
  setCommissionPaymentStatus(value: string) {
    this.commissionPaymentStatus = value;
    return this;
  }
  setAgreementPartyRecipient(value: AgreementRecipient) {
    this.agreementPartyRecipient = value;
    return this;
  }
  setAgreementPartyManager(value: AgreementRecipient) {
    this.agreementPartyManager = value;
    return this;
  }

  build() {
    return new JobCostsMetadata(this);
  }
}

export class JobCostsMetadataDirector {
  private builder: JobCostsMetadataBuilderBehavior;

  setBuilder(builder: JobCostsMetadataBuilderBehavior) {
    this.builder = builder;
  }

  createJobCostsMetadataPartialState(
    markup: number,
    changeOrder: number,
    sales: number,
    referredBySalesRep: boolean,
    commissionPaymentStatus: string,
    agreementaPartyRecipient: AgreementRecipient,
    agreementaPartyManager: AgreementRecipient
  ): JobCostsMetadata {
    return this.builder
      .setMarkup(markup)
      .setChangeOrder(changeOrder)
      .setSales(sales)
      .setReferredBySalesRep(referredBySalesRep)
      .setCommissionPaymentStatus(commissionPaymentStatus)
      .setAgreementPartyRecipient(agreementaPartyRecipient)
      .setAgreementPartyManager(agreementaPartyManager)
      .build();
  }
}
