import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

const selectId = entity => entity._id;

const entityMetadata: EntityMetadataMap = {
  TeamMember: {
    selectId
  }
};

const pluralNames = {};

export const teamMemberEntityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
