import { Component, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'instructions-text-editor',
  templateUrl: './instructions-text-editor.component.html',
  styleUrls: ['./instructions-text-editor.component.scss']
})
export class InstructionsTextEditorComponent implements OnInit {
  @Input() text: string;
  @Input() title: string;
  @Output() textChange = new Subject();

  constructor() {}

  ngOnInit() {}

  onTextChanges(values: any) {
    this.textChange.next(values);
  }
}
