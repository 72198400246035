/**
 * JobCostsSection Interface
 * Implemented by the Estimated and Actual job costs section estimator
 */
import {
  AggregatedTotals,
  IMiscellaneousCost,
  IMiscellaneousCostVM,
  JobCostsTotal,
  JobCostsTradeSection
} from "@contract-estimator/shared/interfaces";

export class JobCostsSection {
  _id: string;

  _woOrPoId: string;

  _orderId: string;

  _sectionId: string;

  name: string;

  total: number;

  calculateTotal: (calculateSectionTotalInput) => void;
}

/**
 * MiscellaneousCosts
 * Implemented by the estimated and actual miscellaneous costs estimator
 */
export class MiscellaneousCost implements  IMiscellaneousCost{

  get total(): number {
    return +this.qty * +this.price;
  }
  constructor(
    public _id: string,
  public name: string,
  public qty: number,
  public price: number,
    public isDeletable?: boolean
  ) {
  }

  // Creates instance from id only
  static fromId(_id: string): MiscellaneousCost {
    if(!_id) throw new Error('Miscellaneous cost ID is required');
    return new MiscellaneousCost(_id, "", 0, 0, true);
  }

  // Create instance from VM object
  static fromVM(cost: IMiscellaneousCostVM): MiscellaneousCost {
    return new MiscellaneousCost(
      cost._id,
      cost.name ,
      cost.estimatedQty,
      cost.estimatedPrice
    )
  }
}

export class JobCostsTradeChildren {
  workOrderSections: JobCostsSection[];

  purchaseOrderSections: JobCostsSection[];

  miscellaneousCosts: MiscellaneousCost[];
}

export class ActualJobCostsTradeChildren extends JobCostsTradeChildren {
  contractType: string;
}

export class EstimatedJobCostsTradeChildren extends JobCostsTradeChildren {}


export class AdditionalScopeJobCostsSummary implements  JobCostsTotal {
  constructor(
    public name?: string,
    public estimatedTotal?: number
  ) {
  }

}


export class AdditionalScopeJobCostsTradeSection implements JobCostsTradeSection {
  constructor(
   public  _id?: string,
  public _woOrPoId?: string,
  public _orderId?: string,
  public _sectionId?: string,
  public name?: string,
  public estimatedTotal?: number
  ) {}
}


export class AdditionalScopeJobCostsAggregatedTotal  implements  AggregatedTotals {
  constructor(public estimatedTotal: number) {}

}
