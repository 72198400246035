import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';

import * as models from './team-member.models';
import { ApolloTeamMemberManagerService } from '../providers/apollo-team-member-manager.service';
import { Go } from '@contract-estimator/shared/router/data-access';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberEntityCollectionService extends EntityCollectionServiceBase<
  models.TeamMember
> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apolloTeamMemberManagerService: ApolloTeamMemberManagerService
  ) {
    super('TeamMember', serviceElementsFactory);
  }

  [name: string]: any;

  /**
   * Create a new team member
   * @param payload
   */
  createTeamMember(payload: models.CreateTeamMemberServicePayload) {
    return this.apolloTeamMemberManagerService.createTeamMember(payload);
  }

  /**
   * Update a team member
   * @param criteria
   * @param changes
   */
  updateTeamMember(
    criteria: models.UpdateTeamMemberServiceCriteria,
    changes: models.UpdateTeamMemberServiceChanges
  ) {
    return this.apolloTeamMemberManagerService.updateTeamMember(
      criteria,
      changes
    );
  }

  /**
   * Search team members by search criteria
   * @param searchCriteria
   */
  searchTeamMembers(
    searchCriteria: models.SearchTeamMembersServiceSearchCriteria
  ) {
    return this.apolloTeamMemberManagerService.searchTeamMembers(
      searchCriteria
    );
  }

  /**
   * Update the url with search criteria
   * @param searchCriteria
   */
  updateUrlWithSearchCriteria(
    searchCriteria?: models.SearchTeamMembersServiceSearchCriteria
  ) {
    if (!!searchCriteria) {
      this.dispatch(
        new Go({
          path: ['/settings/team-members'],
          extras: {
            queryParams: {
              ...searchCriteria
            }
          }
        })
      );
    } else {
      this.dispatch(
        new Go({
          path: ['/settings/team-members']
        })
      );
    }
  }
}
