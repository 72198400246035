import { isEmpty } from 'lodash';

// Calculates work order estimate totals using section totals
export function calculateWorkOrderSectionTotal(sections) {
  if (isEmpty(sections)) return 0;
  let total = 0;
  for (let i = 0; i < sections.length; i++) {
    if (isEmpty(sections[i])) continue;
    const labors = sections[i].labors;
    if (isEmpty(labors)) continue;
    for (let j = 0; j < labors.length; j++) {
      if (isEmpty(labors[j])) continue;
      if (isNaN(labors[j].price) || isNaN(labors[j].qty)) continue;
      total += labors[j].price * labors[j].qty;
    }
  }
  return total;
}

// Calculates work order estimate totals using section totals
export function calculateGrandPurchaseOrderTotalFromSectionTotalsWithTax(sections) {
  if (isEmpty(sections)) return 0;
  let total = 0;
  for (let i = 0; i < sections.length; i++) {
    if (isEmpty(sections[i])) continue;
    const section = sections[i];
    total += section.total;
  }
  return total;
}
