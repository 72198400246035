import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'contract-total-table',
  templateUrl: './contract-total-table.component.html',
  styleUrls: ['./contract-total-table.component.scss']
})
export class ContractTotalTableComponent implements OnInit {
  @Input() total: number;
  @Input() altLabel: string = '';

  constructor() {}

  ngOnInit() {}

  // Simple currency validation
  isNaN(val: any) {
    return isNaN(val);
  }
}
