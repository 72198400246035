import {
  FormArray,
  FormControl,
  ValidatorFn,
  AbstractControl,
  FormGroup
} from '@angular/forms';
import { isEmpty } from 'lodash';
import * as moment from 'moment';

export class SyncValidatorsService {
  // Accepts currency values only 123.34, 1200.00, etc
  currencyValidator(c: FormControl | { value: any }) {
    if (c.value) {
      let NUMBERS_REGEX = /^-?[0-9]+((\.)([0-9]*))?$/g;
      if (!NUMBERS_REGEX.test(c.value)) {
        return { invalidAmount: true };
      }
      return null;
    }
    return null;
  }

  // Email validator
  emailValidator(c: FormControl | { value: any }) {
    if (c.value) {
      var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!EMAIL_REGEXP.test(c.value)) {
        return { invalidEmail: true };
      }
    }
    return null;
  }

  // Validate if invoice has one or more line items
  lineItems(fa: FormArray) {
    let newLineItems = [];
    if (!isEmpty(fa.value)) {
      newLineItems = fa.value.filter(
        line => line.description !== '' && line.amount !== ''
      );
    }
    return isEmpty(newLineItems) ? { invalidLineItems: true } : null;
  }

  // Uses moment.js to check if it is a valid date
  isAValidDate(c: FormControl) {
    try {
      const newDate = moment(c.value, 'MM/DD/YYYY', true);
      return newDate.isValid() ? null : { invalidDate: true };
    } catch (e) {
      return null;
    }
  }

  // To be used with literal values
  // Uses moment.js to check if it is a valid date
  isAValidDateValue(value: any) {
    try {
      const newDate = moment(value, 'MM/DD/YYYY', true);
      return newDate.isValid() ? true : false;
    } catch (e) {
      return false;
    }
  }

  // Used from a directive
  isAValidDateFactory(): ValidatorFn {
    return this.isAValidDate;
  }

  isAValidDateRangeFactory(): ValidatorFn {
    return (c: FormGroup) => {
      // Implement logic here
      return null;
    };
  }

  isAValidDateRangeValue(startDate: any, endDate: any) {
    try {
      if (
        startDate === null ||
        startDate === '' ||
        (startDate === undefined && endDate === null) ||
        endDate === '' ||
        endDate === undefined
      ) {
        return true;
      }
      if (
        this.isAValidDateValue(startDate) &&
        this.isAValidDateValue(endDate)
      ) {
        // returns true if end date is greater or equal than start date
        if (moment(endDate, 'MM/DD/YYYY') >= moment(startDate, 'MM/DD/YYYY')) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  //Only numbers Validators
  onlyNumbersValidator(c: FormControl) {
    if (c.value) {
      let NUMBERS_REGEX = /^[0-9]*$/;
      if (!NUMBERS_REGEX.test(c.value)) {
        return { invalidValue: true };
      }
      return null;
    }
    return null;
  }

  accountNumbersMatch(fg: FormGroup): any {
    try {
      let accountNumber: any = fg.get('accountNumber').value;
      let confirmNumber: any = fg.get('confirmAccountNumber').value;
      if (accountNumber === confirmNumber) {
        return null;
      }
      return { invalidAccountNumbers: true };
    } catch (e) {
      return false;
    }
  }

  //Only numbers Validators
  isPhoneNumberFormatValid(c: FormControl) {
    if (c.value) {
      let PHONE_REGEX = /^(([0-9]{3}){1}\-([0-9]{3}){1}\-([0-9]{4}){1}){1}$/g;
      if (!PHONE_REGEX.test(c.value)) {
        return { invalidPhoneNumber: true };
      }
      return null;
    }
    return null;
  }

  isEmailArrayValidAllowEmptyArray(c: FormControl) {
    try {
      const result = this.validateEmailArray(c);
      return result;
    } catch (error) {
      return { invalidEmail: true };
    }
  }

  validateEmailArray(c: FormControl) {
    // Validates string first
    if (this.emailValidator({ value: c.value }) === null) return null;
    // Validates arrays
    for (let i = 0; i < c.value.length; i++) {
      const item = c.value[i];
      if (isEmpty(item)) throw new Error('Item cannot be empty');
      const validation = this.emailValidator({ value: item });
      if (!isEmpty(validation)) throw new Error('Email is invalid');
    }
    return null;
  }

  // Format wo start date
  formatDateToMMDDYYYY(startDate) {
    try {
      const date = moment.utc(startDate).format('MM/DD/YYYY');
      return date;
    } catch (error) {
      return startDate;
    }
  }

  isInFormatMMDDYYYY(val: any) {
    try {
      if (!val) return true;
      const regex = new RegExp(
        /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/g
      );
      const result = regex.test(val);
      return result;
    } catch (error) {
      return false;
    }
  }
}
