import { SalesRepDropDown } from '../../../../interfaces/src/lib/sales-rep-drop-down.interface';
import {
  NewPaymentOptions,
  PaymentType
} from '../../../../../../apps/contract-estimator/src/app/shared/models/new-payment/new-payment.model';

export const INVOICE_LINE_ITEMS = [
  'Roof install per contract',
  'Roof repair per contract',
  'Gutter install per contract',
  'Gutter repair per contract',
  'Wood replacement - ',
  'Wood replacement - Service charge',
  'Attorney fees dated',
  'Financing dealer fees',
];

export const financeCharge = {
  perAnnum: 18,
  timePeriod: 365,
  dueDaysNumber: 7,
  dynamicCustomFieldId: '2'
};

export const salesReps: Array<SalesRepDropDown> = [
  {
    id: '5',
    displayName: 'Foster Carlock',
    email: 'foster.carlock@gmail.com',
    phoneNumber: '502-905-2444'
  },
  {
    id: '1',
    displayName: 'Jeremy Bosco',
    email: 'eliteroofs@gmail.com',
    phoneNumber: '502-643-4333'
  },
  {
    id: '6',
    displayName: 'Joe Coffey',
    email: 'jciu5133@yahoo.com',
    phoneNumber: '502-939-3781'
  },
  {
    id: '3',
    displayName: 'Kevin Prince',
    email: 'kevin.eliteroofing@gmail.com',
    phoneNumber: '502-224-9530'
  },
  {
    id: '4',
    displayName: 'Mike Fryrear',
    email: 'mfryrear.elite@gmail.com',
    phoneNumber: '502-641-8660'
  },
  {
    id: '2',
    displayName: 'Shawn Wood',
    email: 'swoodmail@yahoo.com',
    phoneNumber: '502-593-2303'
  }
];

export const paymentOptions: Array<NewPaymentOptions> = [
  <NewPaymentOptions>{
    id: '1',
    name: 'deposit',
    value: 'Deposit'
  },
  <NewPaymentOptions>{
    id: '2',
    name: 'payment',
    value: 'Payment'
  },
  <NewPaymentOptions>{
    id: '3',
    name: 'Refund',
    value: 'Refund'
  }
];

export const refundTypes: Array<PaymentType> = [
  <PaymentType>{
    id: 1,
    name: 'Overbilled',
    value: 'Overbilled'
  },
  <PaymentType>{
    id: 2,
    name: 'Overpayed',
    value: 'Overpayed'
  },
  <PaymentType>{
    id: 3,
    name: 'Labor not done',
    value: 'Labor not done'
  },
  <PaymentType>{
    id: 4,
    name: 'Unused Material',
    value: 'Unused Material'
  },
  <PaymentType>{
    id: 5,
    name: 'Cancelled Contract',
    value: 'Cancelled Contract'
  }
];

export const paymentsTypes: Array<PaymentType> = [
  <PaymentType>{
    id: 1,
    name: 'cash',
    value: 'Cash'
  },
  <PaymentType>{
    id: 2,
    name: 'check',
    value: 'Check'
  },
  <PaymentType>{
    id: 3,
    name: 'eCheck',
    value: 'eCheck'
  },
  <PaymentType>{
    id: 4,
    name: 'credit card',
    value: 'Credit card'
  },
  <PaymentType>{
    id: 5,
    name: 'finance',
    value: 'Finance'
  },
  <PaymentType>{
    id: 6,
    name: 'coupon',
    value: 'Coupon'
  },
  <PaymentType>{
    id: 7,
    name: 'Concession',
    value: 'Concession'
  },
  <PaymentType>{
    id: 10,
    name: 'bad debt',
    value: 'Bad debt'
  }
];

export const invoiceComments = `As a General Contractor, we require overhead and profit for projects involving 3 or more trades and/or is complex in nature`;
