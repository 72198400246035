import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumberFormat} from "@contract-estimator/shared/enums";
import {formatPhoneNumber} from "@contract-estimator/shared/utilities";

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any, type: PhoneNumberFormat | string): unknown {
    switch (type) {
      case PhoneNumberFormat.DASHED:
        const format = "$1-$2-$3"
        return formatPhoneNumber(value, format);
      default:
        return formatPhoneNumber(value)
    }
  }

}
