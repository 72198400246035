import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs/Subject";

@Component({
  selector: 'contractor-data-sharing-toolbox',
  templateUrl: './data-sharing-toolbox.component.html',
  styleUrls: ['./data-sharing-toolbox.component.scss']
})
export class DataSharingToolboxComponent implements OnInit {

  @Input() value: any;
  @Input() fileBasename: string;
  @Output() contentChangedEvent = new Subject();


  /**
   * On content changed event
   */
  onContentChangedEvent(e) {
    this.contentChangedEvent.next(e);
  }

  ngOnInit(): void {
  }

  constructor() { }

}
