import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EMailerComponent } from './components/e-mailer/e-mailer.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { PictureCardComponent } from './components/picture-card/picture-card.component';
import { JobMetadataTableComponent } from './components/job-metadata-table/job-metadata-table.component';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkOrderSharedModule } from '../work-order/work-order-shared.module';
import {SharedAngularPipesModule} from "@contract-estimator/shared/angular/pipes";


@NgModule({
  declarations: [
    EMailerComponent,
    FileUploaderComponent,
    PictureCardComponent,
    JobMetadataTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIComponentsModule,
    WorkOrderSharedModule,
    SharedAngularPipesModule
  ],
  exports: [
    EMailerComponent,
    FileUploaderComponent,
    PictureCardComponent,
    JobMetadataTableComponent
  ]
})
export class JobSharedModule {}
