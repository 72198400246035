import {Component, Input, SimpleChanges} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'elite-qci-header-metadata-table',
  templateUrl: './qci-header-metadata-table.component.html',
  styleUrls: ['./qci-header-metadata-table.component.scss']
})
export class QCIHeaderMetadataTableComponent {
  @Input() headerForm: FormGroup = new FormGroup({
    jobNumber: new FormControl(''),
    date: new FormControl('')
  });
  @Input() userHasAdminPrivileges: boolean;

  constructor() {}

  ngOnChanges(changes?: SimpleChanges) {
    if(changes.userHasAdminPrivileges) {
      this.toggleEditingTradeNumber();
    }
  }

  getJobNumber() {
    if (!this.headerForm) return;
    return this.headerForm.controls['jobNumber'].value;
  }


  /**
   * Disable trade number
   */
  toggleEditingTradeNumber() {
    if (this.userHasAdminPrivileges === true) {
      this.headerForm.controls['jobNumber'].enable({emitEvent: false});
    } else {
      this.headerForm.controls['jobNumber'].disable({emitEvent: false});
    }
  }
}
