import * as loginActions from '../actions/login.actions';
import * as loginState from '../state/login.state';
import {
  UserPrivacyTermsAcceptance,
  USER_PRIVACY_TERMS_ACCEPTANCE
} from 'libs/privacy-terms/data-access/src/lib/+state/actions/privacy-terms.index';
import {
  RESET_ALERT_STATE,
  UPDATE_ALERT_STATE,
  UpdateAlertState
} from '../actions/login.actions';

export function reducer(state = loginState.initialLoginState, action: any) {
  switch (action.type) {
    case loginActions.RESET_LOGIN_STATE:
      return resetLoginState(state, action);
    case loginActions.UPDATE_LOGIN_STATE:
      return updateLogInStatus(state, action);
    case USER_PRIVACY_TERMS_ACCEPTANCE:
      return updateUserPrivacyTermsAcceptance(state, action);
    case UPDATE_ALERT_STATE:
      return updateAlertState(state, action);
    case RESET_ALERT_STATE:
      return resetAlertState(state, action);
    default:
      return state;
  }
}

function updateLogInStatus(
  state: loginState.State,
  action: loginActions.UpdateLoginState
): loginState.State {
  try {
    return {
      ...state,
      _userId: action.payload._id,
      name: action.payload.name,
      role: action.payload.role,
      loggedIn: true,
      acceptedPrivacyTerms:
        action.payload.acceptedPrivacyTerms === undefined
          ? state.acceptedPrivacyTerms
          : action.payload.acceptedPrivacyTerms // Do not update if undefined
    };
  } catch (error) {
    return state;
  }
}

function updateUserPrivacyTermsAcceptance(
  state: loginState.State,
  action: UserPrivacyTermsAcceptance
): loginState.State {
  try {
    return {
      ...state,
      acceptedPrivacyTerms: action.payload
    };
  } catch (error) {
    return state;
  }
}

function resetLoginState(state, action) {
  return {
    ...state,
    ...loginState.initialLoginState
  };
}

function updateAlertState(state: loginState.State, action: UpdateAlertState) {
  return {
    ...state,
    ui: {
      ...state.ui,
      alert: {
        ...action.payload
      }
    }
  };
}

function resetAlertState(state, action) {
  return {
    ...state,
    ui: {
      ...state.ui,
      alert: {
        _id: null,
        message: null,
        type: null
      }
    }
  };
}
