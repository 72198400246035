import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { AuthenticationButtonComponent } from './components/authentication-button/authentication-button.component';
import {UIComponentsModule} from "@contract-estimator/shared/angular/components";

@NgModule({
  imports: [
    CommonModule,
    UIComponentsModule
  ],
  declarations: [LoginButtonComponent, LogoutButtonComponent, AuthenticationButtonComponent],
  exports:  [LoginButtonComponent, LogoutButtonComponent, AuthenticationButtonComponent]
})
export class OauthSpaFeatureModule {}
