import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'change-lead-status',
  templateUrl: './change-lead-status.component.html',
  styleUrls: ['./change-lead-status.component.scss']
})
export class ChangeLeadStatusComponent implements OnInit {
  @Input() reasons: Array<any>;
  @Input() title: string;
  @Input() subtitle: string = "Select a reason why the customer didn't buy";
  @Output() selectReasonEvent = new Subject();
  selectedReason: string;

  constructor() {}

  ngOnInit() {}

  changeStatus() {
    this.selectReasonEvent.next(this.selectedReason);
  }

}
