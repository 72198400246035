// Calculates the total of th purchase by summing the subtotal with tax values up.
export function calculateSubTotal(subtotals: Array<number>): number {
  try {
    let workOrderEstimateTotal = 0;
    if (Array.isArray(subtotals)) {
      workOrderEstimateTotal = subtotals.reduce(
        (sub: number, acc: number) =>
          parseFloat(sub.toString()) + parseFloat(acc.toString())
      );
    }
    return workOrderEstimateTotal;
  } catch (e) {
    return 0;
  }
}

export function calculateTotalWithTaxes(
  subtotal: number,
  tax: number,
  exempt: boolean,
  financeCharge: number,
  paymentReceived: number
) {
  if (exempt) {
    return subtotal;
  } else {
    return (
      parseFloat((subtotal * tax).toString()) +
      parseFloat(subtotal.toString()) +
      parseFloat(financeCharge.toString()) -
      parseFloat(paymentReceived.toString())
    );
  }
}

export function extractSubtotals(lines: Array<any>) {
  return lines.map(line => line.amount);
}

export function calcualteNewBalanceDue(
  subtotal: any,
  paymentReceived: any,
  financeCharge: any
): number {
  // Return 0 if amount is not a number
  if (Number.isNaN(subtotal)) {
    return 0;
  }
  // Set to 0 if values are not numbers
  const newPaymentReceived = Number.isNaN(paymentReceived)
    ? 0
    : paymentReceived;
  const newFinanceCharge = Number.isNaN(financeCharge) ? 0 : financeCharge;
  // Compose an array of values to use javascript operators
  const arrayValues = [
    subtotal,
    paymentReceived > 0
      ? parseFloat(paymentReceived.toString()) * -1
      : paymentReceived,
    financeCharge
  ];
  let newTotal = arrayValues.reduce(
    (value, acc = 0) =>
      parseFloat(value.toString()) + parseFloat(acc.toString())
  );
  return +parseFloat(newTotal).toFixed(2);
}
