import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { PayrollListItem } from 'apps/contract-estimator/src/app/shared/models/payroll/payroll.model';

@Component({
  selector: 'wb-payroll-list-component',
  templateUrl: './payroll-list.component.html',
  styleUrls: ['./payroll-list.component.scss']
})
export class PayrollListComponent implements OnInit {
  @Input() lineItems: Array<PayrollListItem>;
  @Input() loading: boolean;
  @Input() status: string;
  @Output() addLineItemEvent = new Subject();
  @Output() deletePayrollEvent = new Subject();
  @Output() navigateToPayrollEvent = new Subject();

  constructor() {}

  ngOnInit() {}

  // Adds a new line item
  addLineItem() {
    this.addLineItemEvent.next();
  }

  // On delete button
  onDeletePayroll(_id) {
    this.deletePayrollEvent.next(_id);
  }

  // Gets footer colspan
  getColspan() {
    if (this.status === 'in progress') {
      return 4;
    } else {
      return 3;
    }
  }

  // Navigated to payroll
  onNavigateToPayroll(_id) {
    this.navigateToPayrollEvent.next(_id);
  }
}
