import { Injectable } from '@angular/core';
import PDFMerger from 'pdf-merger-js';

@Injectable({
  providedIn: 'root'
})
export class PdfMergerJsService {
  get merger(): PDFMerger {
    return new PDFMerger();
  }

  constructor() {}
}
