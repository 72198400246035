import { SalesRepState } from '../states/sales-rep.state';
import { isEmpty } from 'lodash';
import { MenuItem } from 'primeng/api';

export interface SalesRepMenuItem {
  label: string;
  routerLink: Array<any>;
  routerLinkActiveOptions?: any;
  queryParams?: any;
}

// Composes single sales rep menu item
export function composeSalesRepMenuItem(
  salesRep: SalesRepState
): SalesRepMenuItem {
  if (isEmpty(salesRep)) return null;
  return {
    label: salesRep.name,
    routerLink: ['/pipeline', 'In progress'],
    queryParams: { salesRepId: salesRep._id },
    routerLinkActiveOptions: { exact: true }
  };
}

// Composes pipeline menu with single sales rep. When sales rep is logged in
export function composePipelineMenuWithWithSingleSalesRep(
  salesRep: SalesRepState
) {
  return {
    label: salesRep.name,
    routerLink: ['/pipeline', 'In progress'],
    queryParams: { salesRepId: salesRep._id },
    routerLinkActiveOptions: { exact: true }
  };
}

// Adds the new lead menu item
export function composeNewLeadMenuItem() {
  return [
    {
      label: 'Lead',
      routerLink: ['new-lead'],
      routerLinkActiveOptions: { exact: true }
    }
  ];
}

export function composeSalesRepArrayWithIdAndName(
  salesReps: Array<SalesRepState>
) {
  if (isEmpty(salesReps)) return [];
  const list = [];
  for (let i = 0; i < salesReps.length; i++) {
    if (isEmpty(salesReps[i])) continue;
    const salesRep = salesReps[i];
    if (!salesRep._id) continue;
    const item = {
      _id: salesRep._id,
      name: salesRep.name
    };
    list.push(item);
  }
  return list;
}

export function composeSalesRepArrayAsLabelAndValue(
  salesReps: Array<SalesRepState>
) {
  if (isEmpty(salesReps)) return [];
  const list = [];
  for (let i = 0; i < salesReps.length; i++) {
    if (isEmpty(salesReps[i])) continue;
    const salesRep = salesReps[i];
    if (!salesRep._id) continue;
    const item = {
      value: salesRep._id,
      label: salesRep.name
    };
    list.push(item);
  }
  return list;
}

// Gets current sales rep _id and name only
export function getCurrentSalesRep(salesReps: Array<any>, _salesRepId) {
  if (isEmpty(salesReps) || !_salesRepId) return null;
  for (let i = 0; i < salesReps.length; i++) {
    if (isEmpty(salesReps[i])) continue;
    const rep = salesReps[i];
    if (rep._id === _salesRepId) return rep;
  }
  return null;
}
