import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { pluck } from 'rxjs/operators';

import * as models from './apollo-team-member-manager.service.models';
import * as fragments from './apollo-team-member-manager.service.fragments';

@Injectable()
export class ApolloTeamMemberManagerService {
  /**
   * Apollo mutation to create a new team member
   * @param payload
   */
  createTeamMember(payload: models.CreateTeamMemberApolloPayload) {
    return this.apollo
      .mutate({
        mutation: fragments.CREATE_TEAM_MEMBER,
        variables: { payload }
      })
      .pipe(pluck('data', 'createTeamMember'));
  }

  /**
   * Apollo mutation to update existing team member
   * @param criteria
   * @param changes
   */
  updateTeamMember(
    criteria: models.UpdateTeamMemberApolloCriteria,
    changes: models.UpdateTeamMemberApolloChanges
  ) {
    return this.apollo
      .mutate({
        mutation: fragments.UPDATE_TEAM_MEMBER,
        variables: { criteria, changes }
      })
      .pipe(pluck('data', 'updateTeamMember'));
  }

  /**
   * Apollo query to search team members collection
   * @param searchCriteria
   */
  searchTeamMembers(
    searchCriteria: models.SearchTeamMembersApolloSearchCriteria
  ) {
    return this.apollo
      .watchQuery({
        query: fragments.SEARCH_TEAM_MEMBERS,
        variables: { searchCriteria }
      })
      .valueChanges.pipe(pluck('data', 'searchTeamMembersByCriteria'));
  }

  constructor(private apollo: Apollo) {}
}
