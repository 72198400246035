import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { GenerateUniqueId } from 'apps/contract-estimator/src/app/shared/utils/generateUniqueId/generateUniqueId';

@Component({
  selector: 'picture-card',
  templateUrl: './picture-card.component.html',
  styleUrls: ['./picture-card.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('first', style({ transform: 'rotate(90deg)' })),
      state('second', style({ transform: 'rotate(180deg)' })),
      state('third', style({ transform: 'rotate(270deg)' })),
      state('fourth', style({ transform: 'rotate(360deg)' })),
      transition('default => first', animate('1500ms ease-out')),
      transition('first => second', animate('1500ms ease-out')),
      transition('second => third', animate('1500ms ease-out')),
      transition('third => fourth', animate('1500ms ease-out')),
      transition('fourth => default', animate('1500ms ease-out'))
    ])
  ]
})
export class PictureCardComponent implements OnInit {
  state: string = 'default';
  _source: string;

  @Input() set source(source) {
    this._source = source + '?' + new GenerateUniqueId().generate();
  }
  @Input() description: string;
  @Input() contract: boolean;
  @Input() workOrder: boolean;
  @Input() name: string;
  @Input() _id: string;
  @Input() loading: boolean;
  @Input() showAddToContractCheckbox: boolean;
  @Input() showAddToWorkOrderCheckbox: boolean;
  @Output() editDescriptionEvent = new Subject();
  @Output() deletePictureEvent = new Subject();
  @Output() rotatePictureEvent = new Subject();

  constructor() {}

  ngOnInit() {}

  onEditDescription(description: string) {
    this.editDescriptionEvent.next({
      changes: { description },
      name: this.name,
      _id: this._id
    });
  }

  onEditToContract(contract: string) {
    this.editDescriptionEvent.next({
      changes: { contract },
      name: this.name,
      _id: this._id
    });
  }

  onEditToWorkOrder(workOrder: string) {
    this.editDescriptionEvent.next({
      changes: { workOrder },
      name: this.name,
      _id: this._id
    });
  }

  deletePicture() {
    this.deletePictureEvent.next({
      description: this.description,
      name: this.name,
      _id: this._id
    });
  }

  rotatePicture() {
    if (this.state === 'default') {
      this.state = 'first';
    } else if (this.state === 'first') {
      this.state = 'second';
    } else if (this.state === 'second') {
      this.state = 'third';
    } else if (this.state === 'third') {
      this.state = 'fourth';
    } else if (this.state === 'fourth') {
      this.state = 'default';
    }
    this.rotatePictureEvent.next({ name: this.name, _id: this._id });
  }
}
