import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import * as fromStoreSelectors from "../selectors/stores.selector";
import {combineLatest} from "rxjs";
import * as fromStoresActions from "../actions/stores.actions";

@Injectable()
export class StoresStateFacade {
  stores$ = this.store.pipe(select(fromStoreSelectors.selectStoresArray));
  isLoading$ = this.store.pipe(select(fromStoreSelectors.storesLoading));
  error$ = this.store.pipe(select(fromStoreSelectors.storesError));
  vm$ = combineLatest([
    this.stores$,
    this.isLoading$
  ])
    .map(([stores, isLoading]) => ({
      stores,
      isLoading
    }))

  /**
   * Dispatches action
   */
  dispatch(action) {
    this.store.dispatch(action);
  }

  /**
   * Dispatches loading
   * @param loading
   */
  dispatchLoading(loading: boolean) {
    const action = new fromStoresActions.SetStoresLoading(loading);
    this.dispatch(action);
  }

  /**
   * Dispatches errors
   * @param error
   */
  dispatchError(error: string) {
    const action = new fromStoresActions.SetStoresError(error);
    this.dispatch(action);
  }

  /**
   * Dispatches reset state
   */
  dispatchResetState() {
    const action = new fromStoresActions.ResetStoresState();
    this.dispatch(action);
  }


  constructor(
    private store: Store
  ) {
  }
}
