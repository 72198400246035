import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import { QCI } from 'libs/shared/interfaces/src/lib/qci.interfaces';

@Component({
  selector: 'contract-header-table',
  templateUrl: './contract-header-table.component.html',
  styleUrls: ['./contract-header-table.component.scss']
})
export class ContractHeaderTableComponent implements OnInit {
  @Input() headerType: string;
  @Input() contractForm: FormGroup;
  @Input() contractType: string;
  @Input() qci: QCI;
  @Input() date: string;
  @Input() dateLabel: string = 'CONTRACT DATE';
  @Input() jobNumber: string;
  @Input() showJobNumber: boolean;
  showTotalInputField: boolean = false;
  @ViewChild('insuranceCompanyInput', { static: false }) insuranceCompanyInput: ElementRef;

  constructor() {}

  ngOnInit() {}

  toggleTotal(val: boolean) {
    setTimeout(() => {
      if (val) this.insuranceCompanyInput.nativeElement.focus();
    }, 17);
    this.showTotalInputField = val;
  }


  setInsuranceCompany(value) {
    this.contractForm.controls['insurer'].setValue(value, {emitEvent: false});
  }
}
