import {
  TeamMemberStatus,
  UserRoleType
} from '@contract-estimator/shared/enums';

export class TeamMember {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status?: TeamMemberStatus;
  _calendarId?: string;
  name?: string;
  preferredName?: string;
  middleName?: string;
  address?: {
    address?: string;
    countryCode?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  funFact?: string;
  photoName?: string;
  active?: boolean;
  role?: UserRoleType;
  acceptedPrivacyTerms?: boolean;
}

export interface CreateTeamMemberServicePayload {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status?: TeamMemberStatus;
  _calendarId?: string;
  name?: string;
  preferredName?: string;
  middleName?: string;
  address?: {
    address?: string;
    countryCode?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  funFact?: string;
  photoName?: string;

  // Currently the fields below get overwritten with default values in
  // team member application service.
  // That may change, so I'm keeping them here for now.
  active?: boolean;
  role?: UserRoleType;
  acceptedPrivacyTerms?: boolean;
}

export interface UpdateTeamMemberServiceCriteria {
  _id: string;
}

export interface UpdateTeamMemberServiceChanges {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  status?: TeamMemberStatus;
  _calendarId?: string;
  name?: string;
  preferredName?: string;
  middleName?: string;
  address?: {
    address?: string;
    countryCode?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  funFact?: string;
  photoName?: string;
  active?: boolean;
  role?: UserRoleType;
  acceptedPrivacyTerms?: boolean;
}

export interface SearchTeamMembersServiceSearchCriteria {
  searchTerms?: string;
  status?: TeamMemberStatus;
}
