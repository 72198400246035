import {Component, OnInit, Output} from '@angular/core';
import {isEmpty} from 'lodash';
import {Subject} from "rxjs/Subject";

import {DateUtils} from "apps/contract-estimator/src/app/shared/utils/date/date.utils";
import {MenuItem} from "primeng";

@Component({
  selector: 'contractor-multiple-range-option-calendar',
  templateUrl: './multiple-range-option-calendar.component.html',
  styleUrls: ['./multiple-range-option-calendar.component.scss']
})
export class MultipleRangeOptionCalendarComponent implements OnInit {

  datePeriod: Array<any>;
  dateUtils: DateUtils = new DateUtils();
  @Output() changedDatePeriodEvent = new Subject<any>();
  quarterMenuItems: MenuItem[];

  constructor() { }

  ngOnInit(): void {
    this.quarterMenuItems = [
      {label: 'Q2', command: () => {
          this.onSelectedQuarterDateRange(this.getThisYearQuarterByIndex(1));
        }},
      {label: 'Q3', command: () => {
          this.onSelectedQuarterDateRange(this.getThisYearQuarterByIndex(2));
        }},
      {label: 'Q4', command: () => {
          this.onSelectedQuarterDateRange(this.getThisYearQuarterByIndex(3));
        }},
    ];
  }


  onSelectedDatePeriod() {
    if (isEmpty(this.datePeriod)) return;
    if (!this.datePeriod[0]) return;
    if (!this.datePeriod[1]) return;
    const startPeriod = this.dateUtils.setTimeOfDate({
        date: this.datePeriod[0],
        format: '',
        hour: 0,
        min: 0,
        sec: 1
      }),
      endPeriod = this.dateUtils.setTimeOfDate({
        date: this.datePeriod[1],
        format: '',
        hour: 11,
        min: 59,
        sec: 59
      });
    this.changedDatePeriodEvent.next({
      startPeriod,
      endPeriod
    });
  }

  // Get year range
  getYearRange() {
    const startYear = 2017
    const currentYear = +(new Date().getFullYear());
    const endYear = currentYear + 1
    return `${startYear}:${endYear}`;
  }


  /**
   * WEEK
   */

  /**
   * Sets this week's date range
   */
  setThisWeekDateRange() {
    let startDate: any = this.dateUtils.getThisWeekStartDate();
    let endDate: any = this.dateUtils.getThisWeekEndDate();
    this.datePeriod = [
      startDate,
      endDate
    ]
  }

  /**
   * Sets this week's date range
   */
  onSelectedThisWeekDateRange() {
    // Sets range
    this.setThisWeekDateRange();
    // Triggers output
    this.onSelectedDatePeriod();
  }



  /*
  MONTH
   */


  /**
   * Sets this month's date range
   */
  setThisMonthDateRange() {
    let startDate: any = this.dateUtils.getThisMonthStartDate();
    let endDate: any = this.dateUtils.getThisMonthEndDate();
    this.datePeriod = [
      startDate,
      endDate
    ]
  }

  /**
   * Sets this week's date range
   */
  onSelectedThisMonthDateRange() {
    // Sets range
    this.setThisMonthDateRange();
    // Triggers output
    this.onSelectedDatePeriod();
  }


  /*
  YEAR
   */


  /**
   * Sets this month's date range
   */
  setThisYearDateRange() {
    let startDate: any = this.dateUtils.getThisYearStartDate();
    let endDate: any = this.dateUtils.getThisYearEndDate();
    this.datePeriod = [
      startDate,
      endDate
    ]
  }

  /**
   * Sets this week's date range
   */
  onSelectedThisYearDateRange() {
    // Sets range
    this.setThisYearDateRange();
    // Triggers output
    this.onSelectedDatePeriod();
  }


  /*
  Q1, Q2, Q3, Q4
   */


  // Gets quarter name
  getQuarterName(i: number) {
    return `Q${i+1}`;
  }

  /**
   * Get this current year quarters
   */
  getThisYearQuarters(): Date[] {
    // Setting values
    const thisYearStartDate = this.dateUtils.getThisYearStartDate();
    const thisYearEndDate = this.dateUtils.getThisYearEndDate();
    const thisYearQuarters = this.dateUtils.eachQuarterOfInterval(
      thisYearStartDate,
      thisYearEndDate
    );
    return thisYearQuarters;
  }



  /**
   * Sets quarter's date range
   */
  setThisQuarterDateRange(startDate) {
    let endDate: any = this.dateUtils.getLastDayOfQuarter(startDate);
    this.datePeriod = [
      startDate,
      endDate
    ]
  }

  /**
   * Selects quarter date range
   */
  onSelectedQuarterDateRange(date: any) {
    // Sets range
    this.setThisQuarterDateRange(date);
    // Triggers output
    this.onSelectedDatePeriod();
  }

  getThisYearQuarterByIndex(i: number) {
    const quarters = this.getThisYearQuarters();
    return quarters[i];
  }

}
