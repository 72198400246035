import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerUrl} from "apps/contract-estimator/src/app/config/server-url";
import {retry} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MegaMenuHttpClientService {

  apiUrl = this.serverUrl.getUrl();

  constructor(
    private http: HttpClient,
    private serverUrl: ServerUrl
  ) { }

  /**
   * Gets menu options and count
   * @param url
   */
  getMenu(url: string) {
    return this.http.get(url);
  }

  /**
   * Gets menu counts as an array
   * @param url
   */
  getMenuCountAsAnArray(url: string) {
    return this.http.get(url);
  }

  /**
   * Get company's enabled features
   */
  getEnabledFeatures() {
    return this.http.get(this.apiUrl + '/menu/enabled-features')
      .pipe(
        retry(2)
      );
  }
}
