import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { from, of } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import { get } from 'lodash';

@Injectable()
export class LoginService {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(private http: HttpClient, public afAuth: AngularFireAuth) {}

  // Logs in and gets user information
  loginAndGetUserInformation(payload) {
    return this.login(payload).pipe(
      mergeMap(response => this.getIdToken(response)),
      mergeMap((response: any) => {
        // Set tokens
        sessionStorage.setItem('token', response.token);
        sessionStorage.setItem('refreshToken', response.refreshToken);
        return this.getUserInformation(response.email);
      })
    );
  }

  login(payload) {
    return from(
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
    ).pipe(
      map((response: any) => {
        return {
          email: get(response, 'user.email'),
          refreshToken: get(response, 'user.refreshToken')
        };
      })
    );
  }

  // Gets access token
  getIdToken(options?: any) {
    try {
      if (!firebase.auth().currentUser) return of(null);
      return from(
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
      ).pipe(
        take(1),
        map(token => ({
          ...options,
          token
        }))
      );
    } catch (error) {
      // Possible when firebase auth expires
      throw error;
    }
  }

  // Log out
  logout() {
    return from(firebase.auth().signOut()).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  userLogin(payload: any) {
    return this.http.post(this.serverUrl + '/auth/login', payload, {
      observe: 'response'
    });
  }

  userHasValidToken() {
    return sessionStorage.getItem('token');
  }

  validateToken(token: any): any {
    return this.http.post(this.serverUrl + '/auth/validate/token', token, {
      observe: 'response'
    });
  }

  isClientTokenValid$() {
    const token = this.userHasValidToken();
    return this.validateToken(token);
  }

  // Removes refresh token from db
  removeRefreshToken(token) {
    return this.http.delete(
      this.serverUrl + '/auth/remove/token?token=' + token
    );
  }

  // Gets user information
  getUserInformation(email) {
    return this.http.get(this.serverUrl + '/auth/user-information');
  }

  // Send reset password email
  sendResetPasswordEmail(email: string) {
    return from(firebase.auth().sendPasswordResetEmail(email));
  }
}
