import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as subcontractorActions from '../actions/subcontractor.actions';
import { CompanyEstimateTrade } from '../facade/subcontractor-manager.facade.models';

export const subcontractorManagerFeatureKey = 'subcontractors';

export interface SubcontractorState {
  _id: string;
  name: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  frequent?: boolean;
  active?: boolean;
  status?: string;
  trades?: string[];
}

export interface State extends EntityState<SubcontractorState> {
  loading: boolean;
  isLoading: boolean;
  error: string;
  companyEstimateTrades: CompanyEstimateTrade[];
}

export const subcontractorAdapter: EntityAdapter<SubcontractorState> = createEntityAdapter<
  SubcontractorState
>({
  selectId: (sub: SubcontractorState) => sub._id
});

export const initialState: State = subcontractorAdapter.getInitialState({
  loading: false,
  isLoading: false,
  error: null,
  companyEstimateTrades: []
});

export function reducer(
  state: State = initialState,
  action: subcontractorActions.Actions
) {
  switch (action.type) {
    case subcontractorActions.SET_SUBCONTRACTORS:
      return subcontractorAdapter.addMany(action.payload, state);
    case subcontractorActions.ADD_SUBCONTRACTOR:
      return subcontractorAdapter.addOne(action.payload, state);
    case subcontractorActions.SET_ALL_SUBCONTRACTORS:
      return subcontractorAdapter.setAll(action.payload, state);
    case subcontractorActions.SET_ONE_SUBCONTRACTOR:
      return subcontractorAdapter.setOne(action.payload, state);
    case subcontractorActions.SET_NEW_SUBCONTRACTOR:
      return subcontractorAdapter.addOne(action.payload, state);
    case subcontractorActions.SET_UPDATED_SUBCONTRACTOR:
      return subcontractorAdapter.updateOne(action.payload, state);
    case subcontractorActions.REMOVE_DELETED_SUBCONTRACTOR:
      return subcontractorAdapter.removeOne(action._id, state);
    case subcontractorActions.SET_IS_LOADING_FOR_SUBCONTRACTOR_MANAGER:
      return { ...state, isLoading: action.payload };
    case subcontractorActions.SET_ERROR_FOR_SUBCONTRACTOR_MANAGER:
      return { ...state, error: action.payload };
    case subcontractorActions.SET_COMPANY_ESTIMATE_TRADES_FOR_SUBCONTRACTOR_MANAGER:
      return { ...state, companyEstimateTrades: action.payload };
    default:
      return state;
  }
}
