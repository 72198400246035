export class Expense {
  _id: string;
  name: string;
  type: string;
  amount: number;
  cost: number;

  constructor({ _id, name, type, amount, cost }: any) {
    this._id = _id || '';
    this.name = name || '';
    this.type = type || '';
    this.amount = amount || 0;
    this.cost = cost || 0;
  }
}
