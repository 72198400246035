import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'contractor-profile-button-information',
  templateUrl: './profile-button-information.component.html',
  styleUrls: ['./profile-button-information.component.scss']
})
export class ProfileButtonInformationComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

}
