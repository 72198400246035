import { Component, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { get } from 'lodash';

@Component({
  selector: 'wb-payroll-header-table-component',
  templateUrl: './payroll-header-table.component.html',
  styleUrls: ['./payroll-header-table.component.scss']
})
export class PayrollHeaderTableComponent {
  @Input() form: FormGroup = new FormGroup({
    submissionDate: new FormControl(''),
    payPeriod: new FormControl('')
  });
  @Input() message: string = '';
  @Input() showMessage: boolean = false;
  @Output() payPeriodEvent = new Subject();

  constructor() {}

  ngOnChanges() {}

  onSelectingPayPeriod() {
    const payPeriod = get(this.form, 'value.payPeriod');
    this.payPeriodEvent.next(payPeriod);
  }

}
