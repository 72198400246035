import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForecastComponent } from './components/forecast/forecast.component';
import {UIComponentsModule} from "./../ui-components/ui-components.module";

@NgModule({
  imports: [
    CommonModule,
    UIComponentsModule
  ],
  declarations: [
    ForecastComponent
  ],
  exports: [CommonModule, ForecastComponent, UIComponentsModule]
})
export class ForecastSharedModule {}
