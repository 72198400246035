import { Component, OnInit } from '@angular/core';
import {mergeMap} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {
  profileButtonComponentUserData,
} from "../../../../../../../sales-reps/data-access/src/lib/+state/selectors/sales-reps.selector";
import {AuthClientService} from "@contract-estimator/shared/angular/providers";

@Component({
  selector: 'contractor-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent implements OnInit {

  userEmail$ = this.authClientService.getUserEmail();
  profileInfo$ = this.userEmail$.pipe(mergeMap((userEmail: string) => this.store.pipe(
    select(profileButtonComponentUserData(userEmail))
  )));

  constructor(
    private authClientService: AuthClientService,
    private store: Store
  ) { }

  ngOnInit(): void {
  }

}
