import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(public http: HttpClient) {}

  // Get weekly forecast
  getWeeklyForecast() {
    return this.http.get(
      this.serverUrl +
        '/forecast?longitude=-85.7594&latitude=38.2542'
    );
  }
}
