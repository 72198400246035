import { Component, OnInit, Input } from '@angular/core';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'total-sales-table',
  templateUrl: './total-sales-table.component.html',
  styleUrls: ['./total-sales-table.component.scss']
})
export class TotalSalesTableComponent implements OnInit {
  @Input() data: any;
  @Input() maxValues: any;
  @Input() cols: any[];
  @Input() highlightMaxCells: any[];
  @Input() tableType: string;
  cars1: any[];

  constructor() {}

  ngOnInit() {}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  // Gets max value css class
  getMaxValueCSSClass(rowDataValue, fieldName, salesRepName) {
    try {
      if (salesRepName === 'Company') return null;
      if (!this.highlightMaxCells) return null;
      if (rowDataValue === this.maxValues[fieldName] && rowDataValue != 0) {
        return 'max-value';
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
