import { sortBy, indexOf } from 'lodash';

export function composeAggregatedTotalSummaryMap(
  estimatedTotals,
  actualTotals,
  fieldDisplayNames,
  fieldIndexes
) {
  try {
    let myMap = new Map();
    myMap = setValues(myMap, estimatedTotals, 'estimatedTotal');
    myMap = setValues(myMap, actualTotals, 'actualTotal');
    myMap = setValues(myMap, fieldDisplayNames, 'name');
    myMap = setValues(myMap, fieldIndexes, 'index');
    const array = toArray(myMap);
    const sorted = sortBy(array, ['index']);
    return sorted;
  } catch (error) {
    throw error;
  }
}

export function composeAggregatedEstimatedTotalSummaryMap(
  estimatedTotals,
  fieldNames,
  fieldIndexes
) {
  try {
    let myMap = new Map();
    myMap = setValues(myMap, estimatedTotals, 'estimatedTotal');
    myMap = setValues(myMap, fieldNames, 'name');
    myMap = setValues(myMap, fieldIndexes, 'index');
    const array = toArray(myMap);
    const sorted = sortBy(array, ['index']);
    return sorted;
  } catch (error) {
    throw error;
  }
}

/**
 * Modifies map and adds new fields
 * @param myMap Map
 * @param totals Job Costs Totals
 * @param field New Field
 */
export function setValues(myMap, totals, field) {
  const tempMap: any = new Map(myMap);
  const keys = getObjectKeys(totals);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const temp = { ...tempMap.get(key) };
    temp[field] = totals[key];
    tempMap.set(key, temp);
  }
  return tempMap;
}

export function getObjectKeys(totals) {
  try {
    const keys = Object.keys(totals);
    return keys;
  } catch (error) {
    return [];
  }
}

function toArray(map) {
  if (map) return Array.from(map.values());
  else return [];
}


export function hidePrivateSummaryDataFromSalesRep(summary, userHasPrivileges) {
  if(userHasPrivileges === true) {
    return summary;
  } else {
    return removePrivateDataFromSummary(summary);
  }
}


export function removePrivateDataFromSummary(summary) {
  const blacklist = [
    "Equals Net Profit"
  ]
  const newSummary = summary.map(
    item => {
      const name = item.name
      const index = indexOf(blacklist, name)
      if(index >= 0) {
        return null
      } else {
        return item;
      }
    }
  )
    .filter(item => item)
  return newSummary;
}
