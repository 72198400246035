import { isEmpty, get, find } from 'lodash';
import { Injectable } from '@angular/core';
import { environment } from '@contract-estimator/shared/environments';

import * as invoiceModels from 'apps/contract-estimator/src/app/shared/models/invoice/invoice.model';
import * as moment from 'moment';
import * as accountingUtils from 'apps/contract-estimator/src/app/shared/utils/accounting/accounting.utils';

@Injectable()
export class PreviewService {
  constructor() {}

  composeMetaInvoiceVM(
    invoice: any,
    customer: any
  ): invoiceModels.MetaInvoiceVM {
    try {
      //Return a null object if there is not a response
      if (!invoice || !customer) {
        return invoiceModels.initialMetaInvoiceVM;
      }
      // Compose object if response
      const invoiceInformation: invoiceModels.MetaInvoiceVM =
        invoiceModels.initialMetaInvoiceVM;
      invoiceInformation.id = invoice.Id;
      invoiceInformation.syncToken = invoice.SyncToken;
      invoiceInformation.invoiceNumber = invoice.DocNumber;
      invoiceInformation.invoiceDateObj = moment(invoice.TxnDate, 'YYYY-MM-DD');
      invoiceInformation.invoiceDateString = moment(
        invoice.TxnDate,
        'YYYY-MM-DD'
      ).format('L');
      invoiceInformation.salesRep = this.composeSalesRep(invoice.PrivateNote);
      invoiceInformation.lineItems = this.composeLineItems(invoice.Line);
      invoiceInformation.customer = this.composeCustomer(customer);
      invoiceInformation.dueDateObj = moment(invoice.DueDate, 'YYYY-MM-DD');
      invoiceInformation.dueDateString = moment(
        invoice.DueDate,
        'YYYY-MM-DD'
      ).format('L');
      invoiceInformation.subtotal = this.extractSubtotal(invoice.Line);
      invoiceInformation.totalAmount = invoice.TotalAmt;
      invoiceInformation.balance = invoice.Balance;
      invoiceInformation.paidInFull = +invoice.Balance <= 0 ? true : false;
      invoiceInformation.paymentReceived = this.calculatePaymentReceived(
        invoice.TotalAmt,
        invoice.Balance
      );
      invoiceInformation.financeCharge = this.extractFinanceCharge(
        invoice.Line
      );
      invoiceInformation.total = this.calculateTotal(
        invoiceInformation.subtotal,
        invoiceInformation.paymentReceived,
        invoiceInformation.financeCharge
      ); // total = subtotal - paymentReceived + financeCharge
      invoiceInformation.comments = invoice.CustomerMemo
        ? invoice.CustomerMemo.value
        : null;
      invoiceInformation.storedFinanceCharge = this.extractFinanceCharge(
        invoice.Line
      );
      invoiceInformation.financeChargeDynamicDueDate = this.extractFinanceChargeDynamicDueDate(
        invoice.CustomField
      );
      invoiceInformation.sendReview = this.getSendPrivateNoteFlag(
        invoice.PrivateNote,
        'sendReview'
      );
      invoiceInformation.sendReceipt = this.getSendPrivateNoteFlag(
        invoice.PrivateNote,
        'sendReceipt'
      );
      return invoiceInformation;
    } catch (e) {
      throw new Error('There was an error composing the invoice information.');
    }
  }

  // It receives an JSON string like this "{\"givenName\":\"Jeremy\",\"familyName\":\"Escamilla\",\"displayName\":null,\"primaryP
  // returns an object with the properties {firstName, lastName, street, etc}
  composeSalesRep(stringValue: string): invoiceModels.Entity {
    try {
      const jsonSalesRep: any = JSON.parse(stringValue);
      const salesRep: invoiceModels.Entity = {
        displayName: jsonSalesRep.displayName,
        phoneNumber: jsonSalesRep.phoneNumber,
        email: jsonSalesRep.email
      };
      return salesRep;
    } catch (e) {
      return invoiceModels.initialEntity;
    }
  }

  composeAddressLine2(city, state, zipCode) {
    if (city && !state && !zipCode) return city;
    if (!city && state && !zipCode) return state;
    if (!city && !state && zipCode) return zipCode;
    if (!city || !state || !zipCode) return null;
    return `${city}, ${state} ${zipCode}`;
  }

  composeLineItems(qbsLineItems) {
    let filteredLineItems = qbsLineItems.filter(
      lineItem => lineItem.DetailType !== 'SubTotalLineDetail'
    );
    filteredLineItems = filteredLineItems.filter(
      lineItem => lineItem.Id !== '1'
    );
    const newLineItems = filteredLineItems.map(lineItem => ({
      description: lineItem.Description,
      amount: lineItem.Amount
    }));
    return newLineItems;
  }

  composeCustomer(customer: any): invoiceModels.Entity {
    const newCustomer: invoiceModels.Entity = {
      id: customer.Id,
      syncToken: customer.SyncToken,
      displayName: customer.PrintOnCheckName,
      addressLine1: get(customer, 'BillAddr.Line1', null),
      addressLine2: this.composeAddressLine2(
        get(customer, 'BillAddr.City', null),
        get(customer, 'BillAddr.CountrySubDivisionCode', null),
        get(customer, 'BillAddr.PostalCode', null)
      ),
      city: get(customer, 'BillAddr.City', null),
      state: get(customer, 'BillAddr.CountrySubDivisionCode', null),
      zipCode: get(customer, 'BillAddr.PostalCode', null),
      phoneNumber: get(customer, 'PrimaryPhone.FreeFormNumber', null),
      secondaryPhoneNumber: get(
        customer,
        'AlternatePhone.FreeFormNumber',
        null
      ),
      email: get(customer, 'PrimaryEmailAddr.Address', null)
    };
    return newCustomer;
  }

  // Extracts the finance charge from the line items
  extractFinanceCharge(qbsLineItems) {
    const financeCharge = qbsLineItems.filter(lineItem => lineItem.Id === '1');
    if (isEmpty(financeCharge)) {
      return 0;
    }
    return financeCharge[0].Amount;
  }

  extractSubtotalsArray(lines: Array<any>) {
    return lines.map(line => line.Amount);
  }

  extractSubtotal(qbsLineItems: any) {
    let filteredLineItems = qbsLineItems.filter(
      lineItem => lineItem.DetailType !== 'SubTotalLineDetail'
    );
    filteredLineItems = filteredLineItems.filter(
      lineItem => lineItem.Id !== '1'
    );
    if (filteredLineItems) {
      if (filteredLineItems.length > 0) {
        const subtotals = this.extractSubtotalsArray(filteredLineItems);
        return accountingUtils.calculateSubTotal(subtotals);
      }
    }
    return 0;
  }

  // Calculates the total payment received
  calculatePaymentReceived(total: any, balance: any = 0): number {
    try {
      let paymentReceived: any =
        parseFloat(total.toString()) - parseFloat(balance.toString());
      return +parseFloat(paymentReceived.toString()).toFixed(2);
    } catch (e) {
      throw e;
    }
  }

  // Calculates the invoice total found at the bottom of the page
  // total = subtotal - paymentReceived + financeCharge
  calculateTotal(
    subtotal: any,
    paymentReceived: any,
    financeCharge: any
  ): number {
    // Return 0 if amount is not a number
    if (Number.isNaN(subtotal)) {
      return 0;
    }
    // Set to 0 if values are not numbers
    const newPaymentReceived = isNaN(paymentReceived) ? 0 : paymentReceived;
    const newFinanceCharge = isNaN(financeCharge) ? 0 : financeCharge;
    // Compose an array of values to use javascript operators
    const arrayValues = [
      subtotal,
      paymentReceived > 0
        ? parseFloat(paymentReceived.toString()) * -1
        : paymentReceived,
      financeCharge
    ];
    let newTotal = arrayValues.reduce(
      (value, acc = 0) =>
        parseFloat(value.toString()) + parseFloat(acc.toString())
    );
    return +parseFloat(newTotal).toFixed(2);
  }

  extractFinanceChargeDynamicDueDate(fields: Array<any>) {
    try {
      if (isEmpty(fields)) throw 'Empty fields';
      const dynamicDate = find(fields, [
        'DefinitionId',
        environment.financeCharge.dynamicCustomFieldId
      ]);
      if (isEmpty(dynamicDate)) throw 'Empty fields';
      if (!dynamicDate.StringValue) throw 'No finance charge dynamic date id';
      return dynamicDate.StringValue;
    } catch (error) {
      return null;
    }
  }

  // Gets if should/should not send review on this invoice
  getSendPrivateNoteFlag(stringJSON, field: string): boolean {
    try {
      const jsonField: any = JSON.parse(stringJSON);
      const flag: any = jsonField[field];
      if (flag === false || flag === 'false') return false;
      return true;
    } catch (e) {
      return true;
    }
  }
}
