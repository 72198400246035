import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'contractor-lead-badge',
  templateUrl: './lead-badge.component.html',
  styleUrls: ['./lead-badge.component.scss']
})
export class LeadBadgeComponent implements OnInit {
  @Input() title;
  @Input() body;

  constructor() {}

  ngOnInit(): void {}
}
