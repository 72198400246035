import {Component, Input, OnInit} from '@angular/core';
import {isEmpty} from 'lodash';

@Component({
  selector: 'contractor-export-data-as-csv',
  templateUrl: './export-data-as-csv.component.html',
  styleUrls: ['./export-data-as-csv.component.scss']
})
export class ExportDataAsCsvComponent implements OnInit {

  @Input() data: any;
  @Input() basename = "Exported Data";


  ngOnInit(): void {
  }

  /**
   * Exports data to CSV
   */
  exportDataToCSV() {
    let csv = this.jsonToCsv(this.data);
    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });

    let link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    if (link.download !== undefined) {
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', this.basename || "Exported Data" + '.csv');
      link.click();
    } else {
      csv = 'data:text/csv;charset=utf-8,' + csv;
      window.open(encodeURI(csv));
    }
    document.body.removeChild(link);
  }

  jsonToCsv(data) {
    if(isEmpty(data)) return "";
    const delimiter = "::";
    return (
      Object.keys(data[0]).join(delimiter) +
      "\n" +
      data.map((d) => Object.values(d)
        .join(delimiter)).join("\n")
    );
  }

  constructor() { }

}
