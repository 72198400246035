import { Injectable } from '@angular/core';
import {take, map, switchMap, catchError} from 'rxjs/operators';
import { isEmpty, cloneDeep } from 'lodash';
import { of } from 'rxjs/observable/of';
import { zip } from 'rxjs/observable/zip';
import { Store } from '@ngrx/store';

import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { GenerateUniqueId } from 'apps/contract-estimator/src/app/shared/utils/generateUniqueId/generateUniqueId';
import { WorkOrderPicture } from '../../../../../work-order/picture/data-access/src/lib/+state/work-order-pictures.reducer';
import { getRouterParams } from 'libs/shared/router/data-access/src/lib/+state/router.selectors';
import { AppState } from 'apps/contract-estimator/src/app/app-state/app.state';
import {FileUploaderHttpServiceService} from "./file-uploader-http-service.service";
import {CloudStorageFileUploaderService} from "./cloud-storage-file-uploader.service";
import {EMPTY} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  serverUrl: string = new ServerUrl().getUrl();
  uniqueId = new GenerateUniqueId();

  constructor(
    public store: Store<AppState>,
    private fileUploaderHttpServiceService: FileUploaderHttpServiceService,
    private cloudStorageFileUploaderService: CloudStorageFileUploaderService
  ) {}

  /* Http urls */

  getPutObjectSignedUrl(fileName, fileType) {
    return this.fileUploaderHttpServiceService.getPutObjectSignedUrl(fileName, fileType);
  }

  downloadPicture(fileName: string) {
    return this.fileUploaderHttpServiceService.downloadPicture(fileName);
  }

  // Downloads rep image
  downloadSalesRepPicture(fileName: string) {
    return this.fileUploaderHttpServiceService.downloadSalesRepPicture(fileName);
  }

  // Gets storage signed url
  getStorageSignedUrl() {
    return this.fileUploaderHttpServiceService.getStorageSignedUrl();
  }

  // Get signed Delete picture from storage
  getSignedDeletePictureRequest(fileName: string) {
    return this.fileUploaderHttpServiceService.getSignedDeletePictureRequest(fileName);
  }

  // Updates work order picture info
  updateWorkOrderPictureInfo(payload: any) {
    return this.fileUploaderHttpServiceService.updateWorkOrderPictureInfo(payload);
  }

  // Downloads company logo
  downloadPDFCompanyLogoAsBase64DataUrl(name: string) {
    return this.fileUploaderHttpServiceService.downloadPDFCompanyLogoAsBase64DataUrl(name);
  }

  /* End of http */

  uploadWorkOrderFiles(
    _estimateId,
    _orderId,
    _workOrderId,
    selectedFiles: Array<File>
  ) {
    try {
      if (isEmpty(selectedFiles)) throw new Error('Select a file!');
      const list = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        if (!selectedFiles[i]) continue;
        const selectedFile = selectedFiles[i];
        // Allows image/jpeg, image/png, and image/jpg
        if (
          selectedFile.type === 'image/jpeg' ||
          selectedFile.type === 'image/png' ||
          selectedFile.type === 'image/jpg'
        ) {
          const stream = this.composeSingleFileUploader(
            _estimateId,
            _orderId,
            _workOrderId,
            selectedFile,
            i
          );
          list.push(stream);
        }
      }
      return list;
    } catch (error) {
      throw error;
    }
  }

  // Compose single uploader file stream: Gets s3 signed url, uploads to s3, add images  to mongodb
  composeSingleFileUploader(
    _estimateId,
    _orderId,
    _workOrderId,
    selectedFile: File,
    index: number
  ) {
    const fileName = this.composeNewFileName(
      _estimateId,
      _orderId,
      _workOrderId,
      index
    );
    const fd = new FormData();
    fd.append('file', selectedFile, fileName);
    return this.getSignedUrlAndUploadFile(fileName, selectedFile);
  }

  // Signed url and upload file
  getSignedUrlAndUploadFile(fileName, selectedFile) {
    return this.cloudStorageFileUploaderService.getSignedUrlAndUploadFile(
      fileName,
      selectedFile
    );
  }

  // Makes put req to aws s3 servers with file
  uploadFile(signedRequest, file, fileName) {
    return this.cloudStorageFileUploaderService.uploadFile(
      signedRequest,
      file,
      fileName
    );
  }

  // Composes new file name
  composeNewFileName(_estimateId, _orderId, _workOrderId, index: number) {
    const timestamp = this.getTimestamp();
    const name = `${_estimateId}-${_orderId}-${_workOrderId}-${timestamp}-${index}`;
    return name;
  }

  // Get timestamp
  getTimestamp() {
    const time = Math.floor(Date.now() / 1000);
    return time;
  }

  // Composes add many work order picture payload
  composeWorkOrderPicturePayload(
    responses: Array<{ error: boolean; fileName: string }>
  ): Array<WorkOrderPicture> {
    try {
      if (isEmpty(responses)) return [];
      const list = [];
      for (let i = 0; i < responses.length; i++) {
        if (isEmpty(responses[i])) continue;
        const item = responses[i];
        if (item.error) continue; // Ignored errored responses
        const woPictureItem = this.composeSingleWorkOrderPictureItem(
          item.fileName
        );
        if (isEmpty(woPictureItem)) continue;
        list.push(woPictureItem);
      }
      return list;
    } catch (error) {
      return [];
    }
  }

  composeSingleWorkOrderPictureItem(
    name: string,
    description: string = ''
  ): WorkOrderPicture {
    if (!name) return null;
    return {
      _id: name,
      name,
      description,
      contract: false,
      workOrder: false
    };
  }

  /* Picture downloader */

  downloadWorkOrderPictures(pictures: Array<WorkOrderPicture>) {
    try {
      if (isEmpty(pictures)) return [];
      const list = [];
      for (let i = 0; i < pictures.length; i++) {
        if (isEmpty(pictures[i])) continue;
        const picture = pictures[i];
        const stream = this.downloadJobPhotoAsBase64(picture).pipe(catchError(error => of(EMPTY)));
        if (!stream) continue;
        list.push(stream);
      }
      return list;
    } catch (error) {
      return [];
    }
  }

  /**
   * Download picture from s3
   * and format as base64
   * @param picture
   */
  downloadPictureAndConvertToBase64(picture: WorkOrderPicture) {
    return this.downloadPicture(picture.name)
      .pipe(switchMap(res => this.blobToBase64(res, picture)));
  }

  /**
   * Download job photo from cloudfront and
   * convert to base64
   * @param picture
   */
  downloadJobPhotoAsBase64(picture: WorkOrderPicture) {
    return this.fileUploaderHttpServiceService.downloadJobPhoto(picture.name)
      .pipe(switchMap(res => this.blobToBase64(res, picture)));
  }

  // Downloads sales rep img and convert to base64
  downloadSalesRepPictureAndConvertToBase64(pictureName) {
    return this.downloadSalesRepPicture(pictureName).pipe(
      switchMap(res => this.blobToBase64(res, pictureName))
    );
  }

  blobToBase64(res, picture) {
    return this.cloudStorageFileUploaderService.blobToBase64(res, picture)
  }

  /* Functions */

  // Compose get images stream array
  composeGetWorkOrderPicturesStreamArray(estimate) {
    const observables = this.downloadWorkOrderPictures(estimate.pictures);
    if (isEmpty(observables)) return of(estimate);
    return zip(...observables).pipe(
      map(pictures => {
        const newEstimate = cloneDeep(estimate);
        newEstimate.pictures = pictures;
        return newEstimate;
      })
    );
  }

  // Upload work order pictures files from providers

  onUploadFiles(files: Array<File>) {
    return this.store.select(getRouterParams).pipe(
      take(1),
      switchMap((params: any) => {
        const streams = this.uploadWorkOrderFiles(
          params.jobId,
          params.orderId,
          params.workOrderId,
          files
        );
        if (isEmpty(streams)) return of([]);
        return zip(...streams).pipe(take(1));
      })
    );
  }


  // Uploads change order pdf
  uploadPDF(encodedDocument: string, path) {
    const file = new File([encodedDocument], 'file.pdf', {
      type: 'application/pdf'
    });
    return this.getSignedUrlAndUploadFile(
      path,
      file
    );
  }
}
