import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from '../../../../../../apps/contract-estimator/src/app/config/server-url';
import { isEmpty } from 'lodash';
import { SubcontractorState } from '../+state/reducers/subcontractors.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class SubcontractorService {
  //Variables
  serverUrl: string;
  data: any;

  constructor(public http: HttpClient) {
    this.serverUrl = new ServerUrl().getUrl();
  }

  /* HTTPs */

  getSubcontractors() {
    return this.http.get(this.serverUrl + '/subcontractors');
  }

  /* Business Logic */

  getAndComposeSubcontractors() {
    return this.getSubcontractors().pipe(
      map((subs: any) => this.composeSubcontractors(subs))
    );
  }

  // Composes sales rep array from the response
  composeSubcontractors(rawSub: Array<any>): Array<SubcontractorState> {
    if (isEmpty(rawSub)) return [];
    const list = [];
    for (let i = 0; i < rawSub.length; i++) {
      if (isEmpty(rawSub[i])) continue;
      const sub: SubcontractorState = this.composeSubcontractor(rawSub[i]);
      if (isEmpty(sub)) continue;
      list.push(sub);
    }
    return list;
  }

  // Composes single sales rep
  composeSubcontractor(sub: any): SubcontractorState {
    if (isEmpty(sub)) return null;
    return {
      _id: sub._id,
      name: sub.name,
      email: sub.email,
      phoneNumber: sub.phoneNumber,
      firstName: sub.firstName,
      lastName: sub.lastName,
      frequent: sub.frequent,
      active: sub.active
    };
  }
}
