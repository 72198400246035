// Composes navigation menu for browser app

import {
  TeamMemberStatus,
  UserRoleType
} from '@contract-estimator/shared/enums';

export function composeAppMainMenu({
  userRole,
  enabledFeatures,
  inProgressWarrantyCount,
  inProgressLeadCount,
  unscheduledWorkOrdersCount,
  unscheduledQCICount,
  unscheduledWarrantiesCount,
  /* Invoice count */
  inProgressInvoiceCount,
  unpaidInvoiceCount,
  /* Menu Header Total Counts */
  pipelineTotalCount,
  estimatesTotalCount,
  schedulerTotalCount,
  invoiceTotalCount
}) {
  const WEATHER = {
    label: 'Weather',
    icon: 'cloud',
    items: [
      {
        label: 'Forecast',
        command: () => null
      }
    ]
  };

  const LEADS = {
    label: 'Leads',
    icon: 'filter_alt',
    items: [
      {
        label: 'New Lead',
        route: 'new-lead'
      }
    ]
  };

  const WARRANTIES = {
    label: 'Warranties',
    icon: 'warning',
    items: [
      {
        label: 'Completed Jobs',
        route: 'work-orders/completed'
      },
      {
        label: 'In progress',
        route: 'warranty/lead/In progress',
        count: inProgressWarrantyCount
      },
      {
        label: 'Deleted',
        route: 'warranty/lead/Deleted'
      }
    ]
  };

  const ESTIMATES = {
    label: 'Estimates',
    icon: 'folder',
    count: estimatesTotalCount,
    items: [
      {
        label: 'In Progress',
        count: inProgressLeadCount,
        route: 'pipeline/In progress'
      },
      {
        label: "Didn't buy",
        route: 'pipeline/Did not buy'
      },
      {
        label: 'Sold',
        route: 'pipeline/Sold'
      },
      {
        label: 'Deleted',
        route: 'pipeline/Deleted'
      }
    ]
  };

  const SCHEDULER = {
    label: 'Scheduler',
    icon: 'calendar_view_week',
    count: schedulerTotalCount,
    items: [
      {
        label: 'Work Orders',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledWorkOrdersCount,
            route: 'work-orders/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'work-orders/scheduled'
          },
          {
            label: 'Completed',
            route: 'work-orders/completed'
          },
          {
            label: 'Deleted',
            route: 'work-orders/deleted'
          }
        ]
      },
      {
        label: 'QCIs',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledQCICount,
            route: 'qci/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'qci/scheduled'
          },
          {
            label: 'Completed',
            route: 'qci/completed'
          },
          {
            label: 'Deleted',
            route: 'qci/deleted'
          }
        ]
      },
      {
        label: 'Warranties',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledWarrantiesCount,
            route: 'warranty/work-orders/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'warranty/work-orders/scheduled'
          },
          {
            label: 'Completed',
            route: 'warranty/work-orders/completed'
          },
          {
            label: 'Deleted',
            route: 'warranty/work-orders/Deleted'
          }
        ]
      }
    ]
  };

  const INVOICES = {
    label: 'Invoices',
    icon: 'receipt',
    count: invoiceTotalCount,
    items: [
      {
        label: 'In Progress',
        count: inProgressInvoiceCount,
        route: 'invoices/In progress'
      },
      {
        label: 'Unpaid',
        count: unpaidInvoiceCount,
        route: 'invoices/outstanding'
      },
      {
        label: 'Paid',
        route: 'invoices/paid'
      },
      {
        label: 'Deleted',
        route: 'invoices/deleted'
      }
    ]
  };

  const PRODUCTS = {
    label: 'Products',
    icon: 'verified',
    items: [
      {
        label: 'Shingle Roofs',
        items: [
          {
            label: 'Owens Corning',
            link: 'https://www.owenscorning.com/roofing/shingles'
          },
          {
            label: 'IKO',
            link: 'https://www.iko.com/na/'
          }
        ]
      },
      {
        label: 'Flat Roofs',
        items: [
          {
            label: 'GAF',
            link:
              'https://www.gaf.com/en-us/roofing-products/commercial-roofing-products'
          },
          {
            label: 'IB',
            link: 'https://www.ibroof.com/'
          },
          {
            label: 'Mulehide',
            link: 'https://www.mulehide.com/'
          },
          {
            label: 'Genflex',
            link: 'http://www.genflex.com/'
          },
          {
            label: 'Coatings',
            link: 'https://henry.com/'
          }
        ]
      }
    ]
  };

  const TEAM_ANALYTICS = {
    label: 'Analytics',
    icon: 'analytics',
    items: [
      {
        label: 'My Analytics',
        route: 'analytics/team-analytics'
      }
    ]
  };

  const PAYROLL = {
    label: 'Payroll',
    icon: 'assignment',
    items: [
      {
        label: 'In Progress',
        route: 'payroll/list/in progress'
      },
      {
        label: 'Paid',
        route: 'payroll/list/paid'
      },
      {
        label: 'Deleted',
        route: 'payroll/list/deleted'
      },
      {
        label: 'Logbook',
        route: 'payroll/logbook'
      },
      {
        label: 'Database',
        route: 'payroll/cost-database'
      }
    ]
  };

  const ANALYTICS = {
    label: 'Analytics',
    icon: 'analytics',
    items: [
      {
        label: 'Leads',
        route: 'analytics/leads'
      },
      {
        label: 'Sales',
        route: 'analytics/sales'
      },
      {
        label: 'Closing Ratio',
        route: 'analytics/closing-ratio'
      },
      {
        label: "Didn't Buy",
        route: 'analytics/did-not-buy'
      },
      {
        label: 'Payments',
        route: 'analytics/payments'
      },
      {
        label: 'Predicted Markup',
        route: 'analytics/predict-markup'
      },
      {
        label: 'Rebates',
        route: 'analytics/rebates'
      },
      {
        label: 'Team Analytics',
        route: 'analytics/team-analytics'
      },
      {
        label: 'More Analytics',
        link: 'https://eliteroofingsalesanalytics.onrender.com/'
      }
    ]
  };

  const REPORTS = {
    label: 'Reports',
    icon: 'source',
    items: [
      {
        label: 'Leads',
        items: [
          {
            label: 'In Progress',
            route: 'reports/leads/in progress'
          },
          {
            label: "Didn't Buy",
            route: 'reports/leads/did not buy'
          },
          {
            label: 'Sold',
            route: 'reports/leads/sold'
          }
        ]
      },
      {
        label: 'Advertising',
        items: [
          {
            label: 'In Progress',
            route: 'reports/advertising/in progress'
          },
          {
            label: "Didn't Buy",
            route: 'reports/advertising/did not buy'
          },
          {
            label: 'Sold',
            route: 'reports/advertising/sold'
          }
        ]
      }
    ]
  };

  const SETTINGS = {
    label: 'Settings',
    icon: 'settings',
    items: [
      {
        label: 'Calendar',
        route: 'settings/calendar/auth'
      },
      {
        label: 'eSignature',
        route: 'settings/agreement/auth'
      },
      {
        label: 'Invoice',
        route: 'settings/invoice/auth'
      },
      {
        label: 'Prices',
        route: 'settings/prices'
      },
      {
        label: 'Stores',
        route: 'settings/stores'
      },
      {
        label: 'Paperwork',
        route: 'settings/paperwork'
      },
      {
        label: 'Job Costs',
        route: 'settings/job-cost-manager/filter-set'
      },
      {
        label: 'Subcontractors',
        route: ['settings/subcontractors', { status: 'ACTIVE' }]
      },
      {
        label: 'Team Members',
        route: ['settings/team-members', { status: TeamMemberStatus.ACTIVE }]
      }
    ]
  };

  const menu: any[] = [];

  const hasWeather = accountHas(enabledFeatures, 'weather');
  const hasLeads = accountHas(enabledFeatures, 'leads');
  const hasWarranties = accountHas(enabledFeatures, 'warranties');
  const hasEstimates = accountHas(enabledFeatures, 'estimates');
  const hasScheduler = accountHas(enabledFeatures, 'scheduler');
  const hasInvoices = accountHas(enabledFeatures, 'invoices');
  const hasProducts = accountHas(enabledFeatures, 'products');
  // Based on roles
  const hasPayroll = accountHas(enabledFeatures, 'payroll');
  const hasAnalytics = accountHas(enabledFeatures, 'analytics');
  const hasReports = accountHas(enabledFeatures, 'reports');
  const hasSettings = accountHas(enabledFeatures, 'settings');
  const hasTeamAnalytics = accountHas(enabledFeatures, 'teamAnalytics');

  if (hasWeather) menu.push(WEATHER);
  if (hasLeads) menu.push(LEADS);
  if (hasWarranties) menu.push(WARRANTIES);
  if (hasEstimates) menu.push(ESTIMATES);
  if (hasScheduler) menu.push(SCHEDULER);
  if (hasInvoices) menu.push(INVOICES);
  if (hasProducts) menu.push(PRODUCTS);

  // Add menu options if admin
  if (userRole === UserRoleType.ADMIN) {
    if (hasPayroll) menu.push(PAYROLL);
    if (hasAnalytics) menu.push(ANALYTICS);
    if (hasReports) menu.push(REPORTS);
    if (hasSettings) menu.push(SETTINGS);
  } else if (userRole === UserRoleType.SALES_REP) {
    if (hasTeamAnalytics) menu.push(TEAM_ANALYTICS);
  }

  return menu;
}

function accountHas(enabledFeatures, value) {
  if (!enabledFeatures) return false;
  return enabledFeatures.includes(value);
}
