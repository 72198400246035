import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '@contract-estimator/shared/environments';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';

@Injectable()
export class InvoiceService {
  constructor(
    private http: HttpClient,
    private serverUrl: ServerUrl
  ) {}

  // Creates the invoice in qbs
  createInvoice(transferInvoiceObj: any) {
    return this.http.post(
      `${this.serverUrl.getUrl()}/invoice/send-new`,
      transferInvoiceObj
    );
  }

  // Updates the invoice in qbs
  updateInvoice(transferInvoiceObj: any) {
    return this.http.post(
      `${this.serverUrl.getUrl()}/invoice/resend`,
      transferInvoiceObj
    );
  }

  // Saves the new invoice in qbs
  saveNewInvoice(transferInvoiceObj: any) {
    return this.http.post(
      `${this.serverUrl.getUrl()}/invoice/save-new`,
      transferInvoiceObj
    );
  }

  // Saves the existing invoice in qbs
  saveExistingInvoice(transferInvoiceObj: any) {
    return this.http.put(
      `${this.serverUrl.getUrl()}/invoice/save-existing`,
      transferInvoiceObj
    );
  }

  // Creates an invoice pdf
  createInvoicePDF(invoiceId: any) {
    return this.http.post(
      `${this.serverUrl.getUrl()}/invoice-preview/create/pdf`,
      { invoiceId }
    );
  }

  // Gets info without encryption
  getInvoiceAndCustomerInformation(id: any) {
    return this.http.get(
      `${this.serverUrl.getUrl()}/invoice/${id}`
    );
  }


  // Gets invoice for preview component
  getInvoicePreview(id: any) {
    return this.http.get(
      this.serverUrl.getUrl() + '/invoice/preview/' + id
    );
  }

  // Gets owner information
  getInvoiceOwnerInformation(_id: any) {
    return this.http.get(
      this.serverUrl.getUrl() + `/invoice/${_id}/owner`
    );
  }

  // Calculate finance charge
  calculateFinanceCharge(
    balance: number,
    perAnnum: number,
    timePeriod: number,
    dueDate: any,
    statementDate: any = moment().format('YYYY-MM-DD'),
    timePeriodName: string = 'days'
  ): number {
    const newStatementDate = moment(statementDate, 'YYYY-MM-DD');
    const newDueDate = moment(dueDate, 'YYYY-MM-DD');
    // if current date is less than the overdue date, it returns 0
    try {
      if (balance === 0) {
        return 0;
      }
      // Return 0 if amount is not a number
      if (Number.isNaN(balance)) {
        return 0;
      }
      // If dates are invalid
      if (!newStatementDate.isValid() || !newDueDate.isValid()) return 0;
      // Finance charge is calculated if statement date is greater than the due date
      if (newStatementDate.isBefore(newDueDate)) return 0;
      // Date calculations
      const overdueUnits = this.calculateDiffBetweenDates(
        dueDate,
        statementDate,
        timePeriodName
      );
      // Overdue days needs to be greater than the grace period
      if (overdueUnits < 1) {
        return 0;
      }
      // number calculations
      const decimalPerAnnum: number = parseFloat(perAnnum.toString()) / 100;
      const periodicRate: number = decimalPerAnnum / timePeriod;
      // If overdue days is not greater than the grace period, we return 0
      const financeCharge = balance * periodicRate;
      const totalFinanceCharge = financeCharge * overdueUnits;
      return +parseFloat(totalFinanceCharge.toString()).toFixed(2);
    } catch (e) {
      throw e;
    }
  }

  calculateDiffBetweenDates(
    startDate: any,
    endDate: any,
    type: string
  ): number {
    let unit = 0;
    let start_date = moment(startDate);
    let end_date = moment(endDate);
    let duration = moment.duration(end_date.diff(start_date));
    switch (type) {
      case 'days':
        unit = duration.asDays();
      default:
        unit = duration.asDays();
    }
    return +parseFloat(unit.toString()).toFixed(3);
  }

  // Calculates the due date
  calculateDueDate(txnDate) {
    return moment(txnDate, 'MM/DD/YYYY')
      .add(environment.financeCharge.dueDaysNumber, 'days')
      .format('YYYY-MM-DD');
  }

  // Void invoice
  voidInvoice(invoiceId: any) {
    // return new ErrorObservable('Error')
    return this.http.post(`${this.serverUrl.getUrl()}/invoice/void/by/id`, {
      invoiceId
    });
  }
}
