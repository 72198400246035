import { of } from 'rxjs/observable/of';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as salesRepsActions from '../actions/personnel.actions';
import { ServerUrl } from '../../../../../../../apps/contract-estimator/src/app/config/server-url';
import { PersonnelService } from '../../services/personnel.service';
import { catchError, mergeMap, switchMap, map, take } from 'rxjs/operators';

@Injectable()
export class SalesRepsEffects {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(
    private actions$: Actions,
    private personnelService: PersonnelService
  ) {}

  @Effect()
  getPersonnel$ = this.actions$.pipe(
    ofType(salesRepsActions.GET_PERSONNEL),
    switchMap((action: salesRepsActions.GetPersonnel) => {
      return this.personnelService.getPayrollPersonnel().pipe(
        take(1),
        switchMap((res: any) => {
          const personnel = this.personnelService.composePersonnel(res);
          return [new salesRepsActions.SetPersonnel(personnel)];
        }),
        catchError(err => {
          return of({
            type: 'GET_PERSONNEL_ERROR'
          });
        })
      );
    })
  );
}
