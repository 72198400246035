import { get } from 'lodash';

export function estimatedJobCostsHeaderDataProjector(headerData, margin, profit) {
  return {
    ...headerData,
    margin,
    profit
  };
}

export function actualJobCostsHeaderDataProjector(
  headerData,
  margin,
  variance,
  profit
) {
  const value =  variance && +variance.toFixed(2)
  return {
    ...headerData,
    margin,
    variance: value,
    profit
  };
}

export function jobCostsHeaderDataProjector(estimated, actual) {
  return {
    estimatedMarkup: get(estimated, 'markup'),
    estimatedDate: get(estimated, 'date'),
    estimatedJobNumber: get(estimated, 'jobNumber', ''),
    estimatedNetMargin: get(estimated, 'margin'),
    estimatedNetProfit: get(estimated, 'profit'),
    estimatedVariance: get(estimated, 'variance'),
    actualDate: get(actual, 'date'),
    actualJobNumber: get(actual, 'jobNumber', ''),
    actualMarkup: get(actual, 'markup'),
    actualNetMargin: get(actual, 'margin'),
    actualNetProfit: get(actual, 'profit'),
    actualVariance: get(actual, 'variance')
  };
}

export function partialActualJobCostsHeaderDataProjector(markup, date, jobNumber) {
  return {
    markup,
    date,
    jobNumber
  };
}
