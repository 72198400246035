import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { pluck } from 'rxjs/operators';

import * as models from './apollo-subcontractor-manager.service.models';
import * as fragments from './apollo-subcontractor-manager.service.fragments';

@Injectable()
export class ApolloSubcontractorManagerService {
  /**
   * Creates a new subcontractor
   * @param payload
   */
  createSubcontractor(payload: models.CreateSubcontractorApolloPayload) {
    return this.apollo
      .mutate({
        mutation: fragments.CREATE_SUBCONTRACTOR,
        variables: { payload }
      })
      .pipe(pluck('data', 'createSubcontractor'));
  }

  /**
   * Gets all subcontractors
   */
  getAllSubcontractors() {
    return this.apollo
      .watchQuery({
        query: fragments.GET_ALL_SUBCONTRACTORS
      })
      .valueChanges.pipe(pluck('data', 'subcontractors'));
  }

  /**
   * Gets a subcontractor by id
   * @param _id
   */
  getSubcontractor(_id: string) {
    return this.apollo
      .watchQuery({
        query: fragments.GET_SUBCONTRACTOR,
        variables: { _id }
      })
      .valueChanges.pipe(pluck('data', 'subcontractor'));
  }

  /**
   * Updates a subcontractor
   * @param _id
   * @param payload
   */
  updateSubcontractor(
    _id: string,
    payload: models.UpdateSubcontractorApolloPayload
  ) {
    return this.apollo
      .mutate({
        mutation: fragments.UPDATE_SUBCONTRACTOR,
        variables: { _id, payload }
      })
      .pipe(pluck('data', 'updateSubcontractor'));
  }

  /**
   * Sets a subcontractor as active
   * @param _id
   */
  setSubcontractorAsActive(_id: string) {
    return this.apollo
      .mutate({
        mutation: fragments.SET_SUBCONTRACTOR_AS_ACTIVE,
        variables: { _id }
      })
      .pipe(pluck('data', 'setSubcontractorAsActive'));
  }

  /**
   * Sets a subcontractor as inactive
   * @param _id
   */
  setSubcontractorAsInactive(_id: string) {
    return this.apollo
      .mutate({
        mutation: fragments.SET_SUBCONTRACTOR_AS_INACTIVE,
        variables: { _id }
      })
      .pipe(pluck('data', 'setSubcontractorAsInactive'));
  }

  /**
   * Sets a subcontractor as frequent
   * @param _id
   */
  setSubcontractorAsFrequent(_id: string) {
    return this.apollo
      .mutate({
        mutation: fragments.SET_SUBCONTRACTOR_AS_FREQUENT,
        variables: { _id }
      })
      .pipe(pluck('data', 'setSubcontractorAsFrequent'));
  }

  /**
   * Sets a subcontractor as infrequent
   * @param _id
   */
  setSubcontractorAsInfrequent(_id: string) {
    return this.apollo
      .mutate({
        mutation: fragments.SET_SUBCONTRACTOR_AS_INFREQUENT,
        variables: { _id }
      })
      .pipe(pluck('data', 'setSubcontractorAsInfrequent'));
  }

  /**
   * Searches subcontractors by criteria
   * @param searchCriteria
   */
  searchSubcontractors(
    searchCriteria?: models.SearchSubcontractorsApolloPayload
  ) {
    return this.apollo
      .watchQuery({
        query: fragments.SEARCH_SUBCONTRACTORS,
        variables: { payload: searchCriteria }
      })
      .valueChanges.pipe(pluck('data', 'searchSubcontractorsByCriteria'));
  }

  /**
   * Gets all estimate trades for a company
   */
  getCompanyEstimateTrades() {
    return this.apollo
      .watchQuery({
        query: fragments.GET_COMPANY_ESTIMATE_TRADES
      })
      .valueChanges.pipe(pluck('data', 'companyEstimateTrades'));
  }

  constructor(private apollo: Apollo) {}
}
