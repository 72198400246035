import { Action } from '@ngrx/store';

export const GET_WARRANTY_LIST = 'WARRANTY > GET_WARRANTY_LIST';
export class GetWarrantyList {
  readonly type = GET_WARRANTY_LIST;
  constructor(
    public status: string,
    public warrantyType: string,
    public criteria?: any
  ) {}
}

export const WARRANTY_LIST = 'WARRANTY > WARRANTY_LIST';
export class WarrantyList {
  readonly type = WARRANTY_LIST;
  constructor(public payload: any) {}
}

export const UPDATE_WARRANTY_LIST_STATE =
  'WARRANTY > UPDATE_WARRANTY_LIST_STATE';
export class UpdateWarrantyListState {
  readonly type = UPDATE_WARRANTY_LIST_STATE;
  constructor(public changes: any) {}
}

export const RESET_WARRANTY_LIST_STATE =
  'WARRANTY > UPDATE_WARRANTY_LIST_STATE';
export class ResetWarrantyListState {
  readonly type = RESET_WARRANTY_LIST_STATE;
  constructor() {}
}

export const UPDATE_WARRANTY_INFORMATION =
  'WARRANTY LIST > UPDATE_WARRANTY_INFORMATION';
export class UpdateWarrantyInformation {
  readonly type = UPDATE_WARRANTY_INFORMATION;
  constructor(
    public _jobId,
    public _orderId,
    public _workOrderId,
    public changes: any
  ) {}
}

export const REMOVE_WARRANTY_FROM_LIST = 'REMOVE_WARRANTY_FROM_LIST';
export class RemoveWarrantyFromList {
  readonly type = REMOVE_WARRANTY_FROM_LIST;
  constructor(public _id: string) {}
}

export const UPDATE_WARRANTY_LIST_ELEMENT =
  'WORK_ORDER_LIST > UPDATE_WARRANTY_LIST_ELEMENT';
export class UpdateWarrantyListElement {
  readonly type = UPDATE_WARRANTY_LIST_ELEMENT;
  constructor(public _orderId: string, public changes: any) {}
}

export const CHANGE_CLAIM_SALES_REP = 'WORK_ORDER_LIST > CHANGE_CLAIM_SALES_REP';
export class ChangeClaimSalesRep {
  readonly type = CHANGE_CLAIM_SALES_REP;
  constructor(
    public _jobId,
    public _tradeId,
    public _salesRepId,
    public salesRepName
  ) {
  }

}

export const CHANGE_WARRANTY_SALES_REP = 'WORK_ORDER_LIST > CHANGE_WARRANTY_CLAIM';
export class ChangeWarrantySalesRep {
  readonly type = CHANGE_WARRANTY_SALES_REP;
  constructor(
    public _jobId,
    public _tradeId,
    public _salesRepId,
    public salesRepName
  ) {
  }

}

export type Actions =
  | GetWarrantyList
  | WarrantyList
  | UpdateWarrantyListState
  | ResetWarrantyListState
  | UpdateWarrantyInformation
  | ChangeClaimSalesRep
  | ChangeWarrantySalesRep;
