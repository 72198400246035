import { SubcontractorState } from '../reducers/subcontractors.reducer';
import { isEmpty } from 'lodash';
import { SubcontractorOption } from 'libs/shared/interfaces/src/lib/interfaces';

export function composeSubcontractorsArrayWithIdAndName(
  subs: Array<SubcontractorOption>
) {
  if (isEmpty(subs)) return [];
  const list = [];
  for (let i = 0; i < subs.length; i++) {
    if (isEmpty(subs[i])) continue;
    const salesRep = subs[i];
    if (!salesRep._id) continue;
    const item = {
      _id: salesRep._id,
      name: salesRep.name
    };
    list.push(item);
  }
  return list;
}

export function composeSubcontractorsArrayAsLabelAndValue(
  salesReps: Array<SubcontractorState>
) {
  if (isEmpty(salesReps)) return [];
  const list = [];
  for (let i = 0; i < salesReps.length; i++) {
    if (isEmpty(salesReps[i])) continue;
    const salesRep = salesReps[i];
    if (!salesRep._id) continue;
    const item = {
      value: salesRep._id,
      label: salesRep.name
    };
    list.push(item);
  }
  return list;
}

// Gets selected sub by _id
export function getSelectedSubcontractorById(
  subs: Array<SubcontractorState>,
  _subId
) {
  if (isEmpty(subs) || !_subId) return null;
  for (let i = 0; i < subs.length; i++) {
    if (isEmpty(subs[i])) continue;
    const sub = subs[i];
    if (sub._id === _subId) return sub;
  }
  return null;
}
