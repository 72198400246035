export interface TotalSummary {
  sales: number | string;
  additionalScopeSales: number | string;
  additionalScopeCosts: number | string;
  totalSales: number | string;
  advertising: number | string;
  overhead: number | string;
  salesRepCommission: number | string;
  managerCommission: number | string;
  workOrderTotal: number | string;
  purchaseOrderTotal: number | string;
  miscellaneousCost: number | string;
  profit: number | string;
  netProfit: number | string;
}

export class TotalSummary implements TotalSummary {
  sales: number | string;
  additionalScopeSales: number | string;
  additionalScopeCosts: number | string;
  totalSales: number | string;
  advertising: number | string;
  overhead: number | string;
  salesRepCommission: number | string;
  managerCommission: number | string;
  workOrderTotal: number | string;
  purchaseOrderTotal: number | string;
  miscellaneousCost: number | string;
  profit: number | string;
  netProfit: number | string;

  constructor(builder: MyTotalSummaryBuilder) {
    this.sales = builder.sales;
    this.additionalScopeSales = builder.additionalScopeSales;
    this.additionalScopeCosts = builder.additionalScopeCosts;
    this.totalSales = builder.totalSales;
    this.advertising = builder.advertising;
    this.overhead = builder.overhead;
    this.salesRepCommission = builder.salesRepCommission;
    this.managerCommission = builder.managerCommission;
    this.workOrderTotal = builder.workOrderTotal;
    this.purchaseOrderTotal = builder.purchaseOrderTotal;
    this.miscellaneousCost = builder.miscellaneousCost;
    this.profit = builder.profit;
    this.netProfit = builder.netProfit
  }
}

export interface MyTotalSummaryBuilder {
  sales: number | string;
  additionalScopeSales: number | string;
  additionalScopeCosts: number | string;
  totalSales: number | string;
  workOrderTotal: number | string;
  purchaseOrderTotal: number | string;
  miscellaneousCost: number | string;
  advertising: number | string;
  overhead: number | string;
  profit: number | string;
  salesRepCommission: number | string;
  managerCommission: number | string;
  netProfit: number | string;
}

export class TotalSummaryBuilder implements MyTotalSummaryBuilder {
  sales: number | string;
  additionalScopeSales: number | string;
  additionalScopeCosts: number | string;
  totalSales: number | string;
  workOrderTotal: number | string;
  purchaseOrderTotal: number | string;
  miscellaneousCost: number | string;
  advertising: number | string;
  overhead: number | string;
  profit: number | string;
  salesRepCommission: number | string;
  managerCommission: number | string;
  netProfit: number | string;

  setSales(total: number | string) {
    this.sales = total;
    return this;
  }
  setAdditionalScopeSales(total: number | string) {
    this.additionalScopeSales = total;
    return this;
  }

  setAdditionalScopeCosts(total: number | string) {
    this.additionalScopeCosts = total;
    return this;
  }

  setTotalSales(total: number | string) {
    this.totalSales = total;
    return this;
  }
  setWorkOrderTotal(total: number | string) {
    this.workOrderTotal = total;
    return this;
  }
  setPurchaseOrderTotal(total: number | string) {
    this.purchaseOrderTotal = total;
    return this;
  }
  setMiscellaneousCost(total: number | string) {
    this.miscellaneousCost = total;
    return this;
  }
  setAdvertising(total: number | string) {
    this.advertising = total;
    return this;
  }
  setOverhead(total: number | string) {
    this.overhead = total;
    return this;
  }
  setProfit(total: number | string) {
    this.profit = total;
    return this;
  }
  setSalesRepCommission(total: number | string) {
    this.salesRepCommission = total;
    return this;
  }
  setManagerCommission(total: number | string) {
    this.managerCommission = total;
    return this;
  }

  setNetProfit(total: number | string) {
    this.netProfit = total;
    return this;
  }

  build() {
    return new TotalSummary(this);
  }
}

export class TotalSummaryDirector {
  private builder: TotalSummaryBuilder;

  setBuilder(builder: TotalSummaryBuilder) {
    this.builder = builder;
    return this;
  }

  constructTotals(
    sales,
    additionalScopeSales,
    additionalScopeCosts,
    totalSales,
    workOrderTotal,
    purchaseOrderTotal,
    miscellaneousTotal,
    advertising,
    overhead,
    profit,
    salesRepCommission,
    managerCommission,
    netProfit
  ) {
    return this.builder
      .setSales(sales)
      .setAdditionalScopeSales(additionalScopeSales)
      .setAdditionalScopeCosts(additionalScopeCosts)
      .setTotalSales(totalSales)
      .setWorkOrderTotal(workOrderTotal)
      .setPurchaseOrderTotal(purchaseOrderTotal)
      .setMiscellaneousCost(miscellaneousTotal)
      .setAdvertising(advertising)
      .setOverhead(overhead)
      .setProfit(profit)
      .setSalesRepCommission(salesRepCommission)
      .setManagerCommission(managerCommission)
      .setNetProfit(netProfit)
      .build();
  }

  constructTotalSummaryFieldNames() {
    return this.builder
      .setSales('Sales')
      .setAdditionalScopeSales('Additional Scope Sales')
      .setAdditionalScopeCosts('Additional Scope Costs')
      .setTotalSales('Equals Total Sales')
      .setWorkOrderTotal('Less Work Order Costs')
      .setPurchaseOrderTotal('Less Purchase Oder Costs')
      .setMiscellaneousCost('Less Miscellaneous Costs')
      .setAdvertising('Less Advertising Costs')
      .setOverhead('Less Overhead Costs')
      .setProfit('Equals Profit')
      .setSalesRepCommission('Sales Rep Commission')
      .setManagerCommission('Manager Commission')
      .setNetProfit("Equals Net Profit")
      .build();
  }
}
