export function isSectionUndocumented(section): boolean {
  try {
    return isUndocumented(section);
  } catch (error) {
    return false;
  }
}
function isUndocumented(section): boolean {
  if (section._woOrPoId && section._orderId) return false;
  return true;
}
