import { MailingAddressBuilder } from 'apps/contractor-server/src/app/utils/src/app/address/address.class';
import {get} from 'lodash';
export class AgreementRecipient {
  name?: string;

  addressLine1?: string;

  addressLine2?: string;

  city?: string;

  state?: string;

  zipCode?: string;

  phoneNumber?: string;

  altPhoneNumber?: string;

  email?: string;

  altEmail?: string;

  constructor(builder: AgreementRecipientBuilderState) {
    this.name = builder.name;
    this.addressLine1 = builder.addressLine1;
    this.addressLine2 = builder.addressLine2;
    this.city = builder.city;
    this.state = builder.state;
    this.zipCode = builder.zipCode;
    this.phoneNumber = builder.phoneNumber;
    this.altPhoneNumber = builder.altPhoneNumber;
    this.email = builder.email;
    this.altEmail = builder.altEmail;
  }
}

export class AgreementRecipientBuilderState {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phoneNumber?: string;
  altPhoneNumber?: string;
  email?: string;
  altEmail?: string;
}

export class AgreementRecipientBuilderBehavior {
  setName;
  setAddressLine1;
  setCity;
  setState;
  setZipCode;
  setPhoneNumber;
  setAltPhoneNumber;
  setEmail;
  build;
}

export class AgreementRecipientBuilder
  implements AgreementRecipientBuilderState, AgreementRecipientBuilderBehavior {
  private _name?: string;
  private _addressLine1?: string;
  private _city?: string;
  private _state?: string;
  private _zipCode?: string;
  private _phoneNumber?: string;
  private _altPhoneNumber?: string;
  private _email?: string;
  private _altEmail?: string;

  get name() {
    return this._name;
  }

  get addressLine1() {
    return this._addressLine1;
  }

  get addressLine2() {
    return new MailingAddressBuilder()
      .setCity(this._city)
      .setState(this._state)
      .setZipCode(this._zipCode)
      .build().addressLine2;
  }

  get city() {
    return this._city;
  }

  get state() {
    return this._state;
  }

  get zipCode() {
    return this._zipCode;
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  get altPhoneNumber() {
    return this._altPhoneNumber;
  }

  get email() {
    return this._email;
  }

  get altEmail() {
    return this._altEmail;
  }

  setName(name: string) {
    this._name = name;
    return this;
  }

  setAddressLine1(value: string) {
    this._addressLine1 = value;
    return this;
  }

  setCity(city: string) {
    this._city = city;
    return this;
  }

  setState(state: string) {
    this._state = state;
    return this;
  }

  setZipCode(zipCode: string) {
    this._zipCode = zipCode;
    return this;
  }

  setPhoneNumber(phone: string) {
    this._phoneNumber = phone;
    return this;
  }

  setAltPhoneNumber(phone: string) {
    this._altPhoneNumber = phone;
    return this;
  }

  setEmail(email: string) {
    this._email = email;
    return this;
  }

  setAltEmail(email: string) {
    this._altEmail = email;
    return this;
  }

  build() {
    return new AgreementRecipient(this);
  }
}

export class AgreementRecipientDirector {
  private builder: AgreementRecipientBuilder;

  setBuilder(builder: AgreementRecipientBuilder) {
    this.builder = builder;
  }

  createOwner(
    name: string,
    addressLine1: string,
    city: string,
    state: string,
    zipCode: string,
    phoneNumber: string,
    altPhoneNumber: string,
    email: string
  ): AgreementRecipient {
    return this.builder
      .setName(name)
      .setAddressLine1(addressLine1)
      .setCity(city)
      .setState(state)
      .setZipCode(zipCode)
      .setPhoneNumber(phoneNumber)
      .setAltPhoneNumber(altPhoneNumber)
      .setEmail(email)
      .build();
  }

  createSalesRep(name, phoneNumber, email) {
    return this.builder
      .setName(name)
      .setPhoneNumber(phoneNumber)
      .setEmail(email)
      .build();
  }

  createOwnerWithAltEmail(
    name: string,
    addressLine1: string,
    city: string,
    state: string,
    zipCode: string,
    phoneNumber: string,
    altPhoneNumber: string,
    email: string,
    altEmail: string
  ): AgreementRecipient {
    return this.builder
      .setName(name)
      .setAddressLine1(addressLine1)
      .setCity(city)
      .setState(state)
      .setZipCode(zipCode)
      .setPhoneNumber(phoneNumber)
      .setAltPhoneNumber(altPhoneNumber)
      .setEmail(email)
      .setAltEmail(altEmail)
      .build();
  }
}



export class AgreementRecipientFactory {
  createOwnerFromJob(job) {
    const director = new AgreementRecipientDirector();
    const builder = new AgreementRecipientBuilder();
    director.setBuilder(builder);
    return director.createOwner(
      get(job, 'clientInformation.name'),
      get(job, 'address.street'),
      get(job, 'address.city'),
      get(job, 'address.state'),
      get(job, 'address.zipCode'),
      get(job, 'clientInformation.phoneNumber'),
      get(job, 'clientInformation.altPhoneNumber'),
      get(job, 'clientInformation.email'),
    );
  }
}
