import { LoginService } from '../login/login.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { of } from 'rxjs/observable/of';
import {
  switchMap,
  catchError,
  combineLatest,
  take,
  mergeMap,
  concat
} from 'rxjs/operators';
import {
  UpdateLoginState,
  Logout,
  ResetLoginState
} from '../../+state/actions/login.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'apps/contract-estimator/src/app/app-state/app.state';
import { Go } from 'libs/shared/router/data-access/src/lib/+state/router.actions';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { isEmpty, get } from 'lodash';
import {
  getRouterUrl,
  getRouterState
} from 'libs/shared/router/data-access/src/lib/+state/router.selectors';
import { SetSalesRepsBasedOnRole } from 'libs/sales-reps/data-access/src/lib/+state/actions/sales-reps.actions';
import { getStoreDataToValidateUser } from '../../+state/selectors/login.selectors';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private loginService: LoginService,
    public store: Store<AppState>
  ) {}

  canActivate(): Observable<boolean> {
    return this.loginService.isClientTokenValid$().pipe(
      take(1),
      combineLatest(this.store.select(getStoreDataToValidateUser)),
      switchMap(([response, storeData]: [any, any]) => {
        // Check if body in response
        const body = get(response, 'body', null);
        if (isEmpty(body)) {
          this.store.dispatch(new Logout());
          throw new ErrorObservable();
        }
        delete body.acceptedPrivacyTerms;

        // Check if on /login route
        if (storeData.routerUrl) {
          if (storeData.routerUrl.includes('login')) {
            this.store.dispatch(new Go({ path: ['/new-lead'] }));
            this.store.dispatch(new UpdateLoginState(body));
            return of(false);
          }
        }
        // Proceed as normal
        this.store.dispatch(new UpdateLoginState(body));
        // Gets sales reps for main menu if SR entities is empty
        this.getSalesReps(
          storeData.salesRepCount,
          storeData.navigationId,
          response.body
        );
        return of(true);
      }),
      catchError(error => {
        return this.navigateToPathBasedOnRouteAndLoginStatus();
      })
    );
  }

  navigateToPathBasedOnRouteAndLoginStatus() {
    return this.store.select(getRouterState).pipe(
      take(1),
      switchMap((routerState: any) => {
        const url = get(routerState, 'state.url', '');
        const redirectTo = get(routerState, 'state.params.redirectTo', '');
        try {
          if (url.includes('login')) {
            this.store.dispatch(new ResetLoginState());
            return of(true);
          }
        } catch (error) {}
        this.store.dispatch(new Logout({ redirectTo }));
        return of(false);
      })
    );
  }

  // Gets sales reps
  getSalesReps(salesRepCount: number, navigationId: number, payload: any) {
    if (navigationId <= 1 || (navigationId > 1 && salesRepCount === 0))
      this.store.dispatch(new SetSalesRepsBasedOnRole(payload));
  }
}
