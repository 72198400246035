export function calculateJobCostSectionSubtotal(sections) {
  let total = 0;
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    total += +section.total;
  }
  return total;
}

export function calculateMiscellaneousCostSubtotal(sections) {
  let total = 0;
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    total += +section.qty * +section.price;
  }
  return total;
}
