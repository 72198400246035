import {NgModule} from "@angular/core";
import {ExportDataAsCsvModule} from "../export-data-as-csv/export-data-as-csv.module";
import {CsvFilePickerModule} from "./../csv-file-picker/csv-file-picker.module";
import {DataSharingToolboxComponent} from "./data-sharing-toolbox.component";

@NgModule({
  imports: [
    ExportDataAsCsvModule,
    CsvFilePickerModule
  ],
  declarations: [DataSharingToolboxComponent],
  exports: [DataSharingToolboxComponent]
})
export class DataSharingToolboxModule {

}
