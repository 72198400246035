import {
  NgModule,
  SkipSelf,
  Optional,
  ModuleWithProviders
} from '@angular/core';
import { AppRoutingModule } from '../app.routing.module';
import { StoreModule } from '@ngrx/store';
import {
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EntityDataModule } from '@ngrx/data';

import * as fromReducers from './../app-state/app.reducer';
import { TableModule } from 'primeng/table';
import { SalesRepService } from 'libs/sales-reps/data-access/src/lib/services/sales-reps.service';
import { metaReducers } from '../app-state/reducers/app.meta-reducers';
import { environment } from '@contract-estimator/shared/environments';
import { StoresDataAccessModule } from 'libs/stores/data-access/src/lib/stores-data-access.module';
import { PersonnelDataAccessModule } from 'libs/personnel/data-access/src/lib/personnel-data-access.module';
import { SharedMegaMenuFeatureModule } from '@contract-estimator/shared/megamenu/feature';
import { ServerUrl } from '../config/server-url';
import { LoginDataAccessModule } from '@contract-estimator/login/data-access';
import { SharedRouterDataAccessModule } from '@contract-estimator/shared/router/data-access';
import { SubcontractorsDataAccessModule } from '@contract-estimator/subcontractors/data-access';
import { SalesRepsDataAccessModule } from '@contract-estimator/sales-reps/data-access';
import { AppMaterialNavigationModule } from '@contract-estimator/shared/angular/modules/material-navigation';
import { entityMetadataConfig } from '../app-state/entity-metadata-config';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer
    }),
    StoreModule.forRoot(fromReducers.reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false
      }
    }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityMetadataConfig),
    BrowserAnimationsModule,
    SubcontractorsDataAccessModule,
    StoresDataAccessModule,
    SalesRepsDataAccessModule,
    LoginDataAccessModule,
    PersonnelDataAccessModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 100,
          name: 'Contractor'
        })
      : [],
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    TableModule,
    MenubarModule,
    ButtonModule,
    SharedMegaMenuFeatureModule,
    SharedRouterDataAccessModule,
    AppMaterialNavigationModule
  ],
  providers: [
    SalesRepService,
    Title,
    NgxImageCompressService // Compresses images
  ],
  exports: [AppRoutingModule, AppMaterialNavigationModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
    if (coreModule) {
      throw new Error('CoreModule is already injected');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [ServerUrl]
    };
  }
}
