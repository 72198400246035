import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, TableModule } from 'primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ToolbarModule} from 'primeng/toolbar';

import { RepClosingRatioComponent } from './components/rep-closing-ratio/rep-closing-ratio.component';
import { ClosingRatioTableComponent } from './components/closing-ratio-table/closing-ratio-table.component';
import { DidNotBuyTableComponent } from './components/did-not-buy-table/did-not-buy-table.component';
import { PaymentTableComponent } from './components/payment-table/payment-table.component';
import { GridChartComponent } from './components/grid-chart/grid-chart.component';
import { AnalyticsHeaderComponent } from './components/analytics-header/analytics-header.component';
import { TotalSalesTableComponent } from './components/total-sales-table/total-sales-table.component';
import { SharedAngularPipesModule } from '../../../../pipes/src/lib/shared-angular-pipes.module';
import { MultipleRangeOptionCalendarComponent } from './components/multiple-range-option-calendar/multiple-range-option-calendar.component';
import {UIComponentsModule} from "./../ui-components/ui-components.module";
import {TotalDidNotBuySalesTableComponent} from "./components/total-did-not-buy-sales-table/total-did-not-buy-sales-table.component";


@NgModule({
  imports: [
    CommonModule,
    CalendarModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    SharedAngularPipesModule,
    ToolbarModule,
    UIComponentsModule
  ],
  declarations: [
    RepClosingRatioComponent,
    ClosingRatioTableComponent,
    DidNotBuyTableComponent,
    PaymentTableComponent,
    GridChartComponent,
    AnalyticsHeaderComponent,
    TotalSalesTableComponent,
    MultipleRangeOptionCalendarComponent,
    TotalDidNotBuySalesTableComponent
  ],
  exports: [
    CommonModule,
    RepClosingRatioComponent,
    ClosingRatioTableComponent,
    DidNotBuyTableComponent,
    PaymentTableComponent,
    GridChartComponent,
    AnalyticsHeaderComponent,
    TotalSalesTableComponent,
    UIComponentsModule,
    TotalDidNotBuySalesTableComponent,
    MultipleRangeOptionCalendarComponent
  ]
})
export class AnalyticsSharedModule {}
