import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, cloneDeep, filter, find, get } from 'lodash';

import { State, SalesRepState } from '../states/sales-rep.state';
import { salesRepsAdapter } from '../reducers/sales-reps.reducer';
import * as selectorFunctions from './selector.functions';
import { getRouterParams } from 'libs/shared/router/data-access/src/lib/+state/router.selectors';
import { getCurrentSalesRep } from 'libs/login/data-access/src/lib/+state/selectors/selector.function';
import {environment} from "@contract-estimator/shared/environments";

export const getEstimateTemplateLoaderState = createFeatureSelector<State>(
  'salesReps'
);

export const {
  selectEntities: selectSalesRepAsDictionary,
  selectAll: selectSalesRepAsArray
} = salesRepsAdapter.getSelectors(getEstimateTemplateLoaderState);

export const getPipelineMenuStatus = createSelector(
  getEstimateTemplateLoaderState,
  (state: State) => {
    return state.loading;
  }
);

export const getSalesRepsIdAndName = createSelector(
  selectSalesRepAsArray,
  (salesReps: Array<SalesRepState>) => {
    try {
      const salesRepsArray = selectorFunctions.composeSalesRepArrayWithIdAndName(
        salesReps
      );
      return salesRepsArray;
    } catch (error) {
      return null;
    }
  }
);


export const getSalesRepsAsLabelAndValue = createSelector(
  selectSalesRepAsArray,
  (salesReps: Array<SalesRepState>) => {
    try {
      const salesRepsArray = selectorFunctions.composeSalesRepArrayAsLabelAndValue(
        salesReps
      );
      return salesRepsArray;
    } catch (error) {
      return null;
    }
  }
);

export const getSalesRepsListWithEmptyOption = createSelector(
  getSalesRepsAsLabelAndValue,
  (salesReps: Array<any>) => {
    try {
      if (isEmpty(salesReps)) return [];
      const newSalesReps = [{ label: '', value: '' }, ...salesReps];
      return newSalesReps;
    } catch (error) {
      return salesReps;
    }
  }
);

export const getSalesRepInRouterParams = createSelector(
  selectSalesRepAsArray,
  getRouterParams,
  (salesReps: Array<SalesRepState>, routerParams: any) => {
    if (isEmpty(salesReps) || isEmpty(routerParams)) return null;
    if (!routerParams.salesRepId) return null;
    const salesRep = getCurrentSalesRep(salesReps, routerParams.salesRepId);
    if (isEmpty(salesRep)) return null;
    return salesRep;
  }
);

export const getSelectedSalesRep = createSelector(
  selectSalesRepAsArray,
  (salesReps: Array<SalesRepState>, props) => {
    if (isEmpty(salesReps)) return null;
    const salesRep = getCurrentSalesRep(salesReps, props._salesRepId);
    if (isEmpty(salesRep)) return null;
    return {
      ...salesRep
    };
  }
);

export const selectCompanyAsSalesReps = createSelector(
  selectSalesRepAsArray,
  (salesReps: Array<SalesRepState>) => {
    const company = {
      _id: '598e2c650bc1fe1912fdf30d',
      name: 'Company',
      phoneNumber: '502-643-4333',
      email: 'eliteroofs@gmail.com',
      photoName: '',
      funFact: ''
    };
    if (isEmpty(salesReps)) return [company];
    const newSalesReps = cloneDeep(salesReps);
    newSalesReps.push(company);
    return newSalesReps;
  }
);




export const selectSalesRepsForDropDown =  createSelector(
  getSalesRepsListWithEmptyOption,
  (salesReps, props) => {
      return filter(salesReps, rep =>  rep.label !== props.salesRepName);
  }
)


export const salesRepListIsEmpty = createSelector(
  selectSalesRepAsArray,
  salesReps => {
    return isEmpty(salesReps)
  }
)


export const salesRepByEmail = (email: string) => createSelector(
  selectSalesRepAsArray,
  (salesReps) => {
    const salesRep = find(salesReps, ['email', email]);
    return salesRep;
  }
)

export const salesRepIdByEmail = (email) => createSelector(
  salesRepByEmail(email),
  (salesRep) => {
    const _id = get(salesRep, '_id');
    return _id;
  }
)


export const profileButtonComponentUserData = (email) => createSelector(
  salesRepByEmail(email),
  (salesRep) => {
    const name = get(salesRep, 'name');
    const email = get(salesRep, 'email');
    const photoName = get(salesRep, 'photoName');
    const photoUrl = environment.SALES_REP_PHOTO_URL + photoName;

    return {
      name,
      email,
      photoUrl
    }
  }
)
