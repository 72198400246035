import { Company } from '../../../../../../apps/contract-estimator/src/app/shared/models/company/company.model';

export const MAP_URL = 'https://www.google.com/maps/search/?api=1&query=';

export const COMPANY_INFORMATION: Company = {
  _id: '',
  name: 'Elite Roofing, LLC',
  displayName: 'Elite Roofing',
  phoneNumber: '502-643-4333',
  email: 'eliteroofs@gmail.com',
  logoName: 'eliteroof.png',
  taxIDNumber: '80-0422868',
  nonProfit: false,
  faxNumber: '',
  website: 'www.myeliteroof.com',
  addressLine1: '3600 Chamberlain Ln, Ste 230',
  addressLine2: 'Louisville, KY 40241',
  address: {
    street: '3600 Chamberlain Ln, Ste 230',
    street2: '',
    zipCode: '40241',
    city: 'Louisville',
    state: 'KY',
    country: 'United States'
  },
  billingAddress: {
    name: 'Jeremy Bosco',
    street: '3600 Chamberlain Ln, Ste 230',
    street2: '',
    zipCode: '40241',
    city: 'Louisville',
    state: 'KY',
    country: 'United States'
  }
};

export const WRITE_A_REVIEW_LINK = 'https://g.page/r/CWPE0dGYO8EmEAg/review';

export const INVOICE_BCC =
  process.env.NODE_ENV === 'production'
    ? ['eliteroofing.dev@gmail.com', 'eliteroofs@gmail.com']
    : ['myeliteroofing@gmail.com'];

export const SALES_TAX_RATE = 0.06;
// temporary list of work order section names
export const sectionNames = [
  { label: 'HOUSE', value: 'HOUSE' },
  { label: 'GARAGE', value: 'GARAGE' },
  { label: 'SHED', value: 'SHED' },
  { label: 'OTHER', value: 'OTHER' },
  { label: '', value: '' }
];

export const STATES = [
  { label: '', value: '' },
  { label: 'Utah', value: 'UT' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kentucky', value: 'KY' }
];

export const GENDER_OPTIONS = [
  { label: '', value: '' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Company', value: 'Company' }
];

export const LEAD_SOURCES = [
  { label: '', value: '' },
  { label: 'BBB', value: 'BBB' },
  { label: 'Bluebook', value: 'Bluebook' },
  { label: 'BNI', value: 'BNI' },
  { label: 'Business Development Rep', value: 'Business Development Rep' },
  { label: 'Canvasser', value: 'Canvasser' },
  { label: 'Customer Referral', value: 'Customer Referral' },
  { label: 'Email Campaign', value: 'Email Campaign' },
  { label: 'Flyer', value: 'Flyer' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'General Contractor', value: 'General Contractor' },
  { label: 'Home Inspector', value: 'Home Inspector' },
  { label: 'Home Show', value: 'Home Show' },
  { label: 'Insurance Agent', value: 'Insurance Agent' },
  { label: 'Next Door', value: 'Next Door' },
  { label: 'Online', value: 'Online' },
  { label: 'Property Manager', value: 'Property Manager' },
  { label: 'Realtor', value: 'Realtor' },
  { label: 'Repeat Customer', value: 'Repeat Customer' },
  { label: 'Subcontractor', value: 'Subcontractor' },
  { label: 'Truck Sign', value: 'Truck Sign' },
  { label: 'Yard Sign', value: 'Yard Sign' },
  { label: 'Other', value: 'Other' }
];

export const CONTACT_MODE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Phone', value: 'Phone Call' },
  { label: 'Text', value: 'Text Message' },
  { label: 'Email', value: 'Email' }
];

export const LEAD_PHONE_BRAND_OPTIONS = [
  { label: '', value: '' },
  { label: 'Apple', value: 'Apple' },
  { label: 'Android', value: 'Android' },
  { label: 'Other', value: 'Other' }
];

export const LEAD_PHONE_CARRIER_OPTIONS = [
  { label: '', value: '' },
  { label: 'AT&T', value: 'AT&T' },
  { label: 'Verizon', value: 'Verizon' },
  { label: 'TMobile', value: 'TMobile' },
  { label: 'Other', value: 'Other' }
];

export const LEAD_NUMBER_OF_ESTIMATES_OPTIONS = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const LEAD_SALES_REP_SOURCES = [
  { label: '', value: '' },
  { label: 'BNI', value: 'BNI' }
];

export const DEFAULT_LEAD_SOURCES = [
  { label: '', value: '' },
  { label: 'Other', value: 'Other' }
];

export const LEAD_SECTORS = [
  { label: '', value: '' },
  { label: 'Residential', value: 'Residential' },
  { label: 'Multi-Family Rental', value: 'Multi-Family Rental' },
  { label: 'Single-Family Rental', value: 'Single Family Rental' },
  { label: 'Offices', value: 'Offices' },
  { label: 'Religious', value: 'Religious' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Government', value: 'Government' },
  { label: 'Educational', value: 'Educational' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Storage Unit', value: 'Storage Unit' },
  { label: 'Hotel', value: 'Hotel' },
  { label: 'Restaurant', value: 'Restaurant' },
  { label: 'Warehouse', value: 'Warehouse' },
  { label: 'Other', value: 'Other' }
];

export const LEAD_JOB_TYPES = [
  { label: '', value: '' },
  { label: 'Insurance Work', value: 'Insurance Work' },
  { label: 'Roof Repair', value: 'Roof Repair' },
  { label: 'Roof Install', value: 'Roof Install' },
  { label: 'Gutter Install', value: 'Gutter Install' },
  { label: 'Siding Install', value: 'Siding Install' },
  { label: 'Window Install', value: 'Window Install' }
];

export const LEAD_PRODUCT_TYPE = [
  { label: '', value: '' },
  { label: 'Shingle Roof', value: 'Shingle Roof' },
  { label: 'Flat Roof', value: 'Flat Roof' },
  { label: 'Roof Coatings', value: 'Roof Coatings' },
  { label: '5” to 6” Gutters', value: '5” to 6” Gutters' },
  { label: 'Gutter Guards', value: 'Gutter Guards' },
  { label: 'Vinyl Siding', value: 'Vinyl Siding' },
  { label: 'Hardie Siding', value: 'Hardie Siding' },
  { label: 'Vinyl Windows', value: 'Vinyl Windows' }
];

export const LEAD_TEMPERATURES = [
  { label: '', value: '' },
  { label: 'Immediate', value: 'Immediate' },
  { label: 'Next Month', value: 'Next Month' },
  { label: 'Down the Road', value: 'Down the Road' }
];

export const LEAD_AGES = [
  { label: '', value: '' },
  { label: '<15 years', value: '<15 years' },
  { label: '>15 years', value: '>15 years' },
  { label: 'Unknown', value: 'Unknown' }
];

export const LEAD_STORIES = [
  { label: '', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3 plus', value: '3 plus' }
];

export const LEAD_PAYMENT_TYPES = [
  { label: '', value: '' },
  { label: 'Self Pay', value: 'Self Pay' },
  { label: 'Insurance', value: 'Insurance' }
];

export const LEAD_COUPONS = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const LEAD_AERIAL = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const LEAD_INSURANCE_PAPERWORK = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const LEAD_FINANCING_NEEDED = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Maybe', value: 'Maybe' }
];

export const OWNER_PRESENT_OPTIONS = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const CUSTOMER_PRIORITIES = [
  { label: '', value: '' },
  { label: 'Brand Names', value: 'Brand Names' },
  { label: 'Cleanup', value: 'Cleanup' },
  { label: 'Communication', value: 'Communication' },
  { label: 'Coupon', value: 'Coupon' },
  { label: 'Craftmanship', value: 'Craftmanship' },
  { label: 'Free Estimates', value: 'Free Estimates' },
  { label: 'Financing', value: 'Financing' },
  { label: 'Honesty', value: 'Honesty' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Insurance Proceeds', value: 'Insurance Proceeds' },
  { label: 'Price', value: 'Price' },
  { label: 'Professionalism', value: 'Professionalism' },
  { label: 'Recycling', value: 'Recycling' },
  { label: 'Reviews', value: 'Reviews' },
  { label: 'Safety', value: 'Safety' },
  { label: 'Timeliness', value: 'Timeliness' },
  { label: 'Warranty', value: 'Warranty' },
  { label: 'Other', value: 'Other' }
];

/* Pipeline */

export const DID_NOT_BUY_REASONS = [
  {
    label: 'Assigned Claim to Public Adjuster',
    value: 'Assigned Claim to Public Adjuster'
  },
  {
    label: 'Called but never Called Back',
    value: 'Called but never Called Back'
  },
  { label: 'Canceled Appointment', value: 'Canceled Appointment' },
  { label: 'Canceled Contract', value: 'Canceled Contract' },
  { label: 'Claim Denied', value: 'Claim Denied' },
  { label: 'Deductible', value: 'Deductible' },
  { label: 'Did not File Claim', value: 'Did not File Claim' },
  { label: 'Duplicate Lead', value: 'Duplicate Lead' },
  { label: 'Financing Denied', value: 'Financing Denied' },
  { label: 'Fixed Themselves', value: 'Fixed Themselves' },
  { label: 'Freebie', value: 'Freebie' },
  {
    label: 'Gave Estimate But Never Called Back',
    value: 'Gave Estimate But Never Called Back'
  },
  { label: 'No Damage', value: 'No Damage' },
  { label: 'No Money', value: 'No Money' },
  { label: 'Not Going to Fix', value: 'Not Going to Fix' },
  { label: 'Not Our Scope of Work', value: 'Not Our Scope of Work' },
  { label: 'Not the Property Owner', value: 'Not the Property Owner' },
  { label: 'Price', value: 'Price' },
  { label: 'Racism Issue', value: 'Racism Issue' },
  { label: 'Sales Rep', value: 'Sales Rep' },
  { label: 'Software Testing', value: 'Software Testing' },
  { label: 'Sold House', value: 'Sold House' },
  { label: 'Used Another Company', value: 'Used Another Company' },
  {
    label: 'Used us For Realtor Closing',
    value: 'Used us For Realtor Closing'
  },
  { label: 'Wrong Sales Rep', value: 'Wrong Sales Rep' },
  { label: 'Other', value: 'Other' }
];

/* Warranty */

export const NO_REPAIR_NEEDED_REASONS = [
  { label: 'Canceled Job', value: 'Canceled Job' },
  { label: 'Nail Pop', value: 'Nail Pop' },
  { label: 'No Issue', value: 'No Issue' },
  { label: 'Not Our Problem', value: 'No Our Problem' },
  { label: 'Other', value: 'Other' },
  { label: 'Sales Rep Fixed', value: 'Sales Rep Fixed' },
  { label: 'Software Testing', value: 'Software Testing' }
];

export const DELETE_QCI_REASONS = [
  { label: 'Canceled Contract', value: 'Canceled Contract' },
  { label: 'Gutter Job', value: 'Gutter Job' },
  { label: 'Repair Job', value: 'Repair Job' },
  { label: 'Software Testing', value: 'Software Testing' },
  { label: 'Warranty Job', value: 'Warranty Job' }
];

export const AVAILABLE_SALES_REP_APPTS = [
  { label: '', value: '' },
  { label: '08:00am', value: '08:00am' },
  { label: '08:30am', value: '08:30am' },
  { label: '09:00am', value: '09:00am' },
  { label: '09:30am', value: '09:30am' },
  { label: '10:00am', value: '10:00am' },
  { label: '10:30am', value: '10:30am' },
  { label: '11:00am', value: '11:00am' },
  { label: '11:30am', value: '11:30am' },

  // PM

  { label: '12:00pm', value: '12:00pm' },
  { label: '12:30pm', value: '12:30pm' },
  { label: '01:00pm', value: '01:00pm' },
  { label: '01:30pm', value: '01:30pm' },
  { label: '02:00pm', value: '02:00pm' },
  { label: '02:30pm', value: '02:30pm' },
  { label: '03:00pm', value: '03:00pm' },
  { label: '03:30pm', value: '03:30pm' },
  { label: '04:00pm', value: '04:00pm' },
  { label: '04:30pm', value: '04:30pm' },
  { label: '05:00pm', value: '05:00pm' },
  { label: '05:30pm', value: '05:30pm' },
  { label: '06:00pm', value: '06:00pm' }
];

export const EVENT_TIME_REGEX = new RegExp(
  /^([0-9]{2}):([0-9]{2})((am){1}|(pm){1})$/gi
);

export const INSTALL_MARKUP = 30;
export const REPAIR_MARKUP = 70;

export const LEAKING_OPTIONS = [
  { label: '', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Unknown', value: 'Unknown' }
];

export const financeCharge = {
  perAnnum: 18,
  timePeriod: 365
};

export const invoice = {
  reminderFrequency: 7,
  frequentReminderDelay: 7, // Add delay, so we do not
  dueToday: 'dueToday',
  overdue: 'overdue',
  frequentReminderTag: 'frequentReminder',
  reminderSubject: `${COMPANY_INFORMATION.displayName} - Payment Due`,
  smsInvoiceMessage: 'NO REPLY. Thanks for choosing Elite! Tap to see invoice.',
  smsInvoiceReceiptMessage:
    'NO REPLY. Thanks for choosing Elite! Tap to see receipt.',
  smsPaymentConfirmationMsg:
    'NO REPLY. Thanks for your payment! Tap to see invoice.',
  smsPaymentConfirmationAndReceiptMsg:
    'NO REPLY. Thanks for your payment! Tap to see receipt.',
  smsInvoicePartialPayment:
    'NO REPLY. Elite Roofing, LLC. Thank you for payment. Tap to see receipt.',
  smsInvoiceReminderMessage:
    'Thanks for the business! Our work is complete and your payment is due.',
  unpaidInvoiceMessage: `<p>We are wrapping up your job and wanted to say thank you for choosing Elite Roofing.</p>
                           <p>For your convenience we have attached your invoice and would appreciate prompt payment.</p>
                           <p>Please let us know if you need anything else and thanks for your business!</p>
                           <p>Sincerely, </p>`,
  paidInvoiceMessage: `<p>This is confirmation that your invoice was paid in full.  Attached is your receipt.</p>
                         <p>Please let us know if you need anything else and thanks for your business!</p>
                         <p>Sincerely, </p>`,
  reviewMessage: `<p>Here at Elite Roofing we love customers like you! We welcome your opinions and value them.
    We invite you to click on the button below and let us know how we did. </p>`,
  reviewSubjectLine: 'Elite Roofing - Review',
  reviewTextMessage: `We really appreciate your business! Please let us know how we did by writing us a review. http://bit.ly/gglervws`
};

export const invalidTokenAlertEmailGroup = ['alexescamore@gmail.com'];

export const invociePDFPath = './public/invoices/';
export const invociePreviewPDFPath = './public/invoice-preview/';

// QBS must have

// Account

export const accountLineItem = {
  value: 19,
  name: 'Sales'
};

export const DepositToAccountRef = {
  value: '35',
  name: 'Checking'
};

export const InvoiceAccountName = 'Invoice Payments';

// Reminders

export const reminder = {
  overdueMessage: ` <p>The fifteen day grace period has expired.  In order to avoid recurring finance charges, please pay the balance in full as agreed regardless of the status of any pending insurance claims. There is a $2,500.00 cap on all credit card payments per contract.</p>
                      <p>Please let us know if you need anything else and thanks for your business!</p>`,

  dueSoonMessage: `<p>The on-time pay period will end in seven days.  In order to avoid finance charges, please pay the balance in full as agreed regardless of the status of any pending insurance claims. </p>
                     <p>Please let us know if you need anything else and thanks for your business!</p>`,

  dueTodayMessage: `<p>The on-time pay period will be ending today.  In order to avoid finance charges, please pay the balance in full as agreed regardless of the status of any pending insurance claims. </p>
    <p>Please let us know if you need anything else and thanks for your business!</p>`,

  firstOverdueReminderMessage: `<p>The on-time pay period has expired.  In order to avoid finance charges, please pay the balance in full as agreed regardless of the status of any pending insurance claims. </p>
    <p>Please call the office with any questions or concerns you might have.</p>`,

  finalOverdueReminderMessage: `<p>The on-time pay period has expired.  In order to avoid collections, please pay the balance in full as agreed regardless of the status of any pending insurance claims. </p>
    <p>Please call the office with any questions or concerns you might have.</p>`
};
