import { Injectable } from '@angular/core';
import {CanActivate} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {map, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Go} from "@contract-estimator/shared/router/data-access";

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuardService implements CanActivate {

  constructor(
    public auth: AuthService,
    public store: Store
  ) { }

  canActivate() {
    return this.auth.isAuthenticated$
      .pipe(
        tap((isAuthenticated: boolean) => {
          // Navigate to app if user is logged in. Render landing page otherwise.
          if(isAuthenticated) {
            this.store.dispatch(new Go({ path: ['/analytics/team-analytics'] }))
          }
        }),
        map((isAuthenticated: boolean) => !isAuthenticated)
      )

  }
}
