import {get, isEmpty} from 'lodash';

export function getSignersFromDocusignConnect(payload) {
  const recipients = get(payload, 'DocuSignEnvelopeInformation.EnvelopeStatus.RecipientStatuses.RecipientStatus', []);
  if(isEmpty(recipients)) return null;
  const signers = recipients.filter(r => r.Type === "Signer");
  return signers || [];
}


export function getInvoiceIdFromDocusignConnect(payload) {
  const signers = getSignersFromDocusignConnect(payload);
  const signer = signers[0];
  const _invoiceId = get(signer, 'TabStatuses.TabStatus', [])
    .filter(t => t.TabLabel === "_invoiceId")
    .reduce((pv, cv) => cv.TabValue, null);
  return _invoiceId;
}

export function getSignerEmailFromDocusignConnect(payload) {
  const signers = getSignersFromDocusignConnect(payload);
    const signer = signers[0];
  return signer.Email;
}

