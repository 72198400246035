import { Markup } from './markup.class';

export class MarkupEstimate {
  _id?: string;
  date?: string;
  title?: string;
  markup?: number; // markup margin as a decimal
  subtotal?: number;
  subtotalWithMarkup?: number;
  total?: number;
  lineItems?: Markup[];
  mapPOSectToWOSect?: any[];
  manualMarkup?: boolean;
  deductible?: number; // A.K.A deposit
  color?: string;
  brand?: string;
  style?: string;
  upgrades?: number;
  addOns?: string;
  instructions?: string;
  supplement?: string;
  insurer?: string;
  predictedMarkup?: string;
  predictedMarkupProbability?: string;
  salesRepMarkupProbability?: string;
  overhead?: number;
  advertising?: number;
  salesRepCommission?: number;
  managerCommission?: number;

  constructor({
    _id,
    title,
    markup,
    subtotal,
    subtotalWithMarkup,
    total,
    lineItems,
    mapPOSectToWOSect,
    instructions,
    manualMarkup,
    deductible,
    color,
    brand,
    upgrades,
    addOns,
    supplement,
    style,
    insurer,
    predictedMarkup,
    overhead,
    advertising,
    salesRepCommission,
    managerCommission
  }: any) {
    this._id = _id;
    this.title = title;
    this.subtotal = subtotal;
    this.total = total;
    this.subtotalWithMarkup = subtotalWithMarkup;
    this.total = total;
    this.markup = markup;
    this.lineItems = lineItems.map(item => new Markup(item));
    this.mapPOSectToWOSect = mapPOSectToWOSect;
    this.manualMarkup = manualMarkup;
    this.deductible = deductible;
    this.color = color;
    this.brand = brand;
    this.style = style;
    this.upgrades = upgrades;
    this.addOns = addOns;
    this.instructions = instructions;
    this.supplement = supplement;
    this.insurer = insurer;
    this.predictedMarkup = predictedMarkup;
    this.overhead = overhead;
    this.advertising = advertising;
    this.salesRepCommission = salesRepCommission;
    this.managerCommission = managerCommission;
  }

  static create(rawMarkupEstimate: any) {
    return new MarkupEstimate(rawMarkupEstimate);
  }
}
