import {Component, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Subject} from "rxjs";
import {debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {of} from "rxjs/observable/of";

@Component({
  selector: 'contractor-subcontractor-form',
  templateUrl: './subcontractor-form.component.html',
  styleUrls: ['./subcontractor-form.component.scss']
})
export class SubcontractorFormComponent implements OnInit {

  @Output() subcontractorInformationEvent = new Subject();

  subcontractorFormGroup = this.fb.group({
    companyName: [''],
    name: [''],
    phoneNumber: [''],
    email: [''],
  })

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.subcontractorFormGroup
      .valueChanges
      .pipe(
        switchMap(values => of(values)),
        debounceTime(400),
        // takeUntil(this.closeObservables$)
      )
      .subscribe(data => this.subcontractorInformationEvent.next(data))
  }

}
