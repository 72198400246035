import { gql } from 'apollo-angular';

export const CREATE_SUBCONTRACTOR = gql`
  mutation CreateSubcontractor($payload: CreateSubcontractorInputSchema!) {
    createSubcontractor(payload: $payload) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      status
      trades
    }
  }
`;

export const GET_ALL_SUBCONTRACTORS = gql`
  query AllSubcontractorsQuery {
    subcontractors {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
      frequent
      active
      index
      status
      trades
    }
  }
`;

export const GET_SUBCONTRACTOR = gql`
  query SubcontractorQuery($_id: String!) {
    subcontractor(_id: $_id) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
      status
      trades
    }
  }
`;

export const UPDATE_SUBCONTRACTOR = gql`
  mutation UpdateSubcontractor(
    $_id: String!
    $payload: UpdateSubcontractorInputSchema!
  ) {
    updateSubcontractor(_id: $_id, payload: $payload) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      status
      trades
    }
  }
`;

export const SET_SUBCONTRACTOR_AS_ACTIVE = gql`
  mutation SetSubcontractorAsActive($_id: String!) {
    setSubcontractorAsActive(_id: $_id) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
    }
  }
`;

export const SET_SUBCONTRACTOR_AS_INACTIVE = gql`
  mutation SetSubcontractorAsInactive($_id: String!) {
    setSubcontractorAsInactive(_id: $_id) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
    }
  }
`;

export const SET_SUBCONTRACTOR_AS_FREQUENT = gql`
  mutation SetSubcontractorAsFrequent($_id: String!) {
    setSubcontractorAsFrequent(_id: $_id) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
    }
  }
`;

export const SET_SUBCONTRACTOR_AS_INFREQUENT = gql`
  mutation SetSubcontractorAsInfrequent($_id: String!) {
    setSubcontractorAsInfrequent(_id: $_id) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      frequent
      active
      index
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
    }
  }
`;

export const SEARCH_SUBCONTRACTORS = gql`
  query searchSubcontractors($payload: SearchSubcontractorsInputSchema!) {
    searchSubcontractorsByCriteria(payload: $payload) {
      _id
      _companyId
      name
      email
      phoneNumber
      firstName
      lastName
      address {
        number
        street
        city
        state
        zip
        zipCode
      }
      frequent
      active
      index
      status
      trades
    }
  }
`;

export const GET_COMPANY_ESTIMATE_TRADES = gql`
  query getTrades {
    companyEstimateTrades {
      _id
      displayName
    }
  }
`;
