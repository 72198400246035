import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { omit } from 'lodash';
import {
  JobEstimateEntity,
  AvailableStoreListItem,
  RequestMessage
} from 'apps/contract-estimator/src/app/shared/models/job-estimate.model';
import { Expense } from 'apps/contract-estimator/src/app/shared/models/expense.model';
import { Commission } from 'apps/contract-estimator/src/app/shared/models/commission.model';
import { JobEstimateType } from 'libs/shared/interfaces/src/lib/job-estimate-type.interface';
import { Company } from 'apps/contract-estimator/src/app/shared/models/company/company.model';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { StoreVM } from 'apps/contract-estimator/src/app/shared/models/stores/store.model';
import { ContractEstimate } from 'apps/contract-estimator/src/app/shared/models/contract-estimate.model';
import { Template } from 'apps/contract-estimator/src/app/shared/models/template.model';

export const SET_ESTIMATE_TEMPLATE_LOADER_WORK_ORDER_LEAD_INFO =
  'ESTIMATE_TEMPLATE_LOADER > SET_ESTIMATE_TEMPLATE_LOADER_WORK_ORDER_LEAD_INFO';
export class SetEstimateTemplateLoaderWorkOrderLeadInfo implements Action {
  readonly type = SET_ESTIMATE_TEMPLATE_LOADER_WORK_ORDER_LEAD_INFO;

  // raw db query typically expected here. as such, filtering images out of response
  constructor(public payload) {
    const address = { ...this.payload.address };

    this.payload = omit(this.payload, ['images', 'address']);

    this.payload.address = address.street;
    this.payload.city = address.city;
    this.payload.state = address.state;
    this.payload.zipCode = address.zipCode;
  }
}

export const SET_AVAILABLE_JOB_TYPES =
  'ESTIMATE_TEMPLATE_LOADER > SET_AVAILABLE_JOB_TYPES';
export class SetAvailableJobTypes implements Action {
  readonly type = SET_AVAILABLE_JOB_TYPES;

  constructor(public payload: JobEstimateType[]) {}
}

export const SET_JOB_NAME = 'ESTIMATE_TEMPLATE_LOADER > SET_JOB_NAME';
export class SetJobName implements Action {
  readonly type = SET_JOB_NAME;

  // payload expected is new jobname
  constructor(public payload: string) {}
}

export const SET_COMPANY = 'ESTIMATE_TEMPLATE_LOADER > SET_COMPANY';
export class SetCompany implements Action {
  readonly type = SET_COMPANY;

  constructor(public payload: Company) {}
}

export const SET_ESTIMATE_TEMPLATE_LOADER_LEAD_IMAGES =
  'ESTIMATE_TEMPLATE_LOADER > SET_ESTIMATE_TEMPLATE_LOADER_LEAD_IMAGES';
export class SetEstimateTemplateLoaderLeadImages implements Action {
  readonly type = SET_ESTIMATE_TEMPLATE_LOADER_LEAD_IMAGES;

  // expects raw images
  constructor(public payload: any[]) {
    const serverUrl = new ServerUrl().getUrl();

    this.payload = this.payload.map(image => ({
      source: `${serverUrl}/estimates/images/${image.url}`,
      _id: image._id
    }));
  }
}

export const SET_ESTIMATE_TEMPLATE_LOADER_FETCH_ERROR =
  'ESTIMATE_TEMPLATE_LOADER > SET_ESTIMATE_TEMPLATE_LOADER_FETCH_ERROR';
export class SetEstimateTemplateLoaderFetchError implements Action {
  readonly type = SET_ESTIMATE_TEMPLATE_LOADER_FETCH_ERROR;

  // payload is an error message to be stored in 'fetchError' variable
  constructor(public payload: string) {}
}

export const SET_LEAD_CONTRACT_ESTIMATE =
  'ESTIMATE_TEMPLATE_LOADER > SET_LEAD_CONTRACT_ESTIMATE';
export class SetLeadContractEstimate implements Action {
  readonly type = SET_LEAD_CONTRACT_ESTIMATE;

  // payload should have been pre constructed before being passed to this action
  constructor(public payload: ContractEstimate) {}
}

export const SET_LEAD_CONTRACT_ESTIMATE_PAYOUT =
  'ESTIMATE_TEMPLATE_LOADER > SET_LEAD_CONTRACT_ESTIMATE_PAYOUT';
export class SetLeadContractEstimatePayout implements Action {
  readonly type = SET_LEAD_CONTRACT_ESTIMATE_PAYOUT;

  constructor(public payload) {
    // _id thing is how they came back from server. its a workaround for the time being until the server code is refacotred
    this.payload.commissions = this.payload.commissions
      ? this.payload.commissions.map(
          commission => new Commission(commission._id)
        )
      : [];
    this.payload.expenses = this.payload.expenses
      ? this.payload.expenses.map(expense => new Expense(expense._id))
      : [];
    this.payload.overheadMarketing = this.payload.overheadMarketing
      ? this.payload.overheadMarketing.map(item => new Expense(item._id))
      : [];

    this.payload = new Template(this.payload);
  }
}

export const SET_ESTIMATES = 'ESTIMATE_TEMPLATE_LOADER > SET_ESTIMATES';
export class SetEstimates implements Action {
  readonly type = SET_ESTIMATES;

  // raw orders from the server
  constructor(public payload: JobEstimateEntity[]) {}
}

export const UPDATE_ESTIMATE = 'ESTIMATE_TEMPLATE_LOADER > UPDATE_ESTIMATE';
export class UpdateEstimate implements Action {
  readonly type = UPDATE_ESTIMATE;

  // raw orders from the server
  constructor(public payload: Update<JobEstimateEntity>) {}
}

export const SAVE_CONTRACT_ESTIMATE_SUCCESS =
  'ESTIMATE_TEMPLATE_LOADER > SAVE_CONTRACT_ESTIMATE_SUCCESS';
export class SaveContractEstimateSuccess implements Action {
  readonly type = SAVE_CONTRACT_ESTIMATE_SUCCESS;

  constructor() {}
}

export const FETCH_ESTIMATE_DATA_FROM_SERVER =
  'ESTIMATE_TEMPLATE_LOADER > FETCH_ESTIMATE_DATA_FROM_SERVER';
export class FetchEstimateDataFromServer implements Action {
  readonly type = FETCH_ESTIMATE_DATA_FROM_SERVER;

  constructor() {}
}

export const FETCH_UPDATED_ESTIMATE_TEMPLATE =
  'ESTIMATE_TEMPLATE_LOADER > FETCH_UPDATED_ESTIMATE_TEMPLATE';
export class FetchUpdatedEstimateTemplate implements Action {
  readonly type = FETCH_UPDATED_ESTIMATE_TEMPLATE;
  // payload needs to be id of the template that needs to be retrieved
  constructor(public payload: string) {}
}

export const FETCH_INSTALL_TEMPLATE =
  'ESTIMATE_TEMPLATE_LOADER > FETCH_INSTALL_TEMPLATE';
export class FetchInstallTemplate implements Action {
  readonly type = FETCH_INSTALL_TEMPLATE;

  // currently this always fetches install template so there is no payloay. 'install' is hardcoded to url
  constructor() {}
}

export const SEND_CONTRACT_ESTIMATE_TO_SERVER =
  'ESTIMATE_TEMPLATE_LOADER > SEND_CONTRACT_ESTIMATE_TO_SERVER';
export class SendContractEstimateToServer implements Action {
  readonly type = SEND_CONTRACT_ESTIMATE_TO_SERVER;

  // payload should be the contract estimate from this store. It's passed as a payload because the contract
  // is checked before this action is used
  constructor(public payload: any) {}
}

export const RESET_ESTIMATE_STATE = 'ESTIMATE > RESET_ESTIMATE_STATE';
export class ResetEstimateState implements Action {
  readonly type = RESET_ESTIMATE_STATE;
}

export const CHANGE_JOB_TYPE_CONFIRMATION =
  'ESTIMATE > CHANGE_JOB_TYPE_CONFIRMATION';
export class ChangeJobTypeConfirmation implements Action {
  readonly type = CHANGE_JOB_TYPE_CONFIRMATION;
  constructor(public confirmation: boolean) {}
}

export const SET_STORE_LIST = 'ESTIMATE > SET_STORE_LIST';
export class SetStoreList implements Action {
  readonly type = SET_STORE_LIST;
  constructor(public list: Array<StoreVM>) {}
}

export const SET_AVAILABLE_STORE_LIST = 'ESTIMATE > SET_AVAILABLE_STORE_LIST';
export class SetAvailableStoreList implements Action {
  readonly type = SET_AVAILABLE_STORE_LIST;
  constructor(public list: Array<AvailableStoreListItem>) {}
}

export const SAVE_ESTIMATE = 'ESTIMATE > SAVE_ESTIMATE';
export class SaveEstimate {
  readonly type = SAVE_ESTIMATE;
  constructor(public payload?: { autosave?: boolean }) {}
}

export const UPDATE_REQUEST_MESSAGE = 'ESTIMATE > UPDATE_REQUEST_MESSAGE';
export class UpdateRequestMessage {
  readonly type = UPDATE_REQUEST_MESSAGE;
  constructor(public payload: RequestMessage) {}
}

export const UPDATE_OWNER_INFORMATION = 'ESTIMATE > UPDATE_OWNER_INFORMATION';
export class UpdateOwnerInformation {
  readonly type = UPDATE_OWNER_INFORMATION;
  constructor(public owner: any) {}
}

export const SAVE_WORK_ORDER = 'ESTIMATE > SAVE_WORK_ORDER';
export class SaveWorkOrder {
  readonly type = SAVE_WORK_ORDER;
  constructor(public _workOrderId: string) {}
}

export const REMOVE_SECTION = 'ESTIMATE > REMOVE_SECTION';
export class RemoveSection {
  readonly type = REMOVE_SECTION;
  constructor(public _workOrderId: string, public _sectionId: string) {}
}

export const REMOVE_PURCHASE_ORDER_SECTION =
  'ESTIMATE > REMOVE_PURCHASE_ORDER_SECTION';
export class RemovePurchaseOrderSection {
  readonly type = REMOVE_PURCHASE_ORDER_SECTION;
  constructor(public _workOrderSectionId: string) {}
}

export const ESTIMATE_STATUS_REQUEST = 'ESTIMATE > ESTIMATE_STATUS_REQUEST';
export class EstimateStatusRequest {
  readonly type = ESTIMATE_STATUS_REQUEST;
  constructor(public saving: boolean) {}
}


export const UPDATE_ESTIMATE_DELIVERY_STATUS = '[estimate] Update estimate delivery status';
export class UpdateEstimateDeliveryStatus {
  readonly type = UPDATE_ESTIMATE_DELIVERY_STATUS;
  constructor(public sent: boolean) {}
}

export type Actions =
  | SetEstimateTemplateLoaderWorkOrderLeadInfo
  | SetAvailableJobTypes
  | SetJobName
  | SetCompany
  | SetAvailableStoreList
  | SetEstimateTemplateLoaderLeadImages
  | SetEstimateTemplateLoaderFetchError
  | SetLeadContractEstimate
  | SetLeadContractEstimatePayout
  | SetEstimates
  | UpdateEstimate
  | SaveContractEstimateSuccess
  | FetchEstimateDataFromServer
  | FetchUpdatedEstimateTemplate
  | FetchInstallTemplate
  | SendContractEstimateToServer
  | ResetEstimateState
  | ChangeJobTypeConfirmation
  | SetStoreList
  | SaveEstimate
  | UpdateRequestMessage
  | UpdateOwnerInformation
  | RemoveSection
  | EstimateStatusRequest
| UpdateEstimateDeliveryStatus;
