import { GenerateUniqueId } from './../utils/generateUniqueId/generateUniqueId';

export class Labor {
  // Private variables
  public _id: string;
  public _laborId: string;
  public name: string;
  public per: string;
  public qty: number;
  public price: number;
  public subtotal: number;
  public uniqueId: string;

  constructor({
    _id,
    _laborId,
    name,
    per,
    qty,
    price,
    subtotal,
    uniqueId
  }: any) {
    this._id = _id;
    this._laborId = _laborId;
    this.name = name;
    this.per = per;
    this.qty = qty;
    this.price = price;
    this.subtotal = subtotal;
    this.uniqueId = uniqueId || new GenerateUniqueId().generate();
  }

  static create(rawLabor: any): any {
    return new Labor(rawLabor);
  }
}
