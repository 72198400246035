import { JobOwnerInfo } from 'libs/shared/interfaces/src/lib/interfaces';

export const UPDATE_JOB_INFORMATION = 'UPDATE_JOB_INFORMATION';
export class UpdateJobInformation {
  readonly type = UPDATE_JOB_INFORMATION;
  constructor(public changes: any) {}
}

export const SCHEDULER_JOB_INFO = 'SCHEDULER > SCHEDULER_JOB_INFO';
export class SchedulerJobInfo {
  readonly type = SCHEDULER_JOB_INFO;
  constructor(public jobInfo: any) {}
}

export const CHANGE_JOB_SALES_REP = 'SCHEDULER > CHANGE_JOB_SALES_REP';
export class ChangeJobSalesRep {
  readonly type = CHANGE_JOB_SALES_REP;
  constructor(public _id: string) {}
}

export const UPDATE_JOB_INFO_OWNER_INFORMATION =
  'UPDATE_JOB_INFO_OWNER_INFORMATION';
export class UpdateJobOwnerInformation {
  readonly type = UPDATE_JOB_INFO_OWNER_INFORMATION;
  constructor(public payload: JobOwnerInfo) {}
}

export type Actions =
  | SchedulerJobInfo
  | ChangeJobSalesRep
  | UpdateJobOwnerInformation
  | UpdateJobInformation;
