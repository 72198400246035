import * as momentTZ from 'moment-timezone';
import * as moment from 'moment';
import { environment } from '@contract-estimator/shared/environments';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  eachQuarterOfInterval,
  lastDayOfQuarter,
  subMonths
} from 'date-fns';

export class DateUtils {
  getCurrentDate(timeZone: string) {
    try {
      const date = momentTZ()
        .tz(timeZone)
        .format();
      return date;
    } catch (error) {
      return new Date();
    }
  }

  getDate() {
    return new Date();
  }

  // Converts date(mm/dd/yyyy) to valid YYYY-MM-DD
  static convertDateToValidISOFormat(date: string) {
    try {
      const newDate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
      return newDate;
    } catch (error) {
      return date;
    }
  }

  // Composes appt date
  static composeApptDate(start, timeZone) {
    const startDate = momentTZ.tz(start, timeZone).format('dddd[,] MMMM Do');
    const startTime = momentTZ.tz(start, timeZone).format('h:mm');
    const endTime = momentTZ
      .tz(start, timeZone)
      .add(30, 'minutes')
      .format('h:mma');
    const dateString = `${startDate} between ${startTime}-${endTime}`;
    return dateString;
  }

  // Format wo start date
  static formatCustomerCoverLetterWorkOrderStartDate(startDate) {
    try {
      if (!startDate) return null;
      const date = moment(startDate).format('MMMM Do');
      return date;
    } catch (error) {
      return startDate;
    }
  }

  // Format wo start date
  static formatDateToMMDDYYYY(startDate) {
    try {
      const date = moment.utc(startDate).format('MM/DD/YYYY');
      return date;
    } catch (error) {
      return startDate;
    }
  }

  // Gets current date in this forma
  static getDateAndFormatToMMDDYYYY() {
    try {
      const date = moment().format('MM/DD/YYYY');
      return date;
    } catch (error) {
      return '';
    }
  }

  // Calculates contract exp date
  calculateContractExpirationDate() {
    try {
      const expDate = moment()
        .add(environment.CONTRACT_EXPIRATION_DAYS, 'days')
        .format('LL');
      return expDate;
    } catch (error) {
      return null;
    }
  }

  // Substract days from date
  subtractDaysFromCurrentDate(days) {
    try {
      const expDate = moment().subtract(days, 'days');
      return expDate;
    } catch (error) {
      return null;
    }
  }

  setTimeOfDate({ date, hour, min, sec, format }) {
    const newDate = moment(date, format)
      .hour(hour)
      .minute(min)
      .second(sec)
      .format();
    return newDate;
  }

  static createDateWithTimeZone(date, timeZone) {
    const tzDate = momentTZ.tz(date, timeZone);
    return tzDate;
  }

  // Sets hr, min, and sec of date
  static setTimeOfDateWithTimeZone({ date, hour, min, sec, format, timeZone }) {
    const newDate = momentTZ(date, format)
      .tz(timeZone)
      .hour(hour)
      .minute(min)
      .second(sec)
      .format();
    return newDate;
  }


  /**
   * Gets this week's start date
   */
  getThisWeekStartDate(): any {
    return startOfWeek(new Date(), { weekStartsOn: 1  });
  }


  /**
   * Gets this week's end date
   */
  getThisWeekEndDate(): any {
    return endOfWeek(new Date(), { weekStartsOn: 1 });
  }


  /**
   * Gets this week's start date
   */
  getThisMonthStartDate(): any {
    return startOfMonth(new Date());
  }


  /**
   * Gets this week's end date
   */
  getThisMonthEndDate(): any {
    return endOfMonth(new Date());
  }




  /**
   * Gets this year's start date
   */
  getThisYearStartDate(): any {
    return startOfYear(new Date());
  }


  /**
   * Gets this year's end date
   */
  getThisYearEndDate(): any {
    return endOfYear(new Date());
  }

  /**
   * Gets last day of quarter
   */
  getLastDayOfQuarter(date: Date): Date {
    return lastDayOfQuarter(date);
  }

  /**
   * Gets quarter array from any date period
   */
  eachQuarterOfInterval(start, end) {
    return eachQuarterOfInterval({
      start,
      end
    });
  };

  /**
   * Subtract the specified number of months from the given date.
   * @param date
   * @param months
   */
  subtractMonths(date: string | any, months: number) {
    const result = subMonths(date, months)
    return result;
  }


}
