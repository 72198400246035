import { Component, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { isEmpty, sortBy } from 'lodash';
import { Subject } from 'rxjs/Subject';
import { MenuItem } from 'primeng/api';
import { environment } from '@contract-estimator/shared/environments';

@Component({
  selector: 'wb-payroll-table-component',
  templateUrl: './payroll-table.component.html',
  styleUrls: ['./payroll-table.component.scss']
})
export class PayrollTableComponent implements OnInit, OnDestroy {
  rowGroupMetadata: any;
  _lineItems;
  count = 0;
  @Input() set lineItems(lineItems: Array<any>) {
    this._lineItems = lineItems;
    this.updateRowGroupMetaData();
  }
  @Input() personnelDropDownItems: Array<MenuItem>;
  @Input() total: number;
  @Input() tableTitle = '';
  @Input() showAddJobCosts: boolean = false;
  @Input() showFooter: boolean = true;
  @Input() filterable: boolean = false;
  @Input() enableRowSelection: boolean = false;
  @Input() allowScrolling: boolean = false;
  @Input() enableDeleteRow: boolean = true;
  @Input() showAddPayeeDropDown: boolean = true;
  @Output() updateLineItemsEvent = new Subject();
  @Output() addLineItemEvent = new Subject();
  @Output() onOpenJobCostsDialog = new Subject();
  @Output() addSelectedItemsEvent = new Subject();
  @Output() removeRowEvent = new Subject();
  @Output() showEditPayeeCellDialog = new Subject();
  payTypeOptions: Array<any> = environment.PAYROLL_PAY_TYPE_OPTIONS;
  tradeOptions: Array<any> = environment.PAYROLL_TRADE_OPTIONS;
  selectedPayrollPayeeDropDownItem: any;
  cols: Array<any>;
  selectedItems: Array<any>;
  showEditPayrollPayeeCellDialog: boolean = false;
  payrollPayeeCellValue: string = '';
  payeeRow: any;

  constructor() {
    this.cols = [
      { field: 'payeeName', header: 'PAYEE NAME' },
      { field: 'payeeEmail', header: 'EMAIL' },
      { field: 'jobName', header: 'JOB NAME' },
      { field: 'jobNumber', header: 'JOB NUMBER' },
      { field: 'payType', header: 'PAY TYPE' },
      { field: 'section', header: 'GL ACCOUNT' },
      { field: 'subtotal', header: 'SUBTOTAL' }
    ];
  }

  ngOnInit() {}

  // When cell is edited
  handleRowChange(row) {
    if (isEmpty(this._lineItems)) this.updateLineItemsEvent.next([]);
    this.updateRowGroupMetaData();
    this.updateLineItemsEvent.next([...this._lineItems]);
  }

  // Simple currency validation
  isNaN(val: any) {
    return isNaN(val);
  }

  // Improves angular performance
  trackByFn(index, item: any) {
    return item.payeeName; // or item.id
  }

  // Adds a new line item
  addLineItem(payload) {
    this.selectedPayrollPayeeDropDownItem = Math.random() * 100;
    this.addLineItemEvent.next(payload.value);
  }

  // Opens job costs dialog
  openJobCostsDialog() {
    this.onOpenJobCostsDialog.next();
  }

  addSelectedItems() {
    this.addSelectedItemsEvent.next(this.selectedItems);
  }

  // Removes row
  removeRow(e, _id: string) {
    e.preventDefault();
    this.removeRowEvent.next(_id);
  }

  updateRowGroupMetaData() {
    this._lineItems = sortBy(this._lineItems, ['payeeName']);
    this.rowGroupMetadata = {};
    if (this._lineItems) {
      for (let i = 0; i < this._lineItems.length; i++) {
        let rowData = this._lineItems[i];
        let payeeName = rowData.payeeName || '';
        if (i == 0) {
          this.rowGroupMetadata[payeeName] = { index: 0, size: 1 };
        } else {
          let previousRowData = this._lineItems[i - 1];
          let previousRowGroup = previousRowData.payeeName;
          if (payeeName === previousRowGroup)
            this.rowGroupMetadata[payeeName].size++;
          else this.rowGroupMetadata[payeeName] = { index: i, size: 1 };
        }
      }
    }
  }

  // Gets line items
  getLineItems() {
    return [...this._lineItems];
  }

  // Update payee cell dialog
  onShowEditPayeeCellDialog(event, row) {
    if (isEmpty(row)) return;
    this.payeeRow = row;
    this.payrollPayeeCellValue = row.payeeName;
    this.showEditPayrollPayeeCellDialog = true;
  }

  // Updates payee cell value
  updatePayeeRowValue(payeeValue) {
    this.payeeRow.payeeName = this.payrollPayeeCellValue;
    this.showEditPayrollPayeeCellDialog = false;
    this.handleRowChange(this.payeeRow);
    this.updateRowGroupMetaData();
  }

  getTotalColspan() {
  if(this.enableRowSelection) {
    return 1
  } else {
    return 2
  }
  }

  getEmptyRowColSpan() {
    if(this.enableRowSelection) {
      return 9
    } else {
      return 8
    }
  }

  getBorderStyle(field: any) {
    if(field === 'subtotal') {
      return true
    } else {
      return null
    }
  }

  getRowBorderStyle() {
    if(!this.enableRowSelection) {
      return true
    } else {
      return null
    }
  }

  ngOnDestroy() {
    this._lineItems = [];
    this.selectedItems = [];
  }
}
