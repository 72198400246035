export function subtotal(subtotal) {
  return subtotal ? parseFloat(subtotal).toFixed(2) : 0;
}

// Calculate taxes
export function calculateTax(subtotal, tax) {
  if (subtotal && tax) return subtotal * tax;
  return 0;
}

// Calculate total
export function calculateTotalWithTax(subtotal, tax) {
  if (subtotal) {
    if (tax >= 0) {
      const tempSubtotal = parseFloat(subtotal);
      const tempTax = parseFloat(tax);
      const total = tempSubtotal + tempTax;
      return total;
    }
  }
  return 0;
}

// Calculates percentage
export function calculatePercentage(divisor, dividend) {
  try {
    return Math.round((divisor / dividend) * 100);
  } catch (error) {
    return 0;
  }
}

export function toNegative(value) {
  return +value * -1;
}

export function amountTimesPercent(amount, percent) {
  return amount * percent;
}
