import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EliteDomSanitizerPipe } from './dom-sanitizer/elite-dom-sanitizer.pipe';
import { ConvertStringPipe } from './convert-string/convert-string.pipe';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';

@NgModule({
  declarations: [
    EliteDomSanitizerPipe,
    ConvertStringPipe,
    PhoneNumberPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule,
    EliteDomSanitizerPipe,
    ConvertStringPipe,
    PhoneNumberPipe
  ]
})
export class SharedAngularPipesModule {}
