export function aggregateTradeSections(estimatedSections, actualSections) {
  let aggregatedSections = new Map();
  forEachSection(aggregatedSections, estimatedSections, set);
  forEachSection(aggregatedSections, actualSections, upsert);
  return aggregatedSections;
}

function forEachSection(aggregatedSections, tradeSections, updater) {
  for (let i = 0; i < tradeSections.length; i++) {
    const tradeSection = tradeSections[i];
    updater(aggregatedSections, tradeSection);
  }
}

function set(aggregatedSections, tradeSection) {
  const _id = getId(tradeSection._sectionId);
  aggregatedSections.set(_id, {
    name: tradeSection.name,
    estimatedTotal: tradeSection.total
  });
}

function upsert(aggregatedSections, tradeSection) {
  const _id = getId(tradeSection._sectionId);
  aggregatedSections.set(_id, {
    ...aggregatedSections.get(_id),
    name: tradeSection.name,
    actualTotal: tradeSection.total,
    _orderId: tradeSection._orderId,
    _woOrPoId: tradeSection._woOrPoId,
    _sectionId: tradeSection._sectionId
  });
}

const getId = _id => {
  if (_id) return _id;
  else {
    throw new Error('Section does not have valid ID.');
  }
};
