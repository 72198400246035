/**
 * Created by Alex on 8/18/2017.
 */
import { Address } from './address.model';

export function createAddress(address) {
  try {
    // Creating null address if null is passed
    if (!address) {
      return createNullAddress();
    }
    // Creating the address
    const street = address.street;
    const street2 = address.street2;
    const city = address.city;
    const state = address.state;
    const zipCode = address.zipCode;
    const country = address.country;
    return new Address(street, city, state, zipCode, country);
  } catch (e) {
    throw e;
  }
}
// Return a null address
export function createNullAddress() {
  return new Address(null, null, null, null, null);
}
// Concatenates the city, state and zip code
export function composeCityStateAndZipCode(address) {
  if (address) {
    return address.city + ', ' + address.state + ' ' + address.zipCode;
  }
  return '';
}
