import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { SubcontractorState } from '../reducers/subcontractors.reducer';
import { CompanyEstimateTrade } from '../facade/subcontractor-manager.facade.models';

export const GET_SUBCONTRACTORS = 'SUBCONTRACTORS > GET_SUBCONTRACTORS';
export class GetSubcontractors implements Action {
  readonly type = GET_SUBCONTRACTORS;

  constructor() {}
}

export const SET_SUBCONTRACTORS = 'SUBCONTRACTORS > SET_SUBCONTRACTORS';
export class SetSubcontractors implements Action {
  readonly type = SET_SUBCONTRACTORS;

  constructor(public payload: Array<SubcontractorState>) {}
}

export const ADD_SUBCONTRACTOR = 'SUBCONTRACTORS > ADD_SUBCONTRACTOR';
export class AddSubcontractor implements Action {
  readonly type = ADD_SUBCONTRACTOR;
  constructor(public payload: SubcontractorState) {}
}

export const SET_ALL_SUBCONTRACTORS = 'SUBCONTRACTORS > SET_ALL_SUBCONTRACTORS';
export class SetAllSubcontractors implements Action {
  readonly type = SET_ALL_SUBCONTRACTORS;
  constructor(public payload: Array<SubcontractorState>) {}
}

export const SET_ONE_SUBCONTRACTOR = 'SUBCONTRACTORS > SET_ONE_SUBCONTRACTOR';
export class SetOneSubcontractor implements Action {
  readonly type = SET_ONE_SUBCONTRACTOR;
  constructor(public payload: SubcontractorState) {}
}

export const SET_NEW_SUBCONTRACTOR = 'SUBCONTRACTORS > SET_NEW_SUBCONTRACTOR';
export class SetNewSubcontractor implements Action {
  readonly type = SET_NEW_SUBCONTRACTOR;
  constructor(public payload: SubcontractorState) {}
}

export const SET_UPDATED_SUBCONTRACTOR =
  'SUBCONTRACTORS > SET_UPDATED_SUBCONTRACTOR';
export class SetUpdatedSubcontractor implements Action {
  readonly type = SET_UPDATED_SUBCONTRACTOR;
  constructor(public payload: Update<SubcontractorState>) {}
}

export const REMOVE_DELETED_SUBCONTRACTOR =
  'SUBCONTRACTORS > REMOVE_DELETED_SUBCONTRACTOR';
export class RemoveDeletedSubcontractor implements Action {
  readonly type = REMOVE_DELETED_SUBCONTRACTOR;
  constructor(public _id: string) {}
}

export const SET_IS_LOADING_FOR_SUBCONTRACTOR_MANAGER =
  'SUBCONTRACTOR > SET_IS_LOADING_FOR_SUBCONTRACTOR_MANAGER';
export class SetIsLoadingForSubcontractorManager implements Action {
  readonly type = SET_IS_LOADING_FOR_SUBCONTRACTOR_MANAGER;
  constructor(public payload: boolean) {}
}

export const SET_ERROR_FOR_SUBCONTRACTOR_MANAGER =
  'SUBCONTRACTOR > SET_ERROR_FOR_SUBCONTRACTOR_MANAGER';
export class SetErrorForSubcontractorManager implements Action {
  readonly type = SET_ERROR_FOR_SUBCONTRACTOR_MANAGER;
  constructor(public payload: string | null) {}
}

export const SET_COMPANY_ESTIMATE_TRADES_FOR_SUBCONTRACTOR_MANAGER =
  'SUBCONTRACTOR > SET_COMPANY_ESTIMATE_TRADES_FOR_SUBCONTRACTOR_MANAGER';
export class SetCompanyEstimateTradesForSubcontractorManager implements Action {
  readonly type = SET_COMPANY_ESTIMATE_TRADES_FOR_SUBCONTRACTOR_MANAGER;
  constructor(public payload: Array<CompanyEstimateTrade>) {}
}

export type Actions =
  | GetSubcontractors
  | SetSubcontractors
  | AddSubcontractor
  | SetAllSubcontractors
  | SetOneSubcontractor
  | SetNewSubcontractor
  | SetUpdatedSubcontractor
  | RemoveDeletedSubcontractor
  | SetIsLoadingForSubcontractorManager
  | SetErrorForSubcontractorManager
  | SetCompanyEstimateTradesForSubcontractorManager;
