import { Component, OnInit, Input } from '@angular/core';
import { DailyForecast } from 'libs/shared/interfaces/src/lib/interfaces';

@Component({
  selector: 'forecast-component',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss']
})
export class ForecastComponent implements OnInit {
  @Input() data: DailyForecast;

  constructor() {}

  ngOnInit() {}
}
