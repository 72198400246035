import { isEmpty } from 'lodash';
import { calculateTotalFromSubtotalList } from 'apps/contract-estimator/src/app/shared/utils/math/math.shared.utils';
import {
  SalesRepCommissionLine,
  JobCostsTradeSection
} from '@contract-estimator/shared/interfaces';
import { Entity } from 'apps/contract-estimator/src/app/shared/models/entity.model';
import { PayrollItem } from 'apps/contract-estimator/src/app/shared/models/payroll/payroll.model';
import { MiscellaneousExpenseState } from '../../actual-job-costs.models';

// Calculates total
export function calculateExpensesSubotal(
  expenses: Array<JobCostsTradeSection> | Array<MiscellaneousExpenseState>
) {
  if (isEmpty(expenses)) return 0;
  const subtotals = getAmountsAsArray(expenses);
  const subtotal = calculateTotalFromSubtotalList(subtotals);
  return subtotal;
}

// Gets totals as array
export function getAmountsAsArray(
  expenses:
    | Array<JobCostsTradeSection>
    | Array<MiscellaneousExpenseState>
    | Array<PayrollItem>
) {
  if (isEmpty(expenses)) return [];
  const list = [];
  for (let i = 0; i < expenses.length; i++) {
    if (isEmpty(expenses[i])) continue;
    const expense: JobCostsTradeSection = expenses[i];
    if (isNaN(expense.estimatedTotal)) continue;
    list.push(expense.estimatedTotal);
  }
  return list;
}

export function composeSalesRepCommissionObject({
  totalForAllSections,
  changeOrder,
  totalSales,
  overhead,
  marketing,
  workOrderSubtotal,
  purchaseOrderSubtotal,
  miscellaneousExpensesSubtotal,
  subtotal,
  salesRepCommission,
  managerCommission,
}): Array<SalesRepCommissionLine> {
  return [
    {
      name: 'Total Estimated',
      total: totalForAllSections
    },
    {
      name: 'Change Order',
      total: changeOrder
    },
    {
      name: 'Total Sales',
      total: totalSales
    },
    {
      name: 'Less Overhead Costs',
      total: overhead
    },
    {
      name: 'Less Advertising Costs',
      total: marketing
    },
    {
      name: 'Less Work Orders',
      total: workOrderSubtotal
    },
    {
      name: 'Less Purchase Orders',
      total: purchaseOrderSubtotal
    },
    {
      name: 'Less Miscellaneous Expenses',
      total: miscellaneousExpensesSubtotal
    },
    {
      name: 'Profit',
      total: subtotal
    },
    {
      name: 'Sales Rep Commission',
      total: salesRepCommission
    },
    {
      name: 'Manager Commission',
      total: managerCommission
    }
  ];
}

// Composes customer
export function composeEntity(entityState): Entity {
  if (isEmpty(entityState)) return;
  return {
    name: entityState.name,
    email: entityState.email,
    altEmail: entityState.altEmail,
    phoneNumber: entityState.phoneNumber,
    altPhoneNumber: entityState.altPhoneNumber,
    address: entityState.address
  };
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function calculateMargin(totalForAllSections, profit) {
  try {
    if (!totalForAllSections || isNaN(totalForAllSections)) return;
    let margin = +profit / +totalForAllSections;
    return margin;
  } catch (error) {
    return;
  }
}

// Gets commisions
export function getCommissionByName(
  commissions: Array<SalesRepCommissionLine>,
  name: string
) {
  if (isEmpty(commissions)) return 0;
  for (let i = 0; i < commissions.length; i++) {
    if (isEmpty(commissions[i])) continue;
    if (commissions[i].name === name) return commissions[i].total;
  }
  return 0;
}

export function calculateVariance(actMargin, estMargin) {
  const variance =  +actMargin - +estMargin;
  return variance;
}
