import { Injectable } from '@angular/core';
import { select, Action, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import * as actions from '../actions/subcontractor.actions';
import * as selectors from '../selectors/subcontractors.selector';
import * as models from './subcontractor-manager.facade.models';
import { ApolloSubcontractorManagerService } from '../../providers/apollo-subcontractor-manager.service';
import { SubcontractorState } from '../reducers/subcontractors.reducer';
import {
  Go,
  subcontractorManagerQueryParams
} from '@contract-estimator/shared/router/data-access';

@Injectable()
export class SubcontractorManagerFacade {
  /**
   * Observables
   */
  isLoading$ = this.store.pipe(select(selectors.selectIsLoading));
  error$ = this.store.pipe(select(selectors.selectError));
  subcontractors$ = this.store.pipe(select(selectors.selectAllSubcontractors));
  companyEstimateTrades$ = this.store.pipe(
    select(selectors.selectCompanyEstimateTrades)
  );
  companyEstimateTradesForSubcontractorForm$ = this.store.pipe(
    select(selectors.selectCompanyEstimateTradesForSubcontractorForm)
  );
  queryParams$ = this.store.pipe(select(subcontractorManagerQueryParams));

  vm$ = combineLatest([
    this.isLoading$,
    this.error$,
    this.subcontractors$,
    this.companyEstimateTrades$,
    this.companyEstimateTradesForSubcontractorForm$
  ]).pipe(
    map(
      ([
        isLoading,
        error,
        subcontractors,
        companyEstimateTrades,
        companyEstimateTradesForSubcontractorForm
      ]) => ({
        isLoading,
        error,
        subcontractors,
        companyEstimateTrades,
        companyEstimateTradesForSubcontractorForm
      })
    )
  );

  /**
   * Dispatch action methods
   */

  /**
   * Dispatches an action to the store
   * @param action
   */
  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  /**
   * Dispatches an action to set the isLoading property
   * @param isLoading
   */
  dispatchSetIsLoading(isLoading: boolean) {
    const action = new actions.SetIsLoadingForSubcontractorManager(isLoading);
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set the error property
   * @param error
   */
  dispatchSetError(error: string | null) {
    const action = new actions.SetErrorForSubcontractorManager(error);
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set a new subcontractor
   * @param subcontractor
   */
  dispatchSetNewSubcontractor(subcontractor: SubcontractorState) {
    const action = new actions.SetNewSubcontractor(subcontractor);
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set an updated subcontractor
   * @param subcontractor
   */
  dispatchSetUpdatedSubcontractor(subcontractor: SubcontractorState) {
    const id = subcontractor._id;
    const action = new actions.SetUpdatedSubcontractor({
      id,
      changes: subcontractor
    });
    this.dispatch(action);
  }

  /**
   * Dispatches an action to remove a deleted subcontractor
   * @param _id
   */
  dispatchRemoveDeletedSubcontractor(_id: string) {
    const action = new actions.RemoveDeletedSubcontractor(_id);
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set all subcontractors (e.g. for search results)
   * @param subcontractors
   */
  dispatchSetAllSubcontractors(subcontractors: SubcontractorState[]) {
    const action = new actions.SetAllSubcontractors(subcontractors);
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set the trades in the state
   * @param trades
   */
  dispatchSetTrades(trades: models.CompanyEstimateTrade[]) {
    const action = new actions.SetCompanyEstimateTradesForSubcontractorManager(
      trades
    );
    this.dispatch(action);
  }

  /**
   * Dispatches an action to set the url with search criteria
   * @param searchCriteria
   */
  dispatchUpdateUrlWithSearchCriteria(
    searchCriteria?: models.SearchSubcontractorsFacadePayload
  ) {
    if (!!searchCriteria) {
      this.dispatch(
        new Go({
          path: ['/settings/subcontractors'],
          extras: {
            queryParams: {
              ...searchCriteria
            }
          }
        })
      );
    } else {
      this.dispatch(
        new Go({
          path: ['/settings/subcontractors']
        })
      );
    }
  }

  /**
   * Apollo Service Alias Methods
   */

  /**
   * Creates a new subcontractor
   * @param payload
   */
  createSubcontractor(payload: models.CreateSubcontractorFacadePayload) {
    return this.apolloSubcontractorManagerService.createSubcontractor(payload);
  }

  /**
   * Gets all subcontractors
   */
  getAllSubcontractors() {
    return this.apolloSubcontractorManagerService.getAllSubcontractors();
  }

  /**
   * Gets a subcontractor by id
   * @param _id
   */
  getSubcontractor(_id: string) {
    return this.apolloSubcontractorManagerService.getSubcontractor(_id);
  }

  /**
   * Updates a subcontractor
   * @param _id
   * @param payload
   */
  updateSubcontractor(
    _id: string,
    payload: models.UpdateSubcontractorFacadePayload
  ) {
    return this.apolloSubcontractorManagerService.updateSubcontractor(
      _id,
      payload
    );
  }

  /**
   * Sets a subcontractor as active
   * @param _id
   */
  setSubcontractorAsActive(_id: string) {
    return this.apolloSubcontractorManagerService.setSubcontractorAsActive(_id);
  }

  /**
   * Sets a subcontractor as inactive
   * @param _id
   */
  setSubcontractorAsInactive(_id: string) {
    return this.apolloSubcontractorManagerService.setSubcontractorAsInactive(
      _id
    );
  }

  /**
   * Sets a subcontractor as frequent
   * @param _id
   */
  setSubcontractorAsFrequent(_id: string) {
    return this.apolloSubcontractorManagerService.setSubcontractorAsFrequent(
      _id
    );
  }

  /**
   * Sets a subcontractor as infrequent
   * @param _id
   */
  setSubcontractorAsInfrequent(_id: string) {
    return this.apolloSubcontractorManagerService.setSubcontractorAsInfrequent(
      _id
    );
  }

  /**
   * Searches subcontractors by search criteria
   * @param searchCriteria
   */
  searchSubcontractors(
    searchCriteria?: models.SearchSubcontractorsFacadePayload
  ) {
    return this.apolloSubcontractorManagerService.searchSubcontractors(
      searchCriteria
    );
  }

  constructor(
    private store: Store,
    private apolloSubcontractorManagerService: ApolloSubcontractorManagerService
  ) {}
}
