import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {ChangeSalesRepDialogFacade} from "./facades/change-sales-rep-dialog.facade";

@Component({
  selector: 'contractor-change-sales-rep',
  templateUrl: './change-sales-rep.component.html',
  styleUrls: ['./change-sales-rep.component.scss'],
})
export class ChangeSalesRepComponent implements OnInit {

  @Input() salesReps: Array<{ label: any; value: any }>;
  @Input() salesRepName: string;
  salesReps$ = this.changeSalesRepDialogFacade.getSalesReps(this.salesRepName);
  @Input() description = "Change sales rep."
  @Output() changeSalesRepEvent = new Subject();
  selectedSalesRep: string;


  constructor(private changeSalesRepDialogFacade: ChangeSalesRepDialogFacade) { }

  ngOnInit(): void {
  }

  changeSalesRep() {
    this.changeSalesRepEvent.next(this.selectedSalesRep);
  }

}
