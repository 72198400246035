import { of } from 'rxjs/observable/of';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as storesActions from '../actions/stores.actions';
import { ServerUrl } from '../../../../../../../apps/contract-estimator/src/app/config/server-url';
import { StoresService } from '../../services/stores.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { StoreState } from '../reducers/stores.reducer';

@Injectable()
export class StoresEffects {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(
    private actions$: Actions,
    private storesService: StoresService
  ) {}

  @Effect()
  getStores$ = this.actions$.pipe(
    ofType(storesActions.GET_STORES),
    switchMap((action: storesActions.GetStores) => {
      return this.storesService.getAndComposeStores().pipe(
        take(1),
        switchMap((salesReps: Array<StoreState>) => {
          return [new storesActions.SetStores(salesReps)];
        }),
        catchError(err => {
          return of({
            type: 'SET_STORES_FETCH_ERROR',
            payload: 'Error fetching stores'
          });
        })
      );
    })
  );
}
