import { Injectable } from '@angular/core';
import { PdfMergerJsService } from '../pdf-merger-js/pdf-merger-js.service';

@Injectable({
  providedIn: 'root'
})
export class PdfMergerService {
  private async merge(documents: any[]) {
    const merger = this.pdfMergerJsService.merger;
    for (const doc of documents) {
      await merger.add(doc);
    }
    return merger;
  }

  private save(merger, fileName: string) {
    return merger.save(fileName);
  }

  private saveAsBuffer(merger) {
    return merger.saveAsBuffer();
  }

  async download(fileName: string, documents: any[]) {
    const merger = await this.merge(documents);
    return this.save(merger, fileName);
  }

  async getBuffer(documents) {
    const merger = await this.merge(documents);
    return this.saveAsBuffer(merger);
  }

  constructor(private pdfMergerJsService: PdfMergerJsService) {}
}
