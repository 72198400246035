import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'markup-input',
  templateUrl: './markup-input.component.html',
  styleUrls: ['./markup-input.component.scss']
})
export class MarkupInputComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Input() markup: number;
  @Input() date: number;
  @Output() updateMarkupValue = new Subject();
  @Output() updateMarkupValueManually = new Subject();
  @ViewChild('markupInput', { static: false }) nameField: ElementRef;
  percent: boolean = false;

  constructor() {}

  ngOnInit() {}

  handleMarkupUpdated(e) {
    try {
      if (typeof e === 'object') {
        const markup = +e.target.value;
        this.updateMarkupValue.next(markup);
      } else {
        const markup = +e;
        this.updateMarkupValue.next(markup);
      }
    } catch (e) {
      // ... handle errors  here
    }
  }

  // Sets if markup should be set when adding new orders or from input
  updateManualMarkupFlag() {
    this.updateMarkupValueManually.next(true);
  }

  toggleMarkup(val: boolean) {
    setTimeout(() => {
      if (val) this.nameField.nativeElement.focus();
    }, 17);
    this.percent = val;
  }

  formatMarkup(value: number) {
    const markup = value / 100;
    return markup;
  }
}
