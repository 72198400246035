import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { SalesRepsEffects } from './+state/effects/personnel.effects';
import { PersonnelService } from './services/personnel.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([SalesRepsEffects])
  ],
  declarations: [],
  providers: [PersonnelService]
})
export class PersonnelDataAccessModule {}
