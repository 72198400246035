import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DeactivateEstimate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean | any;
}

export class DeactivateEstimateGuardService
  implements CanDeactivate<DeactivateEstimate> {
  canDeactivate(
    component: DeactivateEstimate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate().pipe(
      map(value => {
        if (!value) {
          if (
            confirm(
              'You have unsaved changes! If you leave, your changes may be lost.'
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
    );
  }
}
