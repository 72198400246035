import {
  GenerateUniqueId,
  generateUniqueId
} from './../utils/generateUniqueId/generateUniqueId';

export class Material {
  public _materialId: string;
  public _id: string;
  public name: string;
  public per: string;
  public qty: number;
  public color: string;
  public brand?: string;
  public notes?: string;
  public price: number;
  public priceSubtotal: number; // qty * price
  public group: string;
  public uniqueId?: string;
  public colorRequired: boolean;
  public qtyRequired: boolean;
  public index?: number;

  constructor({
    _id,
    _materialId,
    name,
    per,
    qty,
    color,
    brand,
    notes,
    price,
    priceSubtotal,
    group,
    uniqueId,
    colorRequired,
    index,
    qtyRequired
  }: any) {
    priceSubtotal = priceSubtotal || price * qty;

    this._id = _id;
    this._materialId = _materialId;
    this.name = name;
    this.per = per;
    this.qty = qty;
    this.color = color;
    this.brand = brand;
    this.notes = notes;
    this.price = price;
    this.priceSubtotal = priceSubtotal;
    this.group = group;
    this.uniqueId = uniqueId || generateUniqueId();
    this.colorRequired = colorRequired;
    this.qtyRequired = qtyRequired;
    this.index = index;
  }

  static create(rawMaterial: any) {
    return new Material(rawMaterial);
  }
}
