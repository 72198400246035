import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output, SimpleChanges
} from '@angular/core';
import { StringFormatter } from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';
import { JobEstimateType } from 'libs/shared/interfaces/src/lib/job-estimate-type.interface';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-estimate-po-header-metadata-table',
  templateUrl: './estimate-po-header-metadata-table.component.html',
  styleUrls: ['./estimate-po-header-metadata-table.component.scss']
})
export class EstimatePoHeaderMetadataTableComponent {
  // props
  @Input() hideDate: boolean = false;
  @Input() hideJobNumber: boolean = false;
  @Input() hideTerms: boolean = false;
  @Input() hideMaterial: boolean = false;

  @Input() jobType: string = '';
  @Input() jobStatus: string = 'estimate';
  @Input() termOptions: Array<any>;
  @Input() materialOptions: Array<any>;
  @Input() headerForm: FormGroup = new FormGroup({
    deliveryDate: new FormControl(''),
    jobNumber: new FormControl(''),
    terms: new FormControl(''),
    material: new FormControl('')
  });
  @Input() userHasAdminPrivileges: boolean = false;
  @Output() changeJobType = new EventEmitter();

  // data
  startDate: string = ' ';
  endDate: string = ' ';
  stringFormatter: StringFormatter = new StringFormatter();

  constructor() {}

  ngOnChanges(changes?: SimpleChanges) {
    if(changes.userHasAdminPrivileges) {
      this.disableTradeNumber();
    }
  }

  getFormControlType() {
    return this.jobStatus === 'estimate' ? 'hidden' : 'text';
  }

  getCalendarFormControlType() {
    return this.jobStatus === 'estimate' ? false : true;
  }

  hideDropDown() {
    return this.jobStatus === 'estimate' ? true : false;
  }

  /**
   * Disable trade number
   */
  disableTradeNumber() {
    if (this.userHasAdminPrivileges === true) {
      this.headerForm.controls['jobNumber'].enable({emitEvent: false});
    } else {
      this.headerForm.controls['jobNumber'].disable({emitEvent: false});
    }
  }
}
