import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, filter, get } from 'lodash';

import {
  State,
  SubcontractorState,
  subcontractorAdapter
} from '../reducers/subcontractors.reducer';
import * as selectorFunctions from './selector.functions';

export const getSubcontractorsState = createFeatureSelector<State>(
  'subcontractors'
);

export const {
  selectEntities: selectSubcontractorsDictionary,
  selectAll: selectSubcontractorsArray
} = subcontractorAdapter.getSelectors(getSubcontractorsState);

export const getSubcontractorRequestStatus = createSelector(
  getSubcontractorsState,
  (state: State) => {
    return state.loading;
  }
);

export const selectActiveSubcontractors = createSelector(
  selectSubcontractorsArray,
  subs => {
    return subs.filter((sub: SubcontractorState) => sub.active === true);
  }
);

export const getSubcontractorsNameAndId = createSelector(
  selectSubcontractorsArray,
  (subs: any) => {
    try {
      if (isEmpty(subs)) return [];
      const newSubs = selectorFunctions.composeSubcontractorsArrayWithIdAndName(
        subs
      );
      return newSubs;
    } catch (error) {
      return subs;
    }
  }
);

export const selectSubcontractorsByDefaultValue = createSelector(
  selectActiveSubcontractors,
  (subs: any, props: { frequent: boolean }) => {
    try {
      if (isEmpty(subs)) return [];
      const filteredSubs = filter(subs, ['frequent', get(props, 'frequent')]);
      const newSubs = selectorFunctions.composeSubcontractorsArrayWithIdAndName(
        filteredSubs
      );
      return newSubs;
    } catch (error) {
      return subs;
    }
  }
);

export const selectIsLoading = createSelector(
  getSubcontractorsState,
  (state: State) => state.isLoading
);

export const selectAllSubcontractors = createSelector(
  selectSubcontractorsArray,
  (subs: any) => {
    if (isEmpty(subs)) return [];
    return subs;
  }
);

export const selectError = createSelector(
  getSubcontractorsState,
  (state: State) => state.error
);

export const selectCompanyEstimateTrades = createSelector(
  getSubcontractorsState,
  (state: State) => state.companyEstimateTrades
);

export const selectCompanyEstimateTradesForSubcontractorForm = createSelector(
  selectCompanyEstimateTrades,
  (trades: any) => {
    return trades.map((trade: any) => {
      return {
        label: trade.displayName,
        value: trade._id
      };
    });
  }
);
