import { Injectable } from '@angular/core';
import { environment } from '@contract-estimator/shared/environments';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  TAX_RATE = environment.SALES_TAX_RATE;

  constructor() {}

  // Purchase Orders

  calculateTotalWithQtyAndPrice(qty: number, price: number) {
    const amount = Math.round(qty * price * 100) / 100; // keeps clean 2 decimal number
    return isNaN(amount) ? null : amount;
  }

  // contains sideffect of manipulating po containers's po section
  calculateSectionSalesTax(subtotal) {
    return subtotal * this.TAX_RATE;
  }

  calculateSectionTotal(subtotal, salesTax) {
    return subtotal + salesTax;
  }
}
