export class ProfitMatrixBracket {
  min: number;
  max: number;
  overhead: number;
  advertising: number;
  salesRepCommission: number;
  officeCommission: number;
  markup: number;

  constructor(builder: ProfitMatrixBracketState) {
    this.min = builder.min;
    this.max = builder.max;
    this.overhead = builder.overhead;
    this.advertising = builder.advertising;
    this.salesRepCommission = builder.salesRepCommission;
    this.officeCommission = builder.officeCommission;
    this.markup = builder.markup;
  }

  static fromArguments(
    min: number,
    max: number,
    overhead: number,
    advertising: number,
    salesRepCommission: number,
    officeCommission: number,
    markup: number
  ) {
    return new ProfitMatrixBracket({
      min,
      max,
      overhead,
      advertising,
      salesRepCommission,
      officeCommission,
      markup
    });
  }
}

export interface ProfitMatrixBracketState {
  min: number;
  max: number;
  overhead: number;
  advertising: number;
  salesRepCommission: number;
  officeCommission: number;
  markup: number;
}

export interface ProfitMatrixBracketBehavior {
  setMin: Function;
  setMax: Function;
  setOverhead: Function;
  setAdvertising: Function;
  setSalesRepCommission: Function;
  setOfficeCommission: Function;
  setMarkup: Function;
}

export class ProfitMatrixBracketBuilder
  implements ProfitMatrixBracketBehavior, ProfitMatrixBracketState {
  private _min: number;
  private _max: number;
  private _overhead: number;
  private _advertising: number;
  private _salesRepCommission: number;
  private _officeCommission: number;
  private _markup: number;

  get min() {
    return this._min;
  }

  get max() {
    return this._max;
  }

  get overhead() {
    return this._overhead;
  }

  get advertising() {
    return this._advertising;
  }

  get salesRepCommission() {
    return this._salesRepCommission;
  }

  get officeCommission() {
    return this._officeCommission;
  }

  get markup() {
    return this._markup;
  }

  setMin(value: number) {
    this._min = value;
    return this;
  }

  setMax(value: number) {
    this._max = value;
    return this;
  }

  setOverhead(value: number) {
    this._overhead = value;
    return this;
  }

  setAdvertising(value: number) {
    this._advertising = value;
    return this;
  }

  setMarkup(value: number) {
    this._markup = value;
    return this;
  }

  setSalesRepCommission(value: number) {
    this._salesRepCommission = value;
    return this;
  }

  setOfficeCommission(value: number) {
    this._officeCommission = value;
    return this;
  }

  build() {
    return new ProfitMatrixBracket(this);
  }
}

export class ProfitMatrixBracketDirector {
  builder: ProfitMatrixBracketBuilder;

  setBuilder(builder: ProfitMatrixBracketBuilder) {
    this.builder = builder;
    return this;
  }

  constructProfitMatrixBracket(
    min,
    max,
    overhead,
    advertising,
    salesRepCommission,
    officeCommission,
    markup
  ) {
    return this.builder
      .setMin(min)
      .setMax(max)
      .setOverhead(overhead)
      .setAdvertising(advertising)
      .setSalesRepCommission(salesRepCommission)
      .setOfficeCommission(officeCommission)
      .setMarkup(markup)
      .build();
  }
}
