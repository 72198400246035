import {MenuItem} from "primeng/api";
import {MenuPayload} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {AddTradeMenu} from "../shared/add-trade-menu.class";
import {TradeMenu} from "../shared/trade-menu.class";

export class AdditionalScopeMenu implements MenuItem {
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded: boolean
  ) {
  }

  static from(payload: MenuPayload, callback: any, expanded: boolean) {
    const tradeMenus = TradeMenu.fromAdditionalScopeArray(payload, callback);
    const addScope = AddTradeMenu.from(
      'ADD SCOPE',
      payload.availableJobTypes,
      'change-order',
      callback
    );
    const items = [
        ...tradeMenus,
      addScope
    ];
    return new AdditionalScopeMenu(
      "ADDITIONAL SCOPE",
      items,
      expanded
    )
  }
}
