import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'notes-editor',
  templateUrl: './notes-editor.component.html',
  styleUrls: ['./notes-editor.component.scss']
})
export class NotesEditorComponent implements OnInit {
  @Input() notes: string;
  @Output() editNotesEvent = new Subject();

  constructor() {}

  ngOnInit() {}

  editNotes() {
    this.editNotesEvent.next(this.notes);
  }
}
