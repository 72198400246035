import { ContractTypeEnum } from '@contract-estimator/shared/enums';
import { FinancePlanValue } from '@contract-estimator/shared/interfaces';

export const agreement = {
  companyEmail: 'alexescamore@gmail.com',
  formInputs: function(pageNumber: number) {
    return [
      {
        locations: [
          {
            height: 20,
            width: 200,
            left: 40,
            pageNumber,
            top: 55
          }
        ],
        name: 'labor_signature',
        inputType: 'SIGNATURE',
        contentType: 'SIGNATURE',
        required: true,
        displayLabel: 'Signature'
      }
    ];
  },
  numberOfMiscellaneousPages: 1
};

export const CONTRACT_EXPIRATION_DAYS = 7;

export const CONTRACT_TYPE_HIERARCHY = [
  ContractTypeEnum.INSURANCE,
  ContractTypeEnum.COMMERCIAL,
  ContractTypeEnum.INSTALL,
  ContractTypeEnum.REPAIR
];

export const CONTRACT_HIERARCHY_BY_TYPE = ['Insurance', 'Install', 'Repair'];

export const cashContractTermsAndConditions = [
  {
    text:
      'This Contract (“Contract”) is subject to the Terms and Conditions that appear below.  Elite Roofing, LLC (“Elite”) agrees to furnish all the materials and labor necessary to repair the Owner’s property as noted below.',
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAYMENT SCHEDULE:', style: 'term-item' },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'Owner agrees to pay Elite based on the following payment schedule. (a) A deposit is due upon signing this agreement in the form of a check, credit card, or ACH payment; (b) the remaining balance is due within seven (7) days upon completion of work in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAID WHEN INCURRED COSTS:', style: 'term-item' },
      { text: '@#$', style: 'anchors' },
      {
        text:
          'Paid When Incurred (PWI) Costs refer to items that are unknown during the time of estimate. If incurred, wood replacement and removal of extra layers of shingles will be an additional cost if required. For wood replacement, there will be a one time providers charge of $125.00 plus $5.00 per lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood, which ever is needed. If the job requires more than 10 pieces of wood replacement, prices will be more than those above.  Wood will be replaced at Elite’s discretion. For removal of extra layers of shingles or underlayments, there will be a cost of $15 per 100 square feet.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Elite makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text: [
      {
        text:
          'Elite’s prices are good for 7 days. Work shall commence at Elite’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Elite  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the  construction  site  or',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      {
        text: 'interior items disturbed by ordinary construction activity.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Elite is asked to remove existing gutter covers, then Elite is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Owner is required to provide temporary power and port-o-let services on all new construction job sites.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item'
  },
  {
    text:
      '“Waves” in the roof or walls are due to original construction. Elite is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim (including paint & drywall) on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not responsible for engineering or required to inspect the interior of Owner’s property to detect pre-existing damage or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not responsible for the final connection on internet or satellite dishes, solar panels, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },
  {
    text:
      'There is a possibility that Elite will send out extra materials to the construction site. If so, these materials are property of Elite and will be retrieved by Elite or our roofing supply company at the end of the job. If Owner in any way prevents Elite, its employees or agent, or the supply company or its agents from retrieving the material, Elite is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },
  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Elite, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Elite will make diligent efforts to remove all project debris and excess project materials. Elite also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Elite does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },
  {
    text:
      'Elite may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Elite’s principal office. Owner agrees to pay Elite an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },
  {
    text:
      'Elite promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Elite is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Elite is not required to provide a construction manager on site for the duration of the performance. Elite is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Elite, whether of a similar or dissimilar nature.',
    style: 'term-item'
  },
  {
    text:
      'Should the provisions of the Contract require enforcement by Elite through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Elite under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Elite and Owner prior to commencement of work under the Contract.',
    style: 'term-item',
    pageBreak: 'after'
  },
  {
    text:
      'In no event shall Elite, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Elite receives from the Owner as payment hereunder.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },
  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Elite is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Elite be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Elite disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Elite will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },
  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];

export const insuranceTermsAndConditions = [
  {
    text:
      'INSURANCE WORK AND PRICE: This Contract (“Contract”) is subject to the Terms and Conditions that appear below. Elite Roofing, LLC (“Elite”) agrees to furnish all the materials and labor necessary to repair the Owner’s property per the insurance estimate for the Replacement Cost Value (“RCV”) of Owner’s property as determined by Owner’s insurance company (the “Contract Price”). Owner is responsible for settling insurance claim with insurance company.  All insurance proceeds or settlement including supplements, overhead & profit, paid when incurred amounts (PWI), or the trade summary amounts shall be paid to Elite.',
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAYMENT SCHEDULE:', style: 'term-item' },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'Owner agrees to pay Elite based on the following payment schedule: (i) A Deposit in the amount of deductible is due upon signing this agreement in the form of a check, credit card, or ACH payment: (ii) the Contract Price, less the Deposit and any applicable depreciation retained by Owner’s insurance company, plus Upgrade Costs, are due and payable to Elite within 48 hours of the work being performed in the form of a check or ACH payment: and, (iii) the remaining Contract Price (equal to any applicable depreciation) due and payable to Elite no later than seven (7) days from the date the work is performed in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAID WHEN INCURRED COSTS:', style: 'term-item' },
      { text: '@#$', style: 'anchors' },
      {
        text:
          'Paid When Incurred (PWI) Costs refer to items that are unknown during the time of estimate. If incurred, wood replacement and removal of extra layers of shingles will be an additional cost if required by Owner. For wood replacement, there will be a one time providers charge of $125.00 plus $5.00 per lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood, which ever is needed. If the job requires more than 10 pieces of wood replacement, prices will be more than those above. Wood will be replaced at Elite’s discretion. For removal of extra layers of shingles or underlayments, there will',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      { text: 'be a cost of $15 per 100 square feet.', style: 'term-item' }
    ],
    style: 'term-item'
  },
  {
    text:
      'DEDUCTIBLE:  It is the Owner’s responsibility to pay the Insurance Deductible. Property Owners’ out of pocket expense will not exceed the deductible amount as stated on the insurance company loss sheet unless paid when incurred costs occur and/or Owner upgrades.',
    style: 'term-item'
  },
  {
    text:
      'OWNER’S DECLARATION OF INTENT: Owner acknowledges and agrees that upon approval of insurance work by Owner’s insurance provider Elite shall perform the repairs. In addition, Owner agrees to commence the work upon receipt of funds from Owner’s insurance.',
    style: 'term-item'
  },
  {
    text:
      'Elite makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text: [
      {
        text:
          'Elite’s prices are good for 7 days. Work shall commence at Elite’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Elite  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the  construction  site  or',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      {
        text: 'interior items disturbed by ordinary construction activity.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Elite is asked to remove existing gutter covers, then Elite is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item'
  },

  {
    text:
      '“Waves” in the roof or walls are due to original construction. Elite is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim (including paint & drywall) on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not responsible for engineering or required to inspect the interior of Owner’s property to detect pre-existing damage or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not responsible for the final connection on internet or satellite dishes, solar panels, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },

  {
    text:
      'There is a possibility that Elite will send out extra materials to the construction site. If so, these materials are property of Elite and will be retrieved by Elite or our roofing supply company at the end of the job. If Owner in any way prevents Elite, its employees or agent, or the supply company or its agents from retrieving the material, Elite is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Elite, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Elite will make diligent efforts to remove all project debris and excess project materials. Elite also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Elite does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },

  {
    text:
      'Elite may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Elite’s principal office. Owner agrees to pay Elite an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'Elite promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Elite is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Elite is not required to provide a construction manager on site for the duration of the performance. Elite is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },

  {
    text:
      'Elite shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Elite, whether of a similar or dissimilar nature.',
    style: 'term-item',
    pageBreak: 'before'
  },

  {
    text:
      'Should the provisions of the Contract require enforcement by Elite through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Elite under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Elite and Owner prior to commencement of work under the Contract.',
    style: 'term-item'
  },

  {
    text:
      'In no event shall Elite, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Elite receives from the Owner as payment hereunder.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },

  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Elite is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Elite be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Elite disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Elite will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },

  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];

export const wbCashContractTermsAndConditions = [
  {
    text:
      'This Contract (“Contract”) is subject to the Terms and Conditions that appear below.  Weatherbuilt, LLC (“Weatherbuilt”) agrees to furnish all the materials and labor necessary to repair the Owner’s property as noted below.',
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAYMENT SCHEDULE:', style: 'term-item' },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'Owner agrees to pay Weatherbuilt based on the following payment schedule. (a) A deposit is due upon signing this agreement in the form of a check, credit card, or ACH payment; (b) the remaining balance is due within seven (7) days upon completion of work in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAID WHEN INCURRED COSTS:', style: 'term-item' },
      { text: '@#$', style: 'anchors' },
      {
        text:
          'Paid When Incurred (PWI) Costs refer to items that are unknown during the time of estimate. If incurred, wood replacement and removal of extra layers of shingles will be an additional cost if required. For wood replacement, there will be a one time providers charge of $125.00 plus $5.00 per lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood, which ever is needed. If the job requires more than 10 pieces of wood replacement, prices will be more than those above.  Wood will be replaced at Weatherbuilt’s discretion. For removal of extra layers of shingles or underlayments, there will be a cost of $15 per 100 square feet.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text: [
      {
        text:
          'Weatherbuilt’s prices are good for 7 days. Work shall commence at Weatherbuilt’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Weatherbuilt  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'construction  site  or interior items disturbed by ordinary construction activity.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Weatherbuilt is asked to remove existing gutter covers, then Weatherbuilt is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Owner is required to provide temporary power and port-o-let services on all new construction job sites.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item'
  },
  {
    text:
      '“Waves” in the roof or walls are due to original construction. Weatherbuilt is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim (including paint & drywall) on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not responsible for engineering or required to inspect the interior of Owner’s property to detect pre-existing damage or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not responsible for the final connection on internet or satellite dishes, solar panels, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },
  {
    text:
      'There is a possibility that Weatherbuilt will send out extra materials to the construction site. If so, these materials are property of Weatherbuilt and will be retrieved by Weatherbuilt or our roofing supply company at the end of the job. If Owner in any way prevents Weatherbuilt, its employees or agent, or the supply company or its agents from retrieving the material, Weatherbuilt is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },
  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Weatherbuilt, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Weatherbuilt will make diligent efforts to remove all project debris and excess project materials. Weatherbuilt also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Weatherbuilt does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Weatherbuilt’s principal office. Owner agrees to pay Weatherbuilt an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Weatherbuilt is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Weatherbuilt is not required to provide a construction manager on site for the duration of the performance. Weatherbuilt is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Weatherbuilt, whether of a similar or dissimilar nature.',
    style: 'term-item',
    pageBreak: 'before'
  },
  {
    text:
      'Should the provisions of the Contract require enforcement by Weatherbuilt through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Weatherbuilt under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Weatherbuilt and Owner prior to commencement of work under the Contract.',
    style: 'term-item'
  },
  {
    text:
      'In no event shall Weatherbuilt, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Weatherbuilt receives from the Owner as payment hereunder.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },
  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Weatherbuilt is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Weatherbuilt be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Weatherbuilt disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Weatherbuilt will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },
  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];

export const wbInsuranceTermsAndConditions = [
  {
    text:
      'INSURANCE WORK AND PRICE: This Contract (“Contract”) is subject to the Terms and Conditions that appear below. Weatherbuilt, LLC (“Weatherbuilt”) agrees to furnish all the materials and labor necessary to repair the Owner’s property per the insurance estimate for the Replacement Cost Value (“RCV”) of Owner’s property as determined by Owner’s insurance company (the “Contract Price”). Owner is responsible for settling insurance claim with insurance company.  All insurance proceeds or settlement including supplements, overhead & profit, paid when incurred amounts (PWI), or the trade summary amounts shall be paid to Weatherbuilt.',
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAYMENT SCHEDULE:', style: 'term-item' },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'Owner agrees to pay Weatherbuilt based on the following payment schedule: (i) A Deposit in the amount of deductible is due upon signing this agreement in the form of a check, credit card, or ACH payment: (ii) the Contract Price, less the Deposit and any applicable depreciation retained by Owner’s insurance company, plus Upgrade Costs, are due and payable to Weatherbuilt within 48 hours of the work being performed in the form of a check or ACH payment: and, (iii) the remaining Contract Price (equal to any applicable depreciation) due and payable to Weatherbuilt no later than seven (7) days from the date the work is performed in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text: [
      { text: 'PAID WHEN INCURRED COSTS:', style: 'term-item' },
      { text: '@#$', style: 'anchors' },
      {
        text:
          'Paid When Incurred  (PWI)  Costs refer to items that are unknown during the time of estimate.  If incurred,  wood  replacement and removal of extra layers of shingles will be an additional cost if required by Owner.   For wood replacement,  there will be a  one time  providers charge of  $125.00  plus  $5.00 per  lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood,  which ever is needed.  If the job requires more than 10 pieces of wood replacement,  prices will be more than those above.  Wood will be replaced at  Weatherbuilt’s  discretion.   For   removal   of   extra   layers   of   shingles  or ',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      {
        text: 'underlayments, there will be a cost of $15 per 100 square feet.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text: [
      { text: 'DEDUCTIBLE:  It is the', style: 'term-item' },
      {
        text:
          'Owner’s responsibility to pay the Insurance Deductible. Property Owners’ out of pocket expense will not exceed the deductible amount as stated on the insurance company loss sheet unless paid when incurred costs occur and/or Owner upgrades.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'OWNER’S DECLARATION OF INTENT: Owner acknowledges and agrees that upon approval of insurance work by Owner’s insurance provider Weatherbuilt shall perform the repairs. In addition, Owner agrees to commence the work upon receipt of funds from Owner’s insurance.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text: [
      {
        text:
          'Weatherbuilt’s prices are good for 7 days. Work shall commence at Weatherbuilt’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Weatherbuilt  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the',
        style: 'term-item'
      },
      { text: '/i1/', style: 'anchors' },
      {
        text:
          'construction  site  or interior items disturbed by ordinary construction activity.',
        style: 'term-item'
      }
    ],
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Weatherbuilt is asked to remove existing gutter covers, then Weatherbuilt is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item'
  },

  {
    text:
      '“Waves” in the roof or walls are due to original construction. Weatherbuilt is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not required to inspect the interior of Owner’s property to detect pre-existing damaged or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not responsible for the final connection on internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },

  {
    text:
      'There is a possibility that Weatherbuilt will send out extra materials to the construction site. If so, these materials are property of Weatherbuilt and will be retrieved by Weatherbuilt or our roofing supply company at the end of the job. If Owner in any way prevents Weatherbuilt, its employees or agent, or the supply company or its agents from retrieving the material, Weatherbuilt is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Weatherbuilt, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Weatherbuilt will make diligent efforts to remove all project debris and excess project materials. Weatherbuilt also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Weatherbuilt does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Weatherbuilt’s principal office. Owner agrees to pay Weatherbuilt an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Weatherbuilt is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Weatherbuilt is not required to provide a construction manager on site for the duration of the performance. Weatherbuilt is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Weatherbuilt, whether of a similar or dissimilar nature.',
    style: 'term-item',
    pageBreak: 'before'
  },

  {
    text:
      'Should the provisions of the Contract require enforcement by Weatherbuilt through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Weatherbuilt under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Weatherbuilt and Owner prior to commencement of work under the Contract.',
    style: 'term-item'
  },

  {
    text:
      'In no event shall Weatherbuilt, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Weatherbuilt receives from the Owner as payment hereunder.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },

  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Weatherbuilt is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Weatherbuilt be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Weatherbuilt disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Weatherbuilt will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },

  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];

export const installContractTerms = [];

export const insuranceContractTerms = [];

export const CONTRACT_FINANCING_PLANS: FinancePlanValue[] = [
  {
    name: '4132',
    term: '120 Months',
    interestRate: 0.0999,
    description: '- 120 months at 9.99% APR',
    paymentFactor: 0.0132,
    fee: 0
  },
  {
    name: '4012',
    term: '84 months',
    interestRate: 0.1299,
    description: '- 84 months at 12.99% APR',
    paymentFactor: 0.0181,
    fee: 0
  },
  {
    name: '4398',
    term: '63 months',
    interestRate: 0.0699,
    description:
      '- 3 months no interest, no payment \n- 60 months at 6.99% APR',
    paymentFactor: 0.0198,
    fee: 0.05
  },
  {
    name: '2012',
    term: '12 months',
    interestRate: 0,
    description: '- 12 months no interest, no payment \n- Same as Cash',
    paymentFactor: 0.08333,
    fee: 0.0625
  }
];

export const CONTRACT_FINANCING_TERMS: any[] = [
  'Elite Roofing, LLC (“Elite”) works with a third party company named Service Finance, LLC (“Service Finance”) to offer financing options on single-family home projects.',
  {
    text: [
      {
        text:
          'The above interest rates and monthly payment amounts are approximate figures from Service Finance. These amounts are based upon minimum & maximum loan amounts, approved credit, payment factors, and are subject to change based upon approval.  Terms may differ and there are  no  prepayment   penalties  on '
      },
      { text: '/i1/', style: 'anchors' },
      { text: 'any Service Finance products.' }
    ]
  },
  "There will be a one-time, additional fee from Service Finance. This fee represents the amount Elite must pay Service Finance and is passed on to the Owner without markup.  This fee will be reflected as a separate line item on Elite's Invoice.",
  'Please contact Service Finance, LLC at 866-393-0033 or                                       to apply for a loan and for a full description of terms and conditions.'
];

export const WB_CONTRACT_FINANCING_TERMS: any[] = [
  'Weatherbuilt, LLC (“Weatherbuilt”) works with a third party company named Service Finance, LLC (“Service Finance”) to offer financing options on single-family home projects.',
  {
    text: [
      {
        text:
          'The above interest rates and monthly payment amounts are approximate figures from Service Finance. These amounts are based upon minimum & maximum loan amounts, approved credit, payment factors, and are subject to change based upon approval.  Terms may differ and there are  no  prepayment   penalties  on '
      },
      { text: '/i1/', style: 'anchors' },
      { text: 'any Service Finance products.' }
    ]
  },
  "There will be a one-time, additional fee from Service Finance. This fee represents the amount Weatherbuilt must pay Service Finance and is passed on to the Owner without markup.  This fee will be reflected as a separate line item on Weatherbuilt's Invoice.",
  'Please contact Service Finance, LLC at 866-393-0033 or                                       to apply for a loan and for a full description of terms and conditions.'
];

export const CONTRACT_REMINDERS_MESSAGE =
  'We look forward to working with you!  When you are ready to move forward with us, please DocuSign the contract that was emailed to you.';

export const eliteRoofingInsuranceContractComponentTermsAndConditions = [
  {
    text:
      'INSURANCE WORK AND PRICE: This Contract (“Contract”) is subject to the Terms and Conditions that appear below. Elite Roofing, LLC (“Elite”) agrees to furnish all the materials and labor necessary to repair the Owner’s property per the insurance estimate for the Replacement Cost Value (“RCV”) of Owner’s property as determined by Owner’s insurance company (the “Contract Price”). Owner is responsible for settling insurance claim with insurance company.  All insurance proceeds or settlement including supplements, overhead & profit, paid when incurred amounts (PWI), or the trade summary amounts shall be paid to Elite.',
    style: 'term-item'
  },
  {
    text:
      'PAYMENT SCHEDULE:  Owner agrees to pay Elite based on the following payment schedule: (i) A Deposit in the amount of deductible is due upon signing this agreement in the form of a check, credit card, or ACH payment: (ii) the Contract Price, less the Deposit and any applicable depreciation retained by Owner’s insurance company, plus Upgrade Costs, are due and payable to Elite within 48 hours of the work being performed in the form of a check or ACH payment: and, (iii) the remaining Contract Price (equal to any applicable depreciation) due and payable to Elite no later than seven (7) days from the date the work is performed in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
    style: 'term-item'
  },
  {
    text:
      'PAID WHEN INCURRED COSTS:  Paid When Incurred (PWI) Costs refer to items that are unknown during the time of estimate. If incurred, wood replacement and removal of extra layers of shingles will be an additional cost if required by Owner. For wood replacement, there will be a one time providers charge of $125.00 plus $5.00 per lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood, which ever is needed. If the job requires more than 10 pieces of wood replacement, prices will be more than those above. Wood will be replaced at Elite’s discretion. For removal of extra layers of shingles or underlayments, there will be a cost of $15 per 100 square feet.',
    style: 'term-item'
  },
  {
    text:
      'DEDUCTIBLE:  It is the Owner’s responsibility to pay the Insurance Deductible. Property Owners’ out of pocket expense will not exceed the deductible amount as stated on the insurance company loss sheet unless paid when incurred costs occur and/or Owner upgrades.',
    style: 'term-item'
  },
  {
    text:
      'OWNER’S DECLARATION OF INTENT: Owner acknowledges and agrees that upon approval of insurance work by Owner’s insurance provider Elite shall perform the repairs. In addition, Owner agrees to commence the work upon receipt of funds from Owner’s insurance.',
    style: 'term-item'
  },
  {
    text:
      'Elite makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text:
      'Elite’s prices are good for 7 days. Work shall commence at Elite’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Elite  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the  construction  site  or interior items disturbed by ordinary construction activity.',
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Elite is asked to remove existing gutter covers, then Elite is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item',
    pageBreak: 'after'
  },

  {
    text:
      '“Waves” in the roof or walls are due to original construction. Elite is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Elite shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim (including paint & drywall) on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Elite is not responsible for engineering or required to inspect the interior of Owner’s property to detect pre-existing damage or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not responsible for the final connection on internet or satellite dishes, solar panels, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },

  {
    text:
      'There is a possibility that Elite will send out extra materials to the construction site. If so, these materials are property of Elite and will be retrieved by Elite or our roofing supply company at the end of the job. If Owner in any way prevents Elite, its employees or agent, or the supply company or its agents from retrieving the material, Elite is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Elite, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Elite will make diligent efforts to remove all project debris and excess project materials. Elite also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Elite does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },

  {
    text:
      'Elite may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Elite’s principal office. Owner agrees to pay Elite an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'Elite promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Elite is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Elite is not required to provide a construction manager on site for the duration of the performance. Elite is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },

  {
    text:
      'Elite shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Elite, whether of a similar or dissimilar nature.',
    style: 'term-item'
  },

  {
    text:
      'Should the provisions of the Contract require enforcement by Elite through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Elite under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Elite and Owner prior to commencement of work under the Contract.',
    style: 'term-item'
  },

  {
    text:
      'In no event shall Elite, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Elite receives from the Owner as payment hereunder.',
    style: 'term-item'
  },

  {
    text:
      'Elite is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },

  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Elite is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Elite be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Elite disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Elite will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },

  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];
export const weatherbuiltInsuranceContractComponentTermsAndConditions = [
  {
    text:
      'INSURANCE WORK AND PRICE: This Contract (“Contract”) is subject to the Terms and Conditions that appear below. Weatherbuilt, LLC (“Weatherbuilt”) agrees to furnish all the materials and labor necessary to repair the Owner’s property per the insurance estimate for the Replacement Cost Value (“RCV”) of Owner’s property as determined by Owner’s insurance company (the “Contract Price”). Owner is responsible for settling insurance claim with insurance company.  All insurance proceeds or settlement including supplements, overhead & profit, paid when incurred amounts (PWI), or the trade summary amounts shall be paid to Weatherbuilt.',
    style: 'term-item'
  },
  {
    text:
      'PAYMENT SCHEDULE: Owner agrees to pay Weatherbuilt based on the following payment schedule: (i) A Deposit in the amount of deductible is due upon signing this agreement in the form of a check, credit card, or ACH payment: (ii) the Contract Price, less the Deposit and any applicable depreciation retained by Owner’s insurance company, plus Upgrade Costs, are due and payable to Weatherbuilt within 48 hours of the work being performed in the form of a check or ACH payment: and, (iii) the remaining Contract Price (equal to any applicable depreciation) due and payable to Weatherbuilt no later than seven (7) days from the date the work is performed in the form of a check or ACH payment. Owner is obligated to pay as agreed regardless of the status of any pending insurance claims. Please note we do not accept cash.',
    style: 'term-item'
  },
  {
    text:
      'PAID WHEN INCURRED COSTS:  Paid When Incurred  (PWI)  Costs refer to items that are unknown during the time of estimate.  If incurred,  wood  replacement and removal of extra layers of shingles will be an additional cost if required by Owner.   For wood replacement,  there will be a  one time  providers charge of  $125.00  plus  $5.00 per  lineal foot of 1x8, $6.00 per lineal foot of 1x10 or 1x12, $7.00 per lineal foot of 2x material, $80 per 4x8 sheet of OSB (oriented strand board) and $90 per 4x8 sheet of plywood,  which ever is needed.  If the job requires more than 10 pieces of wood replacement,  prices will be more than those above.  Wood will be replaced at  Weatherbuilt’s  discretion.   For   removal   of   extra   layers   of   shingles  or underlayments, there will be a cost of $15 per 100 square feet.',
    style: 'term-item'
  },
  {
    text:
      'DEDUCTIBLE:  It is the Owner’s responsibility to pay the Insurance Deductible. Property Owners’ out of pocket expense will not exceed the deductible amount as stated on the insurance company loss sheet unless paid when incurred costs occur and/or Owner upgrades.',
    style: 'term-item'
  },
  {
    text:
      'OWNER’S DECLARATION OF INTENT: Owner acknowledges and agrees that upon approval of insurance work by Owner’s insurance provider Weatherbuilt shall perform the repairs. In addition, Owner agrees to commence the work upon receipt of funds from Owner’s insurance.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt makes no guarantees for repairs to match in color, quality, and size.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt’s prices are good for 7 days. Work shall commence at Weatherbuilt’s discretion. Prior to commencement of work under the Contract, it is the sole responsibility of the Owner to cover all items in attics, swimming pool, and under skylight openings to avoid from roofing debris, and remove any breakable/valuable objects from the property, landscaping, yard, porch areas, as well as pictures and valuables that may hang on interior walls or mantles. Interior items may fall due to the nature of the construction.    Weatherbuilt  is  not  liable  for  damages  to  exterior  items  in  the  proximity  of  the construction  site  or interior items disturbed by ordinary construction activity.',
    style: 'term-item'
  },
  {
    text:
      'Owner is required to remove/detach and reset any existing gutter covers for roof replacements or repairs.  If Weatherbuilt is asked to remove existing gutter covers, then Weatherbuilt is not liable for any additional work or costs charged by existing gutter cover company to the Owner.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for cracks, indentations, or scuff marks on driveway from delivery trucks, lifts, falling debris, noise or vibration or other damages resulting to the personal property of the Owner because of work in ordinary course performed hereunder.',
    style: 'term-item',
    pageBreak: 'after'
  },

  {
    text:
      '“Waves” in the roof or walls are due to original construction. Weatherbuilt is not liable for replacing the decking or sheathing from problems arising from previous construction issues.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not responsible for ice & water shield “hump” transferring through shingles or scuff marks on new shingles from excessive heat.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt shall have no liability or responsibility for roofing nails penetrating through “exposed soffits” around perimeter of roof, cracks & chips in wood siding, replacing interior trim on skylight or framing of skylight.',
    style: 'term-item'
  },
  {
    text:
      'Weatherbuilt is not required to inspect the interior of Owner’s property to detect pre-existing damaged or defective structural components of the building or roof system prior to installation or to replace any such structural components.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not responsible for the final connection on internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices. Owner is responsible for contacting a separate contractor to recalibrate or connect any internet or satellite dishes, lightning rods, power fans, skylights, dryer vents, water heater vents, HVAC vents, kitchen vents, bath vents, heat tape/ cable, or other devices.',
    style: 'term-item'
  },

  {
    text:
      'There is a possibility that Weatherbuilt will send out extra materials to the construction site. If so, these materials are property of Weatherbuilt and will be retrieved by Weatherbuilt or our roofing supply company at the end of the job. If Owner in any way prevents Weatherbuilt, its employees or agent, or the supply company or its agents from retrieving the material, Weatherbuilt is entitled to an amount equal to the cost of the materials as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'It is the Owner’s responsibility to pay any remaining balance in full upon substantial completion of work under this Contract. Invoices not paid in full when it is due will be accessed interest at a rate of 18% per annum. Interest will be calculated and assessed monthly until the Contract is paid in full, including assessed interest charges. Owner shall be liable for costs of collection, including but not limited to, reasonable attorney fees and costs, and all advertising promotions, agreements. Weatherbuilt, at its discretion, may void any discounts if the final invoice is not paid timely as described herein.',
    style: 'term-item'
  },
  {
    text:
      'Upon completion of the Contract, Weatherbuilt will make diligent efforts to remove all project debris and excess project materials. Weatherbuilt also agrees to perform a magnetic sweep for removal of nails along the eve line and walkways surrounding the project area. Weatherbuilt does not guarantee the removal of all nails from the project area and is not liable for any damage or injury resulting from items not located during the removal of project debris.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt may cancel this Contract at any time.  Owner may cancel this Contract without penalty or monetary obligation before midnight on the third business day after signing. Cancelation must be in writing and delivered to Weatherbuilt’s principal office. Owner agrees to pay Weatherbuilt an amount equal to 30% of the total contract price should the Owner cancel this contract for any reason after this day as liquidated damages.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt promises to perform all repairs or installations in a quality workmanship- like manner, adhere to applicable industry standards and manufactured specifications. Weatherbuilt is not responsible for problems arising from inadequate or improperly ventilated attics, pre- existing structural problems, or irregularities in the underlying roofing system. Any questions or concerns of Owner arising during performance of repairs and installations must be directed to the representative that executed the Contract. Weatherbuilt is not required to provide a construction manager on site for the duration of the performance. Weatherbuilt is not responsible for damage to the interior of Owner’s property occurring prior to work performed.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt shall not be liable for any delay or failure to perform hereunder because of delays caused by insurance carriers, material shortages, labor disputes, strikes, fire, weather, other acts of God, war, governmental actions, inability to obtain materials from usual sources, or any other circumstances beyond the control of Weatherbuilt, whether of a similar or dissimilar nature.',
    style: 'term-item'
  },

  {
    text:
      'Should the provisions of the Contract require enforcement by Weatherbuilt through legal action, including but not limited to enforcement of the payment provisions by placing the Contract in the hands of an attorney for collection, all reasonable attorney fees and costs shall be paid by the Owner. Any claims of Owner and/or Weatherbuilt under the Contract shall be adjudicated in the court of Jefferson County, Kentucky.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not liable for damages from fires, windstorms, or other hazards normally contemplated to be covered by Owners Insurance or Builders Risk Insurance, as the case may be, unless a written agreement accepting liability is executed by Weatherbuilt and Owner prior to commencement of work under the Contract.',
    style: 'term-item'
  },

  {
    text:
      'In no event shall Weatherbuilt, its officers, directors, shareholders, representatives, employees, attorneys, affiliated entities or insurers be liable to Owner for incidental, indirect, special or consequential damages arising out of or related to the performance, nonperformance or termination of the Contract, no matter what the cause, nor for any reason, under any circumstances, in an amount in excess of the amount Weatherbuilt receives from the Owner as payment hereunder.',
    style: 'term-item'
  },

  {
    text:
      'Weatherbuilt is not liable for any damage due to leaks caused by animals/critters, excessive rain, wind, ice, or hail during the period of warranty. In the event of these extreme weather conditions that warrant an insurance claim, any labor warranty will terminate on the date of such event.',
    style: 'term-item'
  },

  {
    text:
      'Owner shall have a five (5) year transferable labor warranty on residential and a two (2) year labor warranty on commercial jobs.  Owner shall have a Manufacturer’s Limited Warranty on all replacements/installations. All materials are warranted as specified by the manufacturer. It is the Owner’s responsibility to make the necessary repairs to uphold the Manufacturer’s Limited Warranties. Weatherbuilt is not responsible for years inspections.  There are no labor or material warranties on reused materials. Owner shall have a one (1) year transferable labor warranty on all repairs. If repairs are needed during the applicable labor warranty period, Owner agrees that Weatherbuilt be the first contractor of choice to make any necessary repairs during warranty period. Repairs completed by other contractor(s) shall terminate any labor warranty under the contract. Weatherbuilt disclaims all implied warranties including any implied warranty of merchantability or fitness for a particular use. This warranty shall not be assigned by either party and are nontransferable except by written instrument signed by both parties. Weatherbuilt will not unreasonably withhold its written consent to assignment.',
    style: 'term-item'
  },

  {
    text:
      'This Contract shall not be assigned by either party except by written instrument signed by both parties. I the undersigned have read and understand all statements, terms, and conditions of this agreement and agree that all details of this agreement are accepted and satisfactory. I further understand that this agreement constitutes the entire agreement between the parties and that any further changes or alterations to this agreement must be made in writing and agreed to by both parties.',
    style: 'term-item'
  }
];
