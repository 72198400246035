import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'contractor-lead-options-menu',
  templateUrl: './lead-options-menu.component.html',
  styleUrls: ['./lead-options-menu.component.scss']
})
export class LeadOptionsMenuComponent implements OnInit {
  @Input('menuStatus') status;
  @Input() statuses;
  @Output() duplicatedLead = new Subject();
  @Output() editedNotes = new Subject();
  @Output() changedLeadStatus = new Subject();
  @Output() showChangeSalesRepEvent = new Subject();

  constructor() { }

  ngOnInit(): void {
  }


  duplicateLead() {
    this.duplicatedLead.next();
  }

  editNotes() {
    this.editedNotes.next();
  }


  onChangeStatus(status) {
    this.changedLeadStatus.next(status)
  }

  showChangeSalesRep() {
    this.showChangeSalesRepEvent.next();
  }

}
