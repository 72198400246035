import { Expense } from './expense.model';
import { Commission } from './commission.model';

export class Template {
  wasPayoutAltered: boolean;
  netProfitAmount: number;
  netProfitMargin: number;
  overheadMarketing: Array<Expense>;
  expenses: Array<Expense>;
  commissions: Array<Commission>;

  constructor({
    wasPayoutAltered,
    netProfitAmount,
    netProfitMargin,
    commissions,
    overheadMarketing,
    expenses
  }: any) {
    (this.wasPayoutAltered = wasPayoutAltered || false),
      (this.netProfitAmount = netProfitAmount || 0),
      (this.netProfitMargin = netProfitMargin || 0),
      (this.commissions = commissions || []),
      (this.overheadMarketing = overheadMarketing || []),
      (this.expenses = expenses || []);
  }
}
