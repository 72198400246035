import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from "@angular/material/icon";

import { ListboxModule } from 'primeng/listbox';
import { ChipsModule } from 'primeng/chips';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { GalleriaModule } from 'primeng/galleria';
import { ToastModule } from 'primeng/toast';
import { InplaceModule } from 'primeng/inplace';
import {ChartModule} from "primeng/chart";
import {TabMenuModule} from "primeng/tabmenu";
import {TabViewModule} from "primeng/tabview";
import {FileUploadModule} from 'primeng/fileupload';
import {BreadcrumbModule} from 'primeng/breadcrumb';

import {MatSidenavModule} from "@angular/material/sidenav";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from "@angular/material/select";

@NgModule({
  providers: [

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ListboxModule,
    ChipsModule,
    InputMaskModule,
    ProgressBarModule,
    TableModule,
    PanelMenuModule,
    DropdownModule,
    DialogModule,
    TooltipModule,
    CalendarModule,
    CheckboxModule,
    OverlayPanelModule,
    MessagesModule,
    MessageModule,
    RadioButtonModule,
    SplitButtonModule,
    ConfirmDialogModule,
    TableModule,
    ButtonModule,
    SidebarModule,
    GalleriaModule,
    ToastModule,
    InplaceModule,
    ChartModule,
    TabMenuModule,
    TabViewModule,
    FileUploadModule,
    BreadcrumbModule,

    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ListboxModule,
    ChipsModule,
    InputMaskModule,
    ProgressBarModule,
    TableModule,
    PanelMenuModule,
    DropdownModule,
    DialogModule,
    TooltipModule,
    CalendarModule,
    CheckboxModule,
    OverlayPanelModule,
    MessagesModule,
    MessageModule,
    RadioButtonModule,
    SplitButtonModule,
    ConfirmDialogModule,
    TableModule,
    ButtonModule,
    SidebarModule,
    GalleriaModule,
    ToastModule,
    InplaceModule,
    ChartModule,
    TabMenuModule,
    TabViewModule,
    FileUploadModule,
    BreadcrumbModule,

    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ]
})
export class UIComponentsModule {}
