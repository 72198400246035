import { environment } from '@contract-estimator/shared/environments';
export class ServerUrl {
  url: string = environment.production
    ? 'https://contract-estimator.herokuapp.com'
    : '/api';
  companyLogoServerUrl: string = this.url + '/images/eliteroofing/logo/';
  static FILE_STORAGE_URL = 'https://s3-us-west-2.amazonaws.com/estimate-3/';
  static DOWNLOAD_FILE_FROM_STORAGE_URL =
    'https://estimate-3.s3.amazonaws.com/';
  static JOB_PHOTO_CLOUDFRONT_URL =
    'https://d1uxdb7sk516dp.cloudfront.net/';
  static ROTATE_PICTURE_LAMBDA_URL =
    'https://27e080xyke.execute-api.us-west-2.amazonaws.com/default/rotate?key=';
  static SALES_REP_PICTURE_URL =
    'https://contract-estimator.herokuapp.com/images/sales-reps/';

  getUrl() {
    return environment.API_URL;
  }

  getCompanyLogoServerUrl() {
    return this.companyLogoServerUrl;
  }

  getCompanyLogoURL() {
    return 'assets/company/logo.png';
  }

  getWhiteCompanyLogoURL() {
    return 'assets/company/white_logo.png';
  }
}
