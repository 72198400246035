import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PipelineLead } from 'libs/shared/interfaces/src/lib/pipeline.interface';
import { Subject } from 'rxjs/Subject';
import { MenuItem } from 'primeng';

import { StringFormatter } from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';
import { ShowSalesRepColumn } from 'libs/shared/interfaces/src/lib/interfaces';

@Component({
  selector: 'contractor-pipeline-table',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PipelineComponent implements OnInit {
  items: MenuItem[];
  stringFormatter: StringFormatter = new StringFormatter();
  lead: any;
  leadStatus: any;
  @Input() leads: Array<PipelineLead> = [];
  @Input() loading: boolean = false;
  @Input() showSalesRepColumn: ShowSalesRepColumn;
  @Input() status: string;
  @Input() pipelineTitle: string;
  @Output() changeStatusEvent = new Subject();
  @Output() editNotesEvent = new Subject();
  @Output() navigateEvent = new Subject();
  @Output() copyLeadEvent = new Subject();
  @Output() searchedEvent = new Subject();
  @Output() changeSalesRepPressed = new Subject();

  constructor() {
    this.items = [
      { label: 'Duplicate Lead', icon: 'pi pi-copy' },
      { label: 'Edit Notes', icon: 'pi pi-pencil' },
      { separator: true },
      { label: 'Open Estimate', icon: 'pi pi-folder-open' }
    ];
  }

  ngOnInit() {}

  // Changes the status
  changeLeadStatus(lead, newStatus) {
    this.changeStatusEvent.next({ lead, newStatus });
  }

  // Changes the status
  editNotes(lead) {
    this.editNotesEvent.next(lead);
  }

  // Trims string for nice display of text
  trimString(string: string, length: number) {
    const newString = this.stringFormatter.trimString(string, length);
    return newString;
  }

  // Gets the status for column header
  getStatus(value: any) {
    switch (value) {
      case 'In progress':
        return 'DID NOT BUY';
      default:
        return 'IN PROGRESS';
    }
  }

  // Get sent status
  getSentStatus(sent) {
    switch (sent) {
      case true:
        return 'YES';
      case false:
        return 'NO';
      default:
        return '';
    }
  }

  // Get viewed status
  getViewedStatus(viewed) {
    switch (viewed) {
      case true:
        return 'Viewed';
      default:
        return '';
    }
  }

  // Warns the rep before navigating to the estimate
  warnBeforeNavigatingToEstimate(event, lead) {
    if (!event) return;
    event.preventDefault();
    this.navigateEvent.next(lead);
  }

  // Copy lead
  duplicateLead(lead) {
    this.copyLeadEvent.next(lead);
  }

  // Change lead status
  onChangeLeadStatus(lead, newStatus) {
    switch (newStatus) {
      case 'Didn\'t buy':
        this.changeLeadStatus(lead, 'Did not buy');
        break;
      default:
        this.changeLeadStatus(lead, newStatus);
        break;
    }
  }


  onSearch(e) {
    this.searchedEvent.next(e);
  }

  onShowChangeSalesRepEvent(lead) {
    this.changeSalesRepPressed.next(lead);
  }

  // Returns new status based on current pipeline status. E.g invoices/In progress -> Deleted
  getNewStatuses() {
    switch(this.status) {
      case 'In progress':
        return ['Didn\'t buy'];
      case 'Did not buy':
        return ['In progress'];
      case 'Sold':
        return ['Deleted'];
      default:
        return [];
    }
  }
}
