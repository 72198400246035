import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { isEmpty, get } from 'lodash';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges {
  selectedFiles: Array<File>;
  dragOver: boolean = false;

  @Input() text = 'Drag and drop files or click here';
  @Input() loading = false;
  @Input() acceptedFileTypes = 'image/jpeg, image/png, image/heic, image/heif';
  @Input() allowMultipleFiles = true;

  @Output() uploadFilesEvent = new Subject();
  @Output() selectFilesEvent = new Subject();
  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  UPLOADER_MESSAGE = 'Drag and drop files or click here.';

  constructor() {}

  ngOnInit() {
    window.addEventListener(
      'drop',
      e => {
        e && e.preventDefault();
      },
      false
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateUploaderMessageWhenLoading(changes);
  }

  onFileSelected(files) {
    // Validates files
    files = Array.from(files).filter(file => this.validFileType(file));

    if (!files.length) {
      this.selectedFiles = [];
      this.text = this.UPLOADER_MESSAGE;
      return;
    }

    if (files.length === 1) {
      this.text = `Uploading ${files.length} file. Please wait...`;
    } else if (files.length > 1) {
      this.text = `Uploading ${files.length} files. Please wait...`;
    }
    this.uploadFilesEvent.next(files);
  }

  onDropFile(event: any) {
    this.dragOver = false;
    const files = get(event, 'dataTransfer.files', null);
    if (isEmpty(files)) return;
    this.onFileSelected(files);
  }

  dragOverHandler(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  resetFileUploadInput() {
    try {
      this.fileUpload.nativeElement.value = '';
    } catch (e) {
      // ... do nothing.
    }
  }

  dragLeaveHandler(event: any) {
    this.dragOver = false;
  }

  updateUploaderMessageWhenLoading(changes) {
    const loading = get(changes, 'loading');
    if (loading) {
      if (loading.currentValue == false) {
        this.text = this.UPLOADER_MESSAGE;
      }
    }
  }

  validFileType(file) {
    return this.acceptedFileTypes
      .split(',')
      .map(type => type.trim())
      .includes(file.type);
  }
}
