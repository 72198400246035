import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterLink } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LandingPageGuardService } from '@contract-estimator/shared/angular/route-guards';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@contract-estimator/landing/page/shell').then(
        m => m.LandingPageShellModule
      ),
    pathMatch: 'full',
    canActivate: [LandingPageGuardService]
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('@contract-estimator/analytics/shell').then(
        m => m.AnalyticsShellModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'estimator',
    loadChildren: () =>
      import('@contract-estimator/estimate/feature').then(
        m => m.EstimateFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'new-lead',
    loadChildren: () =>
      import('@contract-estimator/new-lead/feature').then(
        m => m.NewLeadFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'pipeline',
    loadChildren: () =>
      import('@contract-estimator/pipeline/feature').then(
        m => m.PipelineFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'scheduler',
    loadChildren: () =>
      import('@contract-estimator/scheduler/shell/feature').then(
        m => m.SchedulerShellFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'work-orders',
    loadChildren: () =>
      import('@contract-estimator/work-order-list/feature').then(
        m => m.WorkOrderListFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'warranty',
    loadChildren: () =>
      import('@contract-estimator/warranty-list/feature').then(
        m => m.WarrantyListFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'qci',
    loadChildren: () =>
      import('@contract-estimator/qci-list/feature').then(
        m => m.QciListFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'payroll',
    loadChildren: () =>
      import('@contract-estimator/payroll/shell').then(
        m => m.PayrollShellModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('@contract-estimator/reports/shell').then(
        m => m.ReportsShellModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('@contract-estimator/list/invoice/feature').then(
        m => m.InvoiceListFeatureModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'calendar',
        loadChildren: () =>
          import(
            '@contract-estimator/interfaces/web/settings/scheduling-calendar/feature-shell'
          ).then(m => m.SchedulingCalendarFeatureShellModule)
      },
      {
        path: 'agreement',
        loadChildren: () =>
          import(
            '@contract-estimator/interfaces/web/settings/agreement/feature-shell'
          ).then(m => m.AgreementFeatureShellModule)
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import(
            '@contract-estimator/interfaces/web/settings/invoice/feature-shell'
          ).then(m => m.InvoiceAuthFeatureShellModule)
      },
      {
        path: 'prices',
        loadChildren: () =>
          import(
            '@contract-estimator/interfaces/web/estimate-price-updater/shell/feature'
          ).then(m => m.EstimatePriceUpdaterFeatureShellModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('@contract-estimator/stores/feature').then(
            m => m.StoresFeatureModule
          ),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'paperwork',
        loadChildren: () =>
          import(
            '@contract-estimator/ddd/interfaces/web/paperwork/manager/feature'
          ).then(m => m.PaperworkManagerFeatureModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'job-cost-manager',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'filter-set',
            loadChildren: () =>
              import(
                '@contract-estimator/job-cost-manager/platforms/web/job-cost-manager-filter-set/feature'
              ).then(m => m.JobCostManagerFilterSetFeatureModule),
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard]
          },
          {
            path:
              'pay-scale/:payScaleId/filter-set/:filterSetId/bucket/:bucketId',
            loadChildren: () =>
              import(
                '@contract-estimator/job-cost-manager/platforms/web/job-cost-manager-job-costs/feature'
              ).then(m => m.JobCostManagerJobCostsFeatureModule),
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard]
          },
          {
            path: '**',
            redirectTo: 'filter-set'
          }
        ]
      },
      {
        path: 'subcontractors',
        loadChildren: () =>
          import(
            '@contract-estimator/subcontractors/platforms/web/subcontractor-manager/feature'
          ).then(m => m.SubcontractorManagerFeatureModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      },
      {
        path: 'team-members',
        loadChildren: () =>
          import(
            '@contract-estimator/team-members/platforms/web/team-member-manager/feature'
          ).then(m => m.TeamMemberManagerFeatureModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [RouterLink]
})
export class AppRoutingModule {}

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', redirectTo: '', pathMatch: 'full' },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ])
  ],
  exports: [RouterModule]
})
export class WildcardRoutingModule {}
