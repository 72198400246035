import {Action, createAction, createSelector, props} from '@ngrx/store';
import { StoreState } from '../reducers/stores.reducer';

export const GET_STORES = 'STORES > GET_STORES';
export class GetStores implements Action {
  readonly type = GET_STORES;

  constructor() {}
}

export const SET_STORES = 'STORES > SET_STORES';
export class SetStores implements Action {
  readonly type = SET_STORES;

  constructor(public payload: Array<StoreState>) {}
}

export const SET_SELECTED_STORE_ID = "[Stores] Set Selected Stores ID";
export class SetSelectedStoreId implements Action {
  readonly type = SET_SELECTED_STORE_ID;

  constructor(public _id: string) {}
}

export const SET_STORES_LOADING = "[Stores] Set Stores loading";
export class SetStoresLoading implements  Action {
  readonly  type = SET_STORES_LOADING;
  constructor(public loading: boolean) {
  }
}

export const SET_STORES_ERROR = "[Stores] Set Stores Error";
export class SetStoresError implements Action {
  readonly  type = SET_STORES_ERROR;
  constructor(public error: string) {}
}

export const RESET_STORES_STATE = "[Stores] Reset Stores State";
export class ResetStoresState implements  Action {
  readonly type = RESET_STORES_STATE;
  constructor() {}
}

export type Actions =
  GetStores
  | SetStores
  | SetSelectedStoreId
  | SetStoresLoading
  | SetStoresError
  | ResetStoresState;
