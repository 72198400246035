import { Component, OnInit, Input, Output } from '@angular/core';
import { Entity } from 'apps/contract-estimator/src/app/shared/models/entity.model';
import { Subject } from 'rxjs/Subject';
import {IAgreementRecipient} from "../../../../../../../interfaces/src/lib/recipients";

@Component({
  selector: 'contract-entity-table',
  templateUrl: './contract-entity-table.component.html',
  styleUrls: ['./contract-entity-table.component.scss']
})
export class ContractEntityTableComponent implements OnInit {
  @Input() recipientEntity: IAgreementRecipient;
  @Input() senderEntity: IAgreementRecipient;
  @Input() recipientTitle: string = 'OWNER';
  @Input() senderTitle: string = 'SALES REPRESENTATIVE';
  @Input() clerkList: Array<any> = [];
  @Input() tableType: string;
  @Output() updateClerkEvent = new Subject();
  clerkValue: any;

  constructor() {}

  ngOnInit() {}

  clerkChange(_id) {
    this.updateClerkEvent.next(_id);
    this.clerkValue = Math.random() * 100;
  }
}
