import {NgModule} from "@angular/core";
import { ButtonModule } from 'primeng/button';
import {ExportDataAsCsvComponent} from "./export-data-as-csv.component";

@NgModule({
  imports: [ButtonModule],
  declarations: [ExportDataAsCsvComponent],
  exports: [ExportDataAsCsvComponent]
})
export class ExportDataAsCsvModule {

}
