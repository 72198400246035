export function strToFloat2(dirtyInput: string): number {
  const invalidNumberChar = /[^\d.]/g;
  const isInvalidFloat = str => /(\d*\.\d*\.\d*)/.test(str); // more than 2 decimals invalidates number
  const strToFloat2 = str => Math.round(parseFloat(str) * 100) / 100;

  dirtyInput = dirtyInput.replace(invalidNumberChar, ''); // removing everything but numbers and decimal values

  return isInvalidFloat(dirtyInput) ? 0 : strToFloat2(dirtyInput);
}

export function isItAValidNumericValue(val: any) {
  if (val === '' || val === null) return true;
  const regex = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  const result = regex.test(val);
  return result;
}

export function isItAValidCurrencyValue(val: any) {
  if (val === '' || val == null) return true;
  const regex = new RegExp(
    /^-?(?=.*?\d)^-?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,3})?$/g
  );
  const result = regex.test(val);
  return result;
}

export function isItAValidQty(val: any) {
  const result = isItAValidCurrencyValue(val);
  return result;
}

export function isInFormatMMDDYYYY(val: any) {
  try {
    if (!val) return true;
    const regex = new RegExp(
      /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/g
    );
    const result = regex.test(val);
    return result;
  } catch (error) {
    return false;
  }
}
