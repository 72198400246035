import {MenuItem} from "primeng/api";
import {isEmpty} from 'lodash';
import {JobTypeMenu} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {TradeActionMenuItem} from "./trade-action-menu-item.class";
import {AddTradePayload} from "@contract-estimator/shared/classes";

export class AddTradeMenu {
  constructor(
    public label: string,
    public items: MenuItem[]
  ) {
  }

  // Adds multiple types to the "Add new item"
  static  from(name: string, jobTypes: Array<JobTypeMenu>, orderType: string, callback: any) {
    if (isEmpty(jobTypes)) throw new Error("Job types are empty.");
    const items: MenuItem[] = [];
    for (let i = 0; i < jobTypes.length; i++) {
      const jobType = jobTypes[i];
      const addTradePayload = new AddTradePayload(
        jobType._id,
        orderType
      )
      const item = new TradeActionMenuItem(
        jobType.name,
        null,
        callback(addTradePayload)
      )
      items.push(item);
    }
    return new AddTradeMenu(name, items);
  }
}
