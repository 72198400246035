import { Injectable } from '@angular/core';
import {differenceInDays, startOfYear, endOfYear, format, getTime} from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class SharedAngularDateServiceService {
  constructor() { }

  getCurrentDate() {
    return new Date();
  }

  getDayDifference(startDate, endDate) {
    return differenceInDays(startDate, endDate);
  }

  // Gets end of current year
  getEndOfCurrentYear() {
    return endOfYear(new Date());
  }

  // Gets start of current year
  getStartOfCurrentYear() {
    return startOfYear(new Date())
  }

  // Gets date as MM/DD/YYYY
  getMiddleEndianDate() {
    const date = this.getCurrentDate();
    return format(date, 'MM/dd/yyyy');
  }

  // Get date as milliseconds
  getTime(date = this.getCurrentDate()) {
    return getTime(date);
    // You can also use the following.
    // return date.getTime()
  }
}
