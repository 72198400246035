import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {get} from 'lodash';

import { environment as env } from '@contract-estimator/shared/environments';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import {AppRoutingModule, WildcardRoutingModule} from './app.routing.module';
import { environment } from '@contract-estimator/shared/environments';
import { SharedMegaMenuFeatureModule } from '@contract-estimator/shared/megamenu/feature';
import { WeatherModule } from '@eliteroofing/weather';
import { GraphqlClientModule } from '@contract-estimator/shared/graphql/client';
import {SharedAngularProvidersModule} from "@contract-estimator/shared/angular/providers";
import {LoadingScreenComponent} from "libs/shared/angular/components/src/lib/loading-screen/loading-screen.component";
import {RoutingSharedModule} from "@contract-estimator/shared/angular/components";


@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      domain: env.auth.domain,
      clientId: env.auth.clientId,
      redirectUri: get(window, 'location.origin'),
      audience: env.auth.audience,
      responseType: env.auth.responseType,
      httpInterceptor: {
        allowedList:  allowedList(),
      },
    }),
    CoreModule.forRoot(),
    AppRoutingModule,
    SharedMegaMenuFeatureModule,
    WeatherModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.ERROR,
      serverLoggingUrl: 'http://localhost:8080/logger'
    }),
    SharedAngularProvidersModule,
    GraphqlClientModule,
    WildcardRoutingModule,
    RoutingSharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}



 function allowedList() {
   return [
     `${env.API_URL}/menu`,
     `${env.API_URL}/menu/count-array`,
     `${env.API_URL}/menu/enabled-features`,
     `${env.API_URL}/lead`,
     `${env.API_URL}/pipeline/*`,
     `${env.API_URL}/scheduler/*`,
     `${env.API_URL}/subcontractors`,
     `${env.API_URL}/work-order-list/*`,
     `${env.API_URL}/warranty-list`,
     `${env.API_URL}/warranty-list/*`,
     `${env.API_URL}/qci-list/*`,
     `${env.API_URL}/invoice/*`,
     `${env.API_URL}/payroll-list`,
     `${env.API_URL}/payroll-list/*`,
     `${env.API_URL}/payroll-logbook`,
     `${env.API_URL}/payroll/*`,
     `${env.API_URL}/payroll`,
     `${env.API_URL}/analytics/*`,
     `${env.API_URL}/personnel/*`,
     `${env.API_URL}/reports/*`,
     `${env.API_URL}/estimate/*`,
     `${env.API_URL}/file-storage/*`,
     `${env.API_URL}/estimate`,
     `${env.API_URL}/scheduler-work-order/*`,
     `${env.API_URL}/scheduler-purchase-order/*`,
     `${env.API_URL}/stores`,
     `${env.API_URL}/sales-reps`,
     `${env.API_URL}/qci/*`,
     `${env.API_URL}/invoice-list/*`,
     `${env.API_URL}/lead/*`,
     `${env.API_URL}/graphql`,
     `${env.API_URL}/warranty`,
     `${env.API_URL}/job-costs/*`,
     `${env.API_URL}/payment/*`,
     `${env.API_URL}/forecast`,
     `${env.API_URL}/predicted-markup/*`,
     `${env.API_URL}/predicted-markup`,
     `${env.API_URL}/team-analytics`,
     `${env.API_URL}/aws/*`,
     `${env.API_URL}/scheduling-calendar/auth`,
     `${env.API_URL}/scheduling-calendar/code`,
     `${env.API_URL}/scheduling-calendar/revoke`,
     `${env.API_URL}/agreement-token-auth/code`,
     `${env.API_URL}/agreement-token-auth`,
     `${env.API_URL}/invoice-token-auth`,
   ]
 }
