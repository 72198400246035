import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { QCILineItem } from 'libs/shared/interfaces/src/lib/qci.interfaces';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-qci-section',
  templateUrl: './qci-section.component.html',
  styleUrls: ['./qci-section.component.scss']
})
export class QciSectionComponent implements OnInit, OnChanges {
  @Input() lineItems: Array<QCILineItem>;
  @Output() lineItemChange = new Subject();
  @Output() markAllEvent = new Subject();
  rowGroupMetadata: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.lineItems) {
      this.updateRowGroupMetaData();
    }
  }

  // When user click on radio button
  onUpdateWorkOrderSection(rowData) {
    this.lineItemChange.next(rowData);
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    try {
      this.rowGroupMetadata = {};
      const materials = this.lineItems;
      if (materials) {
        for (let i = 0; i < materials.length; i++) {
          let rowData = materials[i];
          let group = rowData.group;
          if (i == 0) {
            this.rowGroupMetadata[group] = { index: 0, size: 1 };
          } else {
            let previousRowData = materials[i - 1];
            let previousRowGroup = previousRowData.group;
            if (group === previousRowGroup) this.rowGroupMetadata[group].size++;
            else this.rowGroupMetadata[group] = { index: i, size: 1 };
          }
        }
      }
    } catch (error) {
      // handle error here
    }
  }

  markAll(group, value) {
    this.markAllEvent.next({
      group,
      value
    });
  }
}
