import { of } from 'rxjs/observable/of';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as salesRepsActions from '../actions/sales-reps.actions';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { SalesRepService } from '../../services/sales-reps.service';
import {catchError, switchMap, mergeMap} from 'rxjs/operators';

@Injectable()
export class SalesRepsEffects {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(
    private actions$: Actions,
    private salesRepService: SalesRepService
  ) {}

  @Effect()
  getSalesRepNames$ = this.actions$.pipe(
    ofType(salesRepsActions.GET_SALES_REPS),
    switchMap((action: salesRepsActions.GetSalesReps) => {
      return this.salesRepService.getSalesReps().pipe(
        mergeMap((res: any) => {
          const salesReps = this.salesRepService.composeSalesReps(res);
          return [
            new salesRepsActions.SetSalesReps(salesReps),
            new salesRepsActions.UpdatePipelineMenuRequestStatus(false)
          ];
        }),
        catchError(err => {
          return of({
            type: salesRepsActions.SET_SALES_REPS_FETCH_ERROR,
            payload: 'Error fetching sales reps.'
          });
        })
      );
    })
  );
}
