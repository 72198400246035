import { generate } from 'shortid';
import { ObjectID } from 'bson';

export const generateUniqueId = (id?: string) => generate();

export class GenerateUniqueId {
  generate() {
    return generate();
  }

  generateObjectId() {
    return new ObjectID();
  }

  /**
   * Generates object id as string
   */
  getnerateObjectIdAsString() {
    return this.generateObjectIdAsString();
  }

  /**
   * Generates object id as string
   */
  generateObjectIdAsString() {
    return this.generateObjectId().toString();
  }

  /**
   * Generates object id as hex string
   */
  getnerateObjectIdAsHexString() {
    return this.generateObjectId().toHexString();
  }

}
