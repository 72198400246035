import { ProfitMatrixBracket } from './profit-matrix-bracket.class';

export class ProfitMatrix {
  _id: string;
  version: string;
  types: any;

  constructor(builder: ProfitMatrixBuilderState) {
    this._id = builder.id;
    this.version = builder.version;
    this.types = builder.types;
  }

  static fromArguments(
    _id: string,
    version: string,
    types: any
  ) {
    return new ProfitMatrix({
      id: _id,
      version,
      types
    });
  }
}

export interface ProfitMatrixBuilderState {
  id: string;
  version: string;
  types: any
}

export interface ProfitMatrixBuilderBehavior {
  setId: Function;
  setVersion: Function;
  setTypes: Function;
  build: Function;
}

export class ProfitMatrixBuilder
  implements ProfitMatrixBuilderState, ProfitMatrixBuilderBehavior {
  private _id: string;
  private _version: string;
  private _values: ProfitMatrixBracket[];

  get id() {
    return this._id;
  }

  get version() {
    return this._version;
  }

  get types() {
    return this._values;
  }

  setId(value: string) {
    this._id = value;
    return this;
  }

  setVersion(value: string) {
    this._version = value;
    return this;
  }

  setTypes(value: any) {
    this._values = value;
    return this;
  }

  build() {
    return new ProfitMatrix(this);
  }
}

export class ProfitMatrixDirector {
  builder: ProfitMatrixBuilder;

  setBuilder(builder: ProfitMatrixBuilder) {
    this.builder = builder;
    return this;
  }

  constructProfitMatrix(_id, version, brackets) {
    return this.builder
      .setId(_id)
      .setVersion(version)
      .setTypes(brackets)
      .build();
  }
}
