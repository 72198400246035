import { isEmpty } from 'lodash';

// Gets current sales rep _id and name only
export function getCurrentSalesRep(
  salesReps: Array<{ name: string; _id: string }>,
  _salesRepId
) {
  if (isEmpty(salesReps) || !_salesRepId) return null;
  for (let i = 0; i < salesReps.length; i++) {
    if (isEmpty(salesReps[i])) continue;
    const rep = salesReps[i];
    if (rep._id === _salesRepId) return rep;
  }
  return null;
}
