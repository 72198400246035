export enum JobCostsTypeEnum {
  ESTIMATED,
  ACTUAL
}

export enum CommissionsPaymentStatus {
  ENABLED = 'enabled',
  INOVICE_NOT_FOUND = 'invoice_not_found',
  PAID = 'paid',
  INVOICE_OUTSTANDING_BALANCE = 'invoice_outstanding_balance',
  PROHIBITED = 'prohibited'
}

export enum JobCostsSectionType {
  WORK_ORDER,
  PURCHASE_ORDER
}

export enum MiscellaneousCostType {
  ESTIMATED,
  ACTUAL
}

export enum TripChargePayee {
  SALES_REP = 'rep',
  SUBCONTRACTOR = 'sub'
}

export enum TradeSectionType {
  WORK_ORDER,
  PURCHASE_ORDER
}

export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export enum RequirementStatus {
  NEEDS_TO_COMPLETE_QCI = 'NEEDS_TO_COMPLETE_QCI',
  NEEDS_TO_COLLECT_PAYMENT = 'NEEDS_TO_COLLECT_PAYMENT',
  NEEDS_TO_PAY_COMMISSIONS = 'NEEDS_TO_PAY_COMMISSIONS',
  PAID_COMMISSIONS = 'PAID_COMMISSIONS'
}

export enum UserRoleType {
  ADMIN = 'admin',
  SALES_REP = 'sales rep'
}

export enum LeadType {
  WARRANTY = 'warranty',
  LEAD = 'lead'
}

export enum LeadTypeRouterParam {
  WARRANTY = 'warranty',
  LEAD = 'lead'
}

export enum WarrantyTypeRouterParam {
  LEAD = 'lead',
  WORK_ORDERS = 'work-orders'
}

export enum LeadComponentType {
  WARRANTY = 'WARRANTY',
  LEAD = 'NEW LEAD'
}

export enum WarrantyType {
  WARRANTY = 'WARRANTY',
  WO = 'WO'
}

export enum TableType {
  WARRANTY = 'WARRANTY',
  WO = 'WO',
  QCI = 'QCI'
}

export enum JobCostsPDFType {
  ESTIMATED,
  ACTUAL
}

export enum EnvelopeStatus {
  CREATED = 'created',
  DELIVERED = 'delivered',
  SENT = 'sent',
  SIGNED = 'signed',
  COMPLETED = 'completed',
  VOIDED = 'voided',
  DELETED = 'deleted'
}

export enum RequestStatusType {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  CANCELLED = 'CANCELLED'
}

export enum PayrollLineItemStatusType {
  IN_PROGRESS = 'in progress',
  PAID = 'paid'
}

export enum ProfitMatrixTypeEnum {
  REFERRAL = 'referral',
  LEAD = 'lead',
  SALES_REP = 'salesRep'
}

export enum InvoiceStatusType {
  PAID = 'paid',
  DELETED = 'deleted',
  IN_PROGRESS = 'in progress',
  OUTSTANDING = 'outstanding',
  UNPAID = 'outstanding'
}

export enum AuthenticatedUserInformation {
  EMAIL = 'authenticated_user_email',
  ROLE = 'authenticated_user_role',
  ID = '_userId',
  COMPANY = 'authenticated_user_company',
  COMPANY_ID = '_companyId',
  COMPANY_REFRESH_TOKEN = 'company_refresh_token',
  COMPANY_DEFAULT_TRADE_ID = 'company_default_trade_id',
  NEW_LEAD_SALES_REP_PHOTO_NAME = 'new_lead_sales_rep_photo_name',
  SALES_REP_PHOTO = 'sales_rep_photo',
  SALES_REP_CALENDAR_ID = 'sales_rep_calendar_id',
  AGREEMENT_AUTH = 'agreement_auth',
  AGREEMENT_AUTH_ENTITY_ID = 'agreement_auth_entity_id',
  AUTH0_EMAIL_NAMESPACE = 'https://www.mobile-contractor.com/email',
  REQUEST_NEEDS_SALES_REP_CALENDAR = 'request_needs_sales_rep_calendar',
  SCHEDULING_CALENDAR_SALES_REP_ID = 'scheduling_calendar_sales_rep_id',
  ENVELOPE_NOTIFICATION = 'envelope_notification',
  STORE_ID = '_storeId',
  CONTRACT_TYPE = 'contract_type',

  CONTRACT_JOB_ID = 'contract_job_id',

  JOB_COST_MANAGER = 'job_cost_manager',
  JOB_COST_MANAGER_ID = 'job_cost_manager_id',
  DEFAULT_JOB_COST_MANAGER = 'default_job_cost_manager',
  DEFAULT_JOB_COST_MANAGER_ID = 'default_job_cost_manager_id',

  JOB_COST_MANAGER_PAY_SCALE = 'job_cost_manager_pay_scale',
  JOB_COST_MANAGER_PAY_SCALE_FILTER_SET = 'job_cost_manager_pay_scale_filter_set'
}

/*DO NOT ALTER THE OLDER*/
export enum Error {
  INVALID_FORM_ERROR = 1,
  HTTP_SERVER_ERROR = 2,
  INVALID_AMOUNTS_ERROR = 3,
  OTHER_ERROR_MESSAGE = 4,
  OK_SERVER_MESSAGE = 5
  //ADD MORE HERE --->
}

export enum dialogMessage {
  NO_CLOSABLE_ERROR_DIALOG_MESSAGE = 1,
  CLOSABLE_ERROR_DIALOG_MESSAGE = 2,
  INFO = 3,
  SUCCESS = 4
}

export enum ContractTypeEnum {
  INSURANCE = 'INSURANCE',
  INSTALL = 'INSTALL',
  REPAIR = 'REPAIR',
  COMMERCIAL = 'COMMERCIAL',
  NONE = 'NONE',
  // Lowercase
  Insurance = 'Insurance',
  Install = 'Install',
  Repair = 'Repair',
  Commercial = 'Commercial',
  None = 'None'
}

export enum TradeTypeEnum {
  ROOF_INSTALL = 'ROOF_INSTALL',
  WINDOW_INSTALL = 'WINDOW_INSTALL',
  GUTTER_INSTALL = 'GUTTER_INSTALL',
  SIDING_INSTALL = 'SIDING_INSTALL',
  ROOF_REPAIR = 'ROOF_REPAIR',
  COMMERCIAL = 'COMMERCIAL',
  INSURANCE = 'INSURANCE',
  /* Lowercase */
  Roof_Install = 'Roof Install',
  Commercial = 'Commercial',
  Insurance = 'Insurance',
  Install = 'Install',
  Gutter_Install = 'Gutter Install'
}

export enum ContextRequestType {
  GRAPHQL = 'graphql'
}

export enum EmailAudience {
  CUSTOMERS = 'CUSTOMERS',
  SUBCONTRACTORS = 'SUBCONTRACTORS'
}

export enum PDFColumnWidth {
  AUTO = 'auto',
  SMART = '*'
}

export enum SchedulerTradeType {
  CHANGE_ORDER = 'change-order'
}

export enum PageBrakeEnum {
  BEFORE = 'before',
  AFTER = 'after'
}

export enum DocusignEnvelopeRecipientRole {
  CARBON_COPY = 'carbonCopy'
}

export enum EnvelopeSignerType {
  REMOTE_SIGNER = 'REMOTE_SIGNER',
  IN_PERSON_SIGNER = 'IN_PERSON_SIGNER',
  UNKNOWN = 'UNKNOWN'
}

export enum ChangeOrderDataValidationStatus {
  EMPTY_SECTION_NAME = 'All trade sections should be named. E.g HOUSE, GARAGE, etc.',
  EMPTY_SECTION_LINE_ITEMS = 'Change order must have at least one labor or material qty.',
  EMPTY_SECTION_LINE_ITEM_PRICE = 'There is at least one line item with a qty, but without a price.',
  MARKUP_WARNING = 'Your markup is different from the suggested markup. Do you still want to continue?',
  VALID = 'VALID',
  UNKNOWN = 'There was an unknown error validating the change order data.'
}

export enum InvoicePDFVersionType {
  ELECTRONIC_PAYMENT = 'Electronic Payment',
  READONLY = 'readonly'
}

export enum SchedulerComponentStatus {
  UNSCHEDULED = 'unscheduled',
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  DELETED = 'deleted'
}

export enum SalesRepStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum PhoneNumberFormat {
  DASHED = 'dashed',
  OTHER = 'other'
}

export enum EnvelopeRecipientStatusEnum {
  VOIDED = 'Voided',
  DECLINED = 'Declined'
}

export enum EnvelopeRecipientTypeEnum {
  SIGNER = 'Signer'
}

export enum LeadStatus {
  IN_PROGRESS = 'In progress',
  DID_NOT_BUY = "Didn't buy",
  SOLD = 'Sold'
}

export enum SalesRepVsPredictedMarkupDifference {
  HIGHER = 'HIGHER',
  EQUAL = 'EQUAL',
  LOWER = 'LOWER',
  UNKNOWN = 'UNKNOWN'
}

export enum PurchaseOrderMaterialGroupEnum {
  OTHER = 'ZZZzzz-Other-index'
}

export enum PaperworkManagerPacketContractType {
  INSURANCE = 'INSURANCE',
  CASH = 'CASH'
}

export enum PaperworkManagerPacketSigningType {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON'
}

export enum EditDocusignTemplateResult {
  SAVE = 'Save',
  CANCEL = 'Cancel'
}

export enum JobCostManagerValueType {
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE'
}

export enum JobCostManagerCostsType {
  COST = 'COST',
  COMMISSION = 'COMMISSION'
}

export enum EstimateStatus {
  IN_PROGRESS = 'In progress',
  DID_NOT_BUY = 'Did not buy',
  SOLD = 'Sold'
}

export enum SubcontractorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  UNKNOWN = 'UNKNOWN'
}

export enum StringConstantType {
  EMPTY_STRING = ''
}

export enum TeamMemberStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  UNKNOWN = 'UNKNOWN'
}

// TeamMemberRole is a placeholder until we have a working roles collection with CRUD capabilities
export enum TeamMemberRole {
  ADMIN_NAME = 'admin',
  ADMIN_DISPLAY_NAME = 'Admin',
  ADMIN_ID = '652ee09f5e4c28f05a179e7b',
  SALES_REP_NAME = 'sales rep',
  SALES_REP_DISPLAY_NAME = 'Sales Rep',
  SALES_REP_ID = '652ee09f5e4c28f05a179e7c'
}
