import { Injectable } from '@angular/core';
import {map} from "rxjs/operators";
import {filter} from 'lodash';

import {ApolloSalesRepsService} from "@contract-estimator/sales-reps/data-access";

@Injectable({
  providedIn: 'root'
})
export class ChangeSalesRepDialogFacade {

  constructor(
    private apolloSalesRepsService: ApolloSalesRepsService
  ) { }

  /**
   * Gets sales reps
   */
  getSalesReps(salesRepName) {
    return this.apolloSalesRepsService.fetchDropDownCompanySalesRepsOptions()
      .pipe(
        map((salesReps: any[]) => salesReps || []),
        map((salesReps: any[]) => [{ label: '', value: '' }, ...salesReps]),
        map((salesReps: any[]) => filter(salesReps, rep =>  rep.label !== salesRepName))
      );
  }
}
