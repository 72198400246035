import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SubcontractorEffects } from './+state/effects/subcontractors.effects';
import { SubcontractorService } from './services/subcontractors.service';
import * as fromSubcontractors from './+state/reducers/subcontractors.reducer';
import { ApolloSubcontractorManagerService } from './providers/apollo-subcontractor-manager.service';
import { SubcontractorManagerFacade } from './+state/facade/subcontractor-manager.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSubcontractors.subcontractorManagerFeatureKey,
      fromSubcontractors.reducer
    ),
    EffectsModule.forFeature([SubcontractorEffects])
  ],
  providers: [
    SubcontractorService,
    ApolloSubcontractorManagerService,
    SubcontractorManagerFacade
  ]
})
export class SubcontractorsDataAccessModule {}
