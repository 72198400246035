import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkupInputComponent } from './components/markup-input/markup-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIComponentsModule } from '../ui-components/ui-components.module';

@NgModule({
  declarations: [MarkupInputComponent],
  imports: [CommonModule, ReactiveFormsModule, UIComponentsModule],
  exports: [CommonModule, MarkupInputComponent]
})
export class SharedJobCostsModule {}
