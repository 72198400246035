export class QCI {
  _id: string;
  _qciId: string;
  _salesRepId: string;
  _subcontractorId: string;
  date: any;
  name: string;
  type: string;
  jobNumber: string;
  notes: string;
  lineItems: Array<QCILineItem>;
  qciPayrollStatus: string;
}

export class QCISection {
  _id: string;
  name: string;
  lineItems: Array<QCILineItem>;
}

export class QCILineItem {
  _id: string;
  task: string;
  value: string;
  group: string;
  index: number;
}
