import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DailyForecast} from '@contract-estimator/shared/interfaces';

@Component({
  selector: 'lib-weather',
  templateUrl: './weather.component.html',
  styleUrls: [
    './weather.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class WeatherComponent implements OnInit {

  @Input() data: DailyForecast[] = [];

  constructor() { }

  ngOnInit(): void {}

  drawDivider(i) {
    const itemCount = i + 1
    const arrayLength = this.data.length;
    const isLastItem = itemCount === arrayLength;
    return !isLastItem;
  }

}
