import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableComponent } from './components/report-table/report-table.component';
import {UIComponentsModule} from "../ui-components/ui-components.module";


@NgModule({
  declarations: [ReportTableComponent],
  imports: [
    CommonModule,
    UIComponentsModule
  ],
  exports: [CommonModule, ReportTableComponent]
})
export class ReportsSharedModule {}
