export interface State {
  timeZone: string;
  timeZones: Array<TimeZone>;
}

export interface TimeZone {
  label: string;
  value: string;
}

export const initialState = {
  timeZone: 'America/New_York',
  timeZones: [
    {
      label: 'Central Time',
      value: 'America/Chicago'
    },
    {
      label: 'Eastern Time',
      value: 'America/New_York'
    },
    {
      label: 'Mountain Time (Denver)',
      value: 'America/Denver'
    },
    {
      label: 'Mountain Time (Phoenix)',
      value: 'America/Phoenix'
    },
    {
      label: 'Pacific Time',
      value: 'America/Los_Angeles'
    }
  ]
};
