export class AddressUtils {
  // Compose address line 2 with state, city, and zip code
  static composeAddressLine(city: string, state: string, zipCode: string) {
    if (!city && !state && !zipCode) return '';
    if (!city && !state && zipCode) return zipCode;
    if (!city && state && zipCode) return `${state} ${zipCode}`;
    if (city && !state && !zipCode) return city;
    if (city && state && !zipCode) return `${city}, ${state}`;
    if (!city && state && !zipCode) return state;
    if (city && !state && zipCode) return `${city} ${zipCode}`;
    if (city && state && zipCode) return `${city}, ${state} ${zipCode}`;
  }
}
