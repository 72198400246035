import { Material } from './material.model';

// currently incomplete. It only contains information from jobs.controllers.orders.purchaseorders.sections
// that is being used
export class PurchaseOrderSection {
  _id: string;
  _workOrderSectionId: string;
  name: string;
  total: number;
  subtotal: number;
  salesTax: number;
  materials: Material[];

  constructor({
    _id,
    _workOrderSectionId,
    name,
    total,
    salesTax,
    materials
  }: any) {
    this._id = _id;
    this._workOrderSectionId = _workOrderSectionId;
    this.name = name;
    this.total = total;
    this.salesTax = salesTax;
    const filteredMaterials = materials.filter(material => material);
    this.materials = filteredMaterials.map(material =>
      Material.create(material)
    );
  }

  static create(rawEstimate: any) {
    return new PurchaseOrderSection(rawEstimate);
  }
}

export interface PurchaseOrderSectionResponse {
  _id: string;
  _workOrderSectionId: string;
  name: string;
  total: number;
  salesTax: number;
  materials: any[];
  _storeId: string;
}
