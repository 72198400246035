import { Component, OnInit } from '@angular/core';
import {environment} from "@contract-estimator/shared/environments";

@Component({
  selector: 'contractor-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getCompanyLogo() {
    return environment.JOB_PHOTO_CLOUDFRONT_URL + "blue_logo.png?d=160x160"
  }

}
