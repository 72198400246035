import {Component, Input} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {environment} from "@contract-estimator/shared/environments";
import {DailyForecast} from "@contract-estimator/shared/interfaces";

@Component({
  selector: 'app-material-navigation',
  templateUrl: './material-navigation.component.html',
  styleUrls: ['./material-navigation.component.scss']
})
export class MaterialNavigationComponent {

  @Input() forecast: DailyForecast[];
  megaMenuUrl = environment.MEGA_MENU_URL;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 1280px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * Gets logo URL
   */
  getLogoUrl() {
    return environment.JOB_PHOTO_CLOUDFRONT_URL + 'mobiledash_logo_white.png?d=250x250';
  }

}
