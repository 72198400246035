import { ActionReducer, MetaReducer } from '@ngrx/store';
import {
  LOGOUT,
  RESET_STORE_STATE
} from 'libs/login/data-access/src/lib/+state/actions/login.actions';

// Resets store state
export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    return reducer(action.type === LOGOUT ? undefined : state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [logout];
