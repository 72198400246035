import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MEGAMENU_FEATURE_KEY,
  State,
  MegaMenuPartialState,
  megaMenuAdapter
} from './mega-menu.reducer';
import {get} from 'lodash';

// Lookup the 'MegaMenu' feature state managed by NgRx
export const getMegaMenuState = createFeatureSelector<
  MegaMenuPartialState,
  State
>(MEGAMENU_FEATURE_KEY);

const { selectAll, selectEntities } = megaMenuAdapter.getSelectors();

export const getMegaMenuLoaded = createSelector(
  getMegaMenuState,
  (state: State) => state.loaded
);

export const getMegaMenuError = createSelector(
  getMegaMenuState,
  (state: State) => state.error
);

export const getAllMegaMenu = createSelector(getMegaMenuState, (state: State) =>
  selectAll(state)
);

export const getMegaMenuEntities = createSelector(
  getMegaMenuState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getMegaMenuState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getMegaMenuEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const enabledFeatures = createSelector(
  getMegaMenuState,
  (state) => get(state, 'enabledFeatures', [])
)
