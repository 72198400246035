import { IMiscellaneousCostVM } from '@contract-estimator/shared/interfaces';

export function aggregateMiscellaneosCosts(
  estimatedMiscellaneousCosts,
  actualMiscellaneousCosts
): Map<string, IMiscellaneousCostVM> {
  let aggregatedCosts = new Map();
  forEachCost(aggregatedCosts, estimatedMiscellaneousCosts, set);
  forEachCost(aggregatedCosts, actualMiscellaneousCosts, upsert);
  return aggregatedCosts;
}

function forEachCost(aggregatedCosts, miscellaneousCosts, updater) {
  for (let i = 0; i < miscellaneousCosts.length; i++) {
    const cost = miscellaneousCosts[i];
    updater(aggregatedCosts, cost);
  }
}

function set(aggregatedSections, cost) {
  const _id = getId(cost._id);
  aggregatedSections.set(_id, createEstimatedCostsObject(cost));
}

function upsert(aggregatedSections, cost) {
  const _id = getId(cost._id);
  aggregatedSections.set(_id, {
    ...createActualCostsObject(cost),
    ...aggregatedSections.get(_id)
  });
}

const getId = _id => {
  if (_id) return _id;
  else {
    throw new Error('Section does not have valid ID.');
  }
};

function createEstimatedCostsObject(cost) {
  return {
    name: cost.name,
    estimatedQty: cost.qty,
    estimatedPrice: cost.price,
    estimatedTotal: calculateSubtotal(cost.qty, cost.price),
    isDeletable: false
  };
}

function createActualCostsObject(cost) {
  return {
    _id: cost._id,
    name: cost.name,
    actualQty: cost.qty,
    actualPrice: cost.price,
    actualTotal: calculateSubtotal(cost.qty, cost.price)
  };
}

function calculateSubtotal(qty, price) {
  return +qty * +price;
}
