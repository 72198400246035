import { get } from 'lodash';

export interface Address {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  addressLine1: string;
  addressLine2: string;
}

export class MaillingAddress implements Address {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  addressLine1: string;
  get addressLine2() {
    return this.composeAddressLine2();
  }
  constructor(builder: MailingAddressBuilder) {
    this.street = builder.street;
    this.city = builder.city;
    this.state = builder.state;
    this.zipCode = builder.zipCode;
    this.street = builder.street;
    this.addressLine1 = builder.street;
  }

  composeAddressLine2() {
    let addressLine = '';
    if (this.city) addressLine = addressLine.concat(this.city + ', ');
    if (this.state) addressLine = addressLine.concat(this.state + ' ');
    if (this.zipCode) addressLine = addressLine.concat(this.zipCode);
    return addressLine;
  }
}

export interface AddressBuilder {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  setStreet: Function;
  setCity: Function;
  setState: Function;
  setZipCode: Function;
  build: Function;
}

export class MailingAddressBuilder implements AddressBuilder {
  street: string;
  city: string;
  state: string;
  zipCode: string;

  setStreet(street) {
    this.street = street;
    return this;
  }
  setCity(city) {
    this.city = city;
    return this;
  }

  setState(state) {
    this.state = state;
    return this;
  }
  setZipCode(zipCode) {
    this.zipCode = zipCode;
    return this;
  }

  build() {
    return new MaillingAddress(this);
  }
}

export class AddressDirector {
  private builder: AddressBuilder;

  setBuilder(builder: AddressBuilder) {
    this.builder = builder;
  }

  composeMailingAdress(city, state, zipCode, street) {
    return this.builder
      .setCity(city)
      .setState(state)
      .setZipCode(zipCode)
      .setStreet(street)
      .build();
  }
}
