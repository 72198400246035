import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Buffer} from 'buffer';

import {ServerUrl} from "apps/contract-estimator/src/app/config/server-url";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FileUploaderHttpServiceService {

  serverUrl = this.serverUrlService.getUrl();


  getPutObjectSignedUrl(fileName, fileType, bucket?: string) {
    // Compos url
    let url = this.serverUrl +
      `/file-storage/object?file-name=${fileName}&file-type=${fileType}`;
    if(bucket) url = `${url}&bucket=${bucket}`;

    // Http request
    return this.http.put(
      url,
      {}
    );
  }

  downloadPicture(fileName: string) {
    return this.http.get(ServerUrl.DOWNLOAD_FILE_FROM_STORAGE_URL + fileName, {
      responseType: 'blob'
    });
  }

  // Downloads rep image
  downloadSalesRepPicture(fileName: string) {
    return this.http.get(ServerUrl.DOWNLOAD_FILE_FROM_STORAGE_URL + fileName, {
      responseType: 'blob'
    });
  }

  // Gets storage signed url
  getStorageSignedUrl() {
    return this.http.get(this.serverUrl + `/file-storage/objects`);
  }

  // Get signed Delete picture from storage
  getSignedDeletePictureRequest(fileName: string) {
    return this.http.delete(
      this.serverUrl + `/file-storage/object?file-name=${fileName}`
    );
  }

  // Updates work order picture info
  updateWorkOrderPictureInfo(payload: any) {
    return this.http.put(
      this.serverUrl + `/estimate/picture-info`,
      payload
    );
  }

  /**
   * Downloads job photo
   * @param fileName
   */
  downloadJobPhoto(fileName: string) {
    return this.http.get(ServerUrl.JOB_PHOTO_CLOUDFRONT_URL + fileName + '?d=2800x2800', {
      responseType: 'blob'
    });
  }


  /**
   * Downloads company logo
   */
  downloadPDFCompanyLogo(name: string) {
    return this.http.get(ServerUrl.JOB_PHOTO_CLOUDFRONT_URL + name + '?d=300x300', {
      responseType: 'arraybuffer'
    });
  }

  /**
   * Download company logo as base64 data url
   */
  downloadPDFCompanyLogoAsBase64DataUrl(name: string) {
    return this.downloadPDFCompanyLogo(name)
      .pipe(
        map(response => {
          const buffer = Buffer.from(response);
          const base64Image = buffer.toString('base64');
          const dataUrl = `data:image/png;base64,${base64Image}`;
          return dataUrl;
        })
      )
  }

  constructor(
    private http: HttpClient,
    private serverUrlService: ServerUrl,
  ) {}
}
