import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MegaMenuService } from './providers/mega-menu.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromMegaMenu from './+state/mega-menu.reducer';
import { MegaMenuEffects } from './+state/mega-menu.effects';
import { MegaMenuFacade } from './+state/mega-menu.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMegaMenu.MEGAMENU_FEATURE_KEY,
      fromMegaMenu.reducer
    ),
    EffectsModule.forFeature([MegaMenuEffects])
  ],
  providers: [MegaMenuService, MegaMenuFacade]
})
export class SharedMegamenuDataAccessModule {}
