import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'contractor-viewed-badge',
  templateUrl: './viewed-badge.component.html',
  styleUrls: ['./viewed-badge.component.scss']
})
export class ViewedBadgeComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
