export const UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE =
  'UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE';
export class UpdateUserPrivacyTermsAcceptance {
  readonly type = UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE;
  constructor() {}
}

export const USER_PRIVACY_TERMS_ACCEPTANCE = 'USER_PRIVACY_TERMS_ACCEPTANCE';
export class UserPrivacyTermsAcceptance {
  readonly type = USER_PRIVACY_TERMS_ACCEPTANCE;
  constructor(public payload: boolean) {}
}

export const ERROR_IN_UPDATE_USER_PRIVACY_TERMS_AND_ACCEPTANCE =
  'ERROR_IN_UPDATE_USER_PRIVACY_TERMS_AND_ACCEPTANCE';
export class ErrorInUpdateUserPrivacyTermsAndAcceptance {
  readonly type = ERROR_IN_UPDATE_USER_PRIVACY_TERMS_AND_ACCEPTANCE;
  constructor(public message: string, id: string) {}
}

export type Actions =
  | UpdateUserPrivacyTermsAcceptance
  | ErrorInUpdateUserPrivacyTermsAndAcceptance;
