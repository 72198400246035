import {get, orderBy} from 'lodash';

import {environment} from '@contract-estimator/shared/environments';
import {AgreementRecipientBuilder, AgreementRecipientDirector} from '@contract-estimator/shared/classes';
import {
  JobCostsAttributeValues,
  JobCostsPDFContentVM,
  JobCostsSectionTotal,
  MiscellaneousCostValues,
  SummarySection,
} from '@contract-estimator/shared/interfaces';
import {composePDFCompany} from '../../../../company/company.utils';
import {percentDecorator, toUSDCurrency} from '../../../../currency/currency.utils';
import {JobCostsPDFType} from "@contract-estimator/shared/enums";

export function jobCostsPDFContentProjector(
  metadata,
  summarySections: any[],
  sections,
  totals,
  company
) {
  company = company || composePDFCompany(environment.COMPANY_INFORMATION);

  const agreeementRecipientBuilder = new AgreementRecipientBuilder();
  const agreementRecipientDirector = new AgreementRecipientDirector();
  agreementRecipientDirector.setBuilder(agreeementRecipientBuilder);
  const owner = agreementRecipientDirector.createOwnerWithAltEmail(
    get(metadata, 'owner.name', ''),
    get(metadata, 'owner.addressLine1', ''),
    get(metadata, 'owner.city', ''),
    get(metadata, 'owner.state', ''),
    get(metadata, 'owner.zipCode', ''),
    get(metadata, 'owner.phoneNumber', ''),
    get(metadata, 'owner.altPhoneNumber', ''),
    get(metadata, 'owner.email', ''),
    get(metadata, 'owner.altEmail', '')
  );

  const salesRep = agreementRecipientDirector.createSalesRep(
    get(metadata, 'salesRep.name', ''),
    get(metadata, 'salesRep.phoneNumber', ''),
    get(metadata, 'salesRep.email', '')
  );

  const date = composeJobCostsPDFAttributeValues(
    get(metadata, 'estimatedDate', null),
    get(metadata, 'actualDate', null)
  );

  const jobNumber = composeJobCostsPDFAttributeValues(
    get(metadata, 'estimatedJobNumber', null),
    get(metadata, 'actualJobNumber', null)
  );

  let estimatedMarkup = get(metadata, 'estimatedMarkup', '');
  let actualMarkup = get(metadata, 'actualMarkup', null);
  actualMarkup = Math.round(actualMarkup);
  estimatedMarkup = addPercentageSymbolToString(estimatedMarkup);
  actualMarkup = addPercentageSymbolToString(actualMarkup);
  const markup = composeJobCostsPDFAttributeValues(
    estimatedMarkup,
    actualMarkup
  );

  let estimatedMargin = get(metadata, 'estimatedNetMargin', null);
  let actualMargin = get(metadata, 'actualNetMargin', null);
  estimatedMargin = percentDecorator(estimatedMargin)
  actualMargin = percentDecorator(actualMargin);
  const netMargin = composeJobCostsPDFAttributeValues(
    estimatedMargin,
    actualMargin
  );

  let estimatedProfit = get(metadata, 'estimatedNetProfit', null);
  let actualProfit = get(metadata, 'actualNetProfit', null);
  const profit = composeJobCostsPDFAttributeValues(
    toUSDCurrency(estimatedProfit),
    toUSDCurrency(actualProfit)
  );

  let estimatedVariance = get(metadata, 'estimatedVariance', null);
  let actualVariance = get(metadata, 'actualVariance', null);
  estimatedVariance = percentDecorator(estimatedVariance);
  actualVariance = percentDecorator(actualVariance);
  const variance = composeJobCostsPDFAttributeValues(
    estimatedVariance,
    actualVariance
  );

  const workOrderSectionArray = [];
  const purchaseOrderSectionArray = [];
  const miscellaneousCostsArray = [];
  const summarySectionArray = [];

  const workOrderSections = get(sections, 'workOrders', []);
  for (let i = 0; i < workOrderSections.length; i++) {
    const section = workOrderSections[i];
    const newSection = composeJobCostsPDFTradeSections(
      section.name,
      section.estimatedTotal,
      section.actualTotal
    );
    workOrderSectionArray.push(newSection);
  }

  const purchaseOrderSections = get(sections, 'purchaseOrders', []);
  for (let i = 0; i < purchaseOrderSections.length; i++) {
    const section = purchaseOrderSections[i];
    const newSection = composeJobCostsPDFTradeSections(
      section.name,
      section.estimatedTotal,
      section.actualTotal
    );
    purchaseOrderSectionArray.push(newSection);
  }

  const miscellaneousCosts = get(sections, 'miscellaneousCosts', []);
  for (let i = 0; i < miscellaneousCosts.length; i++) {
    const cost = miscellaneousCosts[i];
    const newCost = composeMiscellaneousCosts(
      cost.name,
      cost.actualQty,
      cost.actualPrice,
      cost.estimatedTotal,
      cost.actualTotal
    );
    miscellaneousCostsArray.push(newCost);
  }

  summarySections = orderBy(summarySections, ['index']);
  for (let i = 0; i < summarySections.length; i++) {
    const summarySection = summarySections[i];
    const row = composeSummarySections(
      summarySection.name,
      summarySection.estimatedTotal,
      summarySection.actualTotal
    );
    summarySectionArray.push(row);
  }

  let estimatedWOTotal = get(totals, 'workOrderTotals.estimatedTotal');
  estimatedWOTotal = toUSDCurrency(estimatedWOTotal);
  let actualWOTotal = get(totals, 'workOrderTotals.actualTotal');
  actualWOTotal = toUSDCurrency(actualWOTotal);
  const workOrdersTotal: JobCostsAttributeValues = composeJobCostsPDFAttributeValues(
    estimatedWOTotal,
    actualWOTotal
  );

  let estimatedPOTotal = get(totals, 'purchaseOrderTotals.estimatedTotal');
  estimatedPOTotal = toUSDCurrency(estimatedPOTotal);
  let actualPOTotal = get(totals, 'purchaseOrderTotals.actualTotal');
  actualPOTotal = toUSDCurrency(actualPOTotal);
  const purchaseOrdersTotal: JobCostsAttributeValues = composeJobCostsPDFAttributeValues(
    estimatedPOTotal,
    actualPOTotal
  );

  let estimatedMiscTotal = get(
    totals,
    'miscellaneousCostsTotals.estimatedTotal'
  );
  estimatedMiscTotal = toUSDCurrency(estimatedMiscTotal);
  let actualMiscTotal = get(totals, 'miscellaneousCostsTotals.actualTotal');
  actualMiscTotal = toUSDCurrency(actualMiscTotal);
  const miscellaneousCostsTotal: JobCostsAttributeValues = composeJobCostsPDFAttributeValues(
    estimatedMiscTotal,
    actualMiscTotal
  );

  const result: JobCostsPDFContentVM = {
    title: 'JOB COSTS',
    logoUrl: environment.COMPANY_LOGO_DATA_URL,
    company,

    date,
    jobNumber,
    markup,
    netMargin,
    profit,
    variance,

    owner,
    salesRep,

    summary: summarySectionArray,
    workOrders: workOrderSectionArray,
    purchaseOrders: purchaseOrderSectionArray,
    miscellaneousCosts: miscellaneousCostsArray,
    workOrdersTotal,
    purchaseOrdersTotal,
    miscellaneousCostsTotal,

    jobCostsPDFType: JobCostsPDFType.ACTUAL
  };
  return result;
}

export function jobCostsPDFFooterProjector(
  ownerName,
  ownerAddressLine1,
  ownerAddressLine2,
  jobNumber,
  company
) {
  const companyName = get(company, 'name');
  const companyAddressLine1 = get(company, 'addressLine1');
  const companyAddressLine2 = get(company, 'addressLine2');
  return {
    ownerName,
    ownerAddressLine1,
    ownerAddressLine2,
    jobNumber,
    companyName,
    companyAddressLine1,
    companyAddressLine2
  };
}


/**
 * Composes estimated job costs content information
 */
export function estimatedJobCostsPDFContentProjector(
  headerMetadata
) {
  const company = composePDFCompany(environment.COMPANY_INFORMATION);
}



function composeJobCostsPDFAttributeValues(
  estimated,
  actual
): JobCostsAttributeValues {
  return {
    estimated,
    actual
  };
}

function composeJobCostsPDFTradeSections(
  description,
  estimatedTotal,
  actualTotal
): JobCostsSectionTotal {
  estimatedTotal = toUSDCurrency(estimatedTotal);
  actualTotal = toUSDCurrency(actualTotal);
  return {
    description,
    estimatedTotal,
    actualTotal
  };
}

function composeMiscellaneousCosts(
  description,
  actualQty,
  actualPrice,
  estimatedTotal,
  actualTotal
): MiscellaneousCostValues {
  actualPrice = toUSDCurrency(actualPrice);
  estimatedTotal = toUSDCurrency(estimatedTotal);
  actualTotal = toUSDCurrency(actualTotal);
  return {
    description,
    actualQty,
    actualPrice,
    estimatedTotal,
    actualTotal
  };
}

function composeSummarySections(
  description,
  estimatedTotal,
  actualTotal
): SummarySection {
  estimatedTotal = toUSDCurrency(estimatedTotal);
  actualTotal = toUSDCurrency(actualTotal);
  return {
    description,
    estimatedTotal,
    actualTotal
  };
}


export function formatAsPercent(value) {
  try {
    if(!value) return;
    let percent = toFraction(value);
    percent = addPercentageSymbolToString(percent);
    return percent;
  } catch (e) {
    return
  }
}

function addPercentageSymbolToString(value) {
  return value && `${value}%`;
}

function toFraction(value: number) {
  const val = (+value*100)
  if(val % 1 === 0) return val;
  return parseFloat(val.toString()).toFixed(1);
}
