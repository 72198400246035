import { reduce, map, filter } from 'lodash';

export function calculateTradeSectionTotal(sections) {
  let newSections = filter(sections, section => section.total);
  const subtotals = map(newSections, section => section && section.total);
  const subtotal = reduce(
    subtotals,
    (total, subtotal) => {
      return +total + +subtotal;
    },
    0
  );
  return subtotal;
}
