import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'convertString'
})
export class ConvertStringPipe implements PipeTransform {
  transform(value: any, type?: any): any {
    if (!value) return value;
    if (typeof value !== 'string') return value;
    switch (type) {
      case 'upperCase':
        return value.toUpperCase();
      case 'lowerCase':
        return value.toLowerCase();
      case 'capFirstLetter':
        return this.capitalizeFirstLetter(value);
      default:
        return value;
    }
  }

  private capitalizeFirstLetter(value: string) {
    if (!value) return '';
    const words = value.split(' ');
    if (isEmpty(words)) return value;
    let string = '';
    for (let i = 0; i < words.length; i++) {
      if (isEmpty(words[0])) continue;
      const word = words[i];
      string += word[0].toUpperCase() + word.slice(1) + ' ';
    }
    return string && string.trim();
  }
}
