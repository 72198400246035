import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileButtonComponent } from './containers/profile-button.component';
import {OauthSpaFeatureModule} from "@contract-estimator/oauth/spa/feature";
import { ProfileButtonInformationComponent } from './components/profile-button-information.component';

@NgModule({
  imports: [
    CommonModule,
    OauthSpaFeatureModule
  ],
  declarations: [
    ProfileButtonComponent,
    ProfileButtonInformationComponent
  ],
  exports: [
    ProfileButtonComponent
  ]
})
export class ProfileButtonModule {}
