import { Injectable } from '@angular/core';
import {CanLoad} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CanLoadService implements  CanLoad{

  constructor() {
  }
  canLoad(route): boolean {
    return true;
  }
}
