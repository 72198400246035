import {Component, OnInit, Input, Output, OnDestroy} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MegaMenuService } from 'libs/shared/megamenu/data-access/src/lib/providers/mega-menu.service';
import {DailyForecast, MegaMenuItem} from '@contract-estimator/shared/interfaces';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'eliteroofing-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit, OnDestroy {
  @Input() menuItems: Array<MegaMenuItem> = [];
  @Input() menuUrl;
  @Input()  forecast: DailyForecast[];
  @Output() logoutEvent = new Subject();
  menuData$: Observable<any>;
  closeObservables$ = new Subject();

  constructor(public megaMenuService: MegaMenuService) {}

  ngOnInit() {
    this.megaMenuService.setUrls(this.menuUrl);
    this.getMenu();
    this.getMenuCountsAsAnArray();
  }

  // Gets menu data
  getMenu() {
    this.menuData$ = this.megaMenuService.getMenu();
  }


  // Gets menu counts as an array
  getMenuCountsAsAnArray() {
    this.megaMenuService.getMenuCountAsAnArray()
      .pipe(takeUntil(this.closeObservables$))
      .subscribe()
  }

  logout() {
    this.logoutEvent.next();
  }

  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }
}
