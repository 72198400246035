import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { environment } from '@contract-estimator/shared/environments';
import { Subject } from 'rxjs/Subject';
import { CalculatorService } from 'libs/shared/angular/providers/src/lib/calculator/calculator.service';
import * as formInputValidation from 'apps/contract-estimator/src/app/shared/utils/formInput/form-input.validation';
import { StringFormatter } from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';
import { Material } from 'apps/contract-estimator/src/app/shared/models/material.model';
import { PurchaseOrderSection } from 'apps/contract-estimator/src/app/shared/models/purchase-order-section.model';

@Component({
  selector: 'app-purchase-order-estimate-dynamic',
  templateUrl: './purchase-order-estimate-dynamic.component.html',
  styleUrls: ['purchase-order-estimate-dynamic.component.scss']
})
export class PurchaseOrderEstimateDynamicComponent
  implements OnChanges, OnDestroy {
  stringFormatter: StringFormatter = new StringFormatter();
  // props
  @Input() selectableMaterials: Material[];
  @Input() poSection: PurchaseOrderSection;
  @Input() showRemoveSection: boolean = true;
  @Input() rowGroup: boolean = true;
  @Output() setUpdateValuesFlag = new Subject();
  @Output() removeSectionEvent = new Subject();
  // data
  sectionNames = environment.sectionNames;
  selectedMaterialForRow: Material = null; // used for storing value when someone uses inrow dropdown to select a material
  rowGroupMetadata: any;
  // gui state
  displayError: boolean = false;
  errMessage: string = '';
  index: number = 9999;

  @Output() updateSelectedPOSection = new EventEmitter();
  @Output() updateWorkOrderSectionName = new Subject();

  constructor(public calculatorService: CalculatorService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.poEstimate) {
      this.updateRowGroupMetaData();
    }
  }

  // Emits if should or should not update values
  onUpdateValuesChanges(change: boolean) {
    this.setUpdateValuesFlag.next(change);
  }

  handleRowChange(e, poSection: PurchaseOrderSection) {
    this.onUpdateValuesChanges(false);
    // update dirty material data
    this.updateRowNumbers(e.data);

    // update section the row is attached to
    poSection = this.updateSectionTotals(poSection);

    // update section in ngrx
    this.updateSelectedPOSection.emit(poSection);
  }

  // this alters the row object in place. (primeng requires this)
  updateRowNumbers(row) {
    let { qty, price } = row; // editable row data
    // Trim values
    try {
      qty = qty.toString().trim();
      price = price.toString().trim();
    } catch (error) {
      // Error handling here
    }
    const priceSubtotal = this.calculatorService.calculateTotalWithQtyAndPrice(
      qty,
      price
    );
    row.priceSubtotal = priceSubtotal;
    row.qty = qty;
    row.price = price;
  }

  /**
   * this alters a section in place and right now assume there is only one section in this containers
   */
  updateSectionTotals(poSection: PurchaseOrderSection): PurchaseOrderSection {
    const subtotal = this.calculatesSectionSubtotal(poSection);
    poSection.salesTax = this.calculatorService.calculateSectionSalesTax(
      subtotal
    );
    poSection.total = this.calculatorService.calculateSectionTotal(
      subtotal,
      poSection.salesTax
    );
    return poSection;
  }

  // Calculates sections subtotal. Pre-tax total.
  calculatesSectionSubtotal(poSection: PurchaseOrderSection): number {
    // update section locally and in store
    const filteredMaterials = poSection.materials.filter(
      material => material.priceSubtotal && !isNaN(material.priceSubtotal)
    );
    const subtotal = filteredMaterials.reduce(
      (total: number, material: Material) => (total += material.qty * material.price),
      0
    );
    return subtotal;
  }

  // Calculates section tax for container template
  calculateComponentTemplateSectionSalesTax(poSection: PurchaseOrderSection) {
    const subtotal = this.calculatesSectionSubtotal(poSection);
    const salesTax = this.calculatorService.calculateSectionSalesTax(subtotal);
    return salesTax;
  }


  addMaterial(poSection: PurchaseOrderSection) {
    try {
      this.onUpdateValuesChanges(false);
      const nullMaterial = this.addNullMaterialItem();
      const newMaterial = Material.create(nullMaterial);
      poSection.materials = [...poSection.materials, newMaterial];
      this.updateSelectedPOSection.emit(poSection);
    } catch (e) {
      this.displayDialogMessageBox(true, 'Unable to add new line item!');
    }
  }

  removeMaterial(material, poSection: PurchaseOrderSection) {
    try {
      this.onUpdateValuesChanges(false);
      poSection.materials = poSection.materials.filter(
        mat => mat.uniqueId !== material.uniqueId
      );
      poSection = this.updateSectionTotals(poSection);
      this.updateSelectedPOSection.emit(poSection);
    } catch (e) {
      this.displayDialogMessageBox(
        true,
        'Unable to remove row. Please try again.'
      );
    }
  }

  displayDialogMessageBox(visible: boolean = false, message: string = null) {
    this.displayError = visible;
    this.errMessage = message;
  }

  // Adds a null material item to the work order
  addNullMaterialItem() {
    const nullMaterial = Material.create({
      name: '',
      per: '',
      brand: '',
      notes: '',
      color: '',
      group: 'ZZZzzz-Other-index',
      qty: '',
      price: 0,
      priceSubtotal: 0,
      index: this.index++
    });
    return nullMaterial;
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    try {
      this.rowGroupMetadata = {};
      const materials = this.poSection.materials;
      if (materials) {
        for (let i = 0; i < materials.length; i++) {
          let rowData = materials[i];
          let group = rowData.group;
          if (i == 0) {
            this.rowGroupMetadata[group] = { index: 0, size: 1 };
          } else {
            let previousRowData = materials[i - 1];
            let previousRowGroup = previousRowData.group;
            if (group === previousRowGroup) this.rowGroupMetadata[group].size++;
            else this.rowGroupMetadata[group] = { index: i, size: 1 };
          }
        }
      }
    } catch (error) {
      // handle error here
    }
  }

  // Checks if input is valid
  isValidNumericValue(val: any, qtyRequired) {
    if (qtyRequired === true && !+val) return false;
    return formInputValidation.isItAValidQty(val);
  }

  // Checks if input is valid
  isValidCurrencyValue(val: any) {
    return formInputValidation.isItAValidCurrencyValue(val);
  }

  // Checks if input is valid
  mustIgnorePrice(qty, price) {
    if (+qty && !+price) return false;
    return true;
  }

  // Simple currency validation
  isNaN(val: any) {
    return isNaN(val);
  }

  // Trims string for nice display of text
  trimString(string: string, length: number) {
    const newString = this.stringFormatter.trimString(string, length);
    return newString;
  }

  removeSection() {
    this.removeSectionEvent.next(this.poSection._workOrderSectionId);
  }

  updateSectionName() {
    this.onUpdateValuesChanges(false);
    try {
      this.poSection.name = this.poSection.name.toString().toUpperCase();
    } catch (error) {
      // Continue if fails
    }
    this.updateSelectedPOSection.emit(this.poSection);
    this.updateWorkOrderSectionName.next({
      name: this.poSection.name,
      _id: this.poSection._workOrderSectionId
    });
  }

  // Improves angular performance
  trackByFn(index, item: any) {
    return item._id; // or item.id
  }

  ngOnDestroy() {}
}
