import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {environment} from "@contract-estimator/shared/environments";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private titleService: Title,
  ) { }

  setTitle(title: any): void {
    this.titleService.setTitle(title);
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }

  setCompanyTitle(noun) {
    const title = this.composeTitle(noun);
    this.setTitle(title)
  }

  private composeTitle(noun) {
    return "Mobiledash " + noun;
  }
}
