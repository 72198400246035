import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

import { teamMemberEntityConfig } from '@contract-estimator/team-members/platforms/web/team-member-manager/data-access';

const entityMetadata: EntityMetadataMap = {
  ...teamMemberEntityConfig.entityMetadata
};

const pluralNames = {
  ...teamMemberEntityConfig.pluralNames
};

export const entityMetadataConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
