export function calculateProfit(
  totalSales,
  workOrderTotal,
  purchaseOrderTotal,
  miscellaneuousTotal,
  advertisingTotal,
  overheadTotal,
  additionalScopeCosts
) {
  const COGS = +workOrderTotal + +purchaseOrderTotal + +miscellaneuousTotal;
  const overhead = +overheadTotal + +advertisingTotal;
  const profit = +totalSales - (COGS + overhead + +additionalScopeCosts);
  return profit;
}

export function calculateTotalSales(sales: number, changeOrder: number) {
  return +sales + +changeOrder;
}
