import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppState } from 'apps/contract-estimator/src/app/app-state/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, switchMap, mergeMap, tap, catchError } from 'rxjs/operators';
import { Go } from 'libs/shared/router/data-access/src/lib/+state/router.actions';
import { getRouterUrl } from 'libs/shared/router/data-access/src/lib/+state/router.selectors';
import { PrivacyTermsService } from 'libs/privacy-terms/data-access/src/lib/service/privacy-terms.service';
import { UserPrivacyTermsAcceptance } from 'libs/privacy-terms/data-access/src/lib/+state/actions/privacy-terms.index';
import { Logout } from '../../+state/actions/login.actions';
import { of } from 'rxjs/observable/of';

@Injectable({
  providedIn: 'root'
})
export class PrivacyTermsGuardService implements CanActivate {
  constructor(
    public store: Store<AppState>,
    public privacyTermsService: PrivacyTermsService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(getRouterUrl).pipe(
      take(1),
      switchMap((routerUrl: string) => {
        // Ignore if path is /login
        if (
          routerUrl &&
          (routerUrl.includes('login') || routerUrl.includes('privacy-terms'))
        ) {
          return of(true);
        }
        // Gets current user privacy terms status
        return this.privacyTermsService
          .getCurrentUserPrivacyTermAcceptance()
          .pipe(
            tap((response: any) =>
              this.store.dispatch(
                new UserPrivacyTermsAcceptance(response.acceptedPrivacyTerms)
              )
            ),
            mergeMap((response: any) => {
              // Cancel navigation If user hasn't accepted the privacy terms
              if (!response.acceptedPrivacyTerms) {
                this.store.dispatch(new Go({ path: ['/privacy-terms'] }));
                return of(false);
              }
              return of(true);
            }),
            catchError(error => {
              // this.store.dispatch(new Logout());
              // return of(false);
              return of(true);
            })
          );
      })
    );
  }
}
