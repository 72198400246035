import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QciSectionComponent } from './components/qci-section/qci-section.component';
import { QCIHeaderMetadataTableComponent } from './components/qci-header-metadata-table/qci-header-metadata-table.component';
import { QCIAddToPayrollConfirmationComponent } from './components/qci-add-to-payroll-confirmation/qci-add-to-payroll-confirmation.component';
import {
  CalendarModule,
  CheckboxModule,
  RadioButtonModule,
  TableModule
} from 'primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedAngularPipesModule } from '@contract-estimator/shared/angular/pipes';

@NgModule({
  declarations: [
    QciSectionComponent,
    QCIHeaderMetadataTableComponent,
    QCIAddToPayrollConfirmationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    RadioButtonModule,
    CheckboxModule,
    SharedAngularPipesModule
  ],
  exports: [
    CommonModule,
    QciSectionComponent,
    QCIHeaderMetadataTableComponent,
    QCIAddToPayrollConfirmationComponent
  ]
})
export class QciSharedModule {}
