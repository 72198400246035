export const COPY_RIGHT = '&copy; Elite Roofing, LLC 2018';

export const USER_AGREEMENT = [
  'AUTHORIZED USER TERMS OF USE FOR EMPLOYEE/CONTRACTOR',

  `These Authorized User Terms of Use for Employee/Contractor (“<span style="font-weight:bold">Terms of Use</span>”) govern your use of the proprietary customer relationship management  software developed by Elite (the “<span style="font-weight:bold">Software</span>”), including all user manuals, technical manuals, and any other materials provided by Elite, in printed, electronic, or other form, that describe the Software or its use or specifications (the <span style="font-weight:bold">“Documentation”</span>) provided to you (“<span style="font-weight:bold">you</span>” or “<span style="font-weight:bold">your</span>”) by ELITE ROOFING LLC, a Kentucky limited liability company (“<span style="font-weight:bold">Elite</span>”).`,

  `BY CLICKING THE “ACCEPT” BUTTON YOU: (i) REPRESENT THAT YOU ARE AUTHORIZED BY ELITE TO ACCESS AND USE THE SOFTWARE; AND (ii) ACCEPT THESE TERMS OF USE AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT CLICK THE “ACCEPT” BUTTON AND YOU WILL HAVE NO LICENSE TO, AND MUST NOT ACCESS OR USE, THE SOFTWARE.`,

  `<span style="font-weight:bold">License Grant.</span> Subject to your strict compliance with these Terms of Use, Elite hereby grants you a non-exclusive, non-transferable, non-sublicensable, limited license to use the Software in accordance with the Documentation, solely for Elite’s internal business purposes. The foregoing license will terminate immediately on the termination of your employment, whether voluntary or involuntary, if you are an employee of Elite or upon the termination of your contractual relationship with Elite, for whatever reason or no reason at all, if you are an independent contractor working on behalf of Elite.`,

  `<span style="font-weight:bold">Use Restrictions.</span> You shall not, directly or indirectly:`,

  `use the Software or Documentation except as set forth in Section 1;`,

  `copy the Software or Documentation, in whole or in part;`,

  `modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Software or any part thereof;`,

  `combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;`,
  `reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;`,

  `remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices included on or in the Software or Documentation, including any copy thereof;`,

  `rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise provide any access to or use of the Software or any features or functionality of the Software, for any reason, to any other person or entity, including any subcontractor, independent contractor, affiliate, or service provider of licensee, whether or not over a network and whether or not on a hosted basis, including in connection with the internet, web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud, or other technology or service;`,

  `use the Software or Documentation in, or in association with, the design, construction, maintenance, or operation of any hazardous environments or systems, including: (i) power generation systems; (ii) aircraft navigation or communication systems, air traffic control systems, or any other transport management systems; (iii) safety-critical applications, including medical or life-support systems, vehicle operation applications, or any police, fire, or other safety response systems; and (iv) military or aerospace applications, weapons systems, or environments;`,

  `use the Software or Documentation in violation of any law, regulation, or rule; or`,

  `use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service, or any other purpose that is to Elite’s commercial disadvantage.`,

  `<span style="font-weight:bold">Compliance Measures.</span> The Software may contain technological copy protection or other security features designed to prevent unauthorized use of the Software, including features to protect against use of the Software: (i) beyond the scope of the license granted pursuant to Section 1; or (ii) prohibited under Section 2. You shall not, and shall not attempt to, remove, disable, circumvent, or otherwise create or implement any workaround to, any such copy protection or security features.`,

  `<span style="font-weight:bold">Collection and Use of Information.</span>`,

  `Elite may, directly or indirectly through the services of others, collect and store information regarding use of the Software and about equipment on which the Software is installed or through which it otherwise is accessed and used, by means of (i) providing maintenance and support services and (ii) security measures included in the Software as described in Section 3.`,

  `You agree that the Elite may use such information for any purpose related to any use of the Software by you, including but not limited to: (i) improving the performance of the Software or developing updates; and verifying compliance with the terms of this Agreement and enforcing Elite’s rights, including all intellectual property rights in and to the Software.`,

  `<span style="font-weight:bold">Intellectual Property Rights.</span> You acknowledge that the Software is provided under license, and not sold, to you. You do not acquire any ownership interest in the Software under this Agreement, or any other rights to the Software other than to use the Software in accordance with the license granted under this Agreement, subject to all terms, conditions, and restrictions. Elite reserves and shall retain its entire right, title, and interest in and to the Software and all intellectual property rights arising out of or relating to the Software, subject to the license expressly granted to the licensee in this Agreement. You shall use commercially reasonable efforts to safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse, or unauthorized access.`,

  `<span style="font-weight:bold">Disclaimer of Liability.</span> IN NO EVENT WILL ELITE OR ITS AFFILIATES BE LIABLE TO YOU FOR ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE SOFTWARE. YOU ARE PROVIDED THE SOFTWARE SOLELY FOR THE BENEFIT OF ELITE AND AT ELITE’S DISCRETION.`,

  `<span style="font-weight:bold">Governing Law.</span> These Terms of Use are governed by and construed in accordance with the internal laws of the Commonwealth of Kentucky without giving effect to any choice or conflict of law provision or rule (whether of the Commonwealth of Kentucky or any other jurisdiction) that would cause the application of Laws of any jurisdiction other than those of the Commonwealth of Kentucky.`
];

export const CONFIDENTIALITY_AGREEMENT = [
  `CONFIDENTIALITY AND PROPRIETARY RIGHTS AGREEMENT`,

  `This Confidentiality and Proprietary Rights Agreement (this “<span style="font-weight:bold">Agreement</span>”) is between ELITE ROOFING LLC, a Kentucky limited liability company (“<span style="font-weight:bold">Elite</span>”), and you (“<span style="font-weight:bold">you</span>” or “<span style="font-weight:bold">your</span>”), either an employee or independent contractor of Elite, with respect to your use of the proprietary customer relationship management software developed by Elite (the “<span style="font-weight:bold">Software</span>”).`,

  `BY CLICKING THE “ACCEPT” BUTTON YOU: (i) REPRESENT THAT YOU ARE AUTHORIZED BY ELITE TO ACCESS AND USE THE SOFTWARE AS EITHER A CURRENT EMPLOYEE OF ELITE OR A CURRENT INDEPENDENT CONTRACTOR OF ELITE; AND (ii) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY IT. IF YOU DO NOT AGREE TO THIS AGREEMENT, DO NOT CLICK THE “ACCEPT” BUTTON AND YOU WILL HAVE NO LICENSE TO, AND MUST NOT ACCESS OR USE, THE SOFTWARE.`,

  `In consideration of your employment by Elite, or in the case of an independent contractor your contract with Elite, which you acknowledge to be good and valuable consideration for your obligations hereunder, you hereby agree as follows:`,

  `<span style="font-weight:bold">Confidentiality and Security.</span>`,

  `<span style="text-decoration:underline">Confidential Information.</span> You understand and acknowledge that during the course of your employment or contractual relationship with Elite, you will have access to and learn about confidential, secret, and proprietary documents, materials, data, and other information, in tangible and intangible form, of and relating to Elite and its business and existing and prospective customers, suppliers, investors, and other associated third parties (“<span style="font-weight:bold">Confidential Information.</span>”). You further understand and acknowledge that this Confidential Information and Elite’s ability to reserve it for its exclusive knowledge and use is of great competitive importance and commercial value to Elite, and your improper use or disclosure of the Confidential Information will cause irreparable harm to Elite, for which remedies at law will not be adequate and may also cause Elite to incur financial costs and loss of business advantage.`,

  `For purposes of this Agreement, Confidential Information includes, but is not limited to, all information not generally known to the public, in spoken, printed, electronic or any other form or medium, relating directly or indirectly to: business processes, practices, methods, policies, plans, documents, research, operations, services, strategies, techniques, know-how, trade secrets, computer programs, computer software, applications, software design, web design, databases, technologies, financial information, results, accounting information, accounting records, pricing information, reports, sales information, formulae, algorithms, and original works of authorship of Elite, its affiliates, or of any other person or entity that has entrusted information to Elite in confidence. `,

  `You understand that the above list is not exhaustive, and that Confidential Information also includes other information that is marked or otherwise identified as confidential or proprietary, or that would otherwise appear to a reasonable person to be confidential or proprietary in the context and circumstances in which the information is known or used.`,

  `You understand and agree that Confidential Information developed by you in the course of your employment or contract with Elite shall be subject to the terms and conditions of this Agreement as if Elite furnished the same Confidential Information to you in the first instance. Confidential Information shall not include information that is generally available to and known by the public, provided that such disclosure to the public is through no direct or indirect fault of you or person(s) acting on your behalf.`,

  `<span style="text-decoration:underline">Disclosure and Use Restrictions.</span> You agree and covenant:`,

  `to treat all Confidential Information as strictly confidential;`,

  `not to directly or indirectly disclose, publish, communicate, or make available Confidential Information, or allow it to be disclosed, published, communicated, or made available, in whole or part, to any entity or person whatsoever (including other employees of Elite) not having a need to know and authority to know and to use the Confidential Information in connection with Elite’s business and, in any event, not to anyone outside of the direct employ of Elite except as required in the performance of any of your authorized duties to Elite with the prior consent of an authorized officer acting on behalf of Elite in each instance (and then, such disclosure shall be made only within the limits and to the extent of such duties or consent); and`,

  `not to access or use any Confidential Information, and not to copy any documents, records, files, media, or other resources containing any Confidential Information, or remove any such documents, records, files, media, or other resources from the premises or control of Elite, except as required in the performance any of your authorized employment or contractual duties to Elite or with the prior consent of an authorized officer acting on behalf of Elite in each instance (and then, such disclosure shall be made only within the limits and to the extent of such duties or consent).`,

  `<span style="text-decoration:underline">Permitted Disclosures.</span> Nothing in this Agreement shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to the valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of disclosure required by such law, regulation, or order. You shall promptly provide written notice of any such order to an authorized officer of Elite.`,

  `<span style="text-decoration:underline">Duration of Confidentiality Obligations.</span> You understand and acknowledge that your obligations under this Agreement regarding any particular Confidential Information begin immediately and shall continue during and after your employment or contractual relationship with Elite until the Confidential Information has become public knowledge other than as a result of your breach of this Agreement or a breach by those acting in concert with you or on your behalf.`,

  `<span style="font-weight:bold">Proprietary Rights.</span>`,

  `<span style="text-decoration:underline">Work Product.</span> You acknowledge and agree that all writings, works of authorship, technology, inventions, discoveries, ideas and other work product of any nature whatsoever, that are created, prepared, produced, authored, edited, amended, conceived, or reduced to practice by you individually or jointly with others during the period of your employment or contractual relationship with Elite and relating in any way to the business or contemplated business, research, or development of Elite (regardless of when or where the Work Product is prepared or whose equipment or other resources is used in preparing the same) and all printed, physical, and electronic copies, all improvements, rights, and claims related to the foregoing, and other tangible embodiments thereof (collectively, “<span style="font-weight:bold">Work Product</span>”), as well as any and all rights in and to copyrights, trade secrets, trademarks (and related goodwill), patents, and other intellectual property rights therein arising in any jurisdiction throughout the world and all related rights of priority under international conventions with respect thereto, including all pending and future applications and registrations therefor, and continuations, divisions, continuations-in-part, reissues, extensions, and renewals thereof (collectively, “<span style="font-weight:bold">Intellectual Property Rights</span>”), shall be the sole and exclusive property of Elite.`,

  `<span style="text-decoration:underline">Work Made for Hire; Assignment.</span> You acknowledge that, by reason of being employed by Elite at the relevant times, to the extent permitted by law, all of the Work Product consisting of copyrightable subject matter is “work made for hire” as defined in the Copyright Act of 1976 (17 U.S.C. § 101), and such copyrights are therefore owned by Elite. To the extent that the foregoing does not apply, you hereby irrevocably assign to Elite, for no additional consideration, your entire right, title, and interest in and to all Work Product and Intellectual Property Rights therein, including the right to sue, counterclaim, and recover for all past, present, and future infringement, misappropriation, or dilution thereof, and all rights corresponding thereto throughout the world. Nothing contained in this Agreement shall be construed to reduce or limit Elite’s rights, title, or interest in any Work Product or Intellectual Property Rights so as to be less in any respect than that Elite would have had in the absence of this Agreement.`,

  `<span style="text-decoration:underline">Further Assurances; Power of Attorney.</span> During and after your employment or the termination of your contractual relationship with Elite, you agree to reasonably cooperate with Elite to (i) apply for, obtain, perfect, and transfer to Elite the Work Product and Intellectual Property Rights in the Work Product in any jurisdiction in the world; and (ii) maintain, protect, and enforce the same, including, without limitation, executing and delivering to Elite any and all applications, oaths, declarations, affidavits, waivers, assignments, and other documents and instruments as shall be requested by Elite. You hereby irrevocably grant Elite power of attorney to execute and deliver any such documents on your behalf in your name and to do all other lawfully permitted acts to transfer the Work Product to Elite and further the transfer, issuance, prosecution, and maintenance of all Intellectual Property Rights therein, to the full extent permitted by law, if you do not promptly cooperate with Elite’s request (without limiting the rights Elite shall have in such circumstances by operation of law). The power of attorney is coupled with an interest and shall not be impacted by your subsequent incapacity.`,

  `<span style="text-decoration:underline">Moral Rights.</span> To the extent any copyrights are assigned under this Agreement, you hereby irrevocably waive, to the extent permitted by applicable law, any and all claims you may now or hereafter have in any jurisdiction to all rights of paternity, integrity, disclosure, and withdrawal and any other rights that may be known as “moral rights” with respect to all Work Product and all Intellectual Property Rights therein.`,

  `<span style="text-decoration:underline">No License.</span> You understand that this Agreement does not, and shall not be construed to, grant you any license or right of any nature with respect to any Work Product or Intellectual Property Rights or any Confidential Information, materials, software, or other tools made available to you by Elite.`,

  `<span style="font-weight:bold">Security.</span>`,

  `<span style="text-decoration:underline">Security and Access.</span> You agree and covenant (i) to comply with all of Elite’s security policies and procedures as in force from time to time; (ii) not to access or use any of Elite’s information technology resources except as authorized by Elite; and (iii) not to access or use any of Elite’s information technology resources in any manner after the termination of your employment or contractual relationship with Elite, whether such termination is voluntary or involuntary. You agree to notify Elite promptly in the event that you learn of any violation of the foregoing by others, or of any other misappropriation or unauthorized access, use, reproduction or reverse engineering of, or tampering with any of Elite’s information technology resources, property or materials by others.`,

  `<span style="text-decoration:underline">Exit Obligations.</span> Upon (i) voluntary or involuntary termination of your employment or contractual relationship with Elite, or (ii) Elite’s request at any time during your employment or contractual relationship with Elite, you shall (a) provide or return to Elite any and all property, documents and materials belonging to Elite and stored in any fashion, including but not limited to those that constitute or contain any Confidential Information or Work Product, that are in your possession or control, whether they were provided to you by Elite or any of its business associates or created by you in connection with your employment or contractual relationship with Elite; and (b) delete or destroy all copies of any such documents and materials not returned to Elite that remain in your possession or control.`,

  `<span style="font-weight:bold">Acknowledgment.</span> You acknowledge and agree that you will obtain knowledge and skill relevant to Elite’s industry, methods of doing business, and marketing strategies by virtue of your employment or contractual relationship with Elite, and that the terms and conditions of this Agreement are reasonable under these circumstances.`,

  `<span style="font-weight:bold">Remedies.</span> You acknowledge that Elite’s Confidential Information and Elite’s ability to reserve it for its exclusive knowledge and use is of great competitive importance and commercial value to Elite, and that improper use or disclosure of the Confidential Information by you will cause irreparable harm to Elite, for which remedies at law will not be adequate. In the event of a breach or threatened breach by you of any of the provisions of this Agreement, you hereby consent and agree that Elite shall be entitled to seek, in addition to other available remedies, a temporary or permanent injunction or other equitable relief against such breach or threatened breach from any court of competent jurisdiction, without the necessity of showing any actual damages or that monetary damages would not afford an adequate remedy, and without the necessity of posting any bond or other security. The aforementioned equitable relief shall be in addition to, not in lieu of, legal remedies, monetary damages, or other available forms of relief.`,

  `<span style="font-weight:bold">Successors and Assigns.</span> Elite may assign this Agreement, which shall inure to the benefit of Elite’s successors and assigns.`,

  `<span style="font-weight:bold">Governing Law; Jurisdiction and Venue.</span> This Agreement, for all purposes, shall be construed in accordance with the laws of Kentucky without regard to conflicts-of-law principles. Any action or proceeding by either party to enforce this Agreement shall be brought only in any state or federal court located in the Commonwealth of Kentucky, county of Jefferson. You hereby irrevocably submit to the exclusive jurisdiction of such courts and waive the defense of inconvenient forum to the maintenance of any such action or proceeding in such venue.`,

  `<span style="font-weight:bold">Waiver.</span> No waiver by Elite of any breach by you of any condition or provision of this Agreement to be performed by you shall be deemed a waiver of any similar or dissimilar provision or condition at the same or any prior or subsequent time, nor shall the failure of or delay by Elite in exercising any right, power, or privilege hereunder operate as a waiver thereof to preclude any other or further exercise thereof or the exercise of any other such right, power, or privilege.`,

  `<span style="font-weight:bold">Severability.</span> Should any provision of this Agreement be held by a court of competent jurisdiction to be enforceable only if modified, or if any portion of this Agreement shall be held as unenforceable and thus stricken, such holding shall not affect the validity of the remainder of this Agreement, the balance of which shall continue to be binding upon the parties with any such modification to become a part hereof and treated as though originally set forth in this Agreement. The parties further agree that any such court is expressly authorized to modify any such unenforceable provision of this Agreement in lieu of severing such unenforceable provision from this Agreement in its entirety, whether by rewriting the offending provision, deleting any or all of the offending provision, by adding additional language to this Agreement, or by making such other modifications as it deems warranted to carry out the intent and agreement of the parties as embodied herein to the maximum extent permitted by law. The parties expressly agree that this Agreement as so modified by the court shall be binding upon and enforceable against each of them. In any event, should one or more of the provisions of this Agreement be held to be invalid, illegal, or unenforceable in any respect, such invalidity, illegality, or unenforceability shall not affect any other provisions hereof, and if such provision or provisions are not modified as provided above, this Agreement shall be construed as if such invalid, illegal, or unenforceable provisions had not been set forth herein.`,

  `<span style="font-weight:bold">Captions.</span> Captions and headings of the sections and paragraphs of this Agreement are intended solely for convenience and no provision of this Agreement is to be construed by reference to the caption or heading of any section or paragraph.`
];
