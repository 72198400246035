import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, get } from 'lodash';
import * as loginState from '../state/login.state';

import {
  getRouterUrl,
  getNavigationId
} from 'libs/shared/router/data-access/src/lib/+state/router.selectors';
import * as salesRepsSelectors from '../../../../../../sales-reps/data-access/src/lib/+state/selectors/sales-reps.selector';
import * as selectorFunctions from './selector.function';
import {UserRoleType} from "@contract-estimator/shared/enums";

export const getLoginState = createFeatureSelector<loginState.State>('login');

export const isLoggedIn = createSelector(getLoginState, state => {
  if (isEmpty(state)) {
    return null;
  }
  return state.loggedIn;
});

export const getUserRole = createSelector(getLoginState, state => {
  return get(state, 'role', null);
});

export const getUserName = createSelector(getLoginState, state => {
  if (isEmpty(state)) {
    return null;
  }
  return state.name;
});

export const getUserId = createSelector(getLoginState, state => {
  if (isEmpty(state)) {
    return null;
  }
  return state._userId;
});

export const getDefaultSalesRepOption = createSelector(getLoginState, state => {
  if (isEmpty(state)) {
    return null;
  }
  if (state.role === 'sales rep') {
    return state._userId;
  }
  return null;
});

export const getUserPrivacyTermsAcceptance = createSelector(
  getLoginState,
  state => {
    if (isEmpty(state)) {
      return true;
    }
    return state.acceptedPrivacyTerms;
  }
);

export const salesRepCount = createSelector(
  salesRepsSelectors.selectSalesRepAsArray,
  reps => {
    if (isEmpty(reps)) {
      return 0;
    }
    return 1;
  }
);

export const getStoreDataToValidateUser = createSelector(
  getRouterUrl,
  salesRepCount,
  getNavigationId,
  (routerUrl, salesRepCount, navigationId) => {
    return {
      routerUrl,
      salesRepCount,
      navigationId
    };
  }
);

export const getCurrentUserLoggedIn = createSelector(
  getUserId,
  salesRepsSelectors.getSalesRepsIdAndName,
  (_userId: any, salesReps: Array<{ _id: string; name: string }>) => {
    try {
      if (!_userId || isEmpty(salesReps)) {
        return;
      }
      const salesRep: any = selectorFunctions.getCurrentSalesRep(
        salesReps,
        _userId
      );
      if (isEmpty(salesRep)) {
        return;
      }
      return salesRep;
    } catch (error) {
      return null;
    }
  }
);

export const getCurrentLoggedInUserInformation = createSelector(
  getUserId,
  salesRepsSelectors.selectSalesRepAsArray,
  (_userId: any, salesReps: Array<any>) => {
    try {
      if (!_userId || isEmpty(salesReps)) {
        return;
      }
      const salesRep: any = selectorFunctions.getCurrentSalesRep(
        salesReps,
        _userId
      );
      if (isEmpty(salesRep)) {
        return;
      }
      return salesRep;
    } catch (error) {
      return null;
    }
  }
);

export const userHasPrivileges = createSelector(getLoginState, state => {
  if (isEmpty(state)) {
    return null;
  }
  switch (state.role) {
    case 'admin':
      return true;
    default:
      return false;
  }
});

export const selectAlertState = createSelector(getLoginState, state => {
  const type = get(state, 'ui.alert.type', null);
  const message = get(state, 'ui.alert.message', null);
  return {
    type,
    message
  };
});




