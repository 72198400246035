import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'contractor-lead-history-ledger',
  templateUrl: './lead-history-ledger.component.html',
  styleUrls: ['./lead-history-ledger.component.scss']
})
export class LeadHistoryLedgerComponent implements OnInit {
  @Input() daysWithSalesRep;
  @Input() daysSinceFirstSent;
  @Input() daysSinceSent;
  @Input() viewedContractEnvelope;
  @Input() daysSinceCustomerViewedEnvelope;

  constructor() {}

  ngOnInit(): void {}

  showBadge(days) {
    if((!days && days !== 0) ||  isNaN(days)) return false;
    return days >= 0
  }

  composeBadgeMessage(days) {
    if(days === 0) {
      return 'Today'
    } else if(days === 1) {
      return `1 day ago`;
    } else {
      return `${days} days ago`
    }
  }
}
