import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as MegaMenuActions from './mega-menu.actions';
import { MegaMenuEntity } from './mega-menu.models';

export const MEGAMENU_FEATURE_KEY = 'megaMenu';

export interface State extends EntityState<MegaMenuEntity> {
  selectedId?: string | number; // which MegaMenu record has been selected
  loaded: boolean; // has the MegaMenu list been loaded
  error?: string | null; // last none error (if any)
  enabledFeatures: string[]
}

export interface MegaMenuPartialState {
  readonly [MEGAMENU_FEATURE_KEY]: State;
}

export const megaMenuAdapter: EntityAdapter<MegaMenuEntity> = createEntityAdapter<
  MegaMenuEntity
>({
  selectId: (entity) => entity.key
});

export const initialState: State = megaMenuAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  enabledFeatures: []
});

const megaMenuReducer = createReducer(
  initialState,
  on(MegaMenuActions.loadMegaMenu, state => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(MegaMenuActions.loadMegaMenuSuccess, (state, { megaMenu }) =>
    megaMenuAdapter.addAll(megaMenu, { ...state, loaded: true })
  ),
  on(MegaMenuActions.loadMegaMenuFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(MegaMenuActions.loadMegaMenuEnabledFeatures, (state,{enabledFeatures}) => ({
    ...state,
    enabledFeatures
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return megaMenuReducer(state, action);
}
