import {MenuItem} from "primeng/api";
import {MenuPayload} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {TradeMenu} from "../shared/trade-menu.class";
import {TradeComponentMenuItem} from "../shared/trade-component-menu-item.class";
import {QueryParamsMenu} from "../shared/query-params-menu.class";

export class WarrantyMenu implements MenuItem{
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded: boolean
  ) {}

  static from (payload: MenuPayload, callback: any, expanded: boolean) {
    const tradeMenus = TradeMenu.fromWarrantyArray(payload, callback);
    const addClaimMenuItem = new TradeComponentMenuItem(
      'ADD CLAIM',
      ['warranty'],
      new QueryParamsMenu(null, true)
    )
    const items = [
      ...tradeMenus,
      addClaimMenuItem
    ]
    return new WarrantyMenu(
      "WARRANTY",
      [
        ...items
      ],
      expanded
    )
  }
}

