import * as numeral from 'numeral';

export function toUSDCurrency(value: any) {
  return numeral(value).format('$0,0.00');
}


export  function percentDecorator(value) {
  return numeral(value).format('0.00%')
}
