import { Action } from '@ngrx/store';
import { SalesRepState } from '../states/sales-rep.state';

export const GET_SALES_REPS = 'SALES REPS > GET_SALES_REPS';
export class GetSalesReps implements Action {
  readonly type = GET_SALES_REPS;

  constructor() {}
}

export const SET_SALES_REPS = 'SALES REPS > SET_SALES_REPS';
export class SetSalesReps implements Action {
  readonly type = SET_SALES_REPS;

  constructor(public payload: Array<SalesRepState>) {}
}

export const SET_SALES_REPS_FETCH_ERROR =
  'SALES REPS > SET_SALES_REPS_FETCH_ERROR';
export class PipelineSetSalesRepsFetchError implements Action {
  readonly type = SET_SALES_REPS_FETCH_ERROR;

  constructor(public fetchError: any) {}
}

export const SET_SALES_REPS_BASED_ON_ROLE =
  'SALES REPS > SET_SALES_REPS_BASED_ON_ROLE';
export class SetSalesRepsBasedOnRole implements Action {
  readonly type = SET_SALES_REPS_BASED_ON_ROLE;

  constructor(public user: any) {}
}

export const UPDATE_PIPELINE_MENU_REQUEST_STATUS =
  'UPDATE_PIPELINE_MENU_REQUEST_STATUS';
export class UpdatePipelineMenuRequestStatus {
  readonly type = UPDATE_PIPELINE_MENU_REQUEST_STATUS;
  constructor(public status: boolean) {}
}

export type Actions =
  | GetSalesReps
  | SetSalesReps
  | PipelineSetSalesRepsFetchError
  | SetSalesRepsBasedOnRole
  | UpdatePipelineMenuRequestStatus;
