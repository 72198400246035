import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss']
})
export class PaymentTableComponent implements OnInit {
  @Input() payments: Array<any> = [];
  @Input() maxValues: Array<any> = [];
  @Input() totalRow: any;
  @Output() sortTableEvent = new Subject();

  constructor() {}

  ngOnInit() {}

  // Gets max value css class
  getMaxValueCSSClass(value, fieldName) {
    try {
      if (value === 'TOTAL') return null;
      if (value === this.maxValues[fieldName] && value != 0) {
        return 'max-value';
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  onSortingTable(event) {
    this.sortTableEvent.next(event);
  }
}
