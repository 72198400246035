import { isEmpty, sortBy } from 'lodash';

// Gets all keys in array
export function getAllKeysInArray(collection) {
  if (isEmpty(collection)) return [];
  let list = [];
  for (let i = 0; i < collection.length; i++) {
    if (isEmpty(collection[i])) continue;
    const keys = Object.keys(collection[i]);
    if (!keys) continue;
    list = [...list, ...keys];
  }
  list = list.reduce(
    (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
    []
  );
  return list;
}

// Composes key value table column values
export function composeKeyValueTableColumns(keys: Array<string>) {
  if (isEmpty(keys)) return [];
  const list = [];
  for (let i = 0; i < keys.length; i++) {
    if (isEmpty(keys[i]) || keys[i] === '_id') continue;
    const item = {
      field: keys[i],
      header: keys[i] === 'name' ? 'SALES REP' : keys[i]
    };
    if (keys[i] === 'name') {
      list.unshift(item);
    } else {
      list.push(item);
    }
  }
  return list;
}

// Composes key value table column values
export function composeTableHeader(keys: Array<string>) {
  if (isEmpty(keys))
    return [
      {
        field: '',
        header: 'CONTRACT TYPE'
      },
      {
        field: '',
        header: 'OWNER'
      },
      {
        field: '',
        header: 'SALES REP'
      }
    ];
  const list = [];
  for (let i = 0; i < keys.length; i++) {
    if (isEmpty(keys[i])) continue;
    const item = {
      field: keys[i],
      header: splitAndCapitalizeAllCharacters(keys[i])
    };
    if (keys[i] === 'name') {
      list.unshift(item);
    } else {
      list.push(item);
    }
  }
  return list;
}

// Remove values from array
export function removeFromArray(collection, value) {
  if (isEmpty(collection)) return [];
  const list = [];
  for (let i = 0; i < collection.length; i++) {
    if (isEmpty(collection[i])) continue;
    if (collection[i] === value) continue;
    list.push(collection[i]);
  }
  return list;
}

// Checks if id is in object array
export function isElementInObjectArray(list, _id) {
  if (isEmpty(list) || !_id) return false;
  for (let i = 0; i < list.length; i++) {
    if (isEmpty(list[i])) continue;
    if (list[i]._id === _id) return true;
  }
  return false;
}

// Returns the formatted name for the form control name
export function splitAndCapitalizeFirstCharacters(value: string) {
  try {
    // Splits and capitilizes first character
    return value
      .match(/[A-Z]*[^A-Z]+/g)
      .map((word, index) =>
        word
          .toLowerCase()
          .split(' ')
          .map(function(word) {
            return word[0].toUpperCase() + word.substr(1);
          })
          .join(' ')
      )
      .join(' ');
  } catch (error) {
    return value;
  }
}

// Returns the formatted name for the form control name
export function splitAndCapitalizeAllCharacters(value: string) {
  try {
    // Splits and capitilizes first character
    return value
      .match(/[A-Z]*[^A-Z]+/g)
      .map((word, index) =>
        word
          .toLowerCase()
          .split(' ')
          .map(function(word) {
            return word.toUpperCase();
          })
          .join(' ')
      )
      .join(' ');
  } catch (error) {
    return value;
  }
}

// Gets max value css class
export function getMaxValueCSSClass(rowDataValue, fieldName, salesRepName?) {
  try {
    if (salesRepName === 'Company') return null;
    if (!this.highlightMaxCells) return null;
    if (rowDataValue === this.maxValues[fieldName] && rowDataValue != 0) {
      return 'max-value';
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

// Sets new item index in array
export function moveItemToXIndex(array, label, index) {
  if (isEmpty(array)) return [];
  for (let i = 0; i < array.length; i++) {
    if (isEmpty(array[i])) continue;
    if (array[i] === label) {
      array.splice(i, 1);
      break;
    }
  }
  array.splice(index, 0, label);
  return array;
}
