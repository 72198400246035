import { MaterialOption } from '../../../../interfaces/src/lib/interfaces';

export const MATERIAL_OPTIONS: Array<MaterialOption> = [
  {
    label: '',
    value: ''
  },
  {
    label: 'Delivered',
    value: 'Delivered'
  },
  {
    label: 'Pick-up',
    value: 'Pick-up'
  }
];

export const WARRANTY_OPTIONS: Array<MaterialOption> = [
  {
    label: '',
    value: ''
  },
  {
    label: '1 Year',
    value: '1 Year'
  },
  {
    label: '2 Year',
    value: '2 Year'
  },
  {
    label: '5 Year',
    value: '5 Year'
  }
];

export const TERM_OPTIONS: Array<MaterialOption> = [
  {
    label: '',
    value: ''
  },
  {
    label: '30 Day',
    value: '30 Day'
  },
  {
    label: '60 Day',
    value: '60 Day'
  },
  {
    label: '90 Day',
    value: '90 Day'
  }
];
