import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-menu-item',
  templateUrl: './child-menu.component.html',
  styleUrls: ['./child-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChildMenuComponent implements OnInit {
  @Input() items;
  @ViewChild('childMenu', { static: true }) public childMenu;

  constructor() {}

  ngOnInit() {}

  getMenuClass(item) {
    if (isEmpty(item) || isEmpty(item, 'items')) return 'column-item';
    return 'grid-item';
  }
}
