import * as options from './variables/payroll.vars';
import {
  roofInstallQCILineItems,
  gutterInstallQCILineItems,
  defaultQCI
} from './variables/qci.vars';
import COMPANY_LOGO_DATA_URL from '../../../../../apps/contract-estimator/src/assets/company/company-logo-data-url';
import * as contractVars from './variables/contract.vars';
import * as companyVars from './variables/company.vars';
import * as estimateVars from './variables/estimate.vars';
import * as invoiceVars from './variables/invoice.vars';
import * as paymentsVars from './variables/payments.vars';
import * as payrollVars from './variables/payroll.vars';
import * as qciVars from './variables/qci.vars';
import * as schedulerVars from './variables/scheduler.vars';
import * as agreementVars from './variables/agreements.vars';
import * as totalSummaryVars from './variables/total-summary.vars';

export const GLOBAL_ENv_VARS = {
  /* LOGO */

  COMPANY_LOGO_DATA_URL,
  SHARED_COMPANY_LOGO_URL: 'https://s3-us-west-2.amazonaws.com/estimate-3/app-images/',

  /* QCI */
  ROOF_INSTALL_QCI: roofInstallQCILineItems,
  GUTTER_INSTALL_QCI: gutterInstallQCILineItems,
  DEFAULT_QCI: defaultQCI,
  PAY_TYPE_OPTIONS: options.PAYROLL_PAY_TYPE_OPTIONS,
  TRADE_OPTIONS: options.PAYROLL_TRADE_OPTIONS,

  /* COMMISSIONS */
  SALES_REP_COMMISSION_PERCENTAGE: 0.5,
  MANAGER_COMMISSION_PERCENTAGE: 0.2,
  EDITABLE_MARKUP_COMMISSIONS: ['Sales', 'Sales Rep Commission'],

  EDITABLE_PAYOUT_COMMISSIONS: ['Original Scope Sales'],

  SALES_REPS_SMALL_PHOTOS_URL:
    'https://estimate-3.s3-us-west-2.amazonaws.com/sales_reps/small/',
  SALES_REP_PHOTO_URL:
    'https://estimate-3.s3-us-west-2.amazonaws.com/sales_reps/',
  leadsColumns: [
    { field: 'name', header: 'SALES REP' },
    { field: 'opportunities', header: 'TOTAL' },
    { field: 'bySalesRep', header: 'SALES REP' },
    { field: 'salesRepLeadsPercent', header: '% OF SALES REP' },
    { field: 'byOffice', header: 'OFFICE' },
    { field: 'officeLeadsPercent', header: '% OF OFFICE' },
    { field: 'insuranceCount', header: 'INSURANCE' },
    { field: 'insurancePercent', header: '% OF INSURANCE' },
    { field: 'selfPayCount', header: 'SELF PAY' },
    { field: 'selfPayPercent', header: '% OF SELF PAY' }
  ],

  closingRatioColumns: [
    { field: 'name', header: 'SALES REP' },
    { field: 'closingRatio', header: 'TOTAL' },
    { field: 'bySalesRepClosingRatio', header: 'SALES REP LEADS' },
    { field: 'byOfficeClosingRatio', header: 'OFFICE LEADS' },
    { field: 'maleClosingRatio', header: 'MALES' },
    { field: 'femaleClosingRatio', header: 'FEMALES' },
    { field: 'businessClosingRatio', header: 'BUSINESSES' },
    { field: 'insuranceClosingRatio', header: 'INSURANCE' },
    { field: 'selfPayClosingRatio', header: 'SELF PAY' },
  ],

  tradeTypesColumns: [
    { field: '_id', header: 'TRADE' },
    { field: 'total', header: 'TOTAL' },
    { field: 'jobPercent', header: 'JOB PERCENT' },
    { field: 'sold', header: 'SOLD' },
    { field: 'soldPercent', header: 'SOLD PERCENT' },
    { field: 'closingRatio', header: 'CLOSING RATIO' }
  ],

  yearlyClosingRatio: [
    { field: 'year', header: 'YEAR' },
    { field: 'closingRatio', header: 'CLOSING RATIO' }
  ],

  yearlyLeadsAndReferrals: [
    { field: 'year', header: 'YEAR' },
    { field: 'leads', header: 'LEADS' },
    { field: 'referrals', header: 'REFERRALS' }
  ],

  monthlyLeadStatusColumns: [
    { field: 'value', header: 'DATE' },
    { field: 'inProgress', header: 'IN PROGRESS' },
    { field: 'sold', header: 'SOLD' },
    { field: 'didNotBuy', header: 'DIDN\'T BUY' }
  ],

  monthlyOpportunitiesColumns: [
    { field: 'value', header: 'DATE' },
    { field: 'leads', header: 'LEADS' },
    { field: 'referrals', header: 'REFERRALS' }
  ],

  tradeTypeClosingRatioColumns: [
    { field: '_id', header: 'TRADE' },
    { field: 'total', header: 'TOTAL' },
    { field: 'sold', header: 'SOLD' },
    { field: 'soldPercent', header: 'SOLD PERCENT' },
    { field: 'jobPercent', header: 'JOB PERCENT' }
  ],

  closingRatioByYearDataColumns: [
    { field: 'year', header: 'YEAR' },
    { field: 'closingRatio', header: 'CLOSING RATIO' }
  ],

  leadsReferralsByYearDataColumns: [
    { field: 'year', header: 'YEAR' },
    { field: 'leads', header: 'LEADS' },
    { field: 'referrals', header: 'REFERRALS' }
  ],

  inProgressSoldDidNotBuyDataColumns: [
    { field: 'date', header: 'DATE' },
    { field: 'inProgress', header: 'IN PROGRESS' },
    { field: 'sold', header: 'SOLD' },
    { field: 'didNotBuy', header: 'DID NOT BUY' }
  ],

  leadsReferralsByMonthColumns: [
    { field: 'date', header: 'DATE' },
    { field: 'leads', header: 'LEADS' },
    { field: 'sold', header: 'REFERRALS' },
    { field: 'closingRatio', header: 'CLOSING RATIO' }
  ],

  salesColumns: [
    { field: 'name', header: 'SALES REP' },
    { field: 'jobsSold', header: 'JOBS SOLD' },
    { field: 'totalSales', header: 'TOTAL SOLD' },
    { field: 'salesRepSales', header: "SALES REP SALES" },
    { field: 'officeSales', header: 'OFFICE SALES' },
    { field: 'salesRepCommissionEarned', header: 'SALES REP COMMISSION EARNED' },
    // { field: 'netProfitMargin', header: 'NET PROFIT MARGIN' },
    { field: 'netProfitEarned', header: 'NET PROFIT EARNED' }
  ],

  totalDidNotBuySalesColumns: [
    { field: 'name', header: 'SALES REP' },
    { field: 'jobsDidNotBuy', header: "JOBS DIDN'T BUY" },
    { field: 'totalDidNotBuy', header: "TOTAL DIDN'T BUY" },
    { field: 'salesRepSales', header: "SALES REP SALES" },
    { field: 'officeSales', header: 'OFFICE SALES' },
    { field: 'salesRepCommissionLost', header: 'SALES REP COMMISSION LOST' },
    { field: 'netProfitMargin', header: 'NET PROFIT MARGIN' },
    { field: 'netProfitLost', header: 'NET PROFIT LOST' }
  ],

  insuranceVsSelfPayColumns: [
    { field: 'name', header: 'SALES REP' },
    { field: 'insurancePercent', header: 'INSURANCE' },
    { field: 'selfPayPercent', header: 'SELF PAY' }
  ],

  JOB_COSTS_STORE_LIST: [
    'ABC Supply',
    'SRS Distribution',
    'Beacon',
    'Home Depot',
    'Lowe’s',
    'Other',
    ''
  ],
  FILE_STORAGE_URL: 'https://s3-us-west-2.amazonaws.com/estimate-3/',
  AWS_S3_JOB_MEDIA_BUCKET_URL: "https://d1uxdb7sk516dp.cloudfront.net",
  AWS_S3_JOB_MEDIA_BUCKET: "prod-mobiledash-job-media",
  JOB_PHOTO_CLOUDFRONT_URL: 'https://d1uxdb7sk516dp.cloudfront.net/',
  ROTATE_PICTURE_LAMBDA_URL: 'https://27e080xyke.execute-api.us-west-2.amazonaws.com/default/rotate?key=',
  // More env vars
  ...contractVars,
  ...companyVars,
  ...estimateVars,
  ...invoiceVars,
  ...paymentsVars,
  ...payrollVars,
  ...qciVars,
  ...schedulerVars,
  ...agreementVars,
  ...totalSummaryVars
};
