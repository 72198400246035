import { FormControl } from '@angular/forms';
import { isEmpty } from 'lodash';

// Phone number validators xxx-xxx-xxxx
export function isPhoneNumberFormatValid(c: FormControl) {
  if (c.value) {
    let PHONE_REGEX = /^(([0-9]{3}){1}\-([0-9]{3}){1}\-([0-9]{4}){1}){1}$/g;
    if (!PHONE_REGEX.test(c.value)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }
  return null;
}

export function isEmailArrayValid(c: FormControl) {
  try {
    if (isEmpty(c.value)) throw new Error('It cannot be empty');
    const result = validateEmailArray(c);
    return result;
  } catch (error) {
    return { invalidEmail: true };
  }
}

export function isEmailArrayValidAllowEmptyArray(c: FormControl) {
  try {
    const result = validateEmailArray(c);
    return result;
  } catch (error) {
    return { invalidEmail: true };
  }
}

export function validateEmailArray(c: FormControl) {
  // Validates string first
  if (emailValidator({ value: c.value }) === null) return null;
  // Validates arrays
  for (let i = 0; i < c.value.length; i++) {
    const item = c.value[i];
    if (isEmpty(item)) throw new Error('Item cannot be empty');
    const validation = emailValidator({ value: item });
    if (!isEmpty(validation)) throw new Error('Email is invalid');
  }
  return null;
}

// Email validator
export function emailValidator(c: FormControl | { value: any }) {
  if (c.value) {
    var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEXP.test(c.value)) {
      return { invalidEmail: true };
    }
  }
  return null;
}



/**
 * Only alphanumeric and underscore chars
 */
export function onlyAlphanumericAndUnderscoreChars(value: any) {
  if(value) {
    const OWNER_NAME_VALID_CHARACTERS = /^[\w\s-]+$/gi
    return OWNER_NAME_VALID_CHARACTERS.test(value)
  }
  return null;
}

/**
 * Owner name valid characters in only
 */
export function newLeadOwnerNameValidator(c: FormControl) {
  if(c.value) {
    const OWNER_NAME_INVALID_CHARACTERS = /[,:;]/gi
    const hasInvalidChars = OWNER_NAME_INVALID_CHARACTERS.test(c.value);
    if(hasInvalidChars) {
      return {invalidOwnerNameChars: true}
    }
  }
  return null;
}
