import { Component, OnInit, Input } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { get } from 'lodash';

@Component({
  selector: 'did-not-buy-table',
  templateUrl: './did-not-buy-table.component.html',
  styleUrls: ['./did-not-buy-table.component.scss']
})
export class DidNotBuyTableComponent implements OnInit {
  @Input() didNotBuyData: Array<any> = [];
  @Input() tableColumns: Array<any> = [];
  @Input() totalRow: any;
  @Input() maxValues: any;
  @Input() highlightMaxCells: boolean;

  constructor() {}

  ngOnInit() {}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const rep = get(event, 'field.rep');
      const type = get(event, 'field.type');
      if (!rep || !type) return;
      let value1 = data1[rep][type];
      let value2 = data2[rep][type];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  // Gets max value css class
  getMaxValueCSSClass(rowDataValue, fieldName, columnType, salesRepName) {
    try {
      if (salesRepName === 'Company') return null;
      if (!this.highlightMaxCells) return null;
      if (
        rowDataValue === this.maxValues[fieldName][columnType] &&
        rowDataValue != 0
      ) {
        return 'max-value';
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }


  getPercentValue(value) {
    if(value) {
      return value / 100;
    } else {
      return 0;
    }
  }
}
