import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { isEmpty } from 'lodash';
import { StoreState } from '../+state/reducers/stores.reducer';
import { map } from 'rxjs/operators';

@Injectable()
export class StoresService {
  //Variables
  serverUrl: string;
  data: any;

  constructor(public http: HttpClient) {
    this.serverUrl = new ServerUrl().getUrl();
  }

  /* HTTPs */

  getStores() {
    return this.http.get(this.serverUrl + '/stores');
  }

  /* Business Logic */

  getAndComposeStores() {
    return this.getStores().pipe(
      map((stores: any) => this.composeStores(stores))
    );
  }

  // Composes sales rep array from the response
  composeStores(stores: Array<any>): Array<StoreState> {
    if (isEmpty(stores)) return [];
    const list = [];
    for (let i = 0; i < stores.length; i++) {
      if (isEmpty(stores[i])) continue;
      const sub: StoreState = this.composeStore(stores[i]);
      if (isEmpty(sub)) continue;
      list.push(sub);
    }
    return list;
  }

  // Composes single sales rep
  composeStore(stores: any): StoreState {
    if (isEmpty(stores)) return null;
    return {
      _id: stores._id,
      name: stores.name,
      email: stores.email,
      altEmail: stores.altEmail,
      phoneNumber: stores.phoneNumber,
      addressLine1: stores.addressLine1,
      addressLine2: stores.addressLine2
    };
  }
}
