import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {BrowserDetectorBannerComponent} from "./components/browser-detector-banner/browser-detector-banner.component";

@NgModule({
  declarations: [
    PageNotFoundComponent,
    BrowserDetectorBannerComponent
  ],
  imports: [CommonModule],
  exports: [
    CommonModule,
    PageNotFoundComponent,
    BrowserDetectorBannerComponent
  ]
})
export class RoutingSharedModule {}
