import { reduce } from 'lodash';

export function calculateMiscellaneousCostTotal(costs) {
  const subtotal = reduce(
    costs,
    (total, cost) => total + +cost.price * +cost.qty,
    0
  );
  return subtotal;
}
