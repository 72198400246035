import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { SalesRepsEffects } from './+state/effects/sales-reps.effects';
import { StoreModule } from '@ngrx/store';
import * as fromSalesReps from './+state/reducers/sales-reps.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('salesReps', fromSalesReps.reducer),
    EffectsModule.forFeature([SalesRepsEffects])
  ],
  declarations: []
})
export class SalesRepsDataAccessModule {}
