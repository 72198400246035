import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceDirective } from './debounce-input/debounce-input.directive';
import { TrimmerDirective } from './trimmer/trimmer.directive';
import { AutoCompletePlacesDirective } from './places-autocomplete/autocomplete-places.directive';

@NgModule({
  declarations: [
    DebounceDirective,
    TrimmerDirective,
    AutoCompletePlacesDirective
  ],
  imports: [CommonModule],
  exports: [DebounceDirective, TrimmerDirective, AutoCompletePlacesDirective]
})
export class SharedAngularDirectivesModule {}
