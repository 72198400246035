import { createSelector } from '@ngrx/store';
import { getUserRole } from '../../../../../../../libs/login/data-access/src/lib/+state/selectors/login.selectors';
import { menu as MenuFunctions } from '@eliteroofing/shared-functions';

export const selectPipelineMenu = createSelector(
  getUserRole,
  (userRole: string) => {
    try {
      const pipeLineMenu = MenuFunctions.tabs.composePipelineMenu(userRole);
      return pipeLineMenu;
    } catch (error) {
      return [];
    }
  }
);

export const selectEstimatesMenu = createSelector(
  getUserRole,
  (role: string) => {
    const menu = MenuFunctions.tabs.composeEstimatesMenu(role);
    return menu;
  }
);

export const selectSchedulerMenu = createSelector(
  getUserRole,
  (role: string) => {
    const menu = MenuFunctions.tabs.composeSchedulerMenu(role);
    return menu;
  }
);

export const selectInvoicesMenu = createSelector(
  getUserRole,
  (role: string) => {
    const menu = MenuFunctions.tabs.composeInvoicesMenu(role);
    return menu;
  }
);

export const selectProductsMenu = createSelector(
  getUserRole,
  (role: string) => {
    const menu = MenuFunctions.tabs.composeProductsMenu(role);
    return menu;
  }
);

export const selectAdminMenu = createSelector(getUserRole, (role: string) => {
  const menu = MenuFunctions.tabs.composeAdminMenu(role);
  return menu;
});

export const getMainNavMenuElements = createSelector(
  selectPipelineMenu,
  selectEstimatesMenu,
  selectSchedulerMenu,
  selectInvoicesMenu,
  selectProductsMenu,
  selectAdminMenu,
  (
    pipelineMenu,
    estimatesMenu,
    schedulerMenu,
    recivablesMenu,
    productsMenu,
    adminMenu
  ) => {
    try {
      // Returns app menu
      return [
        pipelineMenu,
        estimatesMenu,
        schedulerMenu,
        recivablesMenu,
        productsMenu,
        adminMenu
      ];
    } catch (error) {
      return [];
    }
  }
);
