import { isEmpty, get } from 'lodash';
import { Inject, Injectable } from '@angular/core';
import {WINDOW_TOKEN} from "@contract-estimator/shared/angular/injection-tokens";
import {BrowserDetector} from "@contract-estimator/shared/interfaces";



@Injectable()
export class BrowserDetectorService {

  constructor(@Inject(WINDOW_TOKEN) private browserDetectorToken: BrowserDetector) {
  }
  isChrome(): boolean {
    try {
      return this.isChromium();
    } catch(e) {
      return false;
    }
  }

  isChromium() {
    const chrome = get(this.browserDetectorToken, 'chrome');
    if(isEmpty(chrome)) {
      return false;
    } else {
      return true;
    }
  }
}
