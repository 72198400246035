import { Component, Input, OnInit } from '@angular/core';
import {get} from 'lodash';

@Component({
  selector: 'company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {
  // Variables
  cityStateAndZipCode: string;
  @Input() company: any;
  @Input() showLogo: boolean = false;
  @Input() serverUrl: string = '';
  faxNumber: string = '';
  taxIDNumber: string = '';
  logoServerUrl: string = '';
  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    // Composing the city and state label to show it in the HTML
    if (this.company) {
      if (this.company.address) {
        this.cityStateAndZipCode = this.concatanateCityStateAndZipCode(
          this.company.address.city,
          this.company.address.state,
          this.company.address.zipCode
        );
      } else {
        this.cityStateAndZipCode = get(this.company, 'addressLine2', "") || "";
      }

      if (this.company.faxNumber) {
        this.faxNumber = this.company.faxNumber;
      }

      if (this.company.taxIDNumber) {
        this.taxIDNumber = this.company.taxIDNumber;
      }
      // Composing logo server url
      this.composeLogoServerUrl();
    }
  }

  // Composes the logo server url
  composeLogoServerUrl() {
    this.logoServerUrl = this.serverUrl;
  }

  getCompanyAddressLine1() {
    const addressLine1 = get(this.company, 'addressLine1') || get(this.company,'address.street');
    return addressLine1;
  }

  getCompanyAddressLine2() {
    return this.cityStateAndZipCode;
  }

  concatanateCityStateAndZipCode(city: string, state: string, zipCode: string) {
    return city + ', ' + state + ' ' + zipCode;
  }

  getCompanyLogoUrl() {
    return get(this.company, 'logoUrl') || this.logoServerUrl;
  }
}
