import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityDefinitionService } from '@ngrx/data';

import { teamMemberEntityConfig } from './entity-collection-service/team-member-entity-metadata';
import { ApolloTeamMemberManagerService } from './providers/apollo-team-member-manager.service';
import { TeamMemberEntityCollectionService } from './entity-collection-service/team-member-entity-collection.service';

@NgModule({
  imports: [CommonModule],
  providers: [ApolloTeamMemberManagerService, TeamMemberEntityCollectionService]
})
export class TeamMemberManagerDataAccessModule {
  constructor(private entityDefinitionService: EntityDefinitionService) {
    entityDefinitionService.registerMetadataMap(
      teamMemberEntityConfig.entityMetadata
    );
  }
}
