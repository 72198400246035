import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as formValidators from 'libs/shared/angular/utils/src/lib/validators/form.validators';
import { get, isEmpty } from 'lodash';
import { Entity } from 'apps/contract-estimator/src/app/shared/models/entity.model';
import { switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import { SubcontractorOption } from 'libs/shared/interfaces/src/lib/interfaces';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { StorePurchaseOrderListItem } from 'libs/estimate/data-access/src/lib/+state/reducers/estimate-template-loader.state';
import {
  AddressUtils
} from "../../../../../../../../../apps/contract-estimator/src/app/shared/utils/address/address.utils";
import {composeAddressLine2} from "../../../../../../../utilities/src/lib/address/address";

@Component({
  selector: 'app-job-metadata-table',
  templateUrl: './job-metadata-table.component.html',
  styleUrls: ['./job-metadata-table.component.scss']
})
export class JobMetadataTableComponent implements OnInit, OnChanges, OnDestroy {
  // the formController
  @Input() ownerForm: FormGroup = this.createOwnerForm();

  storeValue: any = '';
  // The list of the store/subcontractor$ and the salesRep$
  @Input() storeList = []; // use Array<Entity>
  @Input() frequentSubcontractors: Array<SubcontractorOption> = []; // use Array<Entity>
  @Input() infrequentSubcontractors: Array<SubcontractorOption> = []; // use Array<Entity>
  @Input() salesReps: Array<any> = null; // use Array<Entity>
  // The variable that control the select option for store/subcontractor$ and salesRep$
  @Input() selectedStore: Entity = null; // Entity
  @Input() selectedSubcontractor: Entity = null; // Entity
  @Input() selectedSalesRep: Entity = null; // Entity
  // variable that control the title for the store/subcontractor$
  @Input() tableType: string = 'STORE';
  @Input() tableTypeLabel: string = 'STORE';
  @Input() jobStatus: string = 'estimate'; // Estimate or scheduler
  // Input to control the visibility of the columns
  @Input() ownerCol: boolean = true;
  @Input() storeCol: boolean = true;
  @Input() saleReCol: boolean = true;
  @Input() displaySubcontractorForm = false;

  // The updated store and sales rep id to send back
  updatedStoreId: string = '';
  storeId: any = null;
  salesRepId: any = null;
  storeOther = false;

  // the Current value the object in the tables have
  currentStore: any;
  currentSalesRep: any = 'SALES REP';
  @Input() owner: Entity = null;

  // Send back the updated ID's
  @Output() updatePurchaseOrderStoreId = new Subject();
  @Output() updatePurchaseOrderSalesRepId = new EventEmitter();
  @Output() selectSubcontractorEvent = new Subject();
  @Output() updateSubcontractorInformationEvent = new Subject();
  @Output() updateNewStore = new EventEmitter();
  @Output() updatedOwner = new EventEmitter();
  closeObservables$ = new Subject();
  initialization: boolean = true;

  constructor(private fb: FormBuilder) {
    this.createOwnerForm();
  }

  ngOnInit() {
    this.subscribeToOwnerForm();
  }

  // Creates owner form
  createOwnerForm() {
    // Setting up OwnerForm the form for the tableForm
    return this.fb.group({
      _id: ['', []],
      name: ['', [Validators.required]],
      phoneNumber: [
        '',
        [Validators.required, formValidators.isPhoneNumberFormatValid]
      ],
      altPhoneNumber: [
        '',
        [Validators.required, formValidators.isPhoneNumberFormatValid]
      ],
      email: ['', [Validators.required, Validators.email]],
      address: this.fb.group({
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        street: ['', [Validators.required]]
      })
    });
  }

  // Subscribe to the owner form
  subscribeToOwnerForm() {
    // Subscribing for the form changement.
    this.ownerForm.valueChanges
      .pipe(
        switchMap(values => of(values)),
        debounceTime(400),
        takeUntil(this.closeObservables$)
      )
      .subscribe(tableValues => {
        // Preparing and  emitting the Owner Udated
        this.updatedOwner.emit(new Entity(tableValues)); // emitting updated owner
      });
  }

  ngOnChanges() {
    // Set the value for for the owner Form with the value of owner input
    if (this.owner && this.initialization) {
      this.loadOwnerInput(); // load the value of the owner @input to the form
      this.initialization = false; // set Initialization to false after loading the info of owner
    }
    // set the current store
    if (this.selectedStore) {
      this.selectedStore;
    }

    if (this.selectedSalesRep) {
      this.currentSalesRep = this.selectedSalesRep;
    }

    if (this.selectedSalesRep) {
      this.salesRepId = this.selectedSalesRep.id;
    }
  }

  // On the change of the selected store, output store purchase order compositeId
  storeChange(compositeId: string) {
    this.updatePurchaseOrderStoreId.next(compositeId);
    this.storeValue = Math.random() * 100;
  }

  // On the change of the selected sub, output subcontractor id
  subcontractorChange(_id: string) {
    this.selectSubcontractorEvent.next(_id);
    this.storeValue = Math.random() * 100;
  }

  // Same for the sales rep...
  salesRepChange(_id: string) {
    this.updatePurchaseOrderSalesRepId.emit(_id);
    this.storeValue = Math.random() * 100;
  }

  loadOwnerInput() {
    if (!this.ownerForm || !this.owner) return;
    const name: any = get(this.owner, 'name');
    let displayName: any = '';
    name ? (displayName = name) : '';
    this.ownerForm.get('_id').setValue(this.owner.id || '', {emitEvent: false});
    this.ownerForm.get('name').setValue(displayName || '', {emitEvent: false});
    this.ownerForm.get('email').setValue(this.owner.email || '', {emitEvent: false});
    this.ownerForm.get('phoneNumber').setValue(this.owner.phoneNumber || '', {emitEvent: false});
    this.ownerForm
      .get('altPhoneNumber')
      .setValue(this.owner.altPhoneNumber || '', {emitEvent: false});
    this.ownerForm
      .get('address')
      .get('street')
      .setValue(this.owner.address.street || '', {emitEvent: false});
    this.ownerForm
      .get('address')
      .get('city')
      .setValue(this.owner.address.city || '', {emitEvent: false});
    this.ownerForm
      .get('address')
      .get('zipCode')
      .setValue(this.owner.address.zipCode || '', {emitEvent: false});
    this.ownerForm
      .get('address')
      .get('state')
      .setValue(this.owner.address.state || '', {emitEvent: false});
  }


  // On subcontractor info changed
  onSubcontractorInfoChanged(data) {
    this.updateSubcontractorInformationEvent.next(data);
  }


  // Shows/hides infrequent group
  showInfrequentGroup() {
    return !isEmpty(this.infrequentSubcontractors);
  }

  // Gets address
  getAddressLine1(selectedStore) {
    return get(selectedStore,'addressLine1') || get(selectedStore, 'address.street');
  }

  getAddressLine2(selectedStore) {
    return get(selectedStore,'addressLine2') || AddressUtils.composeAddressLine(
      get(selectedStore, 'address.city'),
      get(selectedStore, 'address.state'),
      get(selectedStore, 'address.zipCode'),
    );
  }

  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }
}



