export const roofInstallQCILineItems = [
  {
    _id: '5d0afd25af6234bca09c7f91',
    task: 'Is the starter strip installed on eaves & rakes?',
    value: false,
    group: 'Shingles',
    index: 1
  },
  {
    _id: '5d0afd25af6234bca09c7f92',
    task: 'Are the shingles free of scuff marks?',
    value: false,
    group: 'Shingles',
    index: 2
  },
  {
    _id: '5d0afd25af6234bca09c7f93',
    task: 'Do the shingles have a 1” overhang on rakes?',
    value: false,
    group: 'Shingles',
    index: 3
  },
  {
    _id: '5d0afd25af6234bca09c7f94',
    task: 'Are the shingles straight horizontally?',
    value: false,
    group: 'Shingles',
    index: 4
  },
  {
    _id: '5d0afd25af6234bca09c7f95',
    task: 'Are the 3-tab shingles straight vertically?',
    value: false,
    group: 'Shingles',
    index: 5
  },
  {
    _id: '5d0afd25af6234bca09c7f96',
    task: 'Are the valleys closed cut?',
    value: false,
    group: 'Shingles',
    index: 6
  },
  {
    _id: '5d0afd25af6234bca09c7fc1',
    task: 'Are the shingles in valley dehorned / cropped?',
    value: false,
    group: 'Shingles',
    index: 7
  },
  {
    _id: '5d0afd25af6234bca09c7f97',
    task: 'Are the bottom two shingles in valley weaved?',
    value: false,
    group: 'Shingles',
    index: 8
  },
  {
    _id: '5d0afd25af6234bca09c7f98',
    task:
      'Are the shingles free of overspray paint from lead boots or HVAC vents?',
    value: false,
    group: 'Shingles',
    index: 9
  },
  {
    _id: '5d0afd25af6234bca09c7f99',
    task:
      'Is there a 1/2” gap between the shingle and vertical part of HVAC or box vents?',
    value: false,
    group: 'Shingles',
    index: 10
  },

  /* FASTENERS */

  {
    _id: '5d0afd25af6234bca09c7f9a',
    task: 'Do the shingles have a minimum of four nails?',
    value: false,
    group: 'Fasteners',
    index: 11
  },
  {
    _id: '5d0afd25af6234bca09c7f9b',
    task: 'Are the shingles nailed on the nail-line?',
    value: false,
    group: 'Fasteners',
    index: 12
  },
  {
    _id: '5d0afd25af6234bca09c7f9c',
    task: 'Are the shingles free of exposed nails (shiners)?',
    value: false,
    group: 'Fasteners',
    index: 13
  },
  {
    _id: '5d0afd25af6234bca09c7f9d',
    task: 'Are 2.5” nails used on ridge vents?',
    value: false,
    group: 'Fasteners',
    index: 14
  },

  /* VENTILATION */

  {
    _id: '5d0afd25af6234bca09c7f9e',
    task: 'Are the turbine vents level and spinning?',
    value: false,
    group: 'Ventilation',
    index: 15
  },
  {
    _id: '5d0afd25af6234bca09c7f9f',
    task: 'Do the lead boots cover up the plastic or metal pipe completely?',
    value: false,
    group: 'Ventilation',
    index: 16
  },
  {
    _id: '5d0afd25af6234bca09c7fa0',
    task: 'Are the pipe jacks and HVAC vents painted?',
    value: false,
    group: 'Ventilation',
    index: 17
  },
  {
    _id: '5d0afd25af6234bca09c7fc2',
    task: 'Are the HVAC vents painted and is the rain collar caulked?',
    value: false,
    group: 'Ventilation',
    index: 18
  },
  {
    _id: '5d0afd25af6234bca09c7fa1',
    task: 'Are the box vents spaced evenly?',
    value: false,
    group: 'Ventilation',
    index: 19
  },
  {
    _id: '5d0afd25af6234bca09c7fa3',
    task: 'Do the shingles not cover up the bottom flange on plumbing pipes?',
    value: false,
    group: 'Ventilation',
    index: 20
  },

  /* FLASHING */

  {
    _id: '5d0afd25af6234bca09c7fa4',
    task: 'Is the counter flashing cut into brick with tension bend?',
    value: false,
    group: 'Flashing',
    index: 21
  },
  {
    _id: '5d0afd25af6234bca09c7fa5',
    task: 'Is there a kick out on counter flashing above gutter?',
    value: false,
    group: 'Flashing',
    index: 22
  },
  {
    _id: '5d0afd25af6234bca09c7fa6',
    task: 'Is drip edge installed on eaves?',
    value: false,
    group: 'Flashing',
    index: 23
  },
  {
    _id: '5d0afd25af6234bca09c7fa7',
    task: 'Is the skylight properly flashed?',
    value: false,
    group: 'Flashing',
    index: 24
  },
  {
    _id: '5d0afd25af6234bca09c7fa8',
    task: 'Does the apron flashing have a hem on the bottom edge?',
    value: false,
    group: 'Flashing',
    index: 25
  },

  /* CAULK */

  {
    _id: '5d0afd25af6234bca09c7fa9',
    task: 'Are the exposed nail heads caulked on vents?',
    value: false,
    group: 'Caulk',
    index: 26
  },
  {
    _id: '5d0afd25af6234bca09c7faa',
    task: 'Are the exposed nail heads caulked on wall flashing?',
    value: false,
    group: 'Caulk',
    index: 27
  },
  {
    _id: '5d0afd25af6234bca09c7fab',
    task: 'Are the exposed nail heads caulked on shingles?',
    value: false,
    group: 'Caulk',
    index: 28
  },
  {
    _id: '5d0afd25af6234bca09c7fac',
    task: 'Has the chimney flashing been properly caulked?',
    value: false,
    group: 'Caulk',
    index: 29
  },

  /* MODIFIED BITUMEN */

  {
    _id: '5d0afd25af6234bca09c7fad',
    task: 'Is there a base and cap sheet installed?',
    value: false,
    group: 'Modified Bitumen',
    index: 30
  },
  {
    _id: '5d0afd25af6234bca09c7fae',
    task: 'Is modified drip edge installed around perimeter?',
    value: false,
    group: 'Modified Bitumen',
    index: 31
  },
  {
    _id: '5d0afd25af6234bca09c7faf',
    task: 'Have the wrinkles been rolled out?',
    value: false,
    group: 'Modified Bitumen',
    index: 32
  },
  {
    _id: '5d3b31b3d497393c449181a7',
    task: 'Have the penetrations been properly flashed?',
    value: false,
    group: 'Modified Bitumen',
    index: 33
  },
  {
    _id: '5d0afd25af6234bca09c7fb0',
    task: 'Is there bleed out on seams and penetrations?',
    value: false,
    group: 'Modified Bitumen',
    index: 34
  },

  /* TPO */

  {
    _id: '5d0afd25af6234bca09c7fC5',
    task: 'Were screws and plates used on substrate (12 per board)?',
    value: false,
    group: 'TPO',
    index: 35
  },
  {
    _id: '5d0afd25af6234bca09c7fC6',
    task: 'Was this fully adhered?  If so, is the membrane laying flat?',
    value: false,
    group: 'TPO',
    index: 36
  },
  {
    _id: '5d0afd25af6234bca09c7fC7',
    task: 'Was this roof probed?  If so, is the membrane free of cold welds?',
    value: false,
    group: 'TPO',
    index: 37
  },
  {
    _id: '5d0afd25af6234bca09c7fC8',
    task: 'Were short sheets used around the perimeter for wind uplift?',
    value: false,
    group: 'TPO',
    index: 38
  },
  {
    _id: '5d0afd25af6234bca09c7fC9',
    task: 'Was term bar used?  If so, was water block applied without gaps?',
    value: false,
    group: 'TPO',
    index: 39
  },
  {
    _id: '5d0afd25af6234bca09c7fC0',
    task: 'Was cut edge sealant used?',
    value: false,
    group: 'TPO',
    index: 40
  },
  {
    _id: '5d0afd25af6234bca09c7fD1',
    task: 'Were the seams stripped in with flashing membrane?',
    value: false,
    group: 'TPO',
    index: 41
  },
  {
    _id: '5d0afd25af6234bca09c7fD2',
    task: 'Was flashing membrane used around perimeter where drip edge is?',
    value: false,
    group: 'TPO',
    index: 42
  },
  {
    _id: '5d0afd25af6234bca09c7fD3',
    task: 'Was tapered ISO board used around drains?',
    value: false,
    group: 'TPO',
    index: 43
  },

  /* EPDM */
  {
    _id: '5d0afd25af6234bca09c7fE0',
    task: 'Were screws and plates used on substrate (12 per board)?',
    value: false,
    group: 'EPDM',
    index: 44
  },
  {
    _id: '5d0afd25af6234bca09c7fE1',
    task: 'Was 3x3 drip edge and term bar used around the perimeter?',
    value: false,
    group: 'EPDM',
    index: 45
  },
  {
    _id: '5d0afd25af6234bca09c7fE2',
    task: 'Was cover tape used on top of drip edge around the perimeter?',
    value: false,
    group: 'EPDM',
    index: 46
  },
  {
    _id: '5d0afd25af6234bca09c7fE3',
    task: 'Was edge caulk applied where the cover tape and membrane meet?',
    value: false,
    group: 'EPDM',
    index: 47
  },
  {
    _id: '5d0afd25af6234bca09c7fE4',
    task: 'Was seam tape used on all the seams?',
    value: false,
    group: 'EPDM',
    index: 48
  },
  {
    _id: '5d0afd25af6234bca09c7fE5',
    task: 'Is there bleed out from the seam tape?',
    value: false,
    group: 'EPDM',
    index: 49
  },
  {
    _id: '5d0afd25af6234bca09c7fE6',
    task: 'Was this fully adhered?  If so, is the membrane free of bubbles?',
    value: false,
    group: 'EPDM',
    index: 50
  },
  {
    _id: '5d0afd25af6234bca09c7fE7',
    task: 'Was term bar used on walls?',
    value: false,
    group: 'EPDM',
    index: 51
  },
  {
    _id: '5d0afd25af6234bca09c7fE8',
    task: 'Were T-joint patches used where the seams meet?',
    value: false,
    group: 'EPDM',
    index: 52
  },
  {
    _id: '5d0afd25af6234bca09c7fE9',
    task:
      'Were T-joint patches used on outside corners of skylight, RTU, walls, etc?',
    value: false,
    group: 'EPDM',
    index: 53
  },
  {
    _id: '5d0afd25af6234bca09c7fF0',
    task: 'Was tapered ISO board used around drains?',
    value: false,
    group: 'EPDM',
    index: 54
  },
  {
    _id: '5d0afd25af6234bca09c7fF1',
    task: 'Were peel-n-stick pipe boots used?',
    value: false,
    group: 'EPDM',
    index: 55
  },
  {
    _id: '5d0afd25af6234bca09c7fF2',
    task: 'Were peel-n-stick walkway pads used?',
    value: false,
    group: 'EPDM',
    index: 56
  },

  /* COATINGS */

  {
    _id: '5d0afd25af6234bca09c7fF3',
    task: 'Was the membrane power washed with a cleaner?',
    value: false,
    group: 'EPDM',
    index: 57
  },
  {
    _id: '5d0afd25af6234bca09c7fF4',
    task:
      'Was any moisture detected under the substrate?  If so, was substrate replaced?',
    value: false,
    group: 'EPDM',
    index: 58
  },

  /* CLEANUP */
  {
    _id: '5d0afd25af6234bca09c7fb1',
    task: 'Is the roof clean and blown off?',
    value: false,
    group: 'Cleanup',
    index: 59
  },
  {
    _id: '5d0afd25af6234bca09c7fb2',
    task: 'Are the gutters clean blown out?',
    value: false,
    group: 'Cleanup',
    index: 60
  },
  {
    _id: '5d0afd25af6234bca09c7fb3',
    task: 'Are the gutter screens reinstalled?',
    value: false,
    group: 'Cleanup',
    index: 61
  },
  {
    _id: '5d0afd25af6234bca09c7fb4',
    task: 'Are the landscaping beds clean?',
    value: false,
    group: 'Cleanup',
    index: 62
  },
  {
    _id: '5d0afd25af6234bca09c7fb5',
    task: 'Is the air-conditioner unit clean of debris?',
    value: false,
    group: 'Cleanup',
    index: 63
  },
  {
    _id: '5d0afd25af6234bca09c7fb6',
    task: 'Is the swimming pool clean of debris?',
    value: false,
    group: 'Cleanup',
    index: 64
  },
  {
    _id: '5d0afd25af6234bca09c7fb7',
    task: 'Is the yard clean of debris?',
    value: false,
    group: 'Cleanup',
    index: 65
  },
  {
    _id: '5d0afd25af6234bca09c7fb8',
    task: 'Is the neighbors yard clean of debris?',
    value: false,
    group: 'Cleanup',
    index: 66
  },
  {
    _id: '5d0afd25af6234bca09c7fb9',
    task: 'Is the driveway and patio/deck clean?',
    value: false,
    group: 'Cleanup',
    index: 67
  },
  {
    _id: '5d8a5ebaac56da16d876cb60',
    task: 'Has the driveway and yard been rolled with a magnet?',
    value: false,
    group: 'Cleanup',
    index: 68
  },
  {
    _id: '5d8a5ebaac56da16d876cb66',
    task: 'Has all left over material been called in for pickup?',
    value: false,
    group: 'Cleanup',
    index: 69
  }
];

export const gutterInstallQCILineItems = [
  {
    _id: '5d0af7378224b7084c06245b',
    task: 'Are the gutters pitched to the downspouts?',
    value: false,
    group: 'Gutters',
    index: 1
  },
  {
    _id: '5d0af7378224b7084c06245c',
    task: 'Are there hangers every 2’ in the gutters?',
    value: false,
    group: 'Gutters',
    index: 2
  },
  {
    _id: '5d0af7378224b7084c06245d',
    task: 'Are the corners and end caps sealed?',
    value: false,
    group: 'Gutters',
    index: 3
  },
  {
    _id: '5d0af7378224b7084c062460',
    task: 'Do the downspouts have at least 2 straps on them?',
    value: false,
    group: 'Gutters',
    index: 4
  },
  {
    _id: '5d0af7378224b7084c062461',
    task: 'Do the downspouts drain on to the concrete?',
    value: false,
    group: 'Gutters',
    index: 5
  },
  {
    _id: '5d0af7378224b7084c062462',
    task: 'Are there enough downspouts (1 every 30LF)?',
    value: false,
    group: 'Gutters',
    index: 6
  },
  {
    _id: '5d0af7378224b7084c062463',
    task: 'Are the right size of gutters used (5” 7/12 under, 6” 8/12 over)?',
    value: false,
    group: 'Gutters',
    index: 7
  },

  /* GUTTER GUARDS */

  {
    _id: '5d0af7378224b7084c062464',
    task: 'Are the gutter guards screwed on (front and back screw)?',
    value: false,
    group: 'Gutter Guards',
    index: 8
  },
  {
    _id: '5d0af7378224b7084c062465',
    task: 'Are there any missing pieces?',
    value: false,
    group: 'Gutter Guards',
    index: 9
  },
  {
    _id: '5d0af7378224b7084c062466',
    task: 'Do the gutter guards go underneath starter shingle?',
    value: false,
    group: 'Gutter Guards',
    index: 10
  },
  {
    _id: '5d0af7378224b7084c062467',
    task: 'Are there fingerprints or smudges on the gutters?',
    value: false,
    group: 'Cleanup',
    index: 11
  },
  {
    _id: '5d0af7378224b7084c062468',
    task: 'Are the gutters cleaned out?',
    value: false,
    group: 'Cleanup',
    index: 12
  },
  {
    _id: '5d0af7378224b7084c062469',
    task: 'Are the landscaping beds clean?',
    value: false,
    group: 'Cleanup',
    index: 13
  },
  {
    _id: '5d0af7378224b7084c06246a',
    task: 'Is the yard clean of debris?',
    value: false,
    group: 'Cleanup',
    index: 14
  },
  {
    _id: '5d0af7378224b7084c06246b',
    task: 'Is the driveway and patio/deck clean?',
    value: false,
    group: 'Cleanup',
    index: 15
  },
  {
    _id: '5d0af7378224b7084c06246d',
    task: 'Is there left over material? Call in for pickup.',
    value: false,
    group: 'Cleanup',
    index: 16
  }
];

export const defaultQCI = [
  {
    _id: '5d0af7378224b7084c06246d',
    task: 'Is the job complete?',
    value: false,
    group: 'Description',
    index: 1
  }
];
