import { isEmpty } from 'lodash';

import { ProfitMatrixBracket } from '@contract-estimator/shared/classes';

export function getBracketFromProfitMatrix(
  brackets,
  total
): ProfitMatrixBracket {
  if (isEmpty(brackets)) return null;
  for (let i = 0; i < brackets.length; i++) {
    if (total >= brackets[i].min && total <= brackets[i].max) {
      return brackets[i];
    }
  }
  return null;
}
