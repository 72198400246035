import { aggregateTradeSections } from '../aggregator';
import { isSectionUndocumented } from '../functions';

export const aggregateTradeSectionProjector = (estimated, actual) => {
  const aggregatedData = aggregateTradeSections(estimated, actual);
  const array = Array.from(aggregatedData.values());
  return array;
};

export const undocumentedTradeSectionsProjector = sections => {
  const array = [];
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (isSectionUndocumented(section)) array.push(section);
  }
  return array;
};
