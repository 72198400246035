import { MenuItem } from 'primeng/api';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
} from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {
  EstimateMenu,
  OrderMenu,
  JobTypeMenu, MenuPayload
} from 'apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class';
import { isEmpty } from 'lodash';
import { IRenameOrderPayload } from 'apps/contract-estimator/src/app/shared/models/order.model';
import { StringFormatter } from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';
import {EstimatorMenu} from "./estimator/estimator-menu.class";
import {
  AddTradePayload,
  DuplicateTradePayload, NavigateToInvoicePayload, OpenContractPDFPayload,
  RemoveTradePayload,
  RenameTradePayload
} from "../../../../../../../classes/src/lib";
import {OriginalScopeMenu} from "./scheduler/original-scope-menu.class";
import {AdditionalScopeMenu} from "./scheduler/additional-scope-menu.class";
import {BillingMenu} from "./scheduler/billing-menu.class";
import {WarrantyMenu} from "./scheduler/warranty-menu.class";
import {TradeComponentMenuItem} from "./shared/trade-component-menu-item.class";

@Component({
  selector: 'app-estimate-panel-menu',
  templateUrl: './estimate-panel-menu.component.html',
  styleUrls: ['./estimate-panel-menu.component.scss']
})
export class EstimatePanelMenuComponent implements OnChanges {
  // props
  stringFormatter: StringFormatter = new StringFormatter();
  screenHeight;
  screenWidth;
  expanded: boolean = true;

  @Input() _jobId: string;
  @Input() displayEstimates: Array<EstimateMenu>;
  @Input() selectedMenuItemId: string;
  @Input() currentOrderId: string;
  @Input() menuLabel: string = 'ESTIMATE';
  @Input() jobStatus: string = 'estimate'; // Estimate or Scheduler

  @Output() addNewOrderEvent = new Subject();
  @Output() removeOrderEvent = new Subject();
  @Output() renameOrder = new Subject();
  @Output() triggeredNavigationEvent = new Subject();
  @Output() copyTradeEvent = new Subject();
  @Output() openContract = new Subject();
  @Output() markupEvent = new Subject();
  @Output() printAllTradesEvent = new Subject();
  @Output() navigateToInvoice = new Subject();
  @Output() onError = new EventEmitter<string>();

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1100) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  // gui data
  menuItems: MenuItem[] = [];
  initialized: boolean = false;

  constructor() {
    this.onResize();
  }

  ngOnChanges() {
    this.menuItems = this.createMenuItems();
  }

  // Creates the menu items
  createMenuItems(): MenuItem[] {
    try {
      if (isEmpty(this.displayEstimates)) return [];
      return this.createEstimatorMenu(this.displayEstimates[0]);
    } catch (menuError) {
      this.onError.emit(
        'Unable to load menu items. Please reload the page to try again'
      );
    }

  }

  commandCallback = (payload?: RenameTradePayload | RemoveTradePayload | DuplicateTradePayload | AddTradePayload | OpenContractPDFPayload | NavigateToInvoicePayload) => (event?: any) => {
    if(payload instanceof RenameTradePayload) {
      this.renameOrder.next(payload)
    } else if (payload instanceof RemoveTradePayload) {
      this.removeOrderEvent.next(payload);
    } else if (payload instanceof DuplicateTradePayload) {
      this.copyTradeEvent.next(payload._orderId);
    } else if (payload instanceof AddTradePayload) {
      this.addNewOrderEvent.next(payload)
    } else if(payload instanceof OpenContractPDFPayload) {
      this.openContract.next();
    } else if (payload instanceof NavigateToInvoicePayload) {
      this.navigateToInvoice.next()
    } else {
      this.onError.emit("Invalid menu command option.")
    }
  }

  // Creates estimator menu
  createEstimatorMenu(menuPayload: MenuPayload | any) {
    if(this.jobStatus === 'estimate') {
      return [EstimatorMenu.from(
        menuPayload,
        this.commandCallback,
        this.expanded
      )]
    } else  {
      const originalScope = OriginalScopeMenu.from(
        menuPayload,
        this.commandCallback,
        this.expanded
      )
      const additionalScope = AdditionalScopeMenu.from(
        menuPayload,
        this.commandCallback,
        this.expanded
      )
      const jobMedia = new TradeComponentMenuItem(
        'JOB MEDIA',
        ['media-manager'],
      )
      const billingMenu = BillingMenu.from(
        this.commandCallback,
        this.expanded
      )
      const warrantyMenu = WarrantyMenu.from(
        menuPayload,
        this.commandCallback,
        this.expanded
      )


      /**
       * Temporal hack to hide unfinished features
       * TODO:: Fix after making all features multi-office
       */
      const opts: any = [
        originalScope
      ]

      opts.push(additionalScope);
      opts.push(jobMedia)
      opts.push(billingMenu)
      opts.push(warrantyMenu)

      return opts;
    }
  }

}
