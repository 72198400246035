export const PAYROLL_PAY_TYPE_OPTIONS = [
  {
    label: '1099',
    value: '1099'
  },
  {
    label: 'Distribution',
    value: 'Distribution'
  },
  {
    label: 'W2',
    value: 'W2'
  }
];

export const PAYROLL_TRADE_OPTIONS = [
  {
    label: 'Advertising',
    value: 'Advertising'
  },
  {
    label: 'Back Charge',
    value: 'Back Charge'
  },
  {
    label: 'Commission',
    value: 'Commission'
  },
  {
    label: 'Material Reimbursement',
    value: 'Material Reimbursement'
  },
  {
    label: 'Other',
    value: 'Other'
  },
  {
    label: 'Purchase Order',
    value: 'Purchase Order'
  },
  {
    label: 'QCI',
    value: 'QCI'
  },
  {
    label: 'Software',
    value: 'Software'
  },
  {
    label: 'Trip Charge',
    value: 'Trip Charge'
  },
  {
    label: 'Wages',
    value: 'Wages'
  },
  {
    label: 'Work Order',
    value: 'Work Order'
  },
];
