import { Labor } from './labor.model';

// this model is an incomplete version of db model found in Jobs.controllers.orders.workOrders.sections
export class WorkOrderSection {
  _id: string;
  total: number;
  name: string;
  labors: Labor[];
  _estimateTemplateId?: string;

  constructor({ _id, total, labors, name }: any) {
    this._id = _id;
    this.total = total;
    this.name = name || '';
    const filteredLabors = labors.filter(labor => labor);
    this.labors = filteredLabors.map(labor => Labor.create(labor));
  }

  static create(rawWOE: any) {
    return new WorkOrderSection(rawWOE);
  }
}
