import { Action } from '@ngrx/store';
import { PersonnelState } from '../states/personnel.state';

export const GET_PERSONNEL = 'PERSONNEL > GET_PERSONNEL';
export class GetPersonnel implements Action {
  readonly type = GET_PERSONNEL;

  constructor(public trade: string) {}
}

export const SET_PERSONNEL = 'PERSONNEL > SET_PERSONNEL';
export class SetPersonnel implements Action {
  readonly type = SET_PERSONNEL;

  constructor(public payload: Array<PersonnelState>) {}
}

export type Actions = GetPersonnel | SetPersonnel;
