import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Params } from '@angular/router';
import { get } from 'lodash';

import { RouterStateUrl } from './router.reducer';

export const getRouterState = createFeatureSelector<
  RouterReducerState<RouterStateUrl>
>('routerReducer');

export const getRouterParams: any = createSelector(
  getRouterState,
  (router: any) => {
    let currentRoute = get(router, 'state.root', {});
    let params: Params = {};
    const navigationId = get(router, 'navigationId');
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = {
        ...params,
        ...currentRoute.params,
        ...currentRoute.queryParams
      };
    }
    if (navigationId) {
      return {
        ...params,
        navigationId
      };
    }

    return { ...params };
  }
);

export const getRouterQueryParams: any = createSelector(
  getRouterState,
  (router: any) => {
    let currentRoute = get(router, 'state.root', {});
    let params: Params = {};
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = {
        ...params,
        ...currentRoute.queryParams
      };
    }
    return params;
  }
);

export const getRouterUrl: any = createSelector(
  getRouterState,
  (storeRouter: RouterReducerState<RouterStateUrl>) => {
    return get(storeRouter, 'state.url');
  }
);

export const getEstimateId = createSelector(
  getRouterParams,
  (params: { estimateId?: string }) => {
    return get(params, 'estimateId');
  }
);

export const getSalesRepId: any = createSelector(
  getRouterParams,
  (params: { salesRepId?: string }) => {
    return get(params, 'salesRepId');
  }
);

export const getJobId: any = createSelector(
  getRouterParams,
  (params: { jobId?: string }) => {
    return get(params, 'jobId');
  }
);

export const getAdditionalScopeId: any = createSelector(
  getRouterParams,
  (params: { additionalScopeId?: string }) => {
    return get(params, 'additionalScopeId');
  }
);

export const getPurchaseOrderId = createSelector(
  getRouterParams,
  (params: { purchaseOrderId?: string }) => {
    return get(params, 'purchaseOrderId');
  }
);

export const getOrderId = createSelector(
  getRouterParams,
  (params: { purchaseOrderId?: string }) => {
    return get(params, 'orderId');
  }
);

export const getMarkupId = createSelector(
  getRouterParams,
  (params: { purchaseOrderId?: string }) => {
    return get(params, 'markupEstimateId');
  }
);

export const getStoreId = createSelector(
  getRouterParams,
  (params: { storeId?: string }) => {
    return get(params, 'storeId');
  }
);

export const getTradeId = createSelector(
  getRouterParams,
  (params: { tradeId?: string }) => {
    return get(params, 'tradeId');
  }
);

export const getNavigationId: any = createSelector(
  getRouterState,
  (storeRouter: RouterReducerState<RouterStateUrl>) => {
    try {
      return storeRouter.navigationId;
    } catch (error) {
      return null;
    }
  }
);

export const getRedirectToUrlWhenLogIn: any = createSelector(
  getRouterParams,
  (params: { purchaseOrderId?: string }) => {
    return get(params, 'redirectTo', '');
  }
);

export const urlInvoiceIdParams: any = createSelector(
  getRouterParams,
  (params: { purchaseOrderId?: string }) => {
    return get(params, '_invoiceId');
  }
);

export const invoiceUrlParams = createSelector(getRouterUrl, (url: string) => {
  return url;
});

export const selectRouterReducerNavigationId = createSelector(
  getNavigationId,
  (navigationId: number) => {
    return navigationId;
  }
);

export const selectNavigationValues = createSelector(
  selectRouterReducerNavigationId,
  invoiceUrlParams,
  (navigationId, navigationUrl) => ({ navigationId, navigationUrl })
);

export const selectStatusFromRouterParams = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, 'status');
  }
);

export const getWorkOrderId = createSelector(getRouterParams, (params: any) => {
  return get(params, 'workOrderId');
});

export const getInvoiceId: any = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, '_invoiceId');
  }
);

export const calendarAuthorizationCode = createSelector(
  getRouterQueryParams,
  params => {
    return get(params, 'code');
  }
);

export const paperworkManagerPacketId: any = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, 'paperworkManagerPacketId');
  }
);

export const jobCostManagerId: any = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, 'jobCostManagerId');
  }
);

export const payScaleId: any = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, 'payScaleId');
  }
);

export const filterSetId: any = createSelector(
  getRouterParams,
  (params: any) => {
    return get(params, 'filterSetId');
  }
);

export const bucketId: any = createSelector(getRouterParams, (params: any) => {
  return get(params, 'bucketId');
});

export const jobCostManagerIds: any = createSelector(
  payScaleId,
  filterSetId,
  bucketId,
  (_payScaleId: string, _filterSetId: string, _bucketId: string) => {
    return {
      _payScaleId,
      _filterSetId,
      _bucketId
    };
  }
);

export const subcontractorManagerQueryParams: any = createSelector(
  getRouterParams,
  (params: any) => {
    const status = get(params, 'status');
    const trades = get(params, 'trades');
    const searchTerms = get(params, 'searchTerms');

    return {
      status,
      trades,
      searchTerms
    };
  }
);
