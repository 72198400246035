import { get } from 'lodash';
import { toNegative } from 'apps/contractor-server/src/app/utils/src/app/math/math.utils';
import { composeSalesRepCommissionObject } from 'libs/scheduler/job-costs/data-access/src/lib/+state/selectors/functions/actual-job-costs.functions';
import { composeAggregatedTotalSummaryMap } from '../../../../summary-totals/summary-totals.function';
import {
  TotalSummaryDirector,
  TotalSummaryBuilder
} from '@contract-estimator/shared/classes';

export function jobCostSummaryMetadataProjector(
  sales,
  additionalScopeSales,
  additionalScopeCosts,
  totalSales,
  advertising,
  overhead,
  salesRepCommission,
  managerCommission
) {
  return {
    sales,
    additionalScopeSales,
    additionalScopeCosts,
    totalSales,
    advertising,
    overhead,
    salesRepCommission,
    managerCommission
  };
}

export function jobCostCOGSTotalsProjector(
  workOrderTotal,
  purchaseOrderTotal,
  miscellaneousCost
) {
  return {
    workOrderTotal,
    purchaseOrderTotal,
    miscellaneousCost
  };
}

export function summaryTotalsProjector(
  jobCostsSummaryMetadata,
  jobCostsCOGS,
  profit,
  netProfit
) {
  const sales = get(jobCostsSummaryMetadata, 'sales');
  const additionalScopeSales = get(jobCostsSummaryMetadata, 'additionalScopeSales');
  const additionalScopeCosts = get(jobCostsSummaryMetadata, 'additionalScopeCosts');
  const totalSales = get(jobCostsSummaryMetadata, 'totalSales');
  const workOrderTotal = get(jobCostsCOGS, 'workOrderTotal');
  const purchaseOrderTotal = get(jobCostsCOGS, 'purchaseOrderTotal');
  const miscellaneousCost = get(jobCostsCOGS, 'miscellaneousCost');
  const advertising = get(jobCostsSummaryMetadata, 'advertising');
  const overhead = get(jobCostsSummaryMetadata, 'overhead');
  const salesRepCommission = get(
    jobCostsSummaryMetadata,
    'salesRepCommission'
  );
  const managerCommission = get(
    jobCostsSummaryMetadata,
    'managerCommission'
  );

  return constructTotalSummaryInstance(
    sales,
    additionalScopeSales,
    additionalScopeCosts,
    totalSales,
    workOrderTotal,
    purchaseOrderTotal,
    miscellaneousCost,
    advertising,
    overhead,
    profit,
    salesRepCommission,
    managerCommission,
    netProfit
  );
}

export function summaryTotalsWithNegativeValuesProjector(totals) {
  return constructTotalSummaryInstance(
    totals.sales,
    totals.additionalScopeSales,
    toNegative(totals.additionalScopeCosts),
    totals.totalSales,
    toNegative(totals.workOrderTotal),
    toNegative(totals.purchaseOrderTotal),
    toNegative(totals.miscellaneousCost),
    toNegative(totals.advertising),
    toNegative(totals.overhead),
    totals.profit,
    totals.salesRepCommission,
    totals.managerCommission,
    totals.netProfit
  );
}

export function summaryTotalsViewModelProjector(totals) {
  return composeSalesRepCommissionObject({
    totalForAllSections: get(totals, 'sales'),
    changeOrder: get(totals, 'changeOrder'),
    totalSales: get(totals, 'totalSales'),
    overhead: get(totals, 'overhead'),
    marketing: get(totals, 'advertising'),
    workOrderSubtotal: get(totals, 'workOrderTotal'),
    purchaseOrderSubtotal: get(totals, 'purchaseOrderTotal'),
    miscellaneousExpensesSubtotal: get(totals, 'miscellaneousCost'),
    subtotal: get(totals, 'profit'),
    salesRepCommission: get(totals, 'salesRepCommission'),
    managerCommission: get(totals, 'managerCommission')
  });
}

export function aggregatedJobCostsTotalSummaryProjector(
  estimatedTotals,
  actualTotals,
  props
) {
  const map = composeAggregatedTotalSummaryMap(
    estimatedTotals,
    actualTotals,
    props.fieldNames,
    props.fieldIndexes
  );
  return map.values();
}

export function constructTotalSummaryInstance(
  sales,
  additionalScopeSales,
  additionalScopeCosts,
  totalSales,
  workOrderTotal,
  purchaseOrderTotal,
  miscellaneousCost,
  advertising,
  overhead,
  profit,
  salesRepCommission,
  managerCommission,
  netProfit
) {
  const builder = new TotalSummaryBuilder();
  const director = new TotalSummaryDirector();
  const totals = director
    .setBuilder(builder)
    .constructTotals(
      sales,
      additionalScopeSales,
      additionalScopeCosts,
      totalSales,
      workOrderTotal,
      purchaseOrderTotal,
      miscellaneousCost,
      advertising,
      overhead,
      profit,
      salesRepCommission,
      managerCommission,
      netProfit
    );
  return totals;
}

export const aggregatedJobCostsTotalSummaryVMProjector = (
  estimatedTotals,
  actualTotals,
  props: { fieldNames; fieldIndexes }
) => {
  const map = composeAggregatedTotalSummaryMap(
    estimatedTotals,
    actualTotals,
    props.fieldNames,
    props.fieldIndexes
  );
  return map;
};


export const netProfitProjector = (profit, salesRepCommission, managerCommission) => {
  const netProfit = ((+profit - +salesRepCommission) - +managerCommission);
  return netProfit
}
