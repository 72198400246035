import {MenuItem} from "primeng/api";
import {MenuPayload} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {AddTradeMenu} from "../shared/add-trade-menu.class";
import {TradeComponentMenuItem} from "../shared/trade-component-menu-item.class";
import {TradeMenu} from "../shared/trade-menu.class";

export class EstimatorMenu {
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded: boolean
  ) {}

  static from (payload: MenuPayload, callback: any, expanded: boolean) {
    const tradeMenus = TradeMenu.fromEstimateArray(payload, callback);
    const addTrade = AddTradeMenu.from(
      'Add Trade',
      payload.availableJobTypes,
      'estimate-scheduler',
      callback
    );
    const jobMedia = new TradeComponentMenuItem(
      'Job Media',
      ['estimate', payload._id, 'media-manager'],
    )
    const jobCostMenuItem = new TradeComponentMenuItem(
      'Job Costs',
      ['estimate', payload._id, 'totals', payload._markupId],
    )

    const contractMenuItem = new TradeComponentMenuItem(
      'Contract',
      ['estimate', payload._id, 'contract']
    )
    const items = [
      ...tradeMenus,
      addTrade,
      jobCostMenuItem,
      jobMedia,
      contractMenuItem,
    ]
    return new EstimatorMenu(
      "SCOPE",
      [
        ...items,
      ],
        expanded
    )
  }
}

