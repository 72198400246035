import { get } from 'lodash';

import * as EnvironmentSettings from './global.vars';
import {
  domain,
  clientId,
  serverUrl,
  audience,
  responseType
} from '../../../../../auth_config.json';

const API_URL = '/api';
// Hack to load env file from nest app. nest app does not have a window object
let window;
try {
  window = window;
} catch (e) {
  window = null;
}
const PAPERWORK_MANAGER_PACKET_EDITOR_DOCUMENTS_S3_FOLDER =
  'paperwork-manager-packet-documents-development';

export const environment = {
  ENV: 'development',
  production: false,
  auth: {
    domain: 'development-mobiledash.us.auth0.com',
    clientId: 'qzMbFj7mGF2JAgjuQDR1YyQvK7GfMEH1',
    audience: 'https://development-mobiledash.us.auth0.com/api/v2/',
    serverUrl: 'http://localhost:8080',
    responseType: 'id_token',
    issuer: 'https://development-mobiledash.us.auth0.com/',
    algorithms: ['RS256'],
    credentialsRequired: false
  },
  dev: {
    serverUrl
  },
  estimatorUrl: 'http://localhost:4200',
  INVOICE_SERVER_URL: 'http://localhost:8081',
  API_URL,
  MEGA_MENU_URL: API_URL + '/menu/count-array',
  COMPANY_ID: '598e2c650bc1fe1912fdf30d',
  invoiceSubject: 'Elite Roofing - Invoice',
  firebase: {
    apiKey: 'AIzaSyCPo1W4hgKY7F7Ts9aru8BI-sYbnV0kcKc',
    authDomain: 'ng-deployer.firebaseapp.com',
    databaseURL: 'https://ng-deployer.firebaseio.com',
    projectId: 'ng-deployer',
    storageBucket: 'ng-deployer.appspot.com',
    messagingSenderId: '60172905589',
    appId: '1:60172905589:web:bffd661ad077d4c4'
  },
  PAPERWORK_MANAGER_PACKET_EDITOR_DOCUMENTS_S3_FOLDER,
  paperManagerPacketDocumentBucket: `https://estimate-3.s3.us-west-2.amazonaws.com/${PAPERWORK_MANAGER_PACKET_EDITOR_DOCUMENTS_S3_FOLDER}`,
  PAY_SCALE_PROFIT_MATRIX_ENABLED: true,
  /* IMPORTS ENVIRONMENT SETTINGS */

  ...EnvironmentSettings.GLOBAL_ENv_VARS
};
