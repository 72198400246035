import { Injectable } from '@angular/core';
import {map} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {get, find} from 'lodash';

import {MegaMenuHttpClientService} from "./mega-menu-http-client.service";
import {AuthClientService} from "../../../../../angular/providers/src/lib/auth-client/auth-client.service";
import {composeMenu} from "apps/contractor-server/src/app/utils/src/app/menu/menu.utils";
import {MegaMenuFacade} from "./../+state/mega-menu.facade";
import {composeAppMainMenu} from "@contract-estimator/shared/megamenu/utils";

@Injectable({
  providedIn: 'root'
})
export class MegaMenuService {
  menuUrl;

  constructor(
    private megaMenuHttpClientService: MegaMenuHttpClientService,
    private authClientService: AuthClientService,
    private megaMenuFacade: MegaMenuFacade
  ) {}

  setUrls(menuUrl) {
    this.menuUrl = menuUrl;
  }

  // Gets old menu options and count
  getMenu() {
    return this.getMenuFromStore();
  }

  // Gets new menu options and count
  getAppMenu() {
    return this.getAppMenuFromStore();
  }

  // Get menu count as an array
  getMenuCountAsAnArray() {
    return this.megaMenuHttpClientService.getMenuCountAsAnArray(this.menuUrl)
      .pipe(
        map(counts => this.megaMenuFacade.dispatchLoadMenuCounts(counts))
      )
  }

  // Get enabled features
  getEnabledFeatures() {
    return this.megaMenuHttpClientService.getEnabledFeatures()
      .pipe(
        map(features =>  this.megaMenuFacade.dispatchEnabledFeatures(features))
      )
  }

  // Get menuFromSyncFunction
  getMenuFromStore() {
    const userRole$ = this.authClientService.getUserRole();
    const menuCountEntities$ = this.megaMenuFacade.menuCountEntities$;

    const menu$ = combineLatest([
      userRole$,
      menuCountEntities$
    ])

    return menu$
      .pipe(
        map(([userRole, menuCountEntities]) => composeMenu({
          userRole,
          inProgressWarrantyCount: this.getValueFromEntity(menuCountEntities,  'inProgressWarrantyCount'),
          inProgressLeadCount: this.getValueFromEntity(menuCountEntities,  'inProgressLeadCount'),
          unscheduledWorkOrdersCount: this.getValueFromEntity(menuCountEntities,  'unscheduledWorkOrdersCount'),
          unscheduledQCICount: this.getValueFromEntity(menuCountEntities,  'unscheduledQCICount'),
          unscheduledWarrantiesCount: this.getValueFromEntity(menuCountEntities,  'unscheduledWarrantiesCount'),
          /* Invoice count */
          inProgressInvoiceCount: this.getValueFromEntity(menuCountEntities,  'inProgressInvoiceCount'),
          unpaidInvoiceCount: this.getValueFromEntity(menuCountEntities,  'unpaidInvoiceCount'),
          /* Menu Header Total Counts */
          pipelineTotalCount: this.getValueFromEntity(menuCountEntities,  'pipelineTotalCount'),
          estimatesTotalCount: this.getValueFromEntity(menuCountEntities,  'estimatesTotalCount'),
          schedulerTotalCount: this.getValueFromEntity(menuCountEntities,  'schedulerTotalCount'),
          invoiceTotalCount: this.getValueFromEntity(menuCountEntities,  'invoiceTotalCount')
        }))
      )
  }

  // Get menuFromSyncFunction
  getAppMenuFromStore() {
    const userRole$ = this.authClientService.getUserRole();
    const menuCountEntities$ = this.megaMenuFacade.menuCountEntities$;
    const enabledFeatures$ = this.megaMenuFacade.enabledFeatures$;

    const menu$ = combineLatest([
      userRole$,
      menuCountEntities$,
      enabledFeatures$
    ])

    return menu$
      .pipe(
        map(([userRole, menuCountEntities, enabledFeatures]) => composeAppMainMenu({
          userRole,
          enabledFeatures,
          inProgressWarrantyCount: this.getValueFromEntity(menuCountEntities,  'inProgressWarrantyCount'),
          inProgressLeadCount: this.getValueFromEntity(menuCountEntities,  'inProgressLeadCount'),
          unscheduledWorkOrdersCount: this.getValueFromEntity(menuCountEntities,  'unscheduledWorkOrdersCount'),
          unscheduledQCICount: this.getValueFromEntity(menuCountEntities,  'unscheduledQCICount'),
          unscheduledWarrantiesCount: this.getValueFromEntity(menuCountEntities,  'unscheduledWarrantiesCount'),
          /* Invoice count */
          inProgressInvoiceCount: this.getValueFromEntity(menuCountEntities,  'inProgressInvoiceCount'),
          unpaidInvoiceCount: this.getValueFromEntity(menuCountEntities,  'unpaidInvoiceCount'),
          /* Menu Header Total Counts */
          pipelineTotalCount: this.getValueFromEntity(menuCountEntities,  'pipelineTotalCount'),
          estimatesTotalCount: this.getValueFromEntity(menuCountEntities,  'estimatesTotalCount'),
          schedulerTotalCount: this.getValueFromEntity(menuCountEntities,  'schedulerTotalCount'),
          invoiceTotalCount: this.getValueFromEntity(menuCountEntities,  'invoiceTotalCount')
        }))
      )
  }

  getValueFromEntity(entity, fieldName) {
    return get(find(entity, ['key', fieldName]), 'value', 0)
  }
}
