export class StringFormatter {
  trimString(string: string, length, end: any = '...') {
    if (!string) return string;
    const stringLength = string.length;
    if (stringLength < length) {
      // Returns input if it does not need to be trimmed
      return string;
    } else {
      const newString = string.slice(0, length - 1);
      return newString ? newString + end : newString;
    }
  }

  // Format status
  formatStatus(status: string) {
    try {
      if (!status) return '';
      switch (status) {
        case 'Did not buy':
          return "Didn't buy";
        default:
          return status;
      }
    } catch (error) {
      return '';
    }
  }

  toUpperCase(value: any) {
    try {
      const toUpperCase = value.toUpperCase();
      return toUpperCase;
    } catch (error) {
      return '';
    }
  }
}
