export class Markup {
  // Private variables
  public _id: string;
  public _markupId: string;
  public name: string;
  public per: string;
  public qty: number;
  public price: number;
  public subtotal: number;
  public uniqueId?: string;

  constructor({ _id, _markupId, name, per, qty, price, subtotal }: any) {
    this._id = _id;
    this._markupId = _markupId;
    this.name = name;
    this.per = per;
    this.qty = qty;
    this.price = price;
    this.subtotal = subtotal;
  }

  static create(rawMarkup: any) {
    return new Markup(rawMarkup);
  }
}
