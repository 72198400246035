import { Component, OnInit, Input, ElementRef } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'grid-chart',
  templateUrl: './grid-chart.component.html',
  styleUrls: ['./grid-chart.component.scss']
})
export class GridChartComponent implements OnInit {
  @Input() entity: any;
  @Input() color: any = '#b3daff';

  constructor(private container: ElementRef) {}

  ngOnInit() {
    var gridData = this.gridData();
    var grid = d3
      .select(this.container.nativeElement)
      .select('#grid')
      .append('svg')
      .attr('width', '250px')
      .attr('height', '250px');
    var row: any = grid
      .selectAll('.row')
      .data(gridData)
      .enter()
      .append('g')
      .attr('class', 'row');

    var column = row
      .selectAll('.square')
      .data(function(d) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('class', 'square')
      .attr('x', function(d) {
        return d.x;
      })
      .attr('y', function(d) {
        return d.y;
      })
      .attr('width', function(d) {
        return d.width;
      })
      .attr('height', function(d) {
        return d.height;
      })
      .style('fill', (d, i) => {
        if (this.entity && d.i <= this.entity.value) {
          return this.color;
        } else {
          return '#fff';
        }
      })
      .style('stroke', '#222');
  }

  gridData() {
    var data = new Array();
    var xpos = 1; //starting xpos and ypos at 1 so the stroke will show when we make the grid below
    var ypos = 1;
    var width = 25;
    var height = 25;
    var i = 1;
    // iterate for rows
    for (var row = 0; row < 10; row++) {
      data.push(new Array());

      // iterate for cells/columns inside rows
      for (var column = 0; column < 10; column++) {
        data[row].push({
          x: xpos,
          y: ypos,
          width: width,
          height: height,
          i
        });
        // increment the x position. I.e. move it over by 50 (width variable)
        xpos += width;
        i++;
      }
      // reset the x position after a row is complete
      xpos = 1;
      // increment the y position for the next row. Move it down 50 (height variable)
      ypos += height;
    }
    return data;
  }
}
