import { Component, OnDestroy } from '@angular/core';
import { AppState } from './app-state/app.state';
import {select, Store} from '@ngrx/store';
import { getMainNavMenuElements } from './core/main-menu/selectors/main-menu.selectors';
import { Observable } from 'rxjs';
import { SetTimeZone } from './core/app-settings/actions/app-settings.actions';
import { TimeZone } from './core/app-settings/state/app-settings.state';
import * as appSettingsSelector from './core/app-settings/selectors/app.selectors';
import * as loginSelectors from '../../../../libs/login/data-access/src/lib/+state/selectors/login.selectors';
import { ForecastService } from '../../../../libs/shared/angular/providers/src/lib/forecast/forecast.service';
import {takeUntil, switchMap, tap, debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { environment } from '@contract-estimator/shared/environments';
import {AuthService} from "@auth0/auth0-angular";
import { salesRepListIsEmpty } from 'libs/sales-reps/data-access/src/lib/+state/selectors/sales-reps.selector';
import {GetSalesReps} from "@contract-estimator/sales-reps/data-access";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  mainMenuItems$: Observable<any>;
  timeZonesList$: Observable<TimeZone[]>;
  selectedTimeZoneLabel$: Observable<string>;
  timeZone$: Observable<string>;
  timeZoneMenuItems: Array<any>;
  forecastData: Array<any>;
  isLoggedIn$: Observable<boolean>;
  userName$: Observable<string>;
  userPrivacyTermsStatus$: Observable<boolean>;
  closeObservables$ = new Subject();
  megaMenuUrl = environment.MEGA_MENU_URL;
  matrix;

  constructor(
    public store: Store<AppState>,
    public forecastService: ForecastService,
    public auth: AuthService,
  ) {
    this.getSalesReps();
    // Subscribes
    this.subscribeToMainMenu$();
    this.subscribeToTimeZones$();
    this.subscribeToSelectedTimeZones$();
    this.subscribeToSelectedTimeZone$();
    this.subscribeToIsLoggedIn$();
    this.subscribeToUserName$();
    this.subscribeToUserPrivacyTermsStatus$();
    // Gets forecast
    this.getWeeklyForecast();
  }

  // Get weekly forecast
  getWeeklyForecast() {
    // Checks if user is logged in
    this.auth.isAuthenticated$
      .pipe(
        takeUntil(this.closeObservables$),
        switchMap((isLoggedIn: boolean) => {
          // If logged in, get weekly forecast.
          if (isLoggedIn === true) {
            return this.forecastService.getWeeklyForecast();
          } else {
            return of(null);
          }
        })
      )
      .subscribe((res: any) => {
        this.forecastData = res;
      });
  }

  // Subscribes to the main menu
  subscribeToMainMenu$() {
    this.mainMenuItems$ = this.store.select(getMainNavMenuElements);
  }

  // On time zone change
  onTimeZoneChange(timeZone: string) {
    this.store.dispatch(new SetTimeZone(timeZone));
  }

  getSalesReps() {
    this.store
      .pipe(
        select(salesRepListIsEmpty),
        debounceTime(600),
        takeUntil(this.closeObservables$),
        tap(isSalesRepEmpty => isSalesRepEmpty && this.store.dispatch(new GetSalesReps()))
      )
      .subscribe()
  }

  // Gets time zones list from store
  subscribeToTimeZones$() {
    this.timeZonesList$ = this.store.select(appSettingsSelector.getTimeZones);
  }

  // Gets time zones list from store
  subscribeToSelectedTimeZones$() {
    this.selectedTimeZoneLabel$ = this.store.select(
      appSettingsSelector.getSelectedTimeZoneLabel
    );
  }

  // Gets time zone from store
  subscribeToSelectedTimeZone$() {
    this.timeZone$ = this.store.select(appSettingsSelector.getTimeZone);
  }

  // Gets is logged in state/flag
  subscribeToIsLoggedIn$() {
    this.isLoggedIn$ = this.store.select(loginSelectors.isLoggedIn);
  }

  // Gets is logged in state/flag
  subscribeToUserName$() {
    this.userName$ = this.store.select(loginSelectors.getUserName);
  }

  // Gets user has agreed to the terms and conditions
  subscribeToUserPrivacyTermsStatus$() {
    this.userPrivacyTermsStatus$ = this.store.select(
      loginSelectors.getUserPrivacyTermsAcceptance
    );
  }


  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }


}
