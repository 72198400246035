import {Component, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {TradeTypeEnum, TripChargePayee} from '@contract-estimator/shared/enums';

@Component({
  selector: 'qci-add-to-payroll-confirmation',
  templateUrl: './qci-add-to-payroll-confirmation.component.html',
  styleUrls: ['./qci-add-to-payroll-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QCIAddToPayrollConfirmationComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  @Input() orderTrade: string;
  @Output() performActionEvent = new Subject();
  payQCI: boolean;
  tripCharge: TripChargePayee;
  tradeTypeEnum = TradeTypeEnum;
  tripChargePayeeType = TripChargePayee;
  slideIndex = 0;

  constructor() {}

  ngOnInit() {
    console.log("ngOnInit")
  }

  // Performs the action
  performAction() {
    this.performActionEvent.next({
      payQCI: this.payQCI,
      tripCharge: this.tripCharge
    });
  }

  nextSlide() {
    this.slideIndex = 1;
  }

  ngOnDestroy() {
    this.payQCI = false;
    this.tripCharge = null;
    this.slideIndex = 0;
  }
}
