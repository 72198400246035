import { Injectable } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {map, pluck} from "rxjs/operators";
import {sortBy} from 'lodash';

export const GET_COMPANY_SALES_REPS = gql`
  query companySalesReps {
    companySalesReps {
        _id
      name
      email
      phoneNumber
      photoName
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ApolloSalesRepsService {

  constructor(
    private apollo: Apollo,
  ) { }

  /**
   * Fetches company sales reps
   * @param _companyId
   */
  fetchCompanySalesReps() {
    return this.apollo.watchQuery({
      query: GET_COMPANY_SALES_REPS,
    }).valueChanges.pipe(
      pluck('data', 'companySalesReps'),
      map((reps: any[]) => reps || []),
      map((salesReps) => sortBy(salesReps, ['name'])),
    );
  }

  /**
   * Fetches dropdown sales reps without blank option
   * @param _companyId
   */
  fetchDropDownCompanySalesRepsOptions() {
    return this.fetchCompanySalesReps()
      .pipe(
        map((reps: any[]) => reps.map(rep => ({
          value: rep._id,
          label: rep.name
        }))),
      );
  }

  /**
   * Fetches dropdown sales reps
   * @param _companyId
   */
  fetchDropDownCompanySalesReps() {
    return this.fetchDropDownCompanySalesRepsOptions()
      .pipe(
      map((reps: any[]) => [{value: "", label: ""}, ...reps])
    );
  }
}
