import { Component, OnInit, Input, HostBinding, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'rep-closing-ratio',
  templateUrl: './rep-closing-ratio.component.html',
  styleUrls: ['./rep-closing-ratio.component.scss']
})
export class RepClosingRatioComponent implements OnInit {
  @Input() repImage: any;
  @Input() closingRatio: number;

  constructor() {}

  ngOnInit() {}

  getPieClass() {
    if (this.closingRatio > 50) {
      return { clip: 'rect(auto, auto, auto, auto)' };
    }
  }

  getLeftSideClass() {
    const deg = +this.closingRatio * 4;
    return { transform: `rotate(${deg}deg)` };
  }

  getRightSideClass() {
    if (this.closingRatio <= 50) {
      return {
        display: 'none'
      };
    } else {
      return { transform: 'rotate(180deg)' };
    }
  }
}
