import { ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import * as fromAppSettings from './../core/app-settings/reducer/app-settings.reducer';
import * as fromJobInformation from '../../../../../libs/scheduler/job-information/feature/src/lib/reducer/job-information.reducer';
import * as fromWarrantyList from '../../../../../libs/warranty-list/data-access/src/lib/+state/reducers/warranty-list.reducer';
import * as fromPersonnel from '../../../../../libs/personnel/data-access/src/lib/+state/reducers/personnel.reducer';

export function defaultReducer<T>(state: T) {
  return state;
}

export const reducers: ActionReducerMap<any> = {
  routerReducer: fromRouter.routerReducer,
  appSettings: fromAppSettings.reducer,
  'job-information': fromJobInformation.reducer,
  'warranty-list': fromWarrantyList.reducer,
  personnel: fromPersonnel.reducer,
};
