import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as loginActions from '../actions/login.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RouterActions from 'libs/shared/router/data-access/src/lib/+state/router.actions';
import { Store } from '@ngrx/store';
import { LoginService } from '../../services/login/login.service';
import { AppState } from 'apps/contract-estimator/src/app/app-state/app.state';
import { get, isEmpty } from 'lodash';
import * as salesRepsActions from 'libs/sales-reps/data-access/src/lib/+state/actions/sales-reps.actions';
import { SalesRepService } from 'libs/sales-reps/data-access/src/lib/services/sales-reps.service';
import { ResetEstimateState } from 'libs/estimate/data-access/src/lib/+state/actions/estimate-template-loader.actions';
import { SessionStorageService } from '@contract-estimator/shared/session-storage/utils';
import { GenerateUniqueId } from '@contract-estimator/shared/utilities';
import { AlertType } from '@contract-estimator/shared/enums';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private store: Store<AppState>,
    private salesRepService: SalesRepService,
    private sessionStorageService: SessionStorageService,
    private generateUniqueId: GenerateUniqueId
  ) {}

  @Effect()
  userLogin$ = this.actions$.pipe(
    ofType(loginActions.USER_LOGIN),
    switchMap((action: loginActions.UserLogin) => {
      return this.loginService.loginAndGetUserInformation(action.payload).pipe(
        take(1),
        switchMap((user: any) => {
          const redirectTo = get(action, 'payload.redirectTo', null);
          return [
            new loginActions.ResetStoreState(),
            new loginActions.UpdateLoginState(user),
            // new salesRepsActions.SetSalesRepsBasedOnRole(user),
            new salesRepsActions.GetSalesReps(),
            new RouterActions.Go({
              path: [redirectTo || '/new-lead']
            })
          ];
        }),
        catchError(response => {
          try {
            this.sessionStorageService.removeItem('token');
          } catch (error) {
            // ... implement logic here
          }
          const alertState = this.composeAlertStatePayload(
            'Invalid Login. Please enter a valid username and password',
            AlertType.ERROR
          );
          return [
            new loginActions.UpdateAlertState(alertState),
            new loginActions.FailedLogin()
          ];
        })
      );
    })
  );

  @Effect()
  setSalesRepBasedOnRole$ = this.actions$.pipe(
    ofType(salesRepsActions.SET_SALES_REPS_BASED_ON_ROLE),
    switchMap((action: salesRepsActions.SetSalesRepsBasedOnRole): any => {
      try {
        if (isEmpty(action.user)) throw new Error('No user logged in!');
        // Gets sales rep if user logged in is admin
        if (action.user.role === 'admin')
          return [
            new salesRepsActions.GetSalesReps(),
            new salesRepsActions.UpdatePipelineMenuRequestStatus(true)
          ];
        // Compose sales rep from logged in user info
        const salesRep = this.salesRepService.composeSingleSalesRep(
          action.user
        );
        return [new salesRepsActions.SetSalesReps([salesRep])];
      } catch (error) {
        return [{ type: 'CANNOT_SET_SALES_REPS' }];
      }
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(loginActions.LOGOUT),
    switchMap((action: loginActions.Logout) => {
      try {
        this.sessionStorageService.removeItem('token');
        return [
          new ResetEstimateState(),
          new loginActions.ResetLoginState(),
          new loginActions.RemoveRefreshToken(),
          new RouterActions.Go({
            path: ['/login'],
            extras: {
              queryParams: {
                redirectTo: get(action, 'payload.redirectTo', '')
              }
            }
          })
        ];
      } catch (error) {
        return of({ type: 'ERROR_LOGGING_OUT' });
      }
    })
  );

  @Effect()
  removeRefreshToken$ = this.actions$.pipe(
    ofType(loginActions.REMOVE_REFRESH_TOKEN),
    switchMap((action: loginActions.RemoveRefreshToken) => {
      try {
        const refreshToken = this.sessionStorageService.getItem('refreshToken');
        this.sessionStorageService.removeItem('refreshToken');
        if (!refreshToken) {
          return [{ type: 'NO_TOKEN_PRESENT' }];
        }
        return this.loginService.logout().pipe(
          take(1),
          switchMap(res => {
            return [{ type: 'LOGGED_OUT' }];
          }),
          catchError(error => [{ type: 'USER_WAS_NOT_LOGGED_OUT' }])
        );
      } catch (error) {
        return of({ type: 'ERROR_LOGGING_OUT' });
      }
    })
  );

  @Effect()
  sendPasswordEmail$ = this.actions$.pipe(
    ofType(loginActions.SEND_PASSWORD_EMAIL),
    switchMap((action: loginActions.SendPasswordEmail) => {
      if (!action.email) {
        const alertPayload = this.composeAlertStatePayload(
          'Invalid Login. Please enter a valid username and password',
          AlertType.ERROR
        );
        return [new loginActions.UpdateAlertState(alertPayload)];
      }
      return this.loginService.sendResetPasswordEmail(action.email).pipe(
        take(1),
        switchMap(res => {
          const alertPayload = this.composeAlertStatePayload(
            'SUCCESS: We emailed you a link to reset your password.',
            AlertType.SUCCESS
          );
          return [new loginActions.UpdateAlertState(alertPayload)];
        }),
        catchError(error => {
          const alertPayload = this.composeAlertStatePayload(
            'There was an error emailing you the reset password email.',
            AlertType.ERROR
          );
          return [new loginActions.UpdateAlertState(alertPayload)];
        })
      );
    })
  );

  private composeAlertStatePayload(message: string, type: AlertType) {
    return {
      _id: this.generateUniqueId.generate(),
      message,
      type
    };
  }
}
