export function jobCostsUpdaterPayload(
  metadata,
  workOrderSections,
  purchaseOrderSections,
  miscellaneousCosts
) {
  return {
    ...metadata,
    workOrderSections,
    purchaseOrderSections,
    miscellaneousCosts
  };
}
