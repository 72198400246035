import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs/Subject';

import {ServerUrl} from 'apps/contract-estimator/src/app/config/server-url';
import {StringFormatter} from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';

@Component({
  selector: 'analytics-header-component',
  templateUrl: './analytics-header.component.html',
  styleUrls: ['./analytics-header.component.scss'],
})
export class AnalyticsHeaderComponent implements OnInit, OnDestroy {
  logoServerUrl: string = new ServerUrl().getCompanyLogoURL();
  stringFormatter: StringFormatter = new StringFormatter();
  @Input() title: string = 'CLOSING RATIO';
  @Input() status: string;
  @Input() role: string = null;
  @Input() hideSearch: boolean = false;
  search$ = new Subject<string>();
  @Output() datePeriodEvent = new Subject<any>();
  closeObservables$ = new Subject<string>();


  constructor(
  ) {}

  ngOnInit() {}


  onSelectedDatePeriod(datePeriod) {
    this.datePeriodEvent.next(datePeriod);
  }


  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }
}
