import {IRenameOrderPayload} from "apps/contract-estimator/src/app/shared/models/order.model";
import {
  IAddTradePayload,
  IDuplicateTradePayload, INavigateToInvoicePayload,
  IOpenContractPDFPayload,
  IRemoveOrderPayload, IMiscellaneousCostVM
} from "@contract-estimator/shared/interfaces";

export class RenameTradePayload implements  IRenameOrderPayload {
  constructor(public name: string, public _orderId: string) {}
}


export class RemoveTradePayload implements IRemoveOrderPayload {
  constructor(public _orderId: string) {
  }
}


export class DuplicateTradePayload implements  IDuplicateTradePayload {
  constructor(public _orderId: string) {
  }
}

export class AddTradePayload implements  IAddTradePayload {
  constructor(
    public _estimateTemplateId: string,
    public orderType: string
  ) {}
}


export class OpenContractPDFPayload implements IOpenContractPDFPayload {

}


export class NavigateToInvoicePayload implements INavigateToInvoicePayload {

}


export class AdditionalScopeJobCostsMiscellaneousCostVM implements IMiscellaneousCostVM {

  get estimatedTotal() {
    return this.estimatedPrice * this.estimatedQty;
  }

  constructor(
    public  _id?: string,
    public name?: string,
    public estimatedQty?: number,
    public estimatedPrice?: number,
  ) {
  }
}
