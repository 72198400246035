import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsTextEditorComponent } from './components/instructions-text-editor/instructions-text-editor.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [InstructionsTextEditorComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [InstructionsTextEditorComponent]
})
export class TradesSharedModule {}
