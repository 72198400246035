import { Injectable } from '@angular/core';
import {catchError, mergeMap, take} from "rxjs/operators";
import {of} from "rxjs/observable/of";
import {Observable} from "rxjs";

import {FileUploaderHttpServiceService} from "./file-uploader-http-service.service";

@Injectable({
  providedIn: 'root'
})
export class CloudStorageFileUploaderService {

  constructor(
    private fileUploaderHttpServiceService: FileUploaderHttpServiceService
  ) { }

  getPutObjectSignedUrl(fileName, fileType, bucket?) {
    return this.fileUploaderHttpServiceService.getPutObjectSignedUrl(fileName, fileType, bucket);
  }

  // Signed url and upload file
  getSignedUrlAndUploadFile(fileName, selectedFile, bucket?) {
    return this.getPutObjectSignedUrl(fileName, selectedFile.type, bucket)
      .pipe(
        take(1),
        mergeMap((res: any) =>
          this.uploadFile(res.signedRequest, selectedFile, fileName).pipe(take(1))
        ),
        catchError(error => of({ error: true, fileName }))
      );
  }

  // Makes put req to aws s3 servers with file
  uploadFile(signedRequest, file, fileName) {
    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next({ error: false, fileName: fileName });
          } else {
            observer.next({ error: true, fileName: fileName });
          }
        }
      };
      xhr.send(file);
    });
  }

  blobToBase64(res, picture) {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onloadend = function() {
        observer.next({
          source: reader.result,
          name: picture.name,
          description: picture.description
        });
      };
    });
  }

  /**
   * Download job photo
   * @param fileName
   */
  downloadJobPhoto(fileName: string) {
    return this.fileUploaderHttpServiceService.downloadJobPhoto(fileName);
  }
}
