import { createAction, props } from '@ngrx/store';
import { MegaMenuEntity } from './mega-menu.models';

export const loadMegaMenu = createAction('[MegaMenu] Load MegaMenu');

export const loadMegaMenuSuccess = createAction(
  '[MegaMenu] Load megaMenu success',
  props<{ megaMenu: MegaMenuEntity[] }>()
);

export const loadMegaMenuFailure = createAction(
  '[MegaMenu] Load megaMenu failure',
  props<{ error: any }>()
);

export const loadMegaMenuEnabledFeatures = createAction(
  '[MegaMenu] Load mega menu enabled features',
  props<{enabledFeatures: any}>()
)
