import * as storesActions from '../actions/stores.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface StoreState {
  _id: string;
  name: string;
  phoneNumber: string;
  email: string;
  altEmail?: string;
  addressLine1: string;
  addressLine2: string;
}

export interface State extends EntityState<StoreState> {
  loading: boolean;
  selectedId: string;
  error: string;
}

export const storesAdapter: EntityAdapter<StoreState> = createEntityAdapter<
  StoreState
>({
  selectId: (sub: StoreState) => sub._id
});

export const initialState: State = storesAdapter.getInitialState({
  loading: false,
  selectedId: null,
  error: null,
});

export function reducer(
  state: State = initialState,
  action: storesActions.Actions
) {
  switch (action.type) {
    case storesActions.SET_STORES:
      return storesAdapter.addMany(action.payload, state);
    case storesActions.SET_SELECTED_STORE_ID:
      return ({...state, selectedId: action._id});
    case storesActions.SET_STORES_LOADING:
      return ({...state, loading: action.loading});
    case storesActions.SET_STORES_ERROR:
      return ({...state, error: action.error});
    case storesActions.RESET_STORES_STATE:
      return ({...initialState});
    default:
      return state;
  }
}
