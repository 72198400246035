/**
 * Formats phone number to 208-444-4444
 * @param phoneNumber
 */
export function formatPhoneNumber(phoneNumber: string, format = "$1-$2-$3") {
  try {
    if (!phoneNumber) return "";
    const phone =  phoneNumber.replace(/[^\w]/gi, '');
    //check if number length equals to 10
    if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, format);
    }
    return phoneNumber;
  } catch (error) {
    return phoneNumber;
  }
}
