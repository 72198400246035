import * as actions from '../actions/job-information.actions';
import { SchedulerJobInfoState } from 'libs/shared/interfaces/src/lib/scheduler.interfaces';

export { SchedulerJobInfoState as State };

export const initialState: SchedulerJobInfoState = {
  jobName: null,
  _salesRepId: null,
  customer: {
    _id: null,
    name: null,
    addressLine1: null,
    addressLine2: null,
    phoneNumber: null,
    altPhoneNumber: null,
    email: null,
    city: null,
    state: null,
    zipCode: null
  }
};

export function reducer(
  state: SchedulerJobInfoState = initialState,
  action: actions.Actions
) {
  switch (action.type) {
    case actions.SCHEDULER_JOB_INFO:
      return updateSchedulerLeadInfo(state, action);
    case actions.CHANGE_JOB_SALES_REP:
      return changeJobSalesRep(state, action);
    case actions.UPDATE_JOB_INFO_OWNER_INFORMATION:
      return updateOwnerInformation(state, action);
    case actions.UPDATE_JOB_INFORMATION:
      return updateJobInformation(state, action);
    default:
      return state;
  }
}

/* Functions  */

function updateJobInformation(
  state: SchedulerJobInfoState,
  action: actions.UpdateJobInformation
) {
  try {
    return {
      ...state,
      ...action.changes
    };
  } catch (error) {
    return state;
  }
}

function updateSchedulerLeadInfo(
  state: SchedulerJobInfoState,
  action: actions.SchedulerJobInfo
) {
  try {
    return {
      ...state,
      ...action.jobInfo
    };
  } catch (error) {}
}

function changeJobSalesRep(
  state: SchedulerJobInfoState,
  action: actions.ChangeJobSalesRep
) {
  return {
    ...state,
    _salesRepId: action._id
  };
}

function updateOwnerInformation(
  state: SchedulerJobInfoState,
  action: actions.UpdateJobOwnerInformation
) {
  try {
    return {
      ...state,
      customer: {
        _id: action.payload._id,
        name: action.payload.name,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        altPhoneNumber: action.payload.altPhoneNumber,
        addressLine1: action.payload.address.street,
        city: action.payload.address.city,
        state: action.payload.address.state,
        zipCode: action.payload.address.zipCode
      }
    };
  } catch (error) {
    return state;
  }
}
