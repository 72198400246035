import { get } from 'lodash';

import {AgreementCompany} from "@contract-estimator/shared/interfaces";

export function composePDFCompany(company): AgreementCompany {
  return {
    name: get(company, 'name', ''),
    addressLine1: get(company, 'addressLine1', ''),
    addressLine2: get(company, 'addressLine2', ''),
    email: get(company, 'email', ''),
    phoneNumber: get(company, 'phoneNumber', ''),
    taxIDNumber: get(company, 'taxIDNumber', ''),
    logoName: get(company, 'logoName', ''),
  };
}
