import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LoginEffects } from './+state/effects/login.effects';
import * as fromLogin from './+state/reducer/login.reducer';
import { LoginService } from './services/login/login.service';
import { PrivacyTermsGuardService } from './services/privacy-terms-guard/privacy-terms-guard.service';
import { DeactivateEstimateGuardService } from './services/deactivated-estimate-guard/deactivate-estimate-guard.service';
import { SharedSessionStorageUtilsModule } from '@contract-estimator/shared/session-storage/utils';
import { GenerateUniqueId } from '@contract-estimator/shared/utilities';
import {CanLoadService} from "./services/can-load/can-load.service";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('login', fromLogin.reducer),
    EffectsModule.forFeature([LoginEffects]),
    SharedSessionStorageUtilsModule
  ],
  providers: [
    LoginService,
    PrivacyTermsGuardService,
    DeactivateEstimateGuardService,
    GenerateUniqueId,
    CanLoadService
  ]
})
export class LoginDataAccessModule {}
