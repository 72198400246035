import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MegaMenuItem} from "@contract-estimator/shared/interfaces";
import {Observable, Subject} from "rxjs";
import {MegaMenuService} from "../../../../../data-access/src/lib/providers/mega-menu.service";
import {takeUntil} from "rxjs/operators";
import {environment} from "@contract-estimator/shared/environments";

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class AppVerticalMenuComponent implements OnInit, OnDestroy {

  @Input() menuItems: Array<MegaMenuItem> = [];
  @Input() menuUrl;
  menuData$: Observable<any>;
  closeObservables$ = new Subject();

  constructor(public megaMenuService: MegaMenuService) {}

  ngOnInit() {
    this.megaMenuService.setUrls(this.menuUrl);
    this.getMenu();
    this.getCompanyEnabledFeatures();
    this.getMenuCountsAsAnArray();
  }

  // Gets menu data
  getMenu() {
    this.menuData$ = this.megaMenuService.getAppMenu();
  }


  // Gets menu counts as an array
  getMenuCountsAsAnArray() {
    this.megaMenuService.getMenuCountAsAnArray()
      .pipe(takeUntil(this.closeObservables$))
      .subscribe()
  }

  /**
   * Gets company's enabled features
   */
  getCompanyEnabledFeatures() {
    this.megaMenuService.getEnabledFeatures()
      .pipe(takeUntil(this.closeObservables$))
      .subscribe();
  }

  /**
   * Gets logo URL
   */
  getLogoUrl() {
    return environment.JOB_PHOTO_CLOUDFRONT_URL + 'full_logo.png?d=250x250';
  }


  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }

}


@Component({
  selector: 'app-vertical-menu-option',
  template: `<div class="badge-group" *ngIf="menu.count;else nonBadgeOption">
    <p class="title menu-label">{{menu?.label}}</p>
    <p class="my-badge">{{menu?.count}}</p>
  </div>
  <ng-template #nonBadgeOption>
    <p>{{menu?.label}}</p>
  </ng-template>`,
  styleUrls: ['./vertical-menu.component.scss'],
  styles: ['p { text-align: left!important; }']
})
export class AppVerticalMenuOptionComponent {
  @Input() menu;
}
