import { of } from 'rxjs/observable/of';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as subcontractorsActions from '../actions/subcontractor.actions';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { SubcontractorService } from '../../services/subcontractors.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SubcontractorState } from '../reducers/subcontractors.reducer';

@Injectable()
export class SubcontractorEffects {
  serverUrl: string = new ServerUrl().getUrl();

  constructor(
    private actions$: Actions,
    private subcontractorService: SubcontractorService
  ) {}

  @Effect()
  getSubcontractors$ = this.actions$.pipe(
    ofType(subcontractorsActions.GET_SUBCONTRACTORS),
    switchMap((action: subcontractorsActions.GetSubcontractors) => {
      return this.subcontractorService.getAndComposeSubcontractors().pipe(
        take(1),
        switchMap((salesReps: Array<SubcontractorState>) => {
          return [new subcontractorsActions.SetSubcontractors(salesReps)];
        }),
        catchError(err => {
          return of({
            type: 'SET_SUBS_FETCH_ERROR',
            payload: 'Error fetching sales reps.'
          });
        })
      );
    })
  );
}
