import { get } from 'lodash';

import { Address } from './address/address.model';
import {composeCityStateAndZipCode} from "../../../../../contractor-server/src/app/utils/src/app/address";
export class Entity {
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  altEmail?: string;
  phoneNumber?: string;
  altPhoneNumber?: string;
  address?: Address = {
    street: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  };
  id?: string;
  addressLine1?: string;
  addressLine2?: string;

  constructor(obj?: any) {
    if (obj) {
      this.firstName = obj.firstName || '';
      this.lastName = obj.lastName || '';
      this.name = obj.name || '';
      this.email = obj.email || '';
      this.altEmail = obj.altEmail || '';
      this.phoneNumber = obj.phoneNumber || '';
      this.altPhoneNumber = obj.altPhoneNumber || '';
      this.address = obj.address || '';
      this.id = obj.id || obj._id || '';
      this.addressLine1 = get(obj, 'address.street');
      this.addressLine2 = composeCityStateAndZipCode(obj.address)
    } else {
      this.firstName = '';
      this.lastName = '';
      this.name = '';
      this.email = '';
      this.altEmail = '';
      this.phoneNumber = '';
      this.address.state = '';
      this.address.zipCode = '';
      this.address.street = '';
      this.address.city = '';
      this.id = '';
    }
  }
}

export class BasicEntity {
  name?: string;
  phoneNumber?: string;
  altPhoneNumber?: string;
  email?: string;
  altEmail?: string;
  addressLine1?: string;
  addressLine2?: string;
  constructor(builder: any) {
    this.name = builder.name;
    this.phoneNumber = builder.phoneNumber;
    this.altPhoneNumber = builder.altPhoneNumber;
    this.email = builder.email;
    this.altEmail = builder.altEmail;
    this.addressLine1 = builder.addressLine1;
    this.addressLine2 = builder.addressLine2;
  }
}
