import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EstimatePanelMenuComponent } from 'libs/shared/angular/components/src/lib/estimates/components/estimate-panel-menu/estimate-panel-menu.component';
import { EstimateWOHeaderMetadataTableComponent } from 'libs/shared/angular/components/src/lib/estimates/components/estimate-wo-header-metadata-table/estimate-wo-header-metadata-table.component';
import { WorkOrderEstimateDynamicComponent } from './components/work-order-estimate-dynamic/work-order-estimate-dynamic.component';
import { EstimateSplashScreenComponent } from 'libs/shared/angular/components/src/lib/estimates/components/estimate-splash-screen/estimate-splash-screen.component';
import { EstimatePoHeaderMetadataTableComponent } from './components/estimate-po-header-metadata-table/estimate-po-header-metadata-table.component';
import { PurchaseOrderEstimateDynamicComponent } from './components/purchase-order-estimate-dynamic/purchase-order-estimate-dynamic.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { SharedAngularDirectivesModule } from '../../../../directives/src/lib/shared-angular-directives.module';
import { SharedAngularPipesModule } from '../../../../pipes/src/lib/shared-angular-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    UIComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedAngularDirectivesModule,
    SharedAngularPipesModule
  ],
  declarations: [
    EstimatePanelMenuComponent,
    EstimateWOHeaderMetadataTableComponent,
    WorkOrderEstimateDynamicComponent,
    EstimateSplashScreenComponent,
    EstimatePoHeaderMetadataTableComponent,
    PurchaseOrderEstimateDynamicComponent,
    CompanyInformationComponent
  ],
  exports: [
    EstimatePanelMenuComponent,
    EstimateWOHeaderMetadataTableComponent,
    WorkOrderEstimateDynamicComponent,
    EstimateSplashScreenComponent,
    EstimatePoHeaderMetadataTableComponent,
    PurchaseOrderEstimateDynamicComponent,
    CompanyInformationComponent,
    UIComponentsModule
  ]
})
export class EstimatesSharedModule {}
