import { Component, OnInit, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as xlsx from 'xlsx';

@Component({
  selector: 'report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {
  @Input() cols: Array<any> = [
    { field: 'contractType', header: 'CONTRACT TYPE' },
    { field: 'owner', header: 'NAME' },
    { field: 'salesRep', header: 'SALES REP' },
    { field: 'workOrderTotal', header: 'WORK ORDER TOTAL' },
    { field: 'purchaseOrderTotal', header: 'PURCHASE ORDER TOTAL' },
    { field: 'miscellaneousTotal', header: 'MISCELLANEOUS EXPENSES' },
    { field: 'total', header: 'TOTAL SALES' },
    { field: 'markup', header: 'MARKUP' }
  ];

  @Input() lineItems: Array<any>;

  constructor() {}

  ngOnInit() {}

  exportExcel() {
    const worksheet = xlsx.utils.json_to_sheet(this.lineItems);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, 'Elite Roofing Report');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + ' ' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  // Prints report
  print() {
    window.print();
  }
}
