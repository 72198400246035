import { Component, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';

import {
  isEmailArrayValid,
  isEmailArrayValidAllowEmptyArray
} from 'libs/shared/angular/utils/src/lib/validators/form.validators';

@Component({
  selector: 'e-mailer-component',
  templateUrl: './e-mailer.component.html',
  styleUrls: ['./e-mailer.component.scss']
})
export class EMailerComponent implements OnInit {
  sendForm: FormGroup;
  @Input() isSending: boolean = false;
  @Input() toLabel: string = 'To';
  @Input() bccLabel: string = 'BCC';
  @Input() ccLabel: string = 'CC';
  @Input() commentsLabel: string = 'Cover Letter Notes';
  @Input() title: string;
  @Input() calledFrom: string = 'other';
  @Input() showCC = true;
  @Input() showBCC = false;
  @Input() showCheckBox: boolean = false;
  @Input() checkBoxLabel: 'Send copies to all.';
  @Input() showCancelBtn = false;
  @Output() sendEvent = new Subject();
  @Output() canceledSendingEmail = new Subject();

  constructor(public formBuilder: FormBuilder) {
    this.composeFormGroup();
  }

  ngOnInit() {}

  composeFormGroup() {
    this.sendForm = this.formBuilder.group({
      to: [[], [isEmailArrayValid]],
      cc: [[], [isEmailArrayValidAllowEmptyArray]],
      bcc: [[], [isEmailArrayValidAllowEmptyArray]],
      subject: ['', [Validators.required]],
      comments: [''],
      sendCopies: [true]
    });
  }

  send() {
    this.sendEvent.next(this.sendForm);
  }

  setBccRecipientsEmails(emails: Array<string> | string) {
    if (isEmpty(this.sendForm)) return;
    if (typeof emails === 'string') {
      this.sendForm.controls['bcc'].setValue([emails]);
    } else {
      this.sendForm.controls['bcc'].setValue(emails);
    }
  }

  setCCRecipientsEmails(emails: Array<string> | string) {
    if (isEmpty(this.sendForm)) return;
    if (typeof emails === 'string') {
      this.sendForm.controls['cc'].setValue([emails]);
    } else {
      this.sendForm.controls['cc'].setValue(emails);
    }
  }

  setRecipientsEmails(emails: Array<string> | string) {
    if (isEmpty(this.sendForm)) return;
    if (typeof emails === 'string') {
      this.sendForm.controls['to'].setValue([emails]);
    } else {
      this.sendForm.controls['to'].setValue(emails);
    }
  }

  setEmailSubject(subject: string) {
    if (isEmpty(this.sendForm)) return;
    this.sendForm.controls['subject'].setValue(subject);
  }

  getCarbonCopy() {
    try {
      return this.sendForm.controls['cc'].value[0] || '';
    } catch (error) {}
  }

  // Closes dialog
  cancel() {
    this.canceledSendingEmail.next()
  }
}
