import * as salesRepsActions from '../actions/personnel.actions';
import * as fromSalesRepState from '../states/personnel.state';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const personnelAdapter: EntityAdapter<fromSalesRepState.PersonnelState> = createEntityAdapter<
  fromSalesRepState.PersonnelState
>({
  selectId: (salesRep: fromSalesRepState.PersonnelState) => salesRep._id
});

export const initialState: fromSalesRepState.State = personnelAdapter.getInitialState(
  {
    loading: false
  }
);

export function reducer(
  state: fromSalesRepState.State = initialState,
  action: salesRepsActions.Actions
) {
  switch (action.type) {
    case salesRepsActions.SET_PERSONNEL:
      return personnelAdapter.addMany(action.payload, state);
    default:
      return state;
  }
}
