import { DateTime } from 'luxon';
import { ContractTypeEnum } from './../../../../enums/src/lib/enums';
import { toUSDCurrency } from './../../../../utilities/src/lib/currency/currency.utils';

export const COUPON_DURATION_IN_DAYS = 7
export const COUPON_INSURANCE_AMOUNT = 250
export const COUPON_LARGE_JOB_MINIMUM_CONTRACT_AMOUNT = 25000
export const COUPON_LARGE_JOB_AMOUNT = 500
export const COUPON_BIG_JOB_MINIMUM_CONTRACT_AMOUNT = 15000
export const COUPON_BIG_JOB_AMOUNT = 350
export const COUPON_MEDIUM_JOB_MINIMUM_CONTRACT_AMOUNT = 8000
export const COUPON_MEDIUM_JOB_AMOUNT = 250
export const COUPON_SMALL_JOB_MINIMUM_CONTRACT_AMOUNT = 5000
export const COUPON_SMALL_JOB_AMOUNT = 150
export const COUPON_DESCRIPTION = `Elite Roofing offers you a coupon (discount) when you sign a contract within ${COUPON_DURATION_IN_DAYS} days of receiving it.\nThere is a limit of one per customer and it\'s not valid with any other offer or discount.`
export const WB_COUPON_DESCRIPTION = `Weatherbuilt offers you a coupon (discount) when you sign a contract within ${COUPON_DURATION_IN_DAYS} days of receiving it.\nThere is a limit of one per customer and it\'s not valid with any other offer or discount.`


export class Coupon {
  private _currentDate: DateTime = DateTime.local();
  private _description: string = this.companyName.toLowerCase().includes("weatherbuilt") ? WB_COUPON_DESCRIPTION : COUPON_DESCRIPTION;
  get name() {
    return `${toUSDCurrency(this.amount)} OFF ANY JOB`
  }
  get description() {
    return this.companyName.toLowerCase().includes("weatherbuilt") ? WB_COUPON_DESCRIPTION : COUPON_DESCRIPTION;;
  }
  get expirationDate() {
    return this._currentDate.plus({days: COUPON_DURATION_IN_DAYS});
  }

  set currentDate(currentDate: DateTime) {
    this._currentDate = currentDate;
  }
  set description(description: string) {
    this._description = description;
  }
  constructor(
    public amount: number,
    private companyName: string = ""
  ) {}

  // Creates coupon from type, amount, and date
  static fromType(type, amount, companyName="") {
    if(!amount) throw new Error("Invalid contract amount")
    if(!type) throw new Error("Job does not have a valid trade type")
    if(type === ContractTypeEnum.INSURANCE) {
      throw new Error("Insurance coupons were deprecated.")
      // return Coupon.fromAmount(COUPON_INSURANCE_AMOUNT, companyName)
    } else if(type === ContractTypeEnum.COMMERCIAL) {
      throw new Error("Commercial coupons were deprecated.")
      // return Coupon.fromAmount(COUPON_INSURANCE_AMOUNT, companyName)
    } else {
      return Coupon.fromSalesAmount(amount, companyName)
    }
  }

  static fromSalesAmount(amount: number, companyName="") {
    if(amount > COUPON_LARGE_JOB_MINIMUM_CONTRACT_AMOUNT) {
      return Coupon.fromAmount(COUPON_LARGE_JOB_AMOUNT, companyName)
    } else if(amount > COUPON_BIG_JOB_MINIMUM_CONTRACT_AMOUNT) {
      return Coupon.fromAmount(COUPON_BIG_JOB_AMOUNT, companyName)
    } else if(amount > COUPON_MEDIUM_JOB_MINIMUM_CONTRACT_AMOUNT) {
      return Coupon.fromAmount(COUPON_MEDIUM_JOB_AMOUNT, companyName)
    } else if(amount >= COUPON_SMALL_JOB_MINIMUM_CONTRACT_AMOUNT) {
      return Coupon.fromAmount(COUPON_SMALL_JOB_AMOUNT, companyName)
    } else {
      throw new Error("Sorry! Amount does not qualify for coupon.");
    }
  }


  // Creates coupon from amount only
  static fromAmount(amount: number, companyName="") {
    return new Coupon(
      amount,
      companyName
    )
  }

  // Checks if job can be issued a coupon
  static isEligible(days:  number) {
    return days <= COUPON_DURATION_IN_DAYS
  }
}
