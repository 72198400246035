import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash';

import { ServerUrl } from '../../../../../../apps/contract-estimator/src/app/config/server-url';
import { SalesRepState } from '../+state/states/sales-rep.state';


//Creating and interface to guarantee that the response has this values
interface serverResponseInterface {
  error: boolean;
  data: any;
}

@Injectable()
export class SalesRepService {
  //Variables
  serverUrl: string;
  data: any;
  serverResponse: serverResponseInterface;
  salesRepNamesServerResponse: serverResponseInterface;

  //Constructor
  constructor(public http: HttpClient) {
    this.serverUrl = new ServerUrl().getUrl();
  }

  // Gets all sales reps
  getSalesReps() {
    return this.http.get(this.serverUrl + '/sales-reps');
  }

  // Gets all company sales reps for drop down
  getDropdownCompanySalesReps(_companyId: string) {
    return this.http.get(this.serverUrl + '/sales-reps?' + _companyId);
  }

  // Composes sales rep array from the response
  composeSalesReps(rawSalesReps: Array<any>): Array<SalesRepState> {
    if (isEmpty(rawSalesReps)) return [];
    const list = [];
    for (let i = 0; i < rawSalesReps.length; i++) {
      if (isEmpty(rawSalesReps[i])) continue;
      const salesRep: SalesRepState = this.composeSingleSalesRep(
        rawSalesReps[i]
      );
      if (isEmpty(salesRep)) continue;
      list.push(salesRep);
    }
    return list;
  }

  // Composes single sales rep
  composeSingleSalesRep(rawSalesRep: any): SalesRepState {
    if (isEmpty(rawSalesRep)) return null;
    return {
      _id: rawSalesRep._id,
      name: rawSalesRep.name,
      email: rawSalesRep.email,
      phoneNumber: rawSalesRep.phoneNumber,
      photoName: rawSalesRep.photoName,
      funFact: rawSalesRep.funFact
    };
  }
}
