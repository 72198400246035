import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrimmer]'
})
export class TrimmerDirective {
  @HostListener('blur') onBlur() {
    this.trim();
  }

  constructor(public el: ElementRef) {}

  trim() {
    try {
      if (!this.el.nativeElement.value) return;
      this.el.nativeElement.value = this.el.nativeElement.value.trim();
      return this.el.nativeElement.value;
    } catch (error) {
      return;
    }
  }
}
