import * as actions from '../actions/warranty-list.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface WarrantyListState {
  _jobId: string;
  _orderId: string;
  _workOrderId: string;
  jobNumber: string;
  salesRepName: string;
  subcontractorName: string;
  ownerName: string;
  ownerAddressLine1: string;
  ownerAddressLine2: string;
  ownerPhoneNumber: string;
  index: number;
  notes?: string;
  days?: number;
}

export interface State extends EntityState<WarrantyListState> {
  loading: boolean;
  alert: {
    message: string;
    id: any;
  };
}
export const warrantyListAdapter: EntityAdapter<WarrantyListState> = createEntityAdapter<
  WarrantyListState
>({
  selectId: (state: WarrantyListState) => state._workOrderId || state._orderId
});

export const initialState: State = warrantyListAdapter.getInitialState({
  loading: false,
  alert: {
    message: '',
    id: null
  }
});

export function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case actions.WARRANTY_LIST:
      return warrantyListAdapter.addAll(action.payload, state);
    case actions.UPDATE_WARRANTY_LIST_STATE:
      return updateWorkOrderListState(state, action);
    case actions.RESET_WARRANTY_LIST_STATE: {
      return undefined;
    }
    case actions.UPDATE_WARRANTY_LIST_ELEMENT:
      return warrantyListAdapter.updateOne(
        {
          id: action._orderId,
          changes: action.changes
        },
        state
      );
    case actions.REMOVE_WARRANTY_FROM_LIST:
      return warrantyListAdapter.removeOne(action._id, state);
    default:
      return state;
  }
}

/* FUNCTIONS */
function updateWorkOrderListState(
  state: State,
  action: actions.UpdateWarrantyListState
) {
  return {
    ...state,
    ...action.changes
  };
}
