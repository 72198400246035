import { AlertState } from '@contract-estimator/shared/interfaces';

export interface State {
  _userId: string;
  name: string;
  role: string;
  loggedIn: boolean;
  token: any;
  acceptedPrivacyTerms: boolean;
  ui?: {
    alert?: AlertState;
  };
}

export const initialLoginState: State = {
  _userId: null,
  name: null,
  role: null,
  loggedIn: false,
  token: null,
  acceptedPrivacyTerms: false,
  ui: {
    alert: {
      _id: null,
      message: null,
      type: null
    }
  }
};
