import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

import { PipelineComponent } from './pipeline-table/pipeline.component';
import { PipelineSharedModule } from '../../../../shared/angular/components/src/lib/pipeline/pipeline-shared.module';
import { LeadHistoryLedgerComponent } from './lead-history-ledger/lead-history-ledger.component';
import { LeadBadgeComponent } from './lead-badge/lead-badge.component';
import { LeadOptionsMenuComponent } from './lead-options-menu/lead-options-menu.component';
import { ViewedBadgeComponent } from './viewed-badge/viewed-badge.component';

@NgModule({
  imports: [
    CommonModule,
    PipelineSharedModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule
  ],
  declarations: [
    PipelineComponent,
    LeadHistoryLedgerComponent,
    LeadBadgeComponent,
    LeadOptionsMenuComponent,
    ViewedBadgeComponent
  ],
  exports: [
    PipelineComponent,
    PipelineSharedModule,
    LeadHistoryLedgerComponent,
    LeadBadgeComponent,
    LeadOptionsMenuComponent,
    ViewedBadgeComponent
  ]
})
export class PipelineUiModule {}
