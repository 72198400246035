// Composes navigation menu for browser app

import {UserRoleType} from "@contract-estimator/shared/enums";

export function composeMenu({
  userRole,
  inProgressWarrantyCount,
  inProgressLeadCount,
  unscheduledWorkOrdersCount,
  unscheduledQCICount,
  unscheduledWarrantiesCount,
  /* Invoice count */
  inProgressInvoiceCount,
  unpaidInvoiceCount,
  /* Menu Header Total Counts */
  pipelineTotalCount,
  estimatesTotalCount,
  schedulerTotalCount,
  invoiceTotalCount
}) {
  const PIPELINE = {
    label: 'Pipeline',
    count: pipelineTotalCount,
    items: [
      {
        label: 'Leads',
        items: [
          {
            label: 'New Lead',
            route: 'new-lead'
          }
        ]
      },
      {
        label: 'Warranties',
        items: [
          {
            label: 'Completed Jobs',
            route: 'work-orders/completed'
          },
          {
            label: 'In progress',
            route: 'warranty/lead/In progress',
            count: inProgressWarrantyCount
          },
          {
            label: 'Deleted',
            route: 'warranty/lead/Deleted'
          }
        ]
      }
    ]
  };

  const ESTIMATES = {
    label: 'Estimates',
    count: estimatesTotalCount,
    items: [
      {
        label: '',
        items: [
          {
            label: 'In Progress',
            count: inProgressLeadCount,
            route: 'pipeline/In progress'
          },
          {
            label: "Didn't buy",
            route: 'pipeline/Did not buy'
          },
          {
            label: 'Sold',
            route: 'pipeline/Sold'
          },
          {
            label: 'Deleted',
            route: 'pipeline/Deleted'
          }
        ]
      }
    ]
  };

  const SCHEDULER = {
    label: 'Scheduler',
    count: schedulerTotalCount,
    items: [
      {
        label: 'Work Orders',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledWorkOrdersCount,
            route: 'work-orders/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'work-orders/scheduled'
          },
          {
            label: 'Completed',
            route: 'work-orders/completed'
          },
          {
            label: 'Deleted',
            route: 'work-orders/deleted'
          }
        ]
      },
      {
        label: 'QCIs',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledQCICount,
            route: 'qci/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'qci/scheduled'
          },
          {
            label: 'Completed',
            route: 'qci/completed'
          },
          {
            label: 'Deleted',
            route: 'qci/deleted'
          }
        ]
      },
      {
        label: 'Warranties',
        items: [
          {
            label: 'Unscheduled',
            count: unscheduledWarrantiesCount,
            route: 'warranty/work-orders/unscheduled'
          },
          {
            label: 'Scheduled',
            route: 'warranty/work-orders/scheduled'
          },
          {
            label: 'Completed',
            route: 'warranty/work-orders/completed'
          },
          {
            label: 'Deleted',
            route: 'warranty/work-orders/Deleted'
          }
        ]
      }
    ]
  };

  const INVOICES = {
    label: 'Invoices',
    count: invoiceTotalCount,
    items: [
      {
        label: '',
        items: [
          {
            label: 'In Progress',
            count: inProgressInvoiceCount,
            route: 'invoices/In progress'
          },
          {
            label: 'Unpaid',
            count: unpaidInvoiceCount,
            route: 'invoices/outstanding'
          },
          {
            label: 'Paid',
            route: 'invoices/paid'
          },
          {
            label: 'Deleted',
            route: 'invoices/deleted'
          }
        ]
      }
    ]
  };

  const PRODUCTS = {
    label: 'Products',
    items: [
      {
        label: 'Shingle Roofs',
        items: [
          {
            label: 'Owens Corning',
            link: 'https://www.owenscorning.com/roofing/shingles'
          },
          {
            label: 'IKO',
            link: 'https://www.iko.com/na/'
          }
        ]
      },
      {
        label: 'Flat Roofs',
        items: [
          {
            label: 'GAF',
            link:
              'https://www.gaf.com/en-us/roofing-products/commercial-roofing-products'
          },
          {
            label: 'IB',
            link: 'https://www.ibroof.com/'
          },
          {
            label: 'Mulehide',
            link: 'https://www.mulehide.com/'
          },
          {
            label: 'Genflex',
            link: 'http://www.genflex.com/'
          },
          {
            label: 'Coatings',
            link: 'https://henry.com/'
          }
        ]
      }
    ]
  };

  const TEAM_ANALYTICS = {
    label: 'Analytics',
    items: [{
      label: '',
      items: [{
        label: 'My Analytics',
        route: "analytics/team-analytics"
      }]
    }]
  }

  const ADMIN = {
    label: 'Admin',
    items: [
      {
        label: 'Payroll',
        items: [
          {
            label: 'In Progress',
            route: 'payroll/list/in progress'
          },
          {
            label: 'Paid',
            route: 'payroll/list/paid'
          },
          {
            label: 'Deleted',
            route: 'payroll/list/deleted'
          },
          {
            label: 'Logbook',
            route: 'payroll/logbook'
          },
          {
            label: 'Database',
            route: 'payroll/cost-database'
          }
        ]
      },
      {
        label: 'Analytics',
        items: [
          {
            label: 'Leads',
            route: 'analytics/leads'
          },
          {
            label: 'Sales',
            route: 'analytics/sales'
          },
          {
            label: 'Closing Ratio',
            route: 'analytics/closing-ratio'
          },
          {
            label: "Didn't Buy",
            route: 'analytics/did-not-buy'
          },
          {
            label: 'Payments',
            route: 'analytics/payments'
          },
          {
            label: 'Predicted Markup',
            route: 'analytics/predict-markup'
          },
          {
            label: 'Rebates',
            route: 'analytics/rebates'
          },
          {
            label: 'Team Analytics',
            route: "analytics/team-analytics"
          },
          {
            label: 'More Analytics',
            link: 'https://eliteroofing-salesanalytics.herokuapp.com/',
          }
        ]
      },
      {
        label: 'Reports',
        items: [
          {
            label: 'Leads',
            items: [
              {
                label: 'In Progress',
                route: 'reports/leads/in progress'
              },
              {
                label: "Didn't Buy",
                route: 'reports/leads/did not buy'
              },
              {
                label: 'Sold',
                route: 'reports/leads/sold'
              }
            ]
          },
          {
            label: 'Advertising',
            items: [
              {
                label: 'In Progress',
                route: 'reports/advertising/in progress'
              },
              {
                label: "Didn't Buy",
                route: 'reports/advertising/did not buy'
              },
              {
                label: 'Sold',
                route: 'reports/advertising/sold'
              }
            ]
          }
        ]
      }
    ]
  };

  const menu: any[] = [PIPELINE, ESTIMATES, SCHEDULER, INVOICES, PRODUCTS];

  // Add menu options if admin
  if (userRole === UserRoleType.ADMIN) {
    menu.push(ADMIN);
  } else if(userRole === UserRoleType.SALES_REP) {
    menu.push(TEAM_ANALYTICS);
  }

  return menu;
}
