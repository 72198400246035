import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from '../../../../../../apps/contract-estimator/src/app/config/server-url';
import { isEmpty } from 'lodash';
import { PersonnelState } from '../+state/states/personnel.state';

//Creating and interface to guarantee that the response has this values
interface serverResponseInterface {
  error: boolean;
  data: any;
}

@Injectable()
export class PersonnelService {
  //Variables
  serverUrl: string;
  data: any;
  serverResponse: serverResponseInterface;
  personnelNamesServerResponse: serverResponseInterface;

  //Constructor
  constructor(public http: HttpClient) {
    this.serverUrl = new ServerUrl().getUrl();
  }

  getPersonnel(trade) {
    return this.http.get(this.serverUrl + '/personnel/' + trade);
  }

  getPayrollPersonnel() {
    return this.http.get(this.serverUrl + '/personnel/payroll');
  }

  // Composes sales rep array from the response
  composePersonnel(rawPersonnel: Array<any>): Array<PersonnelState> {
    if (isEmpty(rawPersonnel)) return [];
    const list = [];
    for (let i = 0; i < rawPersonnel.length; i++) {
      if (isEmpty(rawPersonnel[i])) continue;
      const salesRep: PersonnelState = this.composeSinglePersonnel(
        rawPersonnel[i]
      );
      if (isEmpty(salesRep)) continue;
      list.push(salesRep);
    }
    return list;
  }

  // Composes single sales rep
  composeSinglePersonnel(personnel: any): PersonnelState {
    if (isEmpty(personnel)) return null;
    return {
      _id: personnel._id,
      name: personnel.name,
      email: personnel.email,
      phoneNumber: personnel.phoneNumber,
      trade: personnel.trade
    };
  }
}
