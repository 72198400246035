export class Address {
  Line1: string;
  City: string;
  Country: string;
  CountrySubDivisionCode: string; // State
  PostalCode: string;

  constructor(street, city, state, zipCode, country) {
    this.Line1 = street;
    this.City = city;
    this.Country = country;
    this.CountrySubDivisionCode = state;
    this.PostalCode = zipCode;
  }
}
