import { MenuItem } from 'primeng/api';
import { get } from 'lodash';
import  isMongoId  from 'validator/lib/isMongoId';

import {
  MenuPayload,
  OrderMenu
} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {TradeActionMenuItem} from "./trade-action-menu-item.class";
import {DuplicateTradePayload, RemoveTradePayload, RenameTradePayload} from "@contract-estimator/shared/classes";
import {TradeComponentMenuItem} from "./trade-component-menu-item.class";

export class TradeMenu implements MenuItem{
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded?: boolean
  ) {
  }
  static fromEstimateArray(payload: MenuPayload, callback) {
    if(!payload) throw new Error("Payload is empty.");
    const menu = payload.orders.map((order:OrderMenu) => TradeMenu.fromEstimate(
      order,
      payload._id,
      null,
      callback,
      payload._currentTradeId
    ) );
    return menu;
  }

  static fromEstimate(
    order: OrderMenu,
    _estimateId: string,
    type: string,
    command: (event?: any) => void,
    _currentTradeId: string
  ) {
    const isCurrentTrade: boolean = order._id === _currentTradeId;
    const woMenuItem =  TradeComponentMenuItem.fromEstimate(
      'Work Order',
      'WO',
      _estimateId,
      order._id,
      order._workOrderId,
    )
    const poMenuItem =  TradeComponentMenuItem.fromEstimate(
      'Purchase Order',
      'PO',
      _estimateId,
      order._id,
      order._purchaseOrderId,
    )
    // Rename trade
    const renameTradePayload = new RenameTradePayload(
      order.name,
      order._id
    )
    const renameActionItem = TradeActionMenuItem.fromTrade(
      'RENAME',
      order.name,
      'pi pi-fw pi-pencil',
      command(renameTradePayload)
    )
    // Remove trade
    const removeTradePayload = new RemoveTradePayload(order._id)
    const deleteActionItem = TradeActionMenuItem.fromTrade(
      'DELETE',
      order.name,
      'pi pi-fw pi-trash',
      command(removeTradePayload)
    )
    // Duplicate trade
    const duplicateTradePayload = new DuplicateTradePayload(order._id);
    const duplicateActionItem = TradeActionMenuItem.fromTrade(
      'DUPLICATE',
      order.name,
      'pi pi-fw pi-copy',
      command(duplicateTradePayload)
    )
    const label = TradeMenu.getName(
      type,
      order.name,
      0
    )

    const items: MenuItem[] = [
      woMenuItem,
      poMenuItem,
      renameActionItem,
      deleteActionItem,
      duplicateActionItem
    ]

    return new TradeMenu(
      label,
      items,
      isCurrentTrade
    )

  }


  static fromOriginalScopeArray(payload: MenuPayload, callback) {
    if(!payload) throw new Error("Payload is empty.");
    const menu = payload.orders
      .filter((order: OrderMenu) => !order.type)
      .map((order:OrderMenu) => TradeMenu.fromOriginalScope(
      order,
      null,
      callback,
      payload._currentTradeId
    ) );
    return menu;
  }

  static fromOriginalScope(
    order: OrderMenu,
    type: string,
    command: (event?: any) => void,
    _currentTradeId: string
  ) {
    const isCurrentTrade: boolean = order._id === _currentTradeId;
    const woMenuItem =  TradeComponentMenuItem.fromScheduler(
      'WO',
      'WO',
      order._workOrderId,
      order._id,
    )
    const poMenuItem =  TradeComponentMenuItem.fromScheduler(
      'PO',
      'PO',
      order._purchaseOrderId,
      order._id,
    )
    const qciMenuItem =  TradeComponentMenuItem.fromScheduler(
      'QCI',
      'QCI',
      null,
      order._id,
    )
    // Remove trade
    const removeTradePayload = new RemoveTradePayload(order._id)
    const deleteActionItem = TradeActionMenuItem.fromTrade(
      'DELETE',
      order.name,
      'pi pi-fw pi-trash',
      command(removeTradePayload)
    )
    // Duplicate trade
    const duplicateTradePayload = new DuplicateTradePayload(order._id);
    const duplicateActionItem = TradeActionMenuItem.fromTrade(
      'DUPLICATE',
      order.name,
      'pi pi-fw pi-copy',
      command(duplicateTradePayload)
    )
    const label = TradeMenu.getName(
      type,
      get(order, 'name'),
      0
    )

    const items: MenuItem[] = [
      woMenuItem,
      poMenuItem,
      qciMenuItem,
    ]
    // Add delete  action item to items
    items.push(deleteActionItem)

    return new TradeMenu(
      label,
      items,
      isCurrentTrade
    )

  }


  static fromAdditionalScopeArray(payload: MenuPayload, callback) {
    if(!payload) throw new Error("Payload is empty.");
    const menu = payload.orders
      .filter((order: OrderMenu) => order.type === 'change-order')
      .map((order:OrderMenu) => TradeMenu.fromAdditionalScope(
      order,
      payload._id,
      null,
      callback,
      payload._currentTradeId
    ));
    return menu;
  }

  static fromAdditionalScope(
    order: OrderMenu,
    _estimateId: string,
    type: string,
    command: (event?: any) => void,
    _currentTradeId: string
  ) {
    const isCurrentTrade = order._id === _currentTradeId;
    const _additionalScopeId = get(order, '_additionalScopeId');
    const hasValidAdditionalScopeId = TradeMenu.hasValidAdditionalScopeId(_additionalScopeId);
    const woMenuItem =  TradeComponentMenuItem.fromScheduler(
      'WO',
      'WO',
      _estimateId,
      order._id,
    )
    const poMenuItem =  TradeComponentMenuItem.fromScheduler(
      'PO',
      'PO',
      _estimateId,
      order._id,
    )
    const qciMenuItem =  TradeComponentMenuItem.fromScheduler(
      'QCI',
      'QCI',
      _estimateId,
      order._id,
    )

    let jobCostsMenuItem, changeOrderMenuItem;
    if(hasValidAdditionalScopeId) {
      jobCostsMenuItem = TradeComponentMenuItem.fromScheduler(
        'JOB COSTS',
        'JOB_COSTS',
        _additionalScopeId,
        order._id,
      )
      changeOrderMenuItem = TradeComponentMenuItem.fromScheduler(
        'CHANGE ORDER',
        'CHANGE_ORDER',
        _additionalScopeId,
        order._id,
      )
    }
    // Remove trade
    const removeTradePayload = new RemoveTradePayload(order._id)
    const deleteActionItem = TradeActionMenuItem.fromTrade(
      'Delete',
      order.name,
      'pi pi-fw pi-trash',
      command(removeTradePayload)
    )
    // Duplicate trade
    const duplicateTradePayload = new DuplicateTradePayload(order._id);
    const duplicateActionItem = TradeActionMenuItem.fromTrade(
      'Duplicate',
      order.name,
      'pi pi-fw pi-copy',
      command(duplicateTradePayload)
    )
    const label = TradeMenu.getName(
      type,
      order.name,
      0
    )

    const items: MenuItem[] = [
      woMenuItem,
      poMenuItem,
    ]
    if(hasValidAdditionalScopeId) {
      items.push(jobCostsMenuItem)
      items.push(changeOrderMenuItem)
    }
    // Adds QCI menu item
    items.push(qciMenuItem)

    // Adds delete  action item to items
    items.push(deleteActionItem)

    return new TradeMenu(
      label,
      items,
      isCurrentTrade
    )

  }

  // Compose instance from warranty array
  static fromWarrantyArray(payload: MenuPayload, callback) {
    if(!payload) throw new Error("Payload is empty.");
    const menu = payload.orders
      .filter((order: OrderMenu) => order.type === 'warranty')
      .map((order:OrderMenu) => TradeMenu.fromOriginalScope(
        order,
        null,
        callback,
        payload._currentTradeId
      ) );
    return menu;
  }


  // Composes trade name
  static getName(orderType, orderName, index) {
    switch (orderType) {
      case 'warranty':
        return `WARRANTY #${index + 1}`;
      default:
        return orderName;
    }
  }

  // Returns true if _additionalScopeId is a valid id
  static hasValidAdditionalScopeId(_additionalScopeId) {
    try {
      return isMongoId(_additionalScopeId)
    } catch (error) {
      return false;
    }
  }
}




