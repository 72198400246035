import {Component, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs/Subject";

@Component({
  selector: 'contractor-csv-file-picker',
  templateUrl: './csv-file-picker.component.html',
  styleUrls: ['./csv-file-picker.component.scss']
})
export class CsvFilePickerComponent implements OnInit {

  @Output() contentEvent: any =  new Subject();
  @Output() errorEvent: any =  new Subject();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Loads file
   */
  loadFile() {
    try {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = (e: any) => {
        try {
          const file = e.target.files[0];
          // setting up the reader
          var reader = new FileReader();
          reader.readAsText(file,'UTF-8');

          // here we tell the reader what to do when it's done reading...
          reader.onload = readerEvent => {
            try {
              var content = readerEvent.target.result; // this is the content!
              const values = this.parse(content);
              this.contentEvent.next(values);
            } catch (e) {
              this.throwError("There was an error parsing the content.")
            }
          }
        } catch (e) {
          this.throwError("There was an error creating the reader.")
        }
      }
      input.click();
    } catch (e) {
      this.throwError("There was an error creating the input element.")
    }
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  parse(content: any) {
    let csvArr = [];
    let csvRecordsArray = (<string>content).split(/\r\n|\n/);

    for (let i = 0; i < csvRecordsArray.length; i++) {
      let currentRecord = csvRecordsArray[i].split('::')
      csvArr.push(currentRecord)
    }
    return csvArr;
  }

  throwError(e) {
    this.errorEvent.next(e);
  }

}
