import { isEmpty } from 'lodash';
import {calculateMarkupTotal} from "../../../../../../../libs/shared/utilities/src/lib/markup";

// Calculates total from an array of subtotals
export function calculateTotalFromSubtotalList(subtotals: Array<number>) {
  if (isEmpty(subtotals)) return 0;
  let total = 0;
  for (let i = 0; i < subtotals.length; i++) {
    if (!subtotals[i]) continue;
    total += +subtotals[i];
  }
  return total;
}

// Calculates total for all sections
export function calculateTotalForAllSections(
  workOrderSubtotal,
  purchaseOrderSubtotal,
  miscellaneousSubtotal,
  markupValue
) {
  try {
    const subtotal =
      +workOrderSubtotal + +purchaseOrderSubtotal + +miscellaneousSubtotal;
    const total = calculateMarkupTotal(subtotal, markupValue);
    return total;
  } catch (error) {
    return 0;
  }
}
