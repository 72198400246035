import { get } from 'lodash';
import {
  calculateMargin,
  calculateVariance
} from 'libs/scheduler/job-costs/data-access/src/lib/+state/selectors/functions/actual-job-costs.functions';
import { CommissionsPaymentStatus } from '@contract-estimator/shared/enums';
import { getBracketFromProfitMatrix } from '../../../../profit-matrix.utils.ts/profit-matrix.utils';

export function calculateMarginProjector(profit, totalSales) {
  return calculateMargin(totalSales, profit);
}

export function varianceProjector(estMargin, actMargin) {
  return calculateVariance(actMargin, estMargin);
}

export function actualJobCostsCOGSTotalProjector(jobCosts) {
  const woTotal = get(jobCosts, 'workOrderTotal');
  const poTotal = get(jobCosts, 'purchaseOrderTotal');
  const miscellaneousTotal = get(jobCosts, 'miscellaneousCost');
  return woTotal + poTotal + miscellaneousTotal;
}

export function profitMatrixBracketProjector(total, brackets) {
  return getBracketFromProfitMatrix(brackets, total);
}

export function markupFromProfitProjector(bracket) {
  return get(bracket, 'markup');
}

export function actualJobCostsMarkupProjector(
  commissionPaymentStatus,
  metadataMarkup,
  matrixMarkup
) {
  // Returns stored markup if present. Otherwise return markup from matrix.
  if (
    commissionPaymentStatus === CommissionsPaymentStatus.PAID &&
    (metadataMarkup !== null || metadataMarkup !== undefined)
  ) {
    return metadataMarkup;
  }
  return matrixMarkup;
}
