import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";

import {UIComponentsModule} from "./../ui-components/ui-components.module";
import { PayrollTableComponent } from './components/payroll-table/payroll-table.component';
import { PayrollHeaderTableComponent } from './components/payroll-header-table/payroll-header-table.component';
import { PayrollCostTableComponent } from './components/payroll-cost-table/payroll-cost-table.component';
import { WbPayrollLogbookComponent } from './components/wb-payroll-logbook/wb-payroll-logbook.component';
import { PayrollTotalTableComponent } from './components/payroll-total-table/payroll-total-table.component';
import { PayrollListComponent } from './components/payroll-list/payroll-list.component';
import {PayrollListOptionsMenuComponent} from "./components/payroll-list-options-menu/payroll-list-options-menu.component";

@NgModule({
  declarations: [
    PayrollTableComponent,
    PayrollHeaderTableComponent,
    PayrollCostTableComponent,
    WbPayrollLogbookComponent,
    PayrollTotalTableComponent,
    PayrollListComponent,
    PayrollListOptionsMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    UIComponentsModule
  ],
  exports: [
    CommonModule,
    PayrollTableComponent,
    PayrollHeaderTableComponent,
    PayrollCostTableComponent,
    WbPayrollLogbookComponent,
    PayrollTotalTableComponent,
    PayrollListComponent,
    PayrollListOptionsMenuComponent
  ]
})
export class PayrollSharedModule {}
