import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'contract-info-table',
  templateUrl: './contract-info-table.component.html',
  styleUrls: ['./contract-info-table.component.scss']
})
export class ContractInfoTableComponent implements OnInit {
  toggleDeposit: boolean = false;
  toggleBrand: boolean = false;
  toggleStyle: boolean = false;
  toggleColor: boolean = false;
  toggleUpgrades: boolean = false;
  toggleAddOns: boolean = false;
  toggleDisclosure: boolean = false;
  toggleSupplement: boolean = false;

  @ViewChild('depositInput', { static: false }) depositInput: ElementRef;
  @ViewChild('brandInput', { static: false }) brandInput: ElementRef;
  @ViewChild('styleInput', { static: false }) styleInput: ElementRef;
  @ViewChild('colorInput', { static: false }) colorInput: ElementRef;
  @ViewChild('upgradesInput', { static: false }) upgradesInput: ElementRef;
  @ViewChild('addOnsInput', { static: false }) addOnsInput: ElementRef;
  @ViewChild('disclosureInput', { static: false }) disclosureInput: ElementRef;
  @ViewChild('supplementInput', { static: false }) supplementInput: ElementRef;

  @Input() form: FormGroup;
  @Input() showSupplement: boolean;
  @Input() showDeposit: boolean;

  constructor() {}

  ngOnInit() {}

  // Returns deposit from form
  getInputValue(inputName: string) {
    try {
      return this.form.get(inputName).value;
    } catch (error) {
      return '';
    }
  }

  toggleInput(inputName: string) {
    switch (inputName) {
      case 'deposit':
        this.toggleDeposit = !this.toggleDeposit;
        if (this.toggleDeposit)
          setTimeout(() => {
            this.depositInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'brand':
        this.toggleBrand = !this.toggleBrand;
        if (this.toggleBrand)
          setTimeout(() => {
            this.brandInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'style':
        this.toggleStyle = !this.toggleStyle;
        if (this.toggleStyle)
          setTimeout(() => {
            this.styleInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'color':
        this.toggleColor = !this.toggleColor;
        if (this.toggleColor)
          setTimeout(() => {
            this.colorInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'upgrades':
        this.toggleUpgrades = !this.toggleUpgrades;
        if (this.toggleUpgrades)
          setTimeout(() => {
            this.upgradesInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'add-ons':
        this.toggleAddOns = !this.toggleAddOns;
        if (this.toggleAddOns)
          setTimeout(() => {
            this.addOnsInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'disclosure':
        this.toggleDisclosure = !this.toggleDisclosure;
        if (this.toggleDisclosure)
          setTimeout(() => {
            this.disclosureInput.nativeElement.focus();
          }, 0);
        return inputName;
      case 'supplement':
        this.toggleSupplement = !this.toggleSupplement;
        if (this.toggleSupplement)
          setTimeout(() => {
            this.supplementInput.nativeElement.focus();
          }, 0);
        return inputName;
      default:
        return inputName;
    }
  }

  notToggleInput(event) {
    event.stopPropagation();
  }

  // Gets the deposit value and checks if it is valid
  getDeposit() {
    const deposit = this.getInputValue('deposit');
    return deposit;
  }
}
