import { EmailInformation } from './../../../shared/models/emails/emails.model';

// Entity is used in the invoice form create and preview.
export interface Entity {
  id?: any;
  syncToken?: string;
  displayName: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: any;
  phoneNumber: string;
  secondaryPhoneNumber?: string;
  email: string;
}

export const initialEntity = {
  displayName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zipCode: null,
  phoneNumber: null,
  email: null
};

export interface Invoice {
  id?: string;
  syncToken?: string;
  lines: Array<Line>;
  customerId: string;
  date?: string;
  dueDate?: string;
  docNumber?: string;
  comments: string;
  financeCharge?: number;
  total?: number;
}

// customer
interface Customer {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: any;
  phoneNumber: string;
  email: string;
}

const customer: Customer = {
  firstName: null,
  lastName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zipCode: null,
  phoneNumber: null,
  email: null
};

interface SalesRep {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const salesRep: SalesRep = {
  firstName: null,
  lastName: null,
  phoneNumber: null,
  email: null
};

export interface Line {
  id: number;
  lineNum: number;
  description: string;
  amount: number;
  detailType: string;
  descriptionLineDetail: {
    serviceDate: number;
    taxCodeRef: string;
  };
}

export const INITIAL_LINE: Line = {
  id: null,
  lineNum: null,
  description: null,
  amount: null,
  detailType: null,
  descriptionLineDetail: {
    serviceDate: null,
    taxCodeRef: null
  }
};

export const initialInvoice: Invoice = {
  lines: [],
  customerId: null,
  date: null,
  dueDate: null,
  docNumber: null,
  comments: null
};

export interface invoiceDataVM {
  id: any;
  syncToken: string;
  number: string;
  date: string;
  dueDate: string;
  lineItems: Array<any>;
  subtotal: number;
  paymentReceived: number;
  financeCharge: number;
  total: number;
  comments: string;
  email?: EmailInformation;
  customer: Entity;
  salesRep: Entity;
  storedFinanceCharge: number;
  financeChargeDynamicDueDate: string;
  sendReview: boolean;
  sendReceipt: boolean;
}

export const INITIAL_INVOICE_DATA_VM: invoiceDataVM = {
  id: null,
  syncToken: null,
  number: null,
  date: null,
  dueDate: null,
  lineItems: [],
  subtotal: 0,
  paymentReceived: 0,
  financeCharge: 0,
  total: 0,
  comments: null,
  customer: initialEntity,
  salesRep: initialEntity,
  storedFinanceCharge: 0,
  financeChargeDynamicDueDate: null,
  sendReview: true,
  sendReceipt: true
};

// Mostly used in the invoice preview

export interface LineItem {
  description: string;
  amount: number;
}

export const lineItem = {
  description: null,
  amount: null
};

export interface MetaInvoiceVM {
  id: string;
  syncToken: string;
  amountPaidWithCreditCard: number;
  disableCreditCard: boolean;
  invoiceNumber: string;
  invoiceDateObj: any;
  invoiceDateString: any;
  salesRep: Entity;
  lineItems: Array<LineItem>;
  customer: Entity;
  dueDateObj: any;
  dueDateString: any;
  subtotal: number;
  totalAmount: number;
  balance: number;
  paymentReceived: number;
  financeCharge: number;
  total: number;
  paidInFull: boolean;
  comments: string;
  financeChargeDynamicDueDate: string;
  storedFinanceCharge: number;
  sendReview: boolean;
  sendReceipt: boolean;
}

export const initialMetaInvoiceVM: MetaInvoiceVM = {
  id: null,
  syncToken: null,
  amountPaidWithCreditCard: null,
  disableCreditCard: false,
  invoiceNumber: null,
  invoiceDateObj: null,
  invoiceDateString: null,
  salesRep: initialEntity,
  lineItems: [lineItem],
  customer: initialEntity,
  dueDateObj: null,
  dueDateString: null,
  subtotal: null,
  totalAmount: null,
  balance: null,
  paymentReceived: null,
  financeCharge: null,
  total: null,
  paidInFull: false,
  comments: null,
  financeChargeDynamicDueDate: null,
  storedFinanceCharge: null,
  sendReview: true,
  sendReceipt: true
};

export class InvoiceListItem {
  id: any;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  daysOutstanding: number;
  customerName: string;
  salesRepName: string;
  totalAmount: number;
  financeCharge: number;
  balanceDue: number;
  paymentReceived: number;

  constructor(builder: any) {
    this.id = builder.id;
    this.invoiceNumber = builder.invoiceNumber;
    this.invoiceDate = builder.invoiceDate;
    this.dueDate = builder.dueDate;
    this.daysOutstanding = builder.daysOutstanding;
    this.customerName = builder.customerName;
    this.salesRepName = builder.salesRepName;
    this.totalAmount = builder.totalAmount;
    this.financeCharge = builder.financeCharge;
    this.balanceDue = builder.balanceDue;
    this.paymentReceived = builder.paymentReceived;
  }
}
