import { Injectable } from '@angular/core';
import {CloudStorageFileUploaderService} from "./cloud-storage-file-uploader.service";

@Injectable({
  providedIn: 'root'
})
export class FileUploaderFacadeService {

  constructor(
    private cloudStorageFileUploaderService: CloudStorageFileUploaderService
  ) { }

  /**
   * Uploads file
   */
  uploadFile(file: File, bucket?) {
    return this.cloudStorageFileUploaderService.getSignedUrlAndUploadFile(
      file.name,
      file,
      bucket,
    )
  }
}
