import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromMegaMenu from './mega-menu.reducer';
import * as MegaMenuSelectors from './mega-menu.selectors';
import {loadMegaMenuEnabledFeatures, loadMegaMenuSuccess} from "./mega-menu.actions";

@Injectable()
export class MegaMenuFacade {
  loaded$ = this.store.pipe(select(MegaMenuSelectors.getMegaMenuLoaded));
  allMegaMenu$ = this.store.pipe(select(MegaMenuSelectors.getAllMegaMenu));
  selectedMegaMenu$ = this.store.pipe(select(MegaMenuSelectors.getSelected));
  menuCountEntities$ = this.store.pipe(select(MegaMenuSelectors.getMegaMenuEntities))
  enabledFeatures$ = this.store.pipe(select(MegaMenuSelectors.enabledFeatures))

  constructor(private store: Store<fromMegaMenu.MegaMenuPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  // Dispatches load menu counts
  dispatchLoadMenuCounts(counts) {
    this.dispatch(loadMegaMenuSuccess({megaMenu: counts}))
  }

  // Dispatches enabled features
  dispatchEnabledFeatures(enabledFeatures) {
    this.dispatch(loadMegaMenuEnabledFeatures({enabledFeatures}))
  }
}
