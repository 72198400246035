import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialNavigationComponent} from "./components/material-navigation/material-navigation.component";
import {BrowserModule} from "@angular/platform-browser";
import {UIComponentsModule} from "@contract-estimator/shared/angular/components";
import {SharedMegaMenuFeatureModule} from "@contract-estimator/shared/megamenu/feature";
import {ForecastFeatureModule} from "@contract-estimator/forecast/feature";
import {OauthSpaFeatureModule} from "@contract-estimator/oauth/spa/feature";
import {ProfileButtonModule} from "@contract-estimator/shared/angular/modules/profile-button";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    UIComponentsModule,
    SharedMegaMenuFeatureModule,
    ForecastFeatureModule,
    OauthSpaFeatureModule,
    ProfileButtonModule
  ],
  declarations: [
    MaterialNavigationComponent
  ],
  exports: [
    MaterialNavigationComponent
  ]
})
export class AppMaterialNavigationModule {}
