export const DEFAULT_TEMPLATE_ID = '5b6d17206244a0f41a87dedf';
export const RIVERCITY_STORE = '58bb3d13b871fb811be5f32b';
export const ABC_STORE = '5a81322cd8d8578425cd2131';
export const REPAIR_CONTRACT_DEPOSIT_AMOUNT = '$100.00';
export const INSURANCE_CONTRACT_DEPOSIT_AMOUNT = 1000;
export const CONTRACT_DEPOSIT_AMOUNT_PERCENT = .3;
export const OTHERS_CONTRACT_DEPOSIT_AMOUNT = '$1,000.00';
export const DEFAULT_PO_GROUP_ORDER = {
  '58bb3d13b871fb811be5f32b': [
    {
      index: 1,
      group: 'Drip Edge',
      jobType: 'RI, RR'
    },
    {
      index: 2,
      group: 'Underlayments - Ice & Water',
      jobType: 'RI, RR'
    },
    {
      index: 3,
      group: 'Underlayment - Felts',
      jobType: 'RI, RR'
    },
    {
      index: 4,
      group: 'GAF Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 5,
      group: 'Owens Corning Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 6,
      group: 'IKO Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 7,
      group: 'Flashing',
      jobType: 'RI, RR'
    },
    {
      index: 8,
      group: 'Suntek Skylights',
      jobType: 'RI, RR'
    },
    {
      index: 9,
      group: 'Velux Skylights',
      jobType: 'RI, RR'
    },
    {
      index: 10,
      group: 'Velux Flashing Kits',
      jobType: 'RI, RR'
    },
    {
      index: 11,
      group: 'Pipe Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 12,
      group: 'Exhaust Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 13,
      group: 'Intake Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 14,
      group: 'Fasteners & Caulk',
      jobType: 'RI, RR'
    },
    {
      index: 15,
      group: 'Wood',
      jobType: 'RI, RR'
    },
    {
      index: 16,
      group: 'Coatings',
      jobType: 'RI, RR'
    },
    {
      index: 17,
      group: 'Modified Bitumen',
      jobType: 'RI, RR'
    },
    {
      index: 18,
      group: 'EPDM',
      jobType: 'RI, RR'
    },
    {
      index: 1,
      group: 'Aluminum 5” Gutters',
      jobType: 'EI'
    },
    {
      index: 2,
      group: 'Aluminum 6” Gutters',
      jobType: 'EI'
    },
    {
      index: 3,
      group: 'Gutter Guards',
      jobType: 'EI'
    },
    {
      index: 4,
      group: 'Vinyl Siding',
      jobType: 'EI'
    },
    {
      index: 5,
      group: 'Vinyl Soffit',
      jobType: 'EI'
    },
    {
      index: 6,
      group: 'Vinyl Windows',
      jobType: 'EI'
    },
    {
      index: 7,
      group: 'Vinyl Shutters',
      jobType: 'EI'
    },
    {
      index: 8,
      group: 'Vinyl Insulated Siding',
      jobType: 'EI'
    },
    {
      index: 9,
      group: 'Fiber Cement Siding',
      jobType: 'EI'
    },
    {
      index: 14,
      group: 'Fasteners & Caulk',
      jobType: 'EI'
    },
    {
      index: 15,
      group: 'Wood',
      jobType: 'EI'
    },
    {
      index: 16,
      group: 'Rescreen & Replacement Glass',
      jobType: 'EI'
    }
  ],
  '5a81322cd8d8578425cd2131': [
    {
      index: 1,
      group: 'Drip Edge',
      jobType: 'RI, RR'
    },
    {
      index: 2,
      group: 'Underlayments - Ice / Water',
      jobType: 'RI, RR'
    },
    {
      index: 2,
      group: 'Underlayment - Ice / Water',
      jobType: 'RI, RR'
    },
    {
      index: 3,
      group: 'Underlayment - Felts',
      jobType: 'RI, RR'
    },
    {
      index: 4,
      group: 'GAF Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 5,
      group: 'Certainteed Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 6,
      group: 'Owens Corning Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 7,
      group: 'IKO Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 8,
      group: 'TAMKO Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 9,
      group: 'Malarkey - Impact Resistant Shingles',
      jobType: 'RI, RR'
    },
    {
      index: 10,
      group: 'Flashing',
      jobType: 'RI, RR'
    },
    {
      index: 11,
      group: 'Skylights',
      jobType: 'RI, RR'
    },
    {
      index: 12,
      group: 'Pipe Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 13,
      group: 'Exhaust Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 14,
      group: 'Intake Ventilation',
      jobType: 'RI, RR'
    },
    {
      index: 15,
      group: 'Fasteners',
      jobType: 'RI, RR'
    },
    {
      index: 16,
      group: 'Caulk',
      jobType: 'RI, RR'
    },
    {
      index: 17,
      group: 'Wood',
      jobType: 'RI, RR'
    },
    {
      index: 18,
      group: 'Modified Bitumen',
      jobType: 'RI, RR'
    }
  ]
};

// Virtual materials that can appear in po sections once only
export const VIRTUAL_MATERIALS = [
  'Supplier Delivery Fee',
  'Supplier Return Fee',
  'Boom Truck Rental'
];

export const MISCELLANEOUS_LINE_ITEMS_TO_BE_UPDATED_ON_ALL_INSTALLS = [
  'Aerial Image',
  'Quality Control Inspection',
  'Material Pick-up',
  'Delivery Fee'
];

export const MISCELLANEOUS_LINE_ITEMS_TO_BE_UPDATED_ON_ROOF_INSTALLS = [
  'Aerial Image'
];

// Work order terms and conditions
export const getWorkOrderTermsAndConditions = (companyName) => [
  { text: 'SUBCONTRACTOR DO’S:', style: 'header' },
  '\n',
  {
    ul: [
      { text: 'Provide all tools to complete the job.', style: 'term-item' },
      { text: 'Provide temporary power if needed.', style: 'term-item' },
      {
        text:
          'Provide and keep a first-aid kit and fire extinguisher on job site at all times.',
        style: 'term-item'
      },
      {
        text: 'Keep an English speaking person on site at all times.',
        style: 'term-item'
      },
      {
        text:
          'Wear fall protection gear above six feet (provided by the Subcontractor) and be OSHA compliant at all times.',
        style: 'term-item'
      },
      {
        text:
          'Wear safety green or safety orange colored shirts (provided by the Subcontractor).',
        style: 'term-item'
      },
      {
        text:
          'Knock the door and tell the customer you will be working on the job before you start work',
        style: 'term-item'
      },
      {
        text:
          'Call the Sales Rep to discuss the Work Order before starting the job and get approval for any changes to Work Order before doing the work.',
        style: 'term-item'
      },
      {
        text:
          'Call the Sales Rep to reschedule work if rain or bad weather begins.',
        style: 'term-item'
      },
      {
        text:
          'Follow all Manufacturer’s specifications.',
        style: 'term-item'
      },
      {
        text:
          'Call the Sales Rep if there is not enough material, and the Sales Rep will place an order with the store.',
        style: 'term-item'
      },
      {
        text:
          'Pick-up material from the store if needed and get paid $125.00 for the trip charge. (up to one trip charge per job no matter how many times Subcontractor goes to store).',
        style: 'term-item'
      },
      {
        text:
          'Leave all unopened materials at the job site.',
        style: 'term-item'
      },
      [
        {
          text:
            'Cover all of the following items with plywood/OSB or tarps (provided by the Subcontractor):',
          style: 'term-item'
        },
        {
          ul: [
            { text: 'ground windows', style: 'term-item' },
            { text: 'garage doors', style: 'term-item' },
            { text: 'HVAC unit', style: 'term-item' },
            { text: 'hot tub covers', style: 'term-item' },
            { text: 'decks', style: 'term-item' },
            { text: 'landscaping', style: 'term-item' },
            { text: 'swimming pools', style: 'term-item' },
            { text: 'siding & stucco', style: 'term-item' },
            { text: 'foundation wall', style: 'term-item' },
            { text: 'etc.', style: 'term-item' }
          ]
        }
      ],
      {
        text:
          'Install temporary roof jacks and toe boards on the eaves of roof to protect gutters and landscaping below.',
        style: 'term-item'
      },
      {
        text:
          'Replace all shingles with visible “shiners”.',
        style: 'term-item'
      },
      {
        text:
          'Blow off the roof, driveway, walkways, and decks with leaf blower and roll the yard two times with magnet to pickup loose nails.',
        style: 'term-item'
      },
      {
        text:
          'Only remove materials that can be installed within the same day.  If to much material is removed, cover the open area with tarps (provided by the Subcontractor) and dry-in.',
        style: 'term-item'
      },
      {
        text:
          'Take before and after photos of bad wood or additional layers of material, and email to Sales Rep for payment.',
        style: 'term-item'
      },
      {
        text:
          'Clean up all the trash daily and haul away.',
        style: 'term-item'
      },
      {
        text:
          'Secure all materials daily so they don’t get stolen from job.',
        style: 'term-item'
      },
      {
        text:
          'Walk job with the customer before leaving and confirm all the work is complete.',
        style: 'term-item'
      }
    ]
  },
  '\n',
  '\n',
  { text: 'SUBCONTRACTOR DON’TS:', style: 'header' },
  '\n',
  {
    ul: [
      {
        text: 'Do not put ladders against the gutters, gutter guards, or siding.  All ladders must have a standoffs attached to the ladder.',
        style: 'term-item'
      },
      { text: 'Do not reuse any materials.', style: 'term-item' },
      { text: 'Do not work in the dark or rain.', style: 'term-item' },
      {
        text: 'Do not park in the driveway if the vehicle leaks oil.',
        style: 'term-item'
      },
      {
        text: 'Do not play loud music.',
        style: 'term-item'
      },
      {
        text: 'Do not smoke or go to bathroom on Customer’s property.',
        style: 'term-item'
      }
    ]
  },
  '\n',
  '\n',
  { text: 'NOTES:', style: 'header', pageBreak: 'before' },
  '\n',
  {
    ul: [
      {
        text:
          'Subcontractor must abide by the Terms and Conditions in Subcontractor Agreement.',
        style: 'term-item'
      },
      {
        text:
          `Subcontractor must provide labor warranty per Subcontractor Agreement.`,
        style: 'term-item'
      },
      {
        text:
          'Subcontractor must manage their own work crew.',
        style: 'term-item'
      },
      {
        text:
          'Subcontractor must fix any faulty workmanship and cover the cost of labor and materials. If subcontractor does not repair faulty workmanship, Subcontractor will be back charged the costs to make it right by another.  Original Subcontractor Work Order will be paid once all back charges are completed.',
        style: 'term-item'
      },
      {
        text:
          'Subcontractor will be back charged $150 per day if work crew does not show on scheduled start date.  Subcontractor will be charged 100% of of Purchase Order amount if material gets stolen.',
        style: 'term-item'
      },
      {
        text:
          'Subcontractor must provide Workers Compensation and General Liability Insurance and keep it current at all times.  If insurance certificate is not provided, the cost will be deducted from Work Order payment.',
        style: 'term-item'
      },
      {
        text:
          'Work Orders will be paid to the company noted on insurance certificate.',
        style: 'term-item'
      },
      {
        text:
          'All jobs must be completed by Tuesday to be paid on Friday.',
        style: 'term-item'
      }
    ]
  }
];

export const getPurchaseOrderTermsAndConditions = (storeName="Store") => [
  {
    ul: [
  { text: `The prices above are from the most recent price sheet provided by ${storeName}.  If they are wrong, ${storeName} must notify our company before allowing material to leave store, or these prices will remain in effect.`, style: 'term-item' },
  { text: `All material substitutes need to be approved by Sales Rep before allowing material to leave ${storeName}.`, style: 'term-item' },
  { text: `For materials being delivered, all sealants (caulk, silicone, etc) must be placed in a bag to protect from weather.  Plywood, OSB, fiberboard, etc. must be covered with a tarp to protect from weather and charge our company for the tarp.`, style: 'term-item' },
]}]

export const deliveryInstructions =
  '';
export const srsDate = '05/06/2021';
export const abcDate = '05/06/2021';
export const specialInstructions = '';
export const contractInstructions = '';
export const defaultDeliveryInstructions =
  '-These prices were provided by the store.  If they are wrong, Store must notify us before allowing material to leave store, or these prices will remain in effect.\n-All material substitutes need to be approved by Sales Rep before allowing material to leave store.';
export const DEFAULT_ESTIMATE_TEMPLATE_ID = '5b6d17206244a0f41a87dedf';
export const defaultInstallsMiscellaneousLineItems = [
  'Aerial Image',
  'Quality Control Inspection',
  'Trip Charge',
  'Delivery Fee'
];
export const defaultRoofInstallsMiscellaneousLineItems = ['Material Pick-up'];
