import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StringFormatter } from 'apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module';

@Component({
  selector: 'pipeline-title',
  templateUrl: './pipeline-title.component.html',
  styleUrls: ['./pipeline-title.component.scss']
})
export class PipelineTitleComponent implements OnInit, OnDestroy {
  logoServerUrl: string = new ServerUrl().getCompanyLogoURL();
  stringFormatter: StringFormatter = new StringFormatter();
  @Input() title: string = 'PIPELINE';
  @Input() status: string;
  @Input() disableSearch: boolean = false;
  search$ = new Subject<string>();
  @Output() searchEvent = new Subject<string>();
  closeObservables$ = new Subject<string>();

  constructor() {
    this.stringFormatter = new StringFormatter();
  }

  ngOnInit() {
    this.subscribeToSearch$();
  }

  formatStatus(status) {
    if (!this.stringFormatter) return status;
    const newStatus = this.stringFormatter.formatStatus(status);
    return newStatus;
  }

  // Subscribes to the invoice number
  // When user stops typing
  // Find invoices by number
  subscribeToSearch$() {
    this.search$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.closeObservables$)
      )
      .subscribe(value => {
        this.pushSearchEvent(value);
      });
  }

  pushSearchEvent(value) {
    this.searchEvent.next(value);
  }

  // Composes containers/header/table title
  getComponentTitle() {
    if (!this.title && !this.status) {
      return 'DATABASE';
    } else if (this.title && !this.status) {
      return this.stringFormatter.toUpperCase(this.title);
    } else if (!this.title && this.status) {
      return this.stringFormatter.toUpperCase(this.formatStatus(this.status));
    } else {
      return `${this.stringFormatter.toUpperCase(
        this.title
      )} - ${this.stringFormatter.toUpperCase(this.formatStatus(this.status))}`;
    }
  }

  getHeaderType() {
    return 'PIPELINE';
  }

  getStatus() {
    return 'IN PROGRESS';
  }

  ngOnDestroy() {
    this.closeObservables$.next();
    this.closeObservables$.complete();
  }
}
