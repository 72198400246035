import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { StoresEffects } from './+state/effects/stores.effects';
import { StoresService } from './services/stores.service';
import { StoreModule } from '@ngrx/store';
import { reducer } from './+state/reducers/stores.reducer';
import {StoresStateFacade} from "./+state/facades/stores.state.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('stores', reducer),
    EffectsModule.forFeature([StoresEffects])
  ],
  declarations: [],
  providers: [
    StoresService,
    StoresStateFacade,
  ]
})
export class StoresDataAccessModule {}
