import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() {}

  removeItem(name): void {
    sessionStorage.removeItem(name);
  }

  getItem(name): string {
    return sessionStorage.getItem(name);
  }
}
