import { Injectable } from '@angular/core';
import {map} from "rxjs/operators";
import jwt_decode from "jwt-decode";

import { get } from 'lodash';
import {UserRoleType} from "@contract-estimator/shared/enums";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({
  providedIn: "root"
})
export class AuthClientService {

  constructor(private readonly auth: AuthService) {}

  getUserRole() {
    return this.auth.getAccessTokenSilently()
      .pipe(
        map((token: any) => {
          try {
            let decoded = jwt_decode(token);
            const rolesNamespace = 'https://www.mobile-contractor.com/roles';
            const roles = get(decoded, rolesNamespace);
            const role = roles[0];
            return role;
          } catch(e) {
            return null;
          }
        })
      )
  }

  getUserEmail() {
    return this.auth.getAccessTokenSilently()
      .pipe(
        map((token: any) => {
          try {
            let decoded = jwt_decode(token);
            const emailNamespace = 'https://www.mobile-contractor.com/email';
            const email = get(decoded, emailNamespace);
            return email;
          } catch(e) {
            return null;
          }
        })
      )
  }

  getUserHasPrivileges() {
    return this.getUserRole()
      .pipe(
        map((role: UserRoleType) => {
          switch (role) {
            case UserRoleType.ADMIN:
              return true;
            default:
              return false;
          }
        })
      )
  }

}
