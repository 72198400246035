import { gql } from 'apollo-angular';

export const CREATE_TEAM_MEMBER = gql`
  mutation createTeamMember($payload: CreateTeamMemberInputSchema!) {
    createTeamMember(payload: $payload) {
      _id
      _companyId
      firstName
      lastName
      phoneNumber
      email
      status
      _calendarId
      name
      preferredName
      middleName
      address {
        address
      }
      funFact
      photoName
      active
      role
      acceptedPrivacyTerms
      roles
    }
  }
`;

export const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMember(
    $criteria: UpdateTeamMemberCriteriaInputSchema!
    $changes: UpdateTeamMemberChangesInputSchema!
  ) {
    updateTeamMember(criteria: $criteria, changes: $changes) {
      _id
      _companyId
      firstName
      lastName
      phoneNumber
      email
      status
      _calendarId
      name
      preferredName
      middleName
      address {
        address
      }
      funFact
      photoName
      active
      role
      acceptedPrivacyTerms
      roles
    }
  }
`;

export const SEARCH_TEAM_MEMBERS = gql`
  query searchTeamMembers($searchCriteria: SearchTeamMembersInputSchema!) {
    searchTeamMembersByCriteria(searchCriteria: $searchCriteria) {
      _id
      _companyId
      firstName
      lastName
      phoneNumber
      email
      status
      _calendarId
      name
      preferredName
      middleName
      address {
        address
      }
      funFact
      photoName
      active
      role
      acceptedPrivacyTerms
      roles
    }
  }
`;
