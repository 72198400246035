import * as appSettingsActions from './../actions/app-settings.actions';
import * as appSettingsState from './../state/app-settings.state';

export function reducer(
  state: appSettingsState.State = appSettingsState.initialState,
  action: appSettingsActions.Action
) {
  switch (action.type) {
    case appSettingsActions.SET_TIME_ZONE:
      return updateTimeZone(state, action);
    default:
      return state;
  }
}

export function updateTimeZone(
  state: appSettingsState.State,
  action: appSettingsActions.SetTimeZone
) {
  try {
    return {
      ...state,
      timeZone: action.timeZone
    };
  } catch (error) {
    return state;
  }
}
