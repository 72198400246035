import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractHeaderTableComponent } from './components/contract-header-table/contract-header-table.component';
import { ContractEntityTableComponent } from './components/contract-entity-table/contract-entity-table.component';
import { ContractInfoTableComponent } from './components/contract-info-table/contract-info-table.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContractSectionTableComponent} from "./components/contract-section-table/contract-section-table.component";
import {ContractTotalTableComponent} from "./components/contract-total-table/contract-total-table.component";
import {UIComponentsModule} from "./../ui-components/ui-components.module";
import {SharedAngularPipesModule} from "@contract-estimator/shared/angular/pipes";

@NgModule({
  declarations: [
    ContractHeaderTableComponent,
    ContractEntityTableComponent,
    ContractInfoTableComponent,
    ContractSectionTableComponent,
    ContractTotalTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIComponentsModule,
    SharedAngularPipesModule
  ],
  exports: [
    CommonModule,
    ContractHeaderTableComponent,
    ContractEntityTableComponent,
    ContractInfoTableComponent,
    ContractSectionTableComponent,
    ContractTotalTableComponent,
    UIComponentsModule
  ]
})
export class ContractSharedModule {}
