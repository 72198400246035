import * as salesRepsActions from '../actions/sales-reps.actions';
import * as fromSalesRepState from '../states/sales-rep.state';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const salesRepsAdapter: EntityAdapter<fromSalesRepState.SalesRepState> = createEntityAdapter<
  fromSalesRepState.SalesRepState
>({
  selectId: (salesRep: fromSalesRepState.SalesRepState) => salesRep._id
});

export const initialState: fromSalesRepState.State = salesRepsAdapter.getInitialState(
  {
    loading: false
  }
);

export function reducer(
  state: fromSalesRepState.State = initialState,
  action: salesRepsActions.Actions
) {
  switch (action.type) {
    case salesRepsActions.SET_SALES_REPS:
      return salesRepsAdapter.addMany(action.payload, state);
    case salesRepsActions.UPDATE_PIPELINE_MENU_REQUEST_STATUS:
      return UpdatePipelineMenuRequestStatus(state, action);
    default:
      return state;
  }
}

function UpdatePipelineMenuRequestStatus(
  state: fromSalesRepState.State,
  action: salesRepsActions.UpdatePipelineMenuRequestStatus
): fromSalesRepState.State {
  return {
    ...state,
    loading: action.status
  };
}
