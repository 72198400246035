import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wb-payroll-logbook',
  templateUrl: './wb-payroll-logbook.component.html',
  styleUrls: ['./wb-payroll-logbook.component.scss']
})
export class WbPayrollLogbookComponent implements OnInit {
  _logbook;
  @Input() set logbook(logbook: Array<any>) {
    this._logbook = logbook;
    this.updateRowGroupMetaData();
  }

  rowGroupMetadata: any;

  constructor() {}

  ngOnInit() {}

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this._logbook) {
      for (let i = 0; i < this._logbook.length; i++) {
        let rowData = this._logbook[i];
        let _jobId = rowData._jobId;
        if (i == 0) {
          this.rowGroupMetadata[_jobId] = { index: 0, size: 1 };
        } else {
          let previousRowData = this._logbook[i - 1];
          let previousRowGroup = previousRowData._jobId;
          if (_jobId === previousRowGroup) this.rowGroupMetadata[_jobId].size++;
          else this.rowGroupMetadata[_jobId] = { index: i, size: 1 };
        }
      }
    }
  }
}
