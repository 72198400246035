import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'payroll-cost-table',
  templateUrl: './payroll-cost-table.component.html',
  styleUrls: ['./payroll-cost-table.component.scss']
})
export class PayrollCostTableComponent implements OnInit {
  @Input() lineItems: Array<any>;

  constructor() {}

  ngOnInit() {}
}
