import {MenuItem} from "primeng/api";
import {QueryParamsMenu} from "./query-params-menu.class";

export class TradeComponentMenuItem implements  MenuItem {
  public routerLinkActiveOptions = { exact: true };
  constructor(
    public label: string,
    public routerLink: string[],
    public queryParams?: QueryParamsMenu
  ) { }

  static fromEstimate(
    tradeName: string,
    componentType: string,
    _estimateId: string,
    _orderId: string,
    _id: string
  ) {
    const routerLink: string[] = TradeComponentMenuItem.getEstimatedTradeComponentUrl(
      componentType,
      _estimateId,
      _orderId,
      _id
    )
    return new TradeComponentMenuItem(
      tradeName,
      routerLink,
    );
  }

  // Composes estimate +state link base on order type (WO/PO)
  static getEstimatedTradeComponentUrl(componentType, _estimateId, _orderId, _id) {
    switch (componentType) {
      case 'PO':
        return [
          'estimate',
          _estimateId,
          'order',
          _orderId,
          'purchase-order',
          _id
        ];
      case 'WO':
        return ['estimate', _estimateId, 'order', _orderId, 'work-order', _id];
      default:
        throw new Error("Invalid option for trade component url composer.")
    }
  }

  static fromScheduler(
    tradeName: string,
    componentType: string,
    _componentId: string,
    _orderId: string,
  ) {
    const routerLink: string[] = TradeComponentMenuItem.getScheduledTradeComponentUrl(
      componentType,
      _componentId,
      _orderId
    )
    const queryParams = new QueryParamsMenu(
      null,
      true
    )
    return new TradeComponentMenuItem(
      tradeName,
      routerLink,
      queryParams
    );
  }

  // Composes scheduler +state link base on order type (WO/PO)
  static getScheduledTradeComponentUrl(componentType, _id, _orderId) {
    switch (componentType) {
      case 'PO':
        return ['order', _orderId, 'purchase-order', _id];
      case 'QCI':
        return ['order', _orderId, 'qci'];
      case 'JOB_COSTS':
        return ['additional-scope', 'job-costs', _id];
      case 'CHANGE_ORDER':
        return ['additional-scope', 'change-order', _id];
      default:
        return ['order', _orderId, 'work-order', _id];
    }
  }
}

