import { StoreState } from '../reducers/stores.reducer';
import { isEmpty } from 'lodash';
import { StoresOption } from 'libs/shared/interfaces/src/lib/interfaces';

export function composeStoresArrayWithIdAndName(stores: Array<StoresOption>) {
  if (isEmpty(stores)) return [];
  const list = [];
  for (let i = 0; i < stores.length; i++) {
    if (isEmpty(stores[i])) continue;
    const store = stores[i];
    if (!store._id) continue;
    const item = {
      _id: store._id,
      name: store.name
    };
    list.push(item);
  }
  return list;
}

export function composeStoresArrayAsLabelAndValue(stores: Array<StoreState>) {
  if (isEmpty(stores)) return [];
  const list = [];
  for (let i = 0; i < stores.length; i++) {
    if (isEmpty(stores[i])) continue;
    const store = stores[i];
    if (!store._id) continue;
    const item = {
      value: store._id,
      label: store.name
    };
    list.push(item);
  }
  return list;
}

// Gets selected sub by _id
export function getSelectedStoresById(stores: Array<StoreState>, _subId) {
  if (isEmpty(stores) || !_subId) return null;
  for (let i = 0; i < stores.length; i++) {
    if (isEmpty(stores[i])) continue;
    const store = stores[i];
    if (store._id === _subId) return store;
  }
  return null;
}

// Composes stores for purchase order drop down
export function composeStorePurchaseOrderList(stores: Array<StoreState>) {
  if (isEmpty(stores)) return [];
  try {
    const list = [];
    for (let i = 0; i < stores.length; i++) {
      if (isEmpty(stores[i])) continue;
      const item = composeStorePurchaseOrderListItem(stores[i]);
      if (isEmpty(item)) continue;
      list.push(item);
    }
    return list;
  } catch (e) {
    return [];
  }
}

export function composeStorePurchaseOrderListItem(store) {
  try {
    if (!store) return null;
    return {
      storeId: store._id,
      _compositeId: store._id,
      storeName: store.name
    };
  } catch (error) {
    return null;
  }
}
