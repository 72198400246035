export function convertToDecimalMargin(markup) {
  const HUNDRED_PERCENT = 100;
  const CONVERT_TO_DECIMAL = 100;
  return (HUNDRED_PERCENT - markup) / CONVERT_TO_DECIMAL;
}

// Calculates the total of the purchase order by summing up the subtotals with taxes
export function calculateMarkupTotal(total: number, markupPercent: number) {
  if (markupPercent >= 100) return total;
  // (this algorithm is setup this way at jeremy's request ask before making major changes)
  const markupMarginAsDecimal = convertToDecimalMargin(markupPercent);
  const trueTotal = total / markupMarginAsDecimal;

  return trueTotal;
}
