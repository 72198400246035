import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app-state/app.state';
import { TimeZone } from '../state/app-settings.state';
import { isEmpty, find } from 'lodash';

export const getAppSettings = (state: AppState) => state.appSettings;

export const getTimeZone = createSelector(getAppSettings, appSettings => {
  try {
    return appSettings.timeZone;
  } catch (error) {
    return null;
  }
});

export const getTimeZones = createSelector(getAppSettings, appSettings => {
  try {
    return appSettings.timeZones;
  } catch (error) {
    return null;
  }
});

export const getSelectedTimeZoneLabel = createSelector(
  getTimeZone,
  getTimeZones,
  (selectedTimeZone: string, timeZones: Array<TimeZone>) => {
    try {
      const item: TimeZone = find(timeZones, ['value', selectedTimeZone]);
      if (isEmpty(item)) return '';
      if (!item.label) return '';
      return item.label;
    } catch (error) {
      return null;
    }
  }
);
