import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { environment } from '@contract-estimator/shared/environments';
import { Labor } from 'apps/contract-estimator/src/app/shared/models/labor.model';
import { WorkOrderSection } from 'apps/contract-estimator/src/app/shared/models/work-order-section.model';
import { Subject } from 'rxjs/Subject';
import { CalculatorService } from 'libs/shared/angular/providers/src/lib/calculator/calculator.service';
import * as formInputValidation from 'apps/contract-estimator/src/app/shared/utils/formInput/form-input.validation';
import { ObjectID } from 'bson';

@Component({
  selector: 'contractor-estimate-work-order-table',
  templateUrl: './work-order-estimate-dynamic.component.html',
  styleUrls: ['./work-order-estimate-dynamic.component.scss']
})
export class WorkOrderEstimateDynamicComponent implements OnInit {
  @Input() woSection: WorkOrderSection;
  @Input() selectableLabors: Labor[];
  @Input() showRemoveSection: boolean = true;

  @Output() updateSelectedWOSection = new EventEmitter();
  @Output() updatePurchaseOrderSectionName = new Subject();
  @Output() setUpdateValuesFlag = new Subject();
  @Output() removeSectionEvent = new Subject();
  TAX_RATE = environment.SALES_TAX_RATE;
  sectionNames = environment.sectionNames;
  selectedLaborForRow: Labor = null; // used for storing value when someone uses in row dropdown to select a labor
  errMessage: string = '';
  displayError: boolean = false;

  constructor(public calculatorService: CalculatorService) {}

  ngOnInit() {}

  updateSectionName() {
    this.onUpdateValuesChanges(false);
    try {
      this.woSection.name = this.woSection.name.toString().toUpperCase();
    } catch (error) {
      // Continue if fails
    }
    this.updateSelectedWOSection.emit(this.woSection);
    this.updatePurchaseOrderSectionName.next({
      name: this.woSection.name,
      _id: this.woSection._id
    });
  }

  // Emits if should or should not update values
  onUpdateValuesChanges(change: boolean) {
    this.setUpdateValuesFlag.next(change);
  }

  handleRowChange(e, woSection: WorkOrderSection) {
    this.onUpdateValuesChanges(false);
    // update dirty labor data
    this.updateRowNumbers(e.data);

    // update section the row is attached to
    woSection.total = this.updateSectionTotal(woSection);

    // update section in ngrx
    this.onUpdateValuesChanges(false);
    this.updateSelectedWOSection.emit(woSection);
  }

  handleUpdateRowData(row, woSection: WorkOrderSection) {
    this.onUpdateValuesChanges(false);
    const { _id, _laborId, name, per, price } = this.selectedLaborForRow;

    row._id = _id;
    row._laborId = _laborId;
    row.name = name;
    row.per = per;
    row.price = price;
    row.subtotal = row.qty * price;

    // update section the row is attached to
    woSection.total = this.updateSectionTotal(woSection);

    // update section in ngrx
    this.updateSelectedWOSection.emit(woSection);
  }

  // this alters the row object in place. (primeng requires this)
  updateRowNumbers(row) {
    let { qty, price } = row; // editable row data
    try {
      qty = qty.toString().trim();
      price = price.toString().trim();
    } catch (error) {
      // Error handling here
    }
    const subtotal = this.calculatorService.calculateTotalWithQtyAndPrice(
      qty,
      price
    );
    row.subtotal = subtotal;
    row.qty = qty;
    row.price = price;
  }

  // this alters a section in place and right now assume there is only one section in this containers
  updateSectionTotal(woSection: WorkOrderSection) {
    const filteredLabors = woSection.labors.filter(
      (labor: any) => labor.subtotal && !isNaN(labor.subtotal)
    );
    // update section locally and in store
    return filteredLabors.reduce(
      (total: number, labor: Labor) => (total += labor.subtotal),
      0
    );
  }

  addLabor(woSection) {
    try {
      this.onUpdateValuesChanges(false);
      const newLaborItem = this.addNullLaborItem();
      woSection.labors = [...woSection.labors, newLaborItem];
      this.updateSelectedWOSection.emit(woSection);
    } catch (e) {
      this.displayDialogMessageBox(
        true,
        'Unable to add row. Please try again.'
      );
    }
  }

  removeLabor(labor, woSection: WorkOrderSection) {
    try {
      this.onUpdateValuesChanges(false);
      woSection.labors = woSection.labors.filter(
        lab => lab.uniqueId !== labor.uniqueId
      );
      woSection.total = this.updateSectionTotal(woSection);

      this.updateSelectedWOSection.emit(woSection);
    } catch (e) {
      this.displayDialogMessageBox(
        true,
        'Unable to remove row. Please try again.'
      );
    }
  }

  displayDialogMessageBox(visible: boolean = false, message: string = null) {
    this.displayError = visible;
    this.errMessage = message;
  }

  // Adds a null labor item to the work order
  addNullLaborItem() {
    const nullLabor = Labor.create({
      _id: new ObjectID(),
      name: '',
      per: '',
      qty: '',
      price: '',
      subtotal: 0
    });
    return nullLabor;
  }

  // Checks if input is valid
  isValidNumericValue(val: any) {
    return formInputValidation.isItAValidQty(val);
  }

  // Checks if input is valid
  isValidCurrencyValue(val: any) {
    return formInputValidation.isItAValidCurrencyValue(val);
  }

  removeSection() {
    this.removeSectionEvent.next(this.woSection._id);
  }

  // Simple currency validation
  isNaN(val: any) {
    return isNaN(val);
  }

  // Checks if input is valid
  mustIgnorePrice(qty, price) {
    if (+qty && !+price) return false;
    return true;
  }

  // Improves angular performance
  trackByFn(index, item: any) {
    return item._id; // or item.id
  }
}
