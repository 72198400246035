import {NgModule} from "@angular/core";
import { ButtonModule } from 'primeng/button';
import {CsvFilePickerComponent} from "./csv-file-picker.component";

@NgModule({
  imports: [ButtonModule],
  declarations: [CsvFilePickerComponent],
  exports: [CsvFilePickerComponent]
})
export class CsvFilePickerModule {

}
