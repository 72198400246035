import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'contract-section-table',
  templateUrl: './contract-section-table.component.html',
  styleUrls: ['./contract-section-table.component.scss']
})
export class ContractSectionTableComponent implements OnInit {

  @Input() section: any;

  constructor() {}

  ngOnInit() {}

  // Simple currency validation
  isNaN(val: any) {
    return isNaN(val);
  }
}
