import { AlertState } from '@contract-estimator/shared/interfaces';

export const USER_LOGIN = 'LOGIN > USER_LOGIN';
export class UserLogin {
  public type = USER_LOGIN;
  constructor(public payload: any) {}
}

export const AUTHENTICATED_USER_LOGIN = 'LOGIN > AUTHENTICATED_USER_LOGIN';
export class AuthenticatedUserLogin {
  public type = AUTHENTICATED_USER_LOGIN;
  constructor(public payload: any) {}
}

export const UPDATE_LOGIN_STATE = 'LOGIN > UPDATE_LOGIN_STATE';
export class UpdateLoginState {
  readonly type = UPDATE_LOGIN_STATE;
  constructor(public payload: any) {}
}

export const LOGOUT = 'LOGIN > LOGOUT';
export class Logout {
  readonly type = LOGOUT;
  constructor(public payload?: any) {}
}

export const RESET_STORE_STATE = 'LOGIN > RESET_STORE_STATE';
export class ResetStoreState {
  readonly type = RESET_STORE_STATE;
  constructor() {}
}

export const LOGGED_OUT = 'LOGIN > LOGGED_OUT';
export class LoggedOut {
  readonly type = LOGGED_OUT;
  constructor(public payload?: any) {}
}

export const FAILED_LOGIN = 'LOGIN > FAILED_LOGIN';
export class FailedLogin {
  readonly type = FAILED_LOGIN;
  constructor(public payload?: any) {}
}

export const RESET_LOGIN_STATE = 'LOGIN > RESET_LOGIN_STATUS';
export class ResetLoginState {
  readonly type = RESET_LOGIN_STATE;
  constructor(public payload?: any) {}
}

export const REMOVE_REFRESH_TOKEN = 'LOGIN > REMOVE_REFRESH_TOKEN';
export class RemoveRefreshToken {
  readonly type = REMOVE_REFRESH_TOKEN;
  constructor() {}
}

export const UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE =
  'UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE';
export class UpdateUserPrivacyTermsAcceptance {
  readonly type = UPDATE_USER_PRIVACY_TERMS_ACCEPTANCE;
  constructor(public payload: boolean) {}
}

export const SEND_PASSWORD_EMAIL = 'LOGIN > SEND_PASSWORD_EMAIL';
export class SendPasswordEmail {
  readonly type = SEND_PASSWORD_EMAIL;
  constructor(public email: string) {}
}

export const UPDATE_ALERT_STATE = '[login] Update alert state';
export class UpdateAlertState {
  readonly type = UPDATE_ALERT_STATE;
  constructor(public payload: AlertState) {}
}

export const RESET_ALERT_STATE = '[login] Reset alert state';
export class ResetAlertState {
  readonly type = RESET_ALERT_STATE;
  constructor() {}
}

export type Actions =
  | AuthenticatedUserLogin
  | FailedLogin
  | Logout
  | LoggedOut
  | ResetLoginState
  | UpdateLoginState
  | UserLogin
  | RemoveRefreshToken
  | UpdateUserPrivacyTermsAcceptance
  | SendPasswordEmail
  | UpdateAlertState
  | ResetAlertState;
