import {StringFormatter} from "apps/contract-estimator/src/app/shared/utils/string-formatter/string-formatter.module";

export class TradeActionMenuItem {
  constructor(
    public label: string,
    public icon: string,
    public command: any,
  ) {
  }

  static fromTrade(
    actionName: string,
    orderName: string,
    icon: string,
    callback: any
  ) {
    const tradeName = new StringFormatter().trimString(`${actionName} ${orderName}`, 20)
    return new TradeActionMenuItem(
      tradeName,
      icon,
      callback
    )
  }
}
