
export class QueryParamsMenu {
  constructor(
    public redirectTo?: string[],
    public preventNavigation?: boolean
  ) {
  }
}


