import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SyncValidatorsService } from './sync-validators/sync-validators.service';
import { FileUploaderService } from './file-uploader/file-uploader.service';
import {PreviewService} from "./preview-invoice/preview.service";
import {InvoiceService} from "./invoice/invoice.service";
import { BrowserDetectorService } from './browser-detector/browser-detector.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    SyncValidatorsService,
    FileUploaderService,
    InvoiceService,
    PreviewService,
    BrowserDetectorService
  ],
})
export class SharedAngularProvidersModule {}
