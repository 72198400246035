import { AgreementRecipient } from './../agreement-recipient/agreement-recipient.class';
import { ActualJobCostsTradeChildren, EstimatedJobCostsTradeChildren } from '.';

const ownerExample = {
  name: 'Jeremy Bosco',
  addressLine1: '123 avenue park city',
  city: 'Pocatello',
  state: 'ID',
  zipCode: 1234,
  phoneNumber: '890-809-3333',
  altPhoneNumber: '890-890-1234',
  email: 'alexescamore@gmail.com',
  altEmail: undefined,
  addressLine2: 'Pocatello, ID 1234'
};

const salesRepExample = {
  addressLine1: undefined,
  addressLine2: '',
  altEmail: undefined,
  altPhoneNumber: undefined,
  city: undefined,
  email: 'eliteroofs@gmail.com',
  name: 'Jeremy Bosco',
  phoneNumber: '502-643-4333',
  state: undefined,
  zipCode: undefined
};

export class JobCosts {
  referredBySalesRep: boolean;
  commissionPaymentStatus: string;
  owner: AgreementRecipient;
  salesRep: AgreementRecipient;
  estimated: EstimatedJobCostsTradeChildren;
  actual: ActualJobCostsTradeChildren;
}
