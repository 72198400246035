import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import {AuthService} from "@auth0/auth0-angular";
import {AuthenticatedUserInformation} from "@contract-estimator/shared/enums";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    public loginService: LoginService,
    public auth: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.user$.pipe(
      mergeMap((profile) => {
        // Adds user email to req headers to be used in api middleware
        const newRequest = req.clone({
                headers: req.headers
                  .set(AuthenticatedUserInformation.EMAIL, `${profile.email}`)
        });
        return next.handle(newRequest)
      })
    );
  }
}
