export const TOTAL_SUMMARY_FIELD_DISPLAY_NAMES = {
  sales: 'Original Scope Sales',
  additionalScopeSales: 'Additional Scope Sales',
  totalSales: 'Equals Total Sales',
  additionalScopeCosts: 'Less Additional Scope Costs',
  overhead: 'Less Overhead Costs',
  advertising: 'Less Advertising Costs',
  workOrderTotal: 'Less Work Order Costs',
  purchaseOrderTotal: 'Less Purchase Order Costs',
  miscellaneousCost: 'Less Miscellaneous Costs',
  profit: 'Equals Profit',
  salesRepCommission: 'Sales Rep Commission',
  managerCommission: 'Manager Commission',
  netProfit: 'Equals Net Profit'
};

export const TOTAL_SUMMARY_FIELD_INDEXES = {
  sales: 0,
  additionalScopeSales: 1,
  totalSales: 2,
  additionalScopeCosts: 3,
  workOrderTotal: 4,
  purchaseOrderTotal: 5,
  miscellaneousCost: 6,
  advertising: 7,
  overhead: 8,
  profit: 9,
  salesRepCommission: 10,
  managerCommission: 11,
  netProfit: 12
};


export const ESTIMATED_SUMMARY_FIELD_DISPLAY_NAMES = {
  sales: 'Sales',
  overhead: 'Less Overhead Costs',
  advertising: 'Less Advertising Costs',
  workOrderTotal: 'Less Work Order Costs',
  purchaseOrderTotal: 'Less Purchase Order Costs',
  miscellaneousCost: 'Less Miscellaneous Costs',
  profit: 'Equals Profit',
  salesRepCommission: 'Sales Rep Commission',
  managerCommission: 'Manager Commission',
  netProfit: 'Equals Net Profit'
};

export const ESTIMATED_SUMMARY_FIELD_INDEXES = {
  sales: 0,
  workOrderTotal: 1,
  purchaseOrderTotal: 2,
  miscellaneousCost: 3,
  advertising: 4,
  overhead: 5,
  profit: 6,
  salesRepCommission: 7,
  managerCommission: 8,
  netProfit: 9
};
