import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/stores.reducer';
import { storesAdapter } from '../reducers/stores.reducer';
import * as selectorFunctions from './selector.functions';
import { isEmpty } from 'lodash';
import {getStoreId} from "@contract-estimator/shared/router/data-access";

export const getStoresState = createFeatureSelector<State>('stores');

export const {
  selectEntities: selectStoresDictionary,
  selectAll: selectStoresArray
} = storesAdapter.getSelectors(getStoresState);

export const _selectedStoreId = createSelector(
  getStoresState,
  (state: State) => {
    return state.selectedId;
  }
);

export const getStoresNameAndId = createSelector(
  selectStoresArray,
  (stores: any) => {
    try {
      if (isEmpty(stores)) return [];
      const newSubs = selectorFunctions.composeStoresArrayWithIdAndName(stores);
      return newSubs;
    } catch (error) {
      return stores;
    }
  }
);


export const routerOrEntityStateStoreId = createSelector(
  getStoreId,
  _selectedStoreId,
  (_routerParamStoreId, _entityStateStoreId) => {
    if(_routerParamStoreId) return _routerParamStoreId;
    if(_entityStateStoreId) return _entityStateStoreId;
    return null;
  }
)

export const selectedStore = createSelector(
  routerOrEntityStateStoreId,
  selectStoresDictionary,
  (selectedId, entities) => selectedId && entities[selectedId]
)

export const storeShowroomStores = createSelector(
  selectStoresArray,
  (stores) => {

  }
)


export const storesLoading = createSelector(
  getStoresState,
  (state: State) => {
    return state && state.loading;
  }
);

export const storesError = createSelector(
  getStoresState,
  (state: State) => {
    return state && state.error;
  }
)
