import {MenuItem} from "primeng/api";
import {MenuPayload} from "apps/contract-estimator/src/app/shared/models/estimate-menu/estimate-menu.class";
import {TradeMenu} from "../shared/trade-menu.class";
import {TradeActionMenuItem} from "../shared/trade-action-menu-item.class";
import {OpenContractPDFPayload} from "@contract-estimator/shared/classes";

export class OriginalScopeMenu implements MenuItem{
  constructor(
    public label: string,
    public items: MenuItem[],
    public expanded?: boolean,
  ) {}

  static from (payload: MenuPayload, callback: any, expanded: boolean) {
    const tradeMenus = TradeMenu.fromOriginalScopeArray(payload, callback);
    const openContractPDF = new OpenContractPDFPayload();
    const contractMenuItem = new TradeActionMenuItem(
      'CONTRACT',
      null,
      callback(openContractPDF)
    )
    const items = [
      ...tradeMenus,
      // contractMenuItem
    ]
    return new OriginalScopeMenu(
      "ORIGINAL SCOPE",
      [
        ...items
      ],
      expanded,
    )
  }
}

