import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLeadStatusComponent } from './components/change-lead-status/change-lead-status.component';
import { NotesEditorComponent } from './components/notes-editor/notes-editor.component';
import { PipelineTitleComponent } from './components/pipeline-title/pipeline-title.component';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { FormsModule } from '@angular/forms';
import { ChangeSalesRepComponent } from './components/change-sales-rep/change-sales-rep.component';

@NgModule({
  declarations: [
    ChangeLeadStatusComponent,
    NotesEditorComponent,
    PipelineTitleComponent,
    ChangeSalesRepComponent
  ],
  imports: [CommonModule, UIComponentsModule, FormsModule],
  exports: [
    CommonModule,
    UIComponentsModule,
    ChangeLeadStatusComponent,
    NotesEditorComponent,
    PipelineTitleComponent,
    ChangeSalesRepComponent
  ]
})
export class PipelineSharedModule {}
