import { NgModule } from '@angular/core';
import { WeatherComponent } from './containers/weather.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import {CommonModule} from "@angular/common";
import {UIComponentsModule} from "@contract-estimator/shared/angular/components";

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatMenuModule,
    UIComponentsModule
  ],
  declarations: [
    WeatherComponent
  ],
  exports: [WeatherComponent]
})
export class ForecastFeatureModule { }
