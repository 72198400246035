import { Injectable } from '@angular/core';
import { ServerUrl } from 'apps/contract-estimator/src/app/config/server-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrivacyTermsService {
  serverUrl = new ServerUrl().getUrl();

  constructor(public http: HttpClient) {}

  // Updates user privacy term field
  updateUserPrivacyTermsAcceptance() {
    return this.http.put(this.serverUrl + '/auth/privacy-terms', {
      acceptedPrivacyTerms: true
    });
  }

  // Gets current privacy term status
  getCurrentUserPrivacyTermAcceptance() {
    return this.http.get(this.serverUrl + '/auth/privacy-terms/get/status');
  }

  /* End of http calls */
}
